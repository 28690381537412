import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "@mui/material";
import EmailPreview from "src/pages/emailTemplates/EmailPreview";
import { setPreviewEmail } from "src/store/reducers/emails-slice";
import { checkIfReviewed } from "src/store/actions/reviews";
import { review } from "src/store/actions/reviews";
import { clearEmailData } from "src/store/reducers/emails-slice";
import { editorActions } from "src/store/reducers/editor-slice";
const PreviewEmail = (props) => {
  const type = "email"
  const dispatch = useDispatch();
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [isLoadingDeclined, setIsLoadingDeclined] = useState(false);
  const { emailId, isOpen, reviewId, isReviewed, versionId } = useSelector(
    (state) => state.emails.preview
  );
  const approveOrDecline = (status) => {
    status ? setIsLoadingApprove(true) : setIsLoadingDeclined(true);
    if (reviewId) {
      const data = { status };
      dispatch(review({ id: reviewId, data, type })).then((res) =>{
        status ? setIsLoadingApprove(false) : setIsLoadingDeclined(false)
      })
    }
  };
  useEffect(() => {
    if (isOpen) {
      clearData();
    }
  }, [isOpen]);

  

  const handleClose = () => {
    dispatch(setPreviewEmail({ emailId, isOpen: false }));
    clearData();
  };

  useEffect(() => {
    if (reviewId) {
      dispatch(checkIfReviewed({id:reviewId , type}));
    }
  }, [reviewId]);

  // To clear editor's data
  const clearData = () => {
    dispatch(clearEmailData());
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          height: "100%",
        },
      }}
      fullScreen
      fullWidth
    >
      <EmailPreview
        emailId={emailId}
        handleClose={handleClose}
        versionId={versionId}
        isReviewed={isReviewed}
        reviewEmail={approveOrDecline}
        isLoadingApprove={isLoadingApprove}
        isLoadingDeclined={isLoadingDeclined}
      />
    </Dialog>
  );
};

export default PreviewEmail;
