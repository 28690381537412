import React, { useEffect, useState, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//MUI Componenets
import { Grid } from "@mui/material";

import Content from "src/layouts/Content";

import PresentationCard from "src/components/presentations/PresentationCard";
import LandingPageCard from "src/components/microsites/LandingPageCard";
import EmailCard from "src/components/emails/EmailsCard";

//Dialogs
import LandingPageDialogs from "src/components/dialogs/microsites/MicrositesMyLibrary-Dialogs";
import PresentationDialog from "src/components/dialogs/presentations/MyLibrary-Dialogs";
import EmailDialog from "src/components/dialogs/emails/EmailMyLibrary-Dialogs.jsx";

import "../../styles/GlobalLibrary.scss";
import { globalSearch } from "src/store/actions/globalSearch";
const GlobalLibrary = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { searchedResults } = useSelector((state) => state.globalSearch);

  const [presentationDetails, setPresentationDetails] = useState({});
  const [landingPageDetails, setLandingPageDetails] = useState({});
  const [emailDetails, setEmailDetails] = useState({});

  const [presentationId, setPresentationId] = useState("");
  const [landingPageId, setLandingPageId] = useState("");
  const [emailId, setEmailId] = useState("");

  //Dialogs State
  const [presenetationDialog, setPresentationDialog] = useState({});
  const [landingPageDialog, setLandingPageDialog] = useState({});
  const [emailDialog, setEmailDialog] = useState({});

  useEffect(() => {
    const params = {};

    const language = searchParams.get("Language");
    const country = searchParams.get("Country");
    const keyword = searchParams.get("search");

    const data = {
      language,
      country,
      keyword,
    };

    dispatch(
      globalSearch({
        data,
        queryParams: { limit: 10, skip: 0 },
      })
    );
  }, []);

  //email info
  const showDetailsEmail = useCallback((email) => {
    setEmailDialog({ info: true });
    setEmailDetails(email);
  }, []);

  //presentation info
  const showDetailsPresentation = useCallback((presenetation) => {
    setPresentationDialog({ info: true });
    setPresentationDetails(presenetation);
  }, []);

  //presentation info
  const showDetailsLandingPage = useCallback((landingPage) => {
    setLandingPageDialog({ info: true });
    setLandingPageDetails(landingPage);
  }, []);

  //open dialogs presentation
  const openDialogPresentation = useCallback((id, name) => {
    setPresentationId(id);
    setPresentationDialog({ [name]: true });
  }, []);

  //open dialogs email
  const openDialogEmail = useCallback((id, name) => {
    setEmailId(id);
    setEmailDialog({ [name]: true });
  }, []);

  //open dialogs landing
  const openDialogLandingPAge = useCallback((id, name) => {
    setLandingPageId(id);
    setLandingPageDialog({ [name]: true });
  }, []);

  return (
    <>
      {/* Dialogs */}
      <PresentationDialog
        dialog={presenetationDialog}
        presentation={presentationDetails}
        presentationId={presentationId}
        onClose={() => setPresentationDialog({})}
      />

      <EmailDialog
        onClose={() => setEmailDialog({})}
        dialog={emailDialog}
        emailId={emailId}
        email={emailDetails}
      />

      <LandingPageDialogs
        onClose={() => setLandingPageDialog({})}
        dialog={landingPageDialog}
        micrositeId={landingPageId}
        microsite={landingPageDetails}
      />
      {/* Dialogs */}

      <Content title="Search Projects">
        <div className="globalLibrary">
          <div className="name">Presentation</div>
          <Grid
            container
            spacing={3}
            className={
              searchedResults?.presentation?.data.length === 0 ? "data" : ""
            }
          >
            {searchedResults?.presentation?.data.length ? (
              searchedResults?.presentation?.data.map((presentation) => {
                return (
                  <PresentationCard
                    key={presentation._id}
                    presentation={presentation}
                    role="owner"
                    showDetails={showDetailsPresentation}
                    openDialog={openDialogPresentation}
                  />
                );
              })
            ) : (
              <div className="noRecord">No Record Available!</div>
            )}
          </Grid>

          <div className="name">Email Templates</div>
          <Grid
            container
            spacing={3}
            className={searchedResults?.email?.data.length === 0 ? "data" : ""}
          >
            {searchedResults?.email?.data.length ? (
              searchedResults?.email?.data.map((email) => {
                return (
                  <EmailCard
                    key={email._id}
                    email={email}
                    showDetails={showDetailsEmail}
                    openDialog={openDialogEmail}
                    role="owner"
                  />
                );
              })
            ) : (
              <div className="noRecord">No Record Available!</div>
            )}
          </Grid>

          <div className="name">Microsites</div>
          <Grid
            container
            spacing={3}
            className={
              searchedResults?.landingPage?.data.length === 0 ? "data" : ""
            }
          >
            {searchedResults?.landingPage?.data.length ? (
              searchedResults?.landingPage?.data.map((landingPage) => {
                return (
                  <LandingPageCard
                    key={landingPage._id}
                    microsite={landingPage}
                    showDetails={showDetailsLandingPage}
                    openDialog={openDialogLandingPAge}
                    role="owner"
                  />
                );
              })
            ) : (
              <div className="noRecord">No Record Available!</div>
            )}
          </Grid>
        </div>
      </Content>
    </>
  );
};
export default GlobalLibrary;
