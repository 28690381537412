import { createSlice } from "@reduxjs/toolkit";
import { salesForce } from "../actions/sf";

const initialState = {
  accessToken: null,
  isAuthenticated: false,
};

const salesforceSlice = createSlice({
  name: "salesforceSlice",
  initialState,
  extraReducers: (builder) => {
    //Saving Access Token in State
    // builder.addCase(salesForce.fulfilled, (state, action) => {
    //   state.accessToken = action.payload.access_token;
    //   state.isAuthenticated = true;
    //   rage.setItem("sf-token", action.payload.access_token);
    // });
  },
});

export default salesforceSlice.reducer;
