import React, { useEffect, useLayoutEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setPresentationAccess } from "../../store/reducers/presentations-slice";
import { canSendRequest } from "src/services/utils";
import { getPresentaionById } from "src/store/actions/presentations";

const AccessControl = ({ children, component }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { roles } = useSelector((state) => state.roles);
  const { presentationAccess, presentation, config } = useSelector(
    (state) => state.presentations
  );

  // API call to get the presentation
  useLayoutEffect(() => {
    if (canSendRequest(config, id)) {
      dispatch(getPresentaionById(id));
    }
  }, [presentation]);

  //Navigate to my-library if user is not authorized to access the presentation
  useEffect(() => {
    if (!presentationAccess) {
      dispatch(setPresentationAccess(true));
      navigate("/presentations/my-library");
    }
  }, [presentationAccess, presentation]);

  return (
    <>
      {presentation._id === id &&
        (roles?.[presentation.role]?.[component] ? (
          children
        ) : (
          <Navigate to={-1} />
        ))}
    </>
  );
};

export default React.memo(AccessControl);
