import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReviewedPresentations } from "src/store/actions/presentations";

const ReviewDropdown = ({ updateHistory, undoRedoChange, anchor }) => {
  const {
    reviewed: { list },
  } = useSelector((state) => state.presentations);

  const dispatch = useDispatch();
  const [selectedReviewd, setSelectedReviewd] = useState("");
  const [loading, setLoading] = useState(false);

  const getURL = (id) => {
    return window.location.origin.toString() + `/preview=[${id}]`;
  };

  const getIdFromUrl = (url) => {
    const oreValue = url?.split("preview=[")[1];
    return oreValue?.substring(oreValue.length - 1, -1);
  };

  const isReviewURL = (url) => {
    return url?.includes("preview=[");
  };

  useEffect(() => {
    if (!list?.length) {
      setLoading(true);
      dispatch(getReviewedPresentations()).then(() => {
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    const selectedId = getIdFromUrl(anchor?.href);
    setSelectedReviewd(selectedId);
  }, [anchor]);

  useEffect(() => {
    if (list.length) {
      if (!selectedReviewd && !isReviewURL(anchor?.href)) {
        const id = list[0]?._id;
        setSelectedReviewd(id);
        onChangeHandler(id);
      }
    }
  }, [list]);

  const onChangeHandler = (value) => {
    const url = getURL(value);
    if (anchor) {
      anchor.href = url;
    }
    setSelectedReviewd(value);
    updateHistory();
  };

  return (
    <>
      {loading ? (
        <label>Loading...</label>
      ) : list.length ? (
        <FormControl>
          <Select
            name="type"
            onChange={(e) => {
              onChangeHandler(e.target.value);
            }}
            value={selectedReviewd ? selectedReviewd : ""}
            classes={{
              select: "select_box_input",
              icon: "select_box_icon",
            }}
          >
            {list?.map((currValue) => (
              <MenuItem value={currValue._id} key={currValue._id}>
                {currValue.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <label className="error">There is not any reviewed presentaion</label>
      )}
    </>
  );
};

export default ReviewDropdown;
