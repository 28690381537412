import { useNavigate } from "react-router-dom";
import Content from "src/layouts/Content";
import { Grid } from "@mui/material";
import CampaignForm from "./CampaignForm";

export default function CreateCampaign() {
  const navigate = useNavigate();

  const onFinshHandler = () => {
    navigate("/campaign/list");
  };

  return (
    <Grid container gap={4}>
      <Grid item xs={12}>
        <Content classes="compaign-stepper" title="Create Campaign">
          <CampaignForm onFinish={onFinshHandler} />
        </Content>
      </Grid>
    </Grid>
  );
}
