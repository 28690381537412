import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../components/ui/Logo";
import LogoLarge from "../assets/images/logo.png";
import Copyright from "../components/ui/Copyright";
import { activateAccount } from "src/store/actions/auth";
import CreatePassword from "src/components/login/CreatePassword";
import { Grid, Box, Typography } from "@mui/material";
import { uiActions } from "src/store/reducers/ui-slice";
import { useSearchParams, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "../styles/ActivateAccount.scss";

const ActivateAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { status, tokenStatus } = useSelector(
    (state) => state.auth.accountActive
  );
  const [param] = useSearchParams(useLocation().pathname);
  const token = param.get("token");

  // const submitHandler = (e) => {
  //   e.preventDefault();
  //   const [pass, confirmPass] = new FormData(e.target);
  //   const [password, confirmPassword] = [pass[1], confirmPass[1]];
  //   if (password == confirmPassword) {
  //     if (password.length >= 8) {
  //       if (token) {
  //         dispatch(activateAccount({ password, confirmPassword, token }));
  //       }
  //     } else {
  //       dispatch(
  //         uiActions.showNotification({
  //           status: "info",
  //           title: "short password",
  //           message: "Password must have 8 or more digits in it",
  //         })
  //       );
  //     }
  //   } else {
  //     dispatch(
  //       uiActions.showNotification({
  //         status: "info",
  //         title: "Password not same",
  //         message: "Password must be same as confirm password",
  //       })
  //     );
  //   }
  // };

  useEffect(() => {
    if (status && token) {
      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Your Account has been activated",
        })
      );
      navigate("/login", { replace: true });
    }
  }, [status]);

  useEffect(() => {
    if (tokenStatus) {
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Your Token has been Expired",
        })
      );
      navigate("/generate-token", { replace: true });
    }
  }, [tokenStatus]);

  return (
    <Grid className="login-page" spacing={2} container>
      <Grid item md={7}>
        <Box id="login-background"></Box>
      </Grid>
      <Grid
        item
        md={5}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Box className="login-form-container">
          <Logo logoSrc={LogoLarge} />
          <Typography
            component="h1"
            variant="h1"
            className="login-heading"
            sx={{
              marginBottom: "30px !important",
            }}
          >
            Activate your Account
          </Typography>

          <CreatePassword token={token} />
          <Copyright />
        </Box>
      </Grid>
    </Grid>
  );
};
export default ActivateAccount;
