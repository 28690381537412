import React, { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import Main from "./Main";

import { ImCross } from "react-icons/im";
import { FiInfo } from "react-icons/fi";
import "../../styles/Guide.scss";

const shortCuts = [
  {
    keys: ["Del"],
    title: "Delete Layer",
    description:
      "First Select the Layer and then press Del/Delete Button to delete that layer. press Enter on popup to confirm it.",
  },
  {
    keys: ["Alt", "O"],
    title: "Select Parent",
    description:
      "Select the Parent of the current selected component, press again to move further up in Hierachy",
  },
  {
    keys: ["Alt", "I"],
    title: "Select Child",
    description:
      "Select the Child of the current selected component, press again to move further down in Hierachy",
  },
  {
    keys: ["Ctrl", "Z"],
    title: "Undo",
    description: "This will undo the changes you made on the editor.",
  },
  {
    keys: ["Ctrl", "Y"],
    title: "Redo",
    description: "This will bring back the changes that you discarded.",
  },
];

const Guide = (props) => {
  const { open, closeHandler } = props;

  const handleClose = () => {
    closeHandler();
  };

  return (
    <Main
      background="white"
      modalWidth="sm"
      isOpen={open}
      className="shortKeyModal"
      handleClose={handleClose}
    >
      <div className="keyHeader flex-between">
        <div className="heading">
          <h4>ShortKeys</h4>
        </div>
        <div className="closeBtn">
          <IconButton onClick={handleClose}>
            <ImCross />
          </IconButton>
        </div>
      </div>
      {shortCuts.map(({ keys, title, description }) => {
        return (
          <div className="shortKeyRow" key={title}>
            <div className="keyTitle">
              <Tooltip title={description} arrow>
                <IconButton color="primary">
                  <FiInfo />
                </IconButton>
              </Tooltip>
              <span>{title}</span>
            </div>
            <div className="keys">
              {keys.map((key, index) => {
                const op =
                  index != keys.length - 1 ? (
                    <React.Fragment key={key + index}>
                      <span className="button">{key}</span>
                      <span className="op">+</span>
                    </React.Fragment>
                  ) : (
                    <span key={key + index} className="button">
                      {key}
                    </span>
                  );
                return op;
              })}
            </div>
          </div>
        );
      })}
    </Main>
  );
};

export default Guide;
