import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  IconButton,
  Tooltip,
  Chip,
} from "@mui/material";

//Icons for status field
import { AiOutlineClose } from "react-icons/ai";
import { BsCheck2 } from "react-icons/bs";
import { MdDelete, MdEdit, MdLock } from "react-icons/md";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import StatusChip from "src/components/ui/StatusChip";
import { IoMdLock, IoMdUnlock } from "react-icons/io";
import SwitchButton from "src/components/ui/formElements/SwitchButton";
import { updateUser } from "src/store/actions/users";
import { useDispatch } from "react-redux";
const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const Tables = ({
  rows: usersData,
  headCells,
  initialRows = 10,
  onDelete = () => {},
  companyId
}) => {
  const dispatch = useDispatch();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(usersData);
  const [rowsPerPage, setRowsPerPage] = useState(initialRows);
  const navigate = useNavigate();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    if (isAsc) {
      setOrder("desc");
    } else if (order === "desc") {
      setOrderBy("");
      setOrder("asc");
    } else {
      setOrderBy(property);
      setOrder("asc");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const editUser = (id) => {
    navigate("edit/" + id);
  };

  const handleChangeStatus = (row) => {
    const status = row.status === "active" ? "in-active" : "active";
    const data = { status: status };
  
    // Dispatch an action to update the user status on the server
    dispatch(updateUser({ data, id: row._id }))
      .then(() => {
        const updatedRows = rows.map(newRow =>
          newRow._id === row._id ? { ...newRow, status: status } : newRow
        );
        console.log(updatedRows)
        setRows(updatedRows);
      })
      .catch(error => {
        console.error('Failed to update user status:', error);
      });
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box className="users-table">
      <TableContainer>
        <Table aria-labelledby="tableTitle">
          {/* Table Header Starts*/}
          <TableHead>
            <TableRow>
              {headCells.map((headCell, index, array) => (
                <TableCell
                  className="table-cell"
                  sx={{ textOverflow: "ellipsis" }}
                  key={headCell.id}
                  padding="normal"
                  align="left"
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.sort ? (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  ) : (
                    headCell.label
                  )}
                </TableCell>
              ))}

              <TableCell
                className="table-cell"
                sx={{ textOverflow: "ellipsis" }}
                align="right"
                padding="normal"
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          {/* Table Header Endes*/}

          {/* Table Body Starts*/}
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                // console.log('row', row)
                return (
                  <TableRow padding="normal" hover tabIndex={-1} key={row._id}>
                    {headCells.map(({ id }, index, array) => {
                      return (
                        <TableCell
                          className="table-cell"
                          key={index}
                          align="left"
                        >
                          {id === "status" ? (
                            row[id] === "pending" ? (
                              <StatusChip label='un-Verified' status={row[id]} />
                            ) : (
                              <SwitchButton
                                checked={row["status"] === "active"}
                                switchHandler={() => {
                                  handleChangeStatus(row);
                                }}
                              />
                            )
                          ) : (
                            row[id]
                          )}
                        </TableCell>
                      );
                    })}
                    <TableCell
                      className="table-cell"
                      sx={{ paddingRight: 0 }}
                      align="right"
                    >
                      <div className="actions-icons">
                        <Tooltip title="Deactive User" arrow>
                          {/* <IconButton size="small" onClick={() => onDelete(row)}>
                          {row.status === 'inActive' ? (
                            <IoMdLock></IoMdLock>
                          ) : (
                            <IoMdUnlock></IoMdUnlock>
                          )}
                        </IconButton> */}
                        </Tooltip>
                        <Tooltip title="Edit" arrow>
                          <IconButton
                            size="small"
                            onClick={() => editUser(row._id)}
                          >
                            <MdEdit />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow>
                <TableCell className="table-cell" colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          {/* Table Body Starts*/}
        </Table>
      </TableContainer>
      {/* Table Pagination*/}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, { label: "All", value: rows.length }]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default Tables;
