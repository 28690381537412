import React from "react";
import { useDispatch } from "react-redux";
import { clearPresentationData } from "src/store/reducers/presentations-slice";
import { Grid, Box, Button, Typography, CircularProgress } from "@mui/material";
import Main from "./Main";
import "../../styles/Preview.scss";
import { downloadbyUrl } from "src/services/utils";

const PreviewElement = ({ format, src }) => {
  if(format){
    format = format.toLowerCase()
  }
  const formats = {
    jpeg: "image",
    jpg: "image",
    png: "image",
    jfif: "image",
    mp3: "audio",
    ogg: "audio",
    wav: "audio",
    mp4: "video",
  };

  if (formats[format] === "image") {
    return <img src={src} />;
  } else if (formats[format] === "audio") {
    return (
      <audio controls>
        <source src={src} />
      </audio>
    );
  } else if (formats[format] === "video") {
    return (
      <video width="500" controls>
        <source src={src} />
      </video>
    );
  }

  return (
    <Box>
      <Typography variant="h2" m={2}>
        Preview Not Available
      </Typography>
      <Button variant="contained" onClick={() => downloadbyUrl(src)}>
        Download File
      </Button>
    </Box>
  );
};

const Preview = (props) => {
  const { show, src, close, reviewAsset, isReview, isReviewed, format,isLoadingApprove,isLoadingDeclined } = props;
  const dispatch = useDispatch();

  const closeDialog = () => {
    close();
    dispatch(clearPresentationData({ clearAll: true }));
  };

  return (
    <Main className="previewDialog" isOpen={show} handleClose={closeDialog}>
      <Box className="dialogBody">
        {isReview && !isReviewed && (
          <Grid container>
            <Grid item xs={12} md={12} px={2} py={2} justifyContent="center">
              <Button
                variant="text"
                color="success"
                size="large"
                onClick={() => reviewAsset(true,'Approve')}
                disabled={isLoadingApprove}
              >
                {isLoadingApprove ? (
              <CircularProgress size={20} sx={{ marginLeft: 1 }} />
            ) : (
              "Approve"
            )}
              </Button>
              <Button
                variant="text"
                color="error"
                size="large"
                onClick={() => reviewAsset(false,'Declined')}
                disabled={isLoadingDeclined}
              >
                 {isLoadingDeclined ? (
              <CircularProgress size={20} sx={{ marginLeft: 1 }} />
            ) : (
              "Declined"
            )}
              </Button>
            </Grid>
          </Grid>
        )}

        <PreviewElement src={src} format={format} />
      </Box>
    </Main>
  );
};

export default Preview;
