import { createSlice, current } from "@reduxjs/toolkit";
import {
  fetchPresentations,
  createPresentation,
  clonePresentation,
  getPresentaionById,
  updatePresentationSetting,
  fetchSlideTemplates,
  createSlide,
  createChapter,
  deleteChapter,
  fetchTemplates,
  updateSlideSetting,
  updateChapterSetting,
  downloadSource,
  downloadVersionSource,
  updateStructure,
  exportPresentation,
  saveSlide,
  getSlide,
  importPresentation,
  deletePresentation,
  deleteSlide,
  fetchCategoryTemplates,
  uploadNewVersion,
  getReviewedPresentations,
  previewPresentation,
  previewPresentationHTML,
  downloadLink,
  cloneSlide,
} from "../actions/presentations";
import { checkIfReviewed, review, sendForReview } from "../actions/reviews";
import { getReviewedPre, getSFPresentations } from "../actions/sf";
import {
  checkIsLock,
  downloadbyUrl,
  downloadPdfByImages,
} from "src/services/utils";
import { removePx } from "src/utils/Utils";
import { useDevices } from "src/hooks/useDevices";
// an initail obj for presentation
const presentation = {
  name: "",
  country: "",
  language: "",
  targetDevice: "",
  chapters: [],
};

const initialState = {
  list: [],
  sfPresentations: [],
  reviewed: {
    count: 0,
    list: [],
  },
  count: 0,
  presentation,
  searchedResults: [],
  allData: [],
  oldChapters: [],
  presentationAccess: true,
  updatedStructure: {},
  shared: [],
  sharedWith: [],
  templates: [],
  templatesCategories: [],
  templatesMsg: "Select Target Device",
  loadingMsg: "Loading...",
  slideTemplates: [],
  editorHtmlData: null,
  currentSharedData: null,
  presentationSlides: [],
  config: { id: null, requestTime: null },
  preview: { presentationId: "", isOpen: false, isReviewed: true },
  downloadLink: "",
};

const getSlidesFromPresentation = (presentation) => {
  // 1.Extracting Slide with it's chapter name
  const chapterArr = presentation.chapters.map((chapter) => {
    if (chapter?.slides && chapter.status) {
      return chapter.slides.map((slideObj) => ({
        ...slideObj,
        chapterName: chapter.name,
      }));
    }
  });
  //2.then pushing required and structured data..
  const slides = [];
  chapterArr?.forEach((slidesArr) => {
    slidesArr?.forEach((slide) => {
      slides.push({
        id: slide._id,
        thumbnail: slide.thumbnail,
        chapterName: slide.chapterName,
        name: slide.name,
        fileId: slide.fileId
      });
    });
  });
  return slides;
};

const presentationsSlice = createSlice({
  name: "presentationsSlice",
  initialState,
  reducers: {
    setPresentationAccess(state, action) {
      state.presentationAccess = action.payload;
    },
    setSharedWith(state, action) {
      state.sharedWith = action.payload;
    },
    setPreiewPresentation(state, action) {
      state.preview = { ...action.payload, isReviewed: true };
    },
    clearPresentationData(state, action) {
      state.editorHtmlData = null;
      state.currentSharedData = null;
      state.reviewed = initialState.reviewed;
      if (action.payload?.clearAll) {
        state.presentation = presentation;
      }
    },
    setUpdatedStruture(state, action) {
      state.oldChapters = JSON.parse(
        JSON.stringify(state.presentation.chapters)
      );
      state.presentation.chapters = action.payload;
    },
    setTemplates(state, action) {
      const { templates, msg } = action.payload;
      if (templates) state.templates = templates;
      if (msg) state.templatesMsg = msg;
    },
    setPresentationStatus(state, action) {
      const index = state.list.findIndex(
        (presentation) => presentation._id === action.payload.id
      );
      if (index !== -1) {
        state.list[index].status = action.payload.status;
        if (action.payload.options) {
          state.list[index].isLock = action.payload.options.isLock;
        }
      }
    },
    clearDownloadLink(state, action) {
      state.downloadLink = "";
    },
  },
  extraReducers: (builder) => {
    // Fetch Presentation : Pending
    builder.addCase(fetchPresentations.pending, (state, action) => {
      state.presentation = presentation;
      (state.config = { id: null, requestTime: null }),
        (state.loadingMsg = "Loading ....");
    });

    // Fetch Presentation : Fulfilled
    builder.addCase(fetchPresentations.fulfilled, (state, action) => {
      const { response, count } = action.payload;
      const presentations = response.map((presentation) => {
        presentation.isLock = checkIsLock(presentation.status);
        return presentation;
      });
      state.count = count;
      state.list = presentations;
      if (response.length === 0) {
        state.loadingMsg = "No Presentations Found !";
      } else {
        state.loadingMsg = "";
      }
    });

    builder.addCase(getReviewedPresentations.fulfilled, (state, action) => {
      const { response, count } = action.payload;
      state.reviewed.count = count;
      state.reviewed.list = response;
    });

    // Fetch Presentation : Rejected
    builder.addCase(fetchPresentations.rejected, (state, action) => {
      state.loadingMsg = "An Error Occured !";
    });

    builder.addCase(importPresentation.fulfilled, (state, action) => {
      state.list.unshift(action.payload);
      state.loadingMsg = "";
      if (state.count >= 12) {
        state.list.pop();
      }
      state.count++;
    });

    // Fetch Templates
    builder.addCase(fetchTemplates.pending, (state, action) => {
      state.templates = [];
      state.templatesMsg = "Loading . . . ";
    });

    // Fetch Templates
    builder.addCase(fetchTemplates.fulfilled, (state, action) => {
      if (!action.payload.length) {
        state.templatesMsg = "No Templates Found";
      } else {
        state.templatesMsg = "";
      }

      state.templates = action.payload;
    });

    // Create Presentations
    builder.addCase(createPresentation.fulfilled, (state, action) => {
      if (state.count >= 12) {
        state.list.pop();
      }
      state.list.unshift(action.payload);
      state.loadingMsg = "";
      state.count++;
    });

    // Clone Presentations
    builder.addCase(clonePresentation.fulfilled, (state, action) => {
      state.list.unshift(action.payload);
      state.loadingMsg = "";
      if (state.count >= 12) {
        state.list.pop();
      }
      state.count++;
      state.presentationSlides = getSlidesFromPresentation(action.payload);
    });

    // Get Presentation by id
    builder.addCase(getPresentaionById.fulfilled, (state, action) => {
      const presentation = action.payload;
      state.presentation = presentation;
      state.updatedStructure = presentation;
      //TODO : isLock is temporary added from redux will be done from BE Soon.
      state.presentation.isLock = checkIsLock(presentation.status);
      //Temp Code Ends Here
      console.log('presentation', presentation)
      state.presentationSlides = getSlidesFromPresentation(presentation);
      state.config = {
        requestTime: Date.now(),
        id: action.payload._id,
      };
    });

    // If user don't have access to presentation
    builder.addCase(getPresentaionById.rejected, (state, action) => {
      state.presentationAccess = false;
    });

    // Update Presentation
    builder.addCase(updatePresentationSetting.fulfilled, (state, action) => {
      state.presentation = { ...state.presentation, ...action.payload };
    });

    // Create New Chapter
    builder.addCase(createChapter.fulfilled, (state, action) => {
      state.presentation.chapters.push(action.payload);
      state.updatedStructure.chapters.push(action.payload);
    });

    // Delete Chapter
    builder.addCase(deleteChapter.fulfilled, (state, action) => {
      state.presentation.chapters.splice(action.payload.chapterIndex, 1);
      state.updatedStructure.chapters.splice(action.payload.chapterIndex, 1);
    });

    // Delete Slide
    builder.addCase(deleteSlide.fulfilled, (state, action) => {
      const { chapterId, slideId } = action.payload;
      const chapterIndex = state.presentation.chapters.findIndex(
        (chapter) => chapter._id === chapterId
      );
      const slideIndex = state.presentation.chapters[
        chapterIndex
      ].slides.findIndex((slide) => slide._id === slideId);
      state.presentation.chapters[chapterIndex].slides.splice(slideIndex, 1);
      state.updatedStructure.chapters[chapterIndex].slides.splice(
        slideIndex,
        1
      );
    });

    // Create New Slide
    builder.addCase(createSlide.fulfilled, (state, action) => {
      const { data, index } = action.payload;
      const createdSlideChapter = state.presentation.chapters[index];
      state.updatedStructure.chapters[index].slides.push(data);
      createdSlideChapter.slides.push(data);
      state.presentationSlides.push({
        id: data._id,
        name: data.name,
        thumbnail: data.thumbnail,
        chapterName: createdSlideChapter.name,
        fileId: data.fileId,
      });
    });

    // Clone Slide
    builder.addCase(cloneSlide.fulfilled, (state, action) => {
      const { data, chapterIndex } = action.payload;
      state.presentation.chapters[chapterIndex] = data.chapters[chapterIndex]
      state.presentationSlides = getSlidesFromPresentation(data);
    });

    // Update Chapter Of Presentation
    builder.addCase(updateChapterSetting.fulfilled, (state, action) => {
      const { chapterIndex, update } = action.payload;
      state.presentation.chapters[chapterIndex] = {
        ...state.presentation.chapters[chapterIndex],
        ...update,
      };

      state.presentationSlides = getSlidesFromPresentation(state.presentation);
    });

    // Update Chapter Of Presentation
    builder.addCase(updateStructure.rejected, (state, action) => {
      state.presentation.chapters = state.oldChapters;
    });

    // Update Chapter Of Presentation
    builder.addCase(updateStructure.fulfilled, (state, action) => {
      state.oldChapters = [];
    });

    // Update Slide Of Chapter
    builder.addCase(updateSlideSetting.fulfilled, (state, action) => {
      const { slideIndex, chapterIndex, update } = action.payload;
      state.presentation.chapters[chapterIndex].slides[slideIndex] = update;
      state.presentationSlides = getSlidesFromPresentation(state.presentation);
    });

    // Fetch Slide Templates
    builder.addCase(fetchSlideTemplates.fulfilled, (state, action) => {
      state.slideTemplates = action.payload;
    });

    // Download Source
    builder.addCase(downloadSource.fulfilled, (state, action) => {
      downloadbyUrl(action.payload);
    });

    // Download Link
    builder.addCase(downloadLink.fulfilled, (state, action) => {
      state.downloadLink = action.payload;
    });

    // Download Version Source
    builder.addCase(downloadVersionSource.fulfilled, (state, action) => {
      downloadbyUrl(action.payload);
    });

    // Save Slide
    builder.addCase(saveSlide.fulfilled, (state, action) => { });

    // get Slide
    builder.addCase(getSlide.fulfilled, (state, action) => {
      const { shared, slide } = action.payload;
      state.editorHtmlData = slide;
      state.currentSharedData = shared;
    });

    // get Slide Preview
    builder.addCase(previewPresentationHTML.fulfilled, (state, action) => {
      const { shared, slide } = action.payload;
      state.editorHtmlData = slide;
      state.currentSharedData = shared;
    });

    // Export Presentation
    builder.addCase(exportPresentation.fulfilled, (state, action) => {
      if (Array.isArray(action.payload.files)) {
        let { files, presentation } = action.payload
        if (presentation && presentation.targetDevice) {
          let { height, width } = useDevices(presentation.targetDevice);
          downloadPdfByImages(files, { height: removePx(height), width: removePx(width) });
        }
      } else {
        downloadbyUrl(action.payload);
      }
    });

    //Check if user has reviewed the presentation
    builder.addCase(checkIfReviewed.fulfilled, (state, action) => {
      state.preview.isReviewed = action.payload;
    });

    //Delete Presentation
    builder.addCase(deletePresentation.fulfilled, (state, action) => {
      const index = state.list.findIndex(
        (presentation) => presentation._id === action.payload
      );
      if (index !== -1) {
        state.list.splice(index, 1);
      }
      if (state.list.length === 0) {
        state.loadingMsg = "No Presentations Found !";
      }
    });

    //Review Presentation
    builder.addCase(review.fulfilled, (state, action) => {
      state.preview.isReviewed = true;
    });

    //Get All Reviewed Presentations
    builder.addCase(getReviewedPre.fulfilled, (state, action) => {
      const { response, count } = action.payload;
      state.reviewed.count = count;
      state.reviewed.list = response;
    });

    //
    builder.addCase(getSFPresentations.fulfilled, (state, action) => {
      const { Presentations } = action.payload;
      state.sfPresentations = Presentations;
    });

    //
    builder.addCase(uploadNewVersion.fulfilled, (state, action) => {
      const index = state.list.findIndex(
        (presentation) => presentation._id === action.payload.id
      );
      if (index !== -1) {
        state.list[index].version = action.payload.version;
      }
    });

    builder.addCase(previewPresentation.pending, (state, action) => {
      state.KPI = null;
    });

    builder.addCase(previewPresentation.fulfilled, (state, action) => {
      state.presentation = action.payload;
      state.presentationSlides = getSlidesFromPresentation(action.payload);
    });
  },
});

export const {
  setPresentationAccess,
  setSharedWith,
  setPreiewPresentation,
  clearPresentationData,
  setUpdatedStruture,
  setPresentationStatus,
  setTemplates,
  clearDownloadLink,
} = presentationsSlice.actions;
export default presentationsSlice.reducer;
