import React, { useState } from "react";
import { useDispatch } from "react-redux";

//Icons
import { MdMoreVert } from "react-icons/md";
import { BiCommentDetail, BiPencil } from "react-icons/bi";
import { AiOutlineFolderOpen } from "react-icons/ai";
import { TiCancel } from "react-icons/ti";
import { BsFillReplyFill } from "react-icons/bs";
import { MdOutlineDone } from "react-icons/md";
import { IoMdSave } from "react-icons/io";
import { IoTrash } from "react-icons/io5";

//MUI Components
import {
  CardHeader,
  CardContent,
  CardActions,
  Collapse,
  Typography,
  Stack,
  Button,
  Box,
} from "@mui/material";

// API's
import { uiActions } from "src/store/reducers/ui-slice";
import {
  editComment,
  deleteComment,
  addReply,
  changeStatus,
} from "src/store/actions/comments";
import { dateFormat } from "src/services/utils";

import CommentReply from "./CommentReply";
import Confirm from "../dialogs/Confirm";
import DropDown from "../ui/DropDown";
import ExpandMore from "./ExpandMore";
import IsOwner from "./IsOwner";

//styles
import "../../styles/review_comment.scss";

const CommnetsCard = (props) => {
  const dispatch = useDispatch();
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [replyBox, setReplyBox] = useState(false);
  const [commentEdit, setCommentEdit] = useState(false);
  const [reply, setReply] = useState("");
  const { comment, id, type } = props;

  const handleExpandClick = () => {
    setExpanded(!expanded);
    setReplyBox(false);
  };

  const edit = () => {
    setReplyBox(true);
    setCommentEdit(true);
    setExpanded(false);
    setReply(comment.comment);
  };

  const deleteCom = () => {
    dispatch(deleteComment({ id: comment._id, type }));
    return true;
  };

  const cancelComment = () => {
    setCommentEdit(false);
    setReplyBox(false);
    setExpanded(false);
    setReply("");
  };

  const handleReplyClick = () => {
    if (!replyBox) {
      setReply("");
      setReplyBox(true);
    } else if (commentEdit) {
      if (!reply.trim().length) {
        return dispatch(
          uiActions.showNotification({
            message: "Please enter comment to edit",
            title: "Empty Comment",
            status: "error",
          })
        );
      }
      dispatch(
        editComment({ id: comment._id, data: { comment: reply }, type })
      );
      cancelComment();
    } else if (replyBox) {
      if (!reply.trim().length) {
        return dispatch(
          uiActions.showNotification({
            message: "Please enter reply",
            title: "Empty Reply",
            status: "error",
          })
        );
      }
      dispatch(addReply({ id: comment._id, data: { reply: reply }, type }));
      cancelComment();
    }
  };

  const handleChangeStatus = () => {
    if (comment.status === "resolved") {
      return dispatch(
        changeStatus({ id: comment._id, data: { status: "open" }, type })
      );
    }
    return dispatch(
      changeStatus({ id: comment._id, data: { status: "resolved" }, type })
    );
  };

  const dropDownData = [
    {
      label: "Delete",
      icon: <IoTrash />,
      onClick: () => setIsOpenConfirm(true),
    },
    {
      label: "Edit",
      icon: <BiPencil />,
      onClick: edit,
    },
    // {
    //   label: "Add Slide",
    //   icon: <AiOutlinePlus />,
    //   onClick: () => {
    //     console.log("preview");
    //   },
    // },
    // {
    //   label: "Show on slide",
    //   icon: <BsImage />,
    //   onClick: () => {
    //     console.log("preview");
    //   },
    // },
  ];

  return (
    <>
      <Confirm
        title="Are you sure?"
        subtitle="Are you sure you want to delete this comment?"
        isOpen={isOpenConfirm}
        handleClose={() => setIsOpenConfirm(false)}
        handleConfirm={deleteCom}
      />
      <Box className="commentCard">
        <CardHeader
          action={
            <Stack className="cardAction">
              <Typography mx={1}>
                {comment.status === "resolved" ? "(Resolved)" : ""}
              </Typography>
              <IsOwner id={comment?.user?._id}>
                <DropDown
                  tooltip="Options"
                  items={dropDownData}
                  dropIcon={<MdMoreVert size={17} />}
                />
              </IsOwner>
            </Stack>
          }
          title={comment.user?.firstName + " " + comment.user?.lastName}
          titleTypographyProps={{ sx: { fontSize: "14px" }, noWrap: true }}
          subheader={dateFormat(comment.createdAt)}
          subheaderTypographyProps={{ sx: { fontSize: "10px" } }}
        />
        {!commentEdit && (
          <CardContent className="comment">
            <Typography
              variant="body2"
              color="text.secondary"
              sx={
                comment.status === "resolved"
                  ? { textDecoration: "line-through" }
                  : {}
              }
            >
              {comment.comment}
            </Typography>
          </CardContent>
        )}
        <Collapse in={replyBox} timeout="auto" unmountOnExit>
          <textarea
            value={reply}
            onChange={(e) => {
              setReply(e.target.value);
            }}
            className="textBox"
          ></textarea>
        </Collapse>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {comment.reply.map((reply) => {
            return (
              <CommentReply
                id={id}
                type={type}
                commentId={comment._id}
                replyId={reply._id}
                reply={reply}
                key={reply._id}
              />
            );
          })}
        </Collapse>
        <CardActions className="actions">
          <Box>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="Show Reply"
              className="m-0"
            >
              <BiCommentDetail size={15} />
            </ExpandMore>
          </Box>
          <Box className="actionButtons">
            {!replyBox && (
              <IsOwner id={comment?.user?._id}>
                <Button
                  size="small"
                  variant="text"
                  sx={{ fontSize: "8px" }}
                  startIcon={
                    comment.status === "resolved" ? (
                      <AiOutlineFolderOpen size={12} />
                    ) : (
                      <MdOutlineDone size={12} />
                    )
                  }
                  onClick={handleChangeStatus}
                >
                  {comment.status === "resolved" ? "REOPEN" : "RESOLVE"}
                </Button>
              </IsOwner>
            )}
            <Button
              onClick={handleReplyClick}
              startIcon={
                commentEdit ? (
                  <IoMdSave size={12} />
                ) : (
                  <BsFillReplyFill size={12} />
                )
              }
            >
              {commentEdit ? "SAVE" : "REPLY"}
            </Button>
            {replyBox && (
              <Button
                onClick={cancelComment}
                startIcon={<TiCancel size={12} />}
              >
                CANCEL
              </Button>
            )}
          </Box>
        </CardActions>
      </Box>
    </>
  );
};

export default React.memo(CommnetsCard);
