import React from "react";
import { Tooltip, Typography } from "@mui/material";
const ToolItem = (props) => {
  const disable = props.disable?props.disable:false
  return (
    disable?
    (<Tooltip title="Coming soon" arrow>
    <div
      className={`toolItem`}
      style={{backgroundColor: '#f9f9f9', color:'#c2baba',  border: '1px solid #c2baba'}}
    >
      <div className="preview">
        {props.icon}
        <Typography style={{'color':'#c2baba'}}>{props.caption}</Typography>
      </div>
    </div>
    </Tooltip>)
    :
    (
      <div
      className={`toolItem ${props.className}`}
      onClick={(e) => props.onItemClick(e, props.type, props.layout)}
      {...(props.itemRef
        ? {
            ref: (ref) => {
              props.itemRef(ref, props.type, props.layout);
            },
          }
        : {})}
    >
      <div className="preview">
        {props.icon}
        <Typography>{props.caption}</Typography>
      </div>
    </div>)
  )
};
export default ToolItem;
