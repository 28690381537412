import { createSlice } from "@reduxjs/toolkit";
import {
  deleteAccount,
  deleteCampaign,
  deleteContact,
  deleteGroup,
  fetchAccountById,
  fetchAccounts,
  fetchCampaignById,
  fetchCampaigns,
  fetchContactById,
  fetchContacts,
  fetchGroupById,
  fetchGroups,
  filterContact,
  getAnalyticsReports,
  getUsersAnalytics,
  updateAccount,
  updateCampaign,
  updateContact,
  updateGroup,
} from "../actions/campaign";

const initialState = {
  accounts: [],
  accountDetail: { name: "" },
  contacts: [],
  contactDetail: {
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    account: "",
    mobile: "",
    phone: "",
    fax: "",
    lang: "",
    address: {
      street: "",
      country: "",
      state: "",
      city: "",
      postalCode: "",
    },
  },
  groups: [],
  groupDetail: {
    name: "",
    accountId: { name: "", _id: "" },
    users: [{ firstName: "", lastName: "", email: "" }],
    timezone: "",
  },
  campaigns: [],
  campaignDetail: {
    name: "",
    timezone: "",
    repeat: "",
    timePeriod: "",
    weekDays: [],
    date: "",
    time: "",
    template: {
      templateId: "",
      name: "",
    },
    groups: [],
  },
  analytics: [],
  reports: {},
};

const compaignSlice = createSlice({
  name: "compaignSlice",
  initialState,
  reducers: {
    clearAnalyticsData: (state) => {
      state.analytics = initialState.analytics;
    },
  },
  extraReducers: (builder) => {
    //Fetch Accounts
    builder.addCase(fetchAccounts.fulfilled, (state, action) => {
      state.accounts = action.payload;
    });

    //fetch account by Id
    builder.addCase(fetchAccountById.fulfilled, (state, action) => {
      state.accountDetail = action.payload;
    });

    //update Account
    builder.addCase(updateAccount.fulfilled, (state, action) => {
      const index = state.accounts.findIndex(
        (user) => user._id === action.payload.id
      );
      if (index !== -1) {
        state.accounts[index].name = action.payload.response.name;
      }
    });

    //deleted Account
    builder.addCase(deleteAccount.fulfilled, (state, action) => {
      const { response, id } = action.payload;
      if (response) {
        const index = state.accounts.findIndex((user) => user._id === id);
        if (index !== -1) {
          state.accounts.splice(index, 1);
        }
      }
    });

    //Fetch Contacts
    builder.addCase(fetchContacts.fulfilled, (state, action) => {
      state.contacts = action.payload;
    });

    //fetch contact by Id
    builder.addCase(fetchContactById.fulfilled, (state, action) => {
      state.contactDetail = action.payload;
    });

    //update Contact
    builder.addCase(updateContact.fulfilled, (state, action) => {
      const index = state.contacts.findIndex(
        (user) => user._id === action.payload.id
      );
      if (index !== -1) {
        state.contacts[index] = {
          ...state.contacts[index],
          ...action.payload.response,
        };
      }
    });

    //deleted Contact
    builder.addCase(deleteContact.fulfilled, (state, action) => {
      const { response, id } = action.payload;
      if (response) {
        const index = state.contacts.findIndex((user) => user._id === id);
        if (index !== -1) {
          state.contacts.splice(index, 1);
        }
      }
    });

    //filter contact
    builder.addCase(filterContact.fulfilled, (state, action) => {
      state.contacts = action.payload;
    });

    //Fetch Groups
    builder.addCase(fetchGroups.fulfilled, (state, action) => {
      state.groups = action.payload;
    });

    //fetch Group by Id
    builder.addCase(fetchGroupById.fulfilled, (state, action) => {
      state.groupDetail = action.payload;
    });

    //update Group
    builder.addCase(updateGroup.fulfilled, (state, action) => {
      const index = state.groups.findIndex(
        (user) => user._id === action.payload.id
      );
      if (index !== -1) {
        state.groups[index] = {
          ...state.groups[index],
          ...action.payload.response,
        };
      }
    });

    //deleted Group
    builder.addCase(deleteGroup.fulfilled, (state, action) => {
      const { response, id } = action.payload;
      if (response) {
        const index = state.groups.findIndex((user) => user._id === id);
        if (index !== -1) {
          state.groups.splice(index, 1);
        }
      }
    });

    //Fetch Campaigns
    builder.addCase(fetchCampaigns.fulfilled, (state, action) => {
      state.campaigns = action.payload;
    });

    //fetch Campaigns by Id
    builder.addCase(fetchCampaignById.fulfilled, (state, action) => {
      state.campaignDetail = action.payload;
    });

    //update Campaigns
    builder.addCase(updateCampaign.fulfilled, (state, action) => {
      const index = state.campaigns.findIndex(
        (user) => user._id === action.payload.id
      );
      if (index !== -1) {
        state.campaigns[index] = {
          ...state.campaigns[index],
          ...action.payload.response,
        };
      }
    });

    //deleted Campaigns
    builder.addCase(deleteCampaign.fulfilled, (state, action) => {
      const { response, id } = action.payload;
      if (response) {
        const index = state.campaigns.findIndex((user) => user._id === id);
        if (index !== -1) {
          state.campaigns.splice(index, 1);
        }
      }
    });

    // getting analytics
    builder.addCase(getUsersAnalytics.fulfilled, (state, action) => {
      const { response } = action.payload;
      if (response) {
        state.analytics = response.analytics;
      }
    });
    builder.addCase(getAnalyticsReports.fulfilled, (state, action) => {
      const { response } = action.payload;
      state.reports = response;
    });
  },
});

export const { clearAnalyticsData } = compaignSlice.actions;
export default compaignSlice.reducer;
