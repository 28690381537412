import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSpendedTime } from "src/services/utils";
import { getAnalyticsReports } from "src/store/actions/campaign";
import { Chip } from "@mui/material";

const BulkAnalyticsView = (props) => {
  const canvasRef = useRef();
  const { id: campaignId } = useParams();
  const [loading, setLoading] = useState(false);
  const { reports: data } = useSelector((state) => state.campaign);
  const [colors, setColors] = useState([]);
  const dispatch = useDispatch();

  const generateLengthColors = (obj) => {
    const colors = [];
    for (let p in obj) {
      const randomColor =
        "#" + Math.floor(Math.random() * 16777215).toString(16);
      colors.push(randomColor);
    }
    setColors(colors);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getAnalyticsReports(campaignId)).then(() => {
      setLoading(false);
    });
  }, []);

  const getFormattedGraphData = (obj) => {
    const data = {
      labels: [],
      datasets: [
        {
          label: "Stayed Seconds",
          data: [],
          backgroundColor: colors,
        },
      ],
    };
    
    Object.keys(obj || {}).forEach((key) => {
      data.labels.push(key.toLocaleUpperCase());
      data.datasets[0].data.push(obj[key]);
    });

    return data;
  };

  const getHighRankedSlide = (obj) => {
    let highNum = 0;
    let highName = "";

    for (let property in obj) {
      const currentNum = obj[property];
      if (highNum < currentNum) {
        highNum = currentNum;
        highName = property;
      }
    }
    return {
      key: highName,
      stayed: highNum,
    };
  };

  const { key: highRankedSlide, stayed: highStayed } = getHighRankedSlide(data);

  useEffect(() => {
    let chart;

    if (!colors.length) {
      generateLengthColors(data);
    }

    if (canvasRef.current) {
      chart = new Chart(canvasRef.current, {
        type: "line",
        data: getFormattedGraphData(data),
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          maintainAspectRatio: false,
        },
      });
    }
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [canvasRef, data, loading]);

  return loading ? (
    <p>Loading users Analytics...</p>
  ) : (
    <div className="bulk-analytics">
      {highRankedSlide ? (
        <canvas className="graph" ref={canvasRef} />
      ) : (
        <div className="no-data">
          <p>No Activity was Found !</p>
        </div>
      )}
    </div>
  );
};

export default BulkAnalyticsView;
