export const headCells = [
  {
    id: "firstName",
    numeric: false,
    sort: true,
    label: "First Name",
  },
  {
    id: "lastName",
    numeric: true,
    sort: true,
    label: "Last Name",
  },
  {
    id: "email",
    numeric: true,
    sort: true,
    label: "Email",
  },
  // {
  //   id: "lang",
  //   numeric: true,
  //   sort: true,
  //   label: "Language",
  // },
  {
    id: "mobile",
    numeric: true,
    sort: true,
    label: "Mobile",
  },
  {
    id: "phone",
    numeric: true,
    sort: true,
    label: "Phone",
  },
  {
    id: "role",
    numeric: true,
    sort: true,
    label: "Role",
  },
  // {
  //   id: "timezone",
  //   numeric: false,
  //   sort: true,
  //   label: "Timezone",
  // },
  // {
  //   id: "fax",
  //   numeric: false,
  //   sort: true,
  //   label: "Fax",
  // },
  {
    id: "status",
    numeric: false,
    sort: false,
    label: "Status",
  },
];
