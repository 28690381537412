import React, { useState, useEffect } from "react";
import {
  Button,
  IconButton,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  MenuItem,
  Select,
  FormControl,
  Box,
  Tooltip,
} from "@mui/material";
import { IoMdAdd } from "react-icons/io";
import Control from "./Control";
import { AiOutlineDelete } from "react-icons/ai";
import { BiDuplicate } from "react-icons/bi";
import { convertRgbAToHexAplha } from "src/services/editor.service";
import AssetUpload from "./AssetUpload";
import Row from "src/components/properties/Row";
import $ from "jquery";
import "src/styles/properties.scss";
import "src/styles/styles.scss";
import Select_Box from "src/components/common/Select_Box";
import {
  MdOutlineAlignHorizontalCenter,
  MdOutlineAlignHorizontalLeft,
  MdOutlineAlignHorizontalRight,
} from "react-icons/md";
import { useSetLayerHeight } from "../editorLogics/components";

const List = (props) => {
  const { updateHistory, elementId, undoRedoChange } = props;

  const element = document.getElementById(elementId);
  const layer = element.closest(".cs-layer");

  const [allList, setAllList] = useState([]);
  const [listType, setListType] = useState("");
  const [positionValue, setpositionValue] = useState("column");
  const [src, setSrc] = useState("");
  const [expanded, setExpanded] = useState("");
  const [imageWidth, setImageWidth] = useState(20);
  const [imageSpacing, setImageSpacing] = useState(5);
  const [isOpen, setIsOpen] = useState(false);
  const [margin, setMargin] = useState(0);
  const [listColor, setListColor] = useState("#000000");
  const [alignment, setAlignment] = useState("left");

  useEffect(() => {
    //get all list item
    const li = element.querySelectorAll("li");
    const list = [];
    li.forEach((element) => {
      return list.push({
        data: element.innerText,
        hrefValue: element.getAttribute("data-href")
          ? element.getAttribute("data-href")
          : "",
      });
    });
    setAllList(list);

    //check the list type
    const img = element.querySelector("li img");
    if (img) {
      //check the image
      const imgUrl = img.src;
      setSrc(imgUrl);
      setListType("image");
      setImageWidth(img.style.width.split("px")[0]);
      setImageSpacing(img.style.marginRight.split("px")[0]);
    } else {
      setSrc("");
      setListType(element.style.listStyle);
    }
    if (getComputedStyle(li[0]).display === "inline") {
      const margin = parseInt(getComputedStyle(li[0]).marginRight);
      setMargin(margin);
      setpositionValue("row");
    } else {
      const margin = parseInt(getComputedStyle(li[0]).marginBottom);
      setMargin(margin);
      setpositionValue("column");
    }
    //set the color of li
    const singleli = element.querySelector("li").style.color;
    if (singleli) {
      const hex = convertRgbAToHexAplha(singleli).hex;
      setListColor(hex);
    } else {
      setListColor("#000000");
    }

    const alignment = element.style.textAlign;
    if (alignment) {
      setAlignment(alignment);
    } else {
      setAlignment("left");
    }
  }, [elementId, undoRedoChange]);

  const imageStyles = `vertical-align:middle; width: ${imageWidth}px; margin:0px ${imageSpacing}px`;

  element.querySelectorAll("a").forEach((elem) => {
    elem.addEventListener("click", (e) => e.preventDefault());
  });

  const updateText = (e, i) => {
    const { value } = e.target;
    const li = element.querySelectorAll("li")[i];
    const span = li.querySelector("span");
    const anchor = li.querySelector("a");
    (anchor || span || li).innerText = value;
    setAllList((pre) => {
      const list = [...pre];
      list[i].data = value;
      return list;
    });
  };

  const removeRow = (i) => {
    setAllList((pre) => {
      const list = [...pre];
      list.splice(i, 1);
      return list;
    });
    element.removeChild(element.children[i]);
    const li = element.querySelector("li:last-child");
    li.style.marginBottom = 0 + "px";
    useSetLayerHeight($(layer));
    updateHistory();
  };

  const duplicaterow = (i) => {
    const li = element.querySelector("li:last-child");
    if (positionValue === "column") {
      li.style.marginBottom = margin + "px";
    } else {
      li.style.marginRight = margin + "px";
    }
    const duplicate = element.querySelectorAll("li")[i].cloneNode(true);
    element.append(duplicate);
    setAllList((pre) => {
      const list = [...pre];
      const data = list[i].data;
      const hrefValue = list[i].hrefValue;
      list.push({ data, hrefValue });
      return list;
    });
    useSetLayerHeight($(layer));
    updateHistory();
  };

  const addRow = () => {
    //check the list type
    const span = element.querySelector("span");
    const img = element.querySelector("img");
    const li = element.querySelector("li:last-child");
    const styleArray = li.getAttribute("style").split(";");
    let styleobj = {};
    styleArray.forEach((val) => {
      const keyValueArray = val.split(":");
      if (keyValueArray.length === 2) {
        styleobj = {
          ...styleobj,
          [keyValueArray[0].trim()]: keyValueArray[1].trim(),
        };
      }
    });
    if (positionValue === "column") {
      li.style.marginBottom = margin + "px";
    } else {
      li.style.marginRight = margin + "px";
    }
    const listStyles = `list-style:inherit; width:auto; display:${
      positionValue === "row" ? "inline" : "list-item"
    }; background-color: ${styleobj["background-color"]}; padding: ${
      styleobj.padding
    }; border-radius: ${styleobj["border-radius"]}`;
    if (span && img) {
      const newLi = ` <li style="${listStyles}">
                          <img style="${imageStyles}" src="${src}" />
                          <span> New Item</span>
                        </li>`;
      element.insertAdjacentHTML("beforeend", newLi);
    } else if (span) {
      const newLi = ` <li style="${listStyles}">
        <span > New Item</span>
      </li>`;
      element.insertAdjacentHTML("beforeend", newLi);
    } else {
      const newLi = ` <li style="${listStyles}" >New Item</li>`;
      element.insertAdjacentHTML("beforeend", newLi);
    }
    setAllList([...allList, { data: "New Item", hrefValue: "" }]);
    useSetLayerHeight($(layer));
    updateHistory();
  };

  const toggleDialog = () => {
    setIsOpen(!isOpen);
  };

  const updateTextHistory = () => {
    updateHistory({
      id: elementId,
    });
  };

  const typeChangeHandler = (e) => {
    setListType(e.target.value);
    if (e.target.value === "image") {
      element.style.listStyle = "none";
      element.style.paddingLeft = 0;
      element.querySelectorAll("li").forEach((elem) => {
        const text = elem.innerText;
        const anchor = elem.querySelector("a");
        anchor && (anchor.innerHTML = text);
        const span = `<span > ${anchor ? anchor.outerHTML : text}</span>`;
        elem.innerHTML = span;
      });
    } else {
      if (e.target.value === "none") {
        element.style.paddingLeft = "0px";
      } else {
        element.style.paddingLeft = "40px";
      }
      element.style.listStyle = e.target.value;
      if (element.querySelector("li").querySelector("span")) {
        element.querySelectorAll("li").forEach((elem) => {
          const anchor = elem.querySelector("a");
          const text = elem.innerText;
          anchor && (anchor.innerHTML = text);
          elem.innerHTML = anchor ? anchor.outerHTML : text;
        });
      }
      if (src) {
        setSrc("");
      }
    }
    e.target.onblur = updateTextHistory;
  };

  // when collapse change
  const listItemChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  //when change in input of link
  const linkchange = (e, i) => {
    const value = e.target.value;
    if (value) {
      const li = element.querySelectorAll("li");
      const span = li[expanded].querySelector("span");
      li[expanded].setAttribute("data-href", value);
      const text = li[expanded].innerText;
      let newElement;
      if (value.indexOf("http") === 0) {
        newElement = `<a style="color: inherit; text-decoration: none;" href=${value} target="_blank">${text}</a>`;
      } else {
        newElement = `<a style="color: inherit; text-decoration: none;" href=${
          "//" + value
        } target="_blank">${text}</a>`;
      }

      (span || li[expanded]).innerHTML = newElement;
    } else {
      const li = element.querySelectorAll("li");
      li[expanded].removeAttribute("data-href");
      const anchor = li[expanded].querySelector("a");
      if (anchor) {
        anchor.parentElement.innerHTML = anchor.innerHTML;
      }
    }
    setAllList((pre) => {
      const list = [...pre];
      list[i].hrefValue = value;
      return list;
    });
  };

  const imageWidthHandler = (e) => {
    const { value } = e.target;
    const img = element.querySelectorAll("img");
    setImageWidth(value);
    img.forEach((element) => {
      element.style.width = `${value}px`;
    });
    e.target.onblur = updateTextHistory;
  };

  useEffect(() => {
    if (positionValue === "row") {
      element.style.paddingLeft = "0px";
    } else {
      if (listType === "none" || listType === "image") {
        element.style.paddingLeft = "0px";
      } else {
        element.style.paddingLeft = "40px";
      }
    }
  }, [listType, positionValue]);

  const positionHandler = (e) => {
    const { value } = e.target;
    const li = element.querySelectorAll("li");
    const list = element.querySelectorAll("li:not(:last-child)");
    if (value === "row") {
      li.forEach((el) => (el.style.width = "auto"));
      list.forEach((el) => {
        el.style.marginBottom = 0 + "px";
        el.style.marginRight = margin + "px";
      });
      element.style.textAlign = alignment;
    } else {
      list.forEach((el) => {
        el.style.marginBottom = margin + "px";
        el.style.marginRight = 0 + "px";
      });
      element.style.textAlign = "initial";
    }
    li.forEach(
      (el) => (el.style.display = value === "row" ? "inline" : "list-item")
    );
    setpositionValue(value);
    e.target.onblur = updateTextHistory;
  };

  let timeout;
  const colorHandler = (e) => {
    clearTimeout(timeout);
    const { value } = e.target;
    const li = element.querySelectorAll("li");
    li.forEach((element) => {
      element.style.color = value;
    });
    timeout = setTimeout(() => {
      setListColor(value);
    }, 400);
    e.target.onblur = updateTextHistory;
  };
  const marginHandler = (event) => {
    const { value } = event.target;
    if (value && value > -1) {
      setMargin(value);
      const li = element.querySelectorAll("li:not(:last-child)");
      if (positionValue === "column") {
        li.forEach((el) => (el.style.marginBottom = value + "px"));
      } else {
        li.forEach((el) => (el.style.marginRight = value + "px"));
      }
    }
    event.target.onblur = updateTextHistory;
  };

  const alignmentChangeHandler = (e, updatedAlignment) => {
    setAlignment(updatedAlignment);
    element.style.textAlign = updatedAlignment;
  };

  const imageSpacingHandler = (e) => {
    const { value } = e.target;
    const img = element.querySelectorAll("img");
    setImageSpacing(value);
    img.forEach((element) => {
      element.style.margin = `0px ${value}px`;
    });
    e.target.onblur = updateTextHistory;
  };
  return (
    <Box className={`checkbox-container`}>
      <Control
        heading="List Items"
        className="list"
        divider={false}
        isCollapse={true}
        expanded={true}
      >
        {allList.map((listItem, i) => {
          return (
            <div className="list-item-row" key={i}>
              <Accordion
                expanded={expanded === i}
                onChange={listItemChange(i)}
                className="accordian_container"
              >
                <AccordionSummary
                  className="accordian_heading"
                  classes={{ content: "accordian_content" }}
                >
                  <Box>
                    <Typography className="list_accordian_heading">
                      {listItem.data}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <Typography className="subheading_list">Text</Typography>
                <AccordionDetails className="accordian_details">
                  <input
                    onChange={(e) => updateText(e, i)}
                    onBlur={updateTextHistory}
                    className="list_input"
                    value={listItem.data}
                  />
                </AccordionDetails>
                <Typography className="subheading_list">Link</Typography>
                <AccordionDetails className="accordian_details">
                  <input
                    onChange={(e) => linkchange(e, i)}
                    className="list_input"
                    placeholder="Type link..."
                    onBlur={updateTextHistory}
                    value={listItem.hrefValue}
                  />
                </AccordionDetails>
              </Accordion>
              <IconButton
                size="small"
                className="duplicateicon"
                onClick={() => duplicaterow(i)}
              >
                <BiDuplicate size={14} />
              </IconButton>
              <IconButton
                size="small"
                className="removeIcon"
                disabled={allList.length === 1}
                onClick={() => removeRow(i)}
              >
                <AiOutlineDelete size={14} />
              </IconButton>
            </div>
          );
        })}
        <Box className="global-btn">
          <Button variant="contained" onClick={addRow}>
            Add Item
            <IoMdAdd className="icon" size="18px" />
          </Button>
        </Box>
      </Control>

      <Control
        heading="List Properties"
        className="list checkboxProperty"
        divider={false}
        isCollapse={true}
        expanded={false}
      >
        {/* <Row
          label="Color"
          element={
            <input
              type="color"
              name="color"
              className="color"
              value={listColor}
              onInput={colorHandler}
            />
          }
        /> */}

        <Row
          label="Position"
          element={
            <Select_Box
              value={positionValue}
              onChange={positionHandler}
              options={[
                { label: "Vertical", value: "column" },
                { label: "Horizontal", value: "row" },
              ]}
            />
          }
        />

        {positionValue == 'column' && <Row
          label="List Type"
          element={
            <Select_Box
              name="list Type"
              value={listType}
              onChange={typeChangeHandler}
              options={[
                { label: "Disc", value: "disc" },
                { label: "Circle", value: "circle" },
                { label: "Square", value: "square" },
                { label: "None", value: "none" },
                { label: "Image", value: "image" },
              ]}
            />
          }
        />}
        {positionValue === "row" && (
          <Row
            label="Alignment"
            element={
              <Box className="alignment_container">
                <Tooltip title="Left" arrow>
                  <IconButton
                    className="iconBtn active"
                    onClick={(e) => alignmentChangeHandler(e, "left")}
                  >
                    <MdOutlineAlignHorizontalLeft />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Center" arrow>
                  <IconButton
                    className="iconBtn"
                    onClick={(e) => alignmentChangeHandler(e, "center")}
                  >
                    <MdOutlineAlignHorizontalCenter />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Right" arrow>
                  <IconButton
                    className="iconBtn"
                    onClick={(e) => alignmentChangeHandler(e, "right")}
                  >
                    <MdOutlineAlignHorizontalRight />
                  </IconButton>
                </Tooltip>
              </Box>
            }
          />
        )}

        <Row
          label="Spacing"
          element={
            <input
              type="number"
              className="input"
              value={margin}
              onChange={marginHandler}
            />
          }
        />
        {listType === "image" && (
          <>
            {/* <Box className="global-btn">
              <Button fullWidth variant="contained" onClick={toggleDialog}>
                Upload Image
                <HiOutlineUpload className="icon" size="18px" />
              </Button>
            </Box> */}

            {/* <ChooseAsset
              show={isOpen}
              assetFormat="image"
              type="edit"
              close={toggleDialog}
              updateHistory={updateHistory}
              setSrc={setSrc}
            /> */}
            <AssetUpload
              isCollapse={false}
              elementId={elementId}
              updateType="list"
              updateHistory={updateHistory}
              externalData={{ imageStyles }}
            />
            {src ? (
              <>
                <Row
                  label="Image Size"
                  element={
                    <input
                      type="number"
                      className="input"
                      value={imageWidth}
                      onChange={imageWidthHandler}
                    />
                  }
                />
                <Row
                  label="Image Spacing"
                  element={
                    <input
                      type="number"
                      name="imageSpacing"
                      className="input"
                      value={imageSpacing}
                      onChange={imageSpacingHandler}
                    />
                  }
                />
              </>
            ) : null}
          </>
        )}
      </Control>
    </Box>
  );
};
export default List;
