import React, { useState, useEffect, useRef } from "react";
import { Button, Box, Checkbox } from "@mui/material";
import {
  convertHexToRgba,
  convertRgbAToHexAplha,
} from "src/services/editor.service";
import ChooseAsset from "src/components/dialogs/ChooseAsset";
import Control from "./Control";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { RiVideoUploadLine } from "react-icons/ri";
import Row from "src/components/properties/Row";
import "src/styles/properties.scss";
import { MdOutlineUpload } from "react-icons/md";
import AssetUpload from "./AssetUpload";
import Check_Box from "src/components/common/Check_Box";

const Audio = (props) => {
  const { elementId, updateHistory, undoRedoChange } = props;
  const element = document.getElementById(elementId);
  const audio = element.querySelector("audio");
  const playBtn = element.querySelector(".play");
  const muteBtn = element.querySelector(".mute");
  const time = element.querySelectorAll(".time span");
  const timeline = element.querySelector(".timeline");
  const duration = element.querySelector(".duration");
  const currentTime = element.querySelector(".currentTime");

  console.log(element);

  const minuteRef = useRef();
  const secondRef = useRef();
  let timeout;
  const [play, setPlay] = useState(false);
  const [mute, setMute] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [colorControl, setColorControl] = useState(`#000000`);
  const [isOpen, setIsOpen] = useState(false);
  const toggleDialog = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const { mute, play, time } = element.dataset;
    const [mins, secs] = new Date(audio.currentTime * 1000)
      .toISOString()
      .slice(14, 19)
      .split(":");

    // Pause
    if (play == "true") {
      setPlay(true);
      audio.play();
    } else {
      setPlay(false);
    }

    // Mute
    if (mute == "true") {
      setMute(true);
      audio.muted = true;
    } else {
      setMute(false);
      audio.muted = false;
    }

    minuteRef.current.max = Math.trunc(audio.duration / 60);
    secondRef.current.max = Math.trunc(audio.duration % 60);

    setColorControl(convertRgbAToHexAplha(playBtn.style.color).hex);
    timeline.value = Math.trunc(audio.currentTime);

    currentTime.textContent = new Date(audio.currentTime * 1000)
      .toISOString()
      .slice(14, 19);

    audio.addEventListener("loadedmetadata", function () {
      duration.textContent = new Date(this.duration * 1000)
        .toISOString()
        .slice(14, 19);
    });

    setMinutes(Number(mins));
    setSeconds(Number(secs));
    timeline.max = Math.trunc(audio.duration);
  }, [elementId]);

  const playHandler = (e) => {
    const play = e.target.checked;
    setPlay(play);
    if (play) {
      audio.play();
      element.dataset.play = true;
      playBtn.innerHTML = `<svg stroke="currentColor" fill="currentColor"
      stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
      <path d="M224 432h-80V80h80zm144 0h-80V80h80z"></path></svg>`;
    } else {
      audio.pause();
      element.dataset.play = false;
      playBtn.innerHTML = `<svg stroke="currentColor"
            fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M96 448l320-192L96 64v384z"></path></svg>`;
    }
  };

  const muteHandler = (e) => {
    const mute = e.target.checked;
    setMute(mute);
    if (mute) {
      audio.muted = true;
      element.dataset.mute = true;
      muteBtn.innerHTML = `
      <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512"
      height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-linecap="square"
      stroke-miterlimit="10" stroke-width="32" d="M416 432L64 80"></path>
      <path d="M352 256c0-24.56-5.81-47.88-17.75-71.27L327 170.47 298.48 185l7.27 14.25C315.34 218.06 320 236.62 320 256a112.91 112.91 0 01-.63 11.74l27.32 27.32A148.8 148.8 0 00352 256zm64 0c0-51.19-13.08-83.89-34.18-120.06l-8.06-13.82-27.64 16.12 8.06 13.82C373.07 184.44 384 211.83 384 256c0 25.93-3.89 46.21-11 65.33l24.5 24.51C409.19 319.68 416 292.42 416 256z"></path>
      <path d="M480 256c0-74.26-20.19-121.11-50.51-168.61l-8.61-13.49-27 17.22 8.61 13.49C429.82 147.38 448 189.5 448 256c0 48.76-9.4 84-24.82 115.55l23.7 23.7C470.16 351.39 480 309 480 256zM256 72l-73.6 58.78 73.6 73.59V72zM32 176.1v159.8h93.65L256 440V339.63L92.47 176.1H32z"></path>
      </svg>
      `;
    } else {
      audio.muted = false;
      element.dataset.mute = false;
      muteBtn.innerHTML = `
      <svg stroke="currentColor" fill="currentColor" stroke-width="0"
      viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
      <path fill="none" stroke-linecap="square" stroke-miterlimit="10" stroke-width="32"
      d="M320 320c9.74-19.38 16-40.84 16-64 0-23.48-6-44.42-16-64m48 176c19.48-33.92 32-64.06 32-112s-12-77.74-32-112m48 272c30-46 48-91.43 48-160s-18-113-48-160"></path>
      <path d="M125.65 176.1H32v159.8h93.65L256 440V72L125.65 176.1z"></path></svg>
      `;
    }

    updateHistory();
  };

  useEffect(() => {
    if (isOpen) {
      setPlay(false);
      playBtn.innerHTML = `<svg stroke="currentColor"
            fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M96 448l320-192L96 64v384z"></path></svg>`;
    } else {
      setTimeout(() => {
        if (element.dataset.src != audio.src) {
          element.dataset.src = audio.src;
          timeline.max = audio.duration;
          currentTime.textContent = new Date(audio.currentTime * 1000)
            .toISOString()
            .slice(14, 19);
          timeline.value = Math.trunc(audio.currentTime);
          audio.addEventListener("loadedmetadata", function () {
            duration.textContent = new Date(this.duration * 1000)
              .toISOString()
              .slice(14, 19);
          });
          if (minuteRef.current) {
            minuteRef.current.max = Math.trunc(audio.duration / 60);
          }
          if (secondRef.current) {
            secondRef.current.max = Math.trunc(audio.duration % 60);
          }
        }
      }, 400);
    }
  }, [isOpen]);

  const colorControlHandler = (e) => {
    const inputColor = e.target.value;
    const color = convertHexToRgba(inputColor).slice(5, -1).split(",");
    time.forEach((span) => (span.style.color = inputColor));
    timeline.style.background = `rgba(${color[0]} , ${color[1]} , ${color[2]} , 0.6)`;
    timeline.style.setProperty("--sliderColor", inputColor);
    playBtn.style.color = inputColor;
    muteBtn.style.color = inputColor;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setColorControl(e.target.value);
      updateHistory();
    }, 200);
  };

  const secondsHandler = (e) => {
    setSeconds(e.target.value);
    console.log(seconds);
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      updateHistory();
    }, 400);
  };
  const minutesHandler = (e) => {
    setMinutes(e.target.value);
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      updateHistory();
    }, 400);
  };

  useEffect(() => {
    const timeInSeconds = Number(minutes) * 60 + Number(seconds);

    audio.currentTime = timeInSeconds;
    element.dataset.time = timeInSeconds;
    timeline.value = timeInSeconds;
  }, [minutes, seconds]);

  return (
    <Control
      heading="Audio"
      className="audio"
      isCollapse={true}
      divider={false}
      expanded={true}
    >
      <AssetUpload
        isCollapse={false}
        elementId={elementId}
        assetFormat="audio"
        updateType="audio"
        updateHistory={updateHistory}
      />
      <Row
        label="Current Time"
        element={
          <>
            <input
              type="number"
              className="input timer-input"
              value={minutes}
              ref={minuteRef}
              min={0}
              max={59}
              onChange={minutesHandler}
            />
            <span> : </span>
            <input
              type="number"
              className="input timer-input"
              value={seconds}
              ref={secondRef}
              min={0}
              max={59}
              onChange={secondsHandler}
            />
          </>
        }
      />
      <Row
        label="Mute"
        element={
          <Check_Box
            name="mute"
            color="secondary"
            size="small"
            checked={mute}
            onChange={muteHandler}
          />
        }
      />
      <Row
        label="Play"
        element={
          <Check_Box
            name="play"
            color="secondary"
            size="small"
            checked={play}
            onChange={playHandler}
          />
        }
      />
      <Row
        label="Control Color"
        element={
          <input
            type="color"
            className="color"
            name="controlColor"
            value={colorControl}
            onChange={colorControlHandler}
          />
        }
      />
    </Control>
  );
};

export default Audio;
