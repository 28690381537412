import React from "react";
import { CircularProgress } from "@mui/material";
import "./CircularLoading.scss";
const CircularLoading = () => {
  return (
    <div className="full-page-loading">
      <CircularProgress color="inherit" />
    </div>
  );
};

export default CircularLoading;
