import React from "react";
import { Box } from "@mui/material";
import { statusTypes } from "src/services/utils";
import { MdOutlineDrafts } from "react-icons/md";
import { BiMessageAltDetail } from "react-icons/bi";
import { RiCheckDoubleLine } from "react-icons/ri";
import { FcExpired } from "react-icons/fc";
import { AiOutlineCloseCircle } from "react-icons/ai";

const statusColors = {
  draft: { bgColor: "#f4f4f4", color: "#92979d", icon: <MdOutlineDrafts /> },
  reviewed: {
    bgColor: "#c2ead6",
    color: "#176a11",
    icon: <RiCheckDoubleLine />,
  },
  expired: { bgColor: "#ff6e59", color: "#ffffff", icon: <FcExpired /> },
  declined: {
    bgColor: "#ff4931",
    color: "#e4e4e4",
    icon: <AiOutlineCloseCircle />,
  },
  inReview: {
    bgColor: "#ffd2ad",
    color: "#ff7300",
    icon: <BiMessageAltDetail />,
  },
};

const Status = ({ status, onCard = false }) => {
  return (
    <>
      {statusTypes[status] && (
        <Box
          className={`status  ${onCard ? "card" : "detail"}`}
          sx={{ backgroundColor: statusColors[status].bgColor, color :  statusColors[status].color}}
        >
          {statusColors[status].icon} {statusTypes[status]}
        </Box>
      )}
    </>
  );
};

export default React.memo(Status);
