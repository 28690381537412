import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/material";
import React from "react";
import "../../styles/properties.scss";

const Row = (props) => {
  const { label, element, component, leftsx, rightsx, button } = props;
  return (
    <Box className="row-property">
      <Grid container>
        <Grid item xs={leftsx ? leftsx : 6}>
          {button ? (
            button
          ) : (
            <Typography component={component ? component : "div"}>
              {label}
            </Typography>
          )}
        </Grid>
        <Grid item xs={rightsx ? rightsx : 6} className="text-right">
          {element}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Row;
