import React, { useEffect, useState } from "react";
import { Checkbox } from "@mui/material";
import Control from "./Control";
import GradientBackground from "./background/GradientBackground";
import ImageBackground from "./background/ImageBackground";
import ColorBackground from "./background/ColorBackground";
import { IoInformationCircleSharp } from "react-icons/io5";
import Row from "src/components/properties/Row";
import "src/styles/properties.scss";
import Check_Box from "src/components/common/Check_Box";
import { HISTORY_ACTIONS } from "src/data/contants";

const Background = (props) => {
  // const { elementId, updateHistory, selector } = props;
  const {
    elementId,
    updateHistory: propsUpdateHistory,
    selector,
    undoRedoChange,
  } = props;

  const updateHistory = () => {
    propsUpdateHistory({ action: HISTORY_ACTIONS.STYLED_ACTIONS.common });
  };

  const [bgColorCheckbox, setbgColorCheckbox] = useState({
    color: false,
    opacity: false,
  });
  const [bgGradientCheckbox, setbgGradientCheckbox] = useState(false);
  const [bgImageCheckbox, setbgImageCheckbox] = useState(false);
  let element;
  if (selector) {
    element = document
      .getElementById(elementId)
      .querySelectorAll(`${selector}`);
  } else {
    element = [document.getElementById(elementId)];
  }
  useEffect(() => {
    //background Color
    const bgColor = getComputedStyle(element[0]).backgroundColor;
    const bg = getComputedStyle(element[0]).background;
    const bgImage = getComputedStyle(element[0]).backgroundImage;

    if (bgColor) {
      if (bgColor === "rgba(0, 0, 0, 0)") {
        setbgColorCheckbox({ color: false, opacity: false });
      } else {
        if (bgColor.includes("rgb(")) {
          setbgColorCheckbox({ color: true, opacity: false });
        } else if (bgColor.includes("rgba(")) {
          setbgColorCheckbox({ color: true, opacity: true });
        } else {
          setbgColorCheckbox({ color: false, opacity: false });
        }
      }
    } else {
      setbgColorCheckbox({ color: false, opacity: false });
    }

    //gradient color
    if (bg) {
      if (bg.includes("linear-gradient") || bg.includes("radial-gradient")) {
        setbgGradientCheckbox(true);
      } else {
        setbgGradientCheckbox(false);
      }
    } else {
      setbgGradientCheckbox(false);
    }

    //background Image
    if (bgImage) {
      if (bgImage.includes("canvas.cfd0ba05.png")) {
        setbgImageCheckbox(false);
      } else if (bgImage.includes("url")) {
        setbgImageCheckbox(true);
      } else {
        setbgImageCheckbox(false);
      }
    } else {
      setbgImageCheckbox(false);
    }
  }, [elementId, undoRedoChange]);

  const inputColor = (e) => {
    setbgColorCheckbox((prev) => ({ ...prev, color: !bgColorCheckbox.color }));
    if (e.target.checked === false) {
      if (element[0].getAttribute("id") === "dropzone") {
        let dropzoneBgImage = getComputedStyle(element[0]).backgroundImage;
        if (dropzoneBgImage === "none") {
          element.forEach((elem) => (elem.style.backgroundImage = ""));
        }
      }
      element.forEach((elem) => (elem.style.backgroundColor = "transparent"));
      setbgColorCheckbox({ color: false, opacity: false });
    } else {
      // if (element[0].getAttribute("id") === "dropzone") {
      //   let dropzoneBgImage = getComputedStyle(element[0]).backgroundImage;
      //   if (dropzoneBgImage.includes("canvas.cfd0ba05.png")) {
      //     element.forEach((elem) => (elem.style.backgroundImage = "none"));
      //   }
      // }
    }
  };

  const inputGradient = () => {
    setbgGradientCheckbox(!bgGradientCheckbox);
    if (bgGradientCheckbox) {
      const bgGradient = element[0].style.background;
      if (bgGradient.includes("linear") || bgGradient.includes("radial")) {
        element.forEach((elem) => {
          elem.style.background = "";
        });
      }
    }
  };
  const inputImage = (e) => {
    setbgImageCheckbox(!bgImageCheckbox);
    // if (element[0].getAttribute("id") === "dropzone") {
    //   if (bgColorCheckbox.color) {
    //     element.forEach((elem) => (elem.style.backgroundImage = "none"));
    //   }
    // }
    if (!e.target.checked) {
      const bgImage = getComputedStyle(element[0]).backgroundImage;
      if (bgImage.includes("url")) {
        element.forEach((elem) => {
          elem.style.backgroundImage = "";
          elem.style.backgroundSize = "";
          elem.style.backgroundRepeat = "";
          elem.style.backgroundPosition = "";
          elem.style.backgroundAttachment = "";
          elem.style.backgroundBlendMode = "";
        });
      }
    }
  };

  return (
    <Control
      heading={"Background"}
      className="bgControl"
      headingSize={"h1"}
      divider={false}
      isCollapse={true}
    >
      {bgColorCheckbox.color === true &&
      bgColorCheckbox.opacity === false &&
      bgImageCheckbox === false ? (
        <p className="warningAlert">
          <IoInformationCircleSharp size={12} color="orange" /> If Background
          Color is active then Background Gradient is disable
        </p>
      ) : null}
      {bgColorCheckbox.color === true && bgColorCheckbox.opacity === true ? (
        <p className="warningAlert">
          <IoInformationCircleSharp size={12} color="orange" /> If Background
          Color and Opacity is active then Background Gradient and Image is
          disable
        </p>
      ) : null}
      {bgGradientCheckbox ? (
        <p className="warningAlert">
          <IoInformationCircleSharp size={12} color="orange" /> If Background
          Gradient is active then Background Color and Image is disable
        </p>
      ) : null}
      {bgImageCheckbox ? (
        <p className="warningAlert">
          <IoInformationCircleSharp size={12} color="orange" /> If Background
          Image is active then Background Gradient is disable
        </p>
      ) : null}
      <Row
        label="Color"
        component="h3"
        element={
          <Check_Box
            name="animationCheckbox"
            color="secondary"
            size="medium"
            disabled={bgGradientCheckbox}
            checked={bgColorCheckbox.color}
            onClick={inputColor}
          />
        }
      />
      {bgColorCheckbox.color ? (
        <ColorBackground
          selector={selector}
          elementId={elementId}
          bgColorCheckbox={bgColorCheckbox}
          setbgColorCheckbox={setbgColorCheckbox}
          setbgImageCheckbox={setbgImageCheckbox}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
      ) : null}
      <Row
        label="Gradient"
        component="h3"
        element={
          <Check_Box
            name="animationCheckbox"
            color="secondary"
            size="medium"
            disabled={bgColorCheckbox.color || bgImageCheckbox}
            checked={bgGradientCheckbox}
            onClick={inputGradient}
          />
        }
      />
      {bgGradientCheckbox ? (
        <GradientBackground
          elementId={elementId}
          selector={selector}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
      ) : null}
      <Row
        label="Image"
        component="h3"
        element={
          <Check_Box
            name="animationCheckbox"
            color="secondary"
            size="medium"
            disabled={bgColorCheckbox.opacity || bgGradientCheckbox}
            checked={bgImageCheckbox}
            onClick={inputImage}
          />
        }
      />
      {bgImageCheckbox ? (
        <ImageBackground
          selector={selector}
          elementId={elementId}
          bgColorCheckbox={bgColorCheckbox}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
          bgImageCheckbox={bgImageCheckbox}
        />
      ) : null}
    </Control>
  );
};

export default Background;
