import { Alert } from "@mui/material";
import React from "react";

const CustomAlert = ({ children, action, icon, color = "info" }) => {
  const styled = {
    alert: {
      alignItems: "center",
      minHeight: "66px",
      borderRadius: "16px",
      color: "#313860",
      fontSize: "14px",
    },
    alertBtn: {
      fontSize: "12px",
      textTransform: "capitalize",
      boxShadow: "none",
    },
  };
  return (
    <div>
      <Alert color={color} style={styled.alert} action={action} icon={icon}>
        {children}
      </Alert>
    </div>
  );
};

export default CustomAlert;
