import React, { useEffect, useState, useRef, useCallback } from "react";
import { Grid, Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import "jquery-ui-dist/jquery-ui";
import "jquery-ui-rotatable";
import Card from "../../ui/Card";
import TopBar from "./topBar/TopBar";
import Leftbar from "./leftBar/LeftBar";
import Rightbar from "../righbar/Rightbar";
import RightbarProperties from "./RightbarProperties";
import { cleanDropzone, takeScreenShot } from "src/services/editor.service";
import { editorActions } from "src/store/reducers/editor-slice";
import { useParams } from "react-router-dom";
import { clearPresentationData } from "src/store/reducers/presentations-slice";
import { usePrompt } from "src/hooks/block_Navigation";
import Confirm from "../../dialogs/Confirm";
import { renderEditableElements, renderEmail } from "./renderEmail";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "src/styles/editor.scss";
import "src/styles/components.scss";
import { useEditorHistory } from "src/hooks/editor/history";
import { useElementStatus } from "src/hooks/editor/element.controller";
import { useEditorHeirarchy } from "src/hooks/editor/heirarchy.controller";

const Editor = (props) => {
  const {
    editorHtmlData,
    onSave = () => {},
    heading = "Editor",
    dimenssions,
    type,
    isLoading,
  } = props;

  const [error, setError] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [confirmNavigation, setConfirmNavigation] = useState({
    open: false,
    type: "alert",
  });
  const mainWrapperRef = useRef();
  const dropzoneRef = useRef();
  const saveDataRef = useRef();
  const callBackRef = useRef();
  const dispatch = useDispatch();
  const { updateHistory, disabledButtons } = useEditorHistory();
  const { activeElement, deActiveElement } = useElementStatus();
  const { requestHierarchyUpdate } = useEditorHeirarchy();

  // To clear editor's data
  const clearData = () => {
    dispatch(editorActions.clearEditorData());
    dispatch(clearPresentationData());
  };
  const { id: emailId } = useParams();
  const isAnyChange = !disabledButtons.isChangedSaved;

  useEffect(() => {
    // To clear redux data on unrender
    return () => {
      clearData();
      window.removeEventListener("beforeunload", beforeUnload);
    };
  }, []);

  // <------BLOCKING NAVIGATION START------>
  usePrompt(
    "Editor Have Some Unsaved Changes, Changes will be Lost \nDo you want to Exit ?",
    isAnyChange,
    (goBackFunc) => {
      if (goBackFunc) {
        toggleConfirmationDialog(true, "confirm");
        callBackRef.current = goBackFunc;
      } else {
        toggleConfirmationDialog(true, "alert");
      }
    }
  ); // since react-router-dom has not stable release (Use Prompt is'nt available) -- Using custom hook

  const beforeUnload = useCallback((event) => {
    event.preventDefault(); // for Firefox
    event.returnValue = ""; // for Chrome
    return "";
  }, []);

  useEffect(() => {
    if (isAnyChange) {
      window.addEventListener("beforeunload", beforeUnload);
    } else {
      window.removeEventListener("beforeunload", beforeUnload);
    }
  }, [isAnyChange]);
  // <------BLOCKING NAVIGATION END------>

  // IMPORT SLIDE CODE ENDS
  useEffect(() => {
    const dropzone = dropzoneRef.current;
    let cleanUp;

    try {
      cleanUp = renderEmail({
        dropzone,
        editorHtmlData,
        activeElement,
        updateHistory,
        requestHierarchyUpdate,
        deActiveElement,
      });
    } catch (e) {
      console.error(e);
      setError({
        message: "Oops ! something went wrong.",
        error: "Email HTML may be invalid!",
      });
    }

    return cleanUp;
  }, [editorHtmlData, mainWrapperRef, dropzoneRef]);

  // ----EXPORTING...
  const handleSlideExport = () => {
    setSaveLoading(true);
    const dropzone = dropzoneRef.current;
    const saveDataDiv = saveDataRef.current;
    // Filtering dropzone and appending it to saveData element (for capture and saving purpose)
    const filterDropzoneNode = cleanDropzone(dropzone, true);
    // rendering it's functionality because graph, carousel and functional components are not rendered !
    saveDataDiv.append(filterDropzoneNode);
    renderEditableElements({
      layersContainer: filterDropzoneNode,
    });

    // after filter making it visible take it's screen shot
    saveDataDiv.style.display = "block";
    // A setTimeout is implemented since waiting for graph to render !
    takeScreenShot(filterDropzoneNode).then(async (res) => {
      const [image, thumbnail] = res;

      // modifying changes after capture and before saving
      filterDropzoneNode.style.overflow = "auto";
      filterDropzoneNode.style.height = "auto";

      filterDropzoneNode
        .querySelectorAll("video")
        .forEach((video) => (video.controls = true));

      // after screenshot clear the saveDiv and make api call
      await onSave(saveDataDiv, image, thumbnail);
      // then clearing our div because it's job is closed
      saveDataDiv.innerHTML = "";
      setSaveLoading(false);
    });
  };

  // For opening and closing confirmation dialog
  const toggleConfirmationDialog = (open = true, type) => {
    setConfirmNavigation((prev) => ({
      type: type ? type : prev.type,
      open,
    }));
  };

  return (
    <>
      <Box className="editorPage">
        {isLoading ? (
          <div className="fullpage">
            <Typography variant="h1">Loading Presentation</Typography>
          </div>
        ) : error ? (
          <div className="fullpage">
            <Typography className="error">{error.message}</Typography>
            <Typography className="error">({error.error})</Typography>
          </div>
        ) : (
          saveLoading && (
            <div className="fullpage">
              <Typography variant="h1">Saving Email...</Typography>
            </div>
          )
        )}

        {/* Editor Header */}
        <Box className="topbar-height">
          <TopBar
            heading={heading}
            dimenssions={dimenssions}
            isAnyChange={isAnyChange}
            component={props.component}
            handleSlideExport={handleSlideExport}
            updateHistory={updateHistory}
            requestHierarchyUpdate={requestHierarchyUpdate}
            activeElement={activeElement}
          />
        </Box>
        {/*Editor Body */}
        <Box className="editorBody">
          <Grid container spacing={1}>
            <Grid item md={2}>
              <Card classes="editorCard leftbar">
                <Leftbar
                  requestHierarchyUpdate={requestHierarchyUpdate}
                  dropzoneRef={dropzoneRef}
                  component={props.component}
                />
              </Card>
            </Grid>
            <Grid item md={7.5}>
              <Card classes="editorCard main">
                <div
                  className="mainWrapper email-editor"
                  id="mainWrapper"
                  ref={mainWrapperRef}
                >
                  {/* EDITOR */}
                  <div
                    id="dropzone"
                    className="cs-slide editable"
                    style={{
                      width: "600px",
                      minHeight: "100%",
                      overflow: "auto",
                    }}
                    ref={dropzoneRef}
                  ></div>
                </div>
                <div
                  id="saveData"
                  style={{ display: "none" }}
                  ref={saveDataRef}
                ></div>
              </Card>
            </Grid>
            <Grid item md={2.5}>
              <Card classes="editorCard rightbar">
                <Rightbar
                  type={type}
                  id={emailId}
                  component="email"
                  ComponentProperties={RightbarProperties}
                />
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Confirm
        isOpen={confirmNavigation.open}
        alert={confirmNavigation.type == "alert"}
        subtitle={
          confirmNavigation.type == "alert"
            ? "Don't forget to save your changes , if you go back, your changes will be discarded without any confirmation, Please be careful before leaving !"
            : "If you go Back, Your Changes will be lost !"
        }
        confirmText="Discard"
        cancelText="Cancel"
        handleConfirm={() => {
          callBackRef.current();
        }}
        handleClose={() => {
          toggleConfirmationDialog(false);
        }}
      />
    </>
  );
};
export default Editor;
