import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { getCsClass, findComponentType } from "src/services/editor.service";
import { editorActions } from "src/store/reducers/editor-slice";

export const useEditorHeirarchy = () => {
  const dispatch = useDispatch();

  // To Update Heirarchy
  const requestHierarchyUpdate = useCallback(() => {
    var div = document.getElementById("dropzone");
    var elementsArray = [];
    var obj = {
      name: getCsClass(div.className),
      type: findComponentType(div.className),
      id: div.id,
      children: [],
    };
    elementsArray.push(obj);

    function getChildElements(element, parent) {
      var childElements = element.children;
      for (var i = 0; i < childElements.length; i++) {
        if (
          typeof childElements[i].className == "string" &&
          childElements[i].className.includes("cs-")
        ) {
          var obj = {
            name: getCsClass(childElements[i].className, i),
            type: findComponentType(childElements[i].className),
            id: childElements[i].id,
            children: [],
          };

          parent.children.push(obj);
          getChildElements(childElements[i], obj);
        } else {
          getChildElements(childElements[i], parent);
        }
      }
    }
    getChildElements(div, obj);
    dispatch(editorActions.updateHierachy(elementsArray));
  }, []);

  return { requestHierarchyUpdate };
};
