import React, { useEffect, useLayoutEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { canSendRequest } from "src/services/utils";
import { fetchEmailById } from "src/store/actions/emails";
import { setEmailAccess } from "src/store/reducers/emails-slice";

const AccessControl = ({ children, component }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { roles } = useSelector((state) => state.roles);
  const { emailAccess, email, config } = useSelector((state) => state.emails);

  // API call to get the presentation
  useLayoutEffect(() => {
    if (canSendRequest(config, id)) {
      dispatch(fetchEmailById(id));
    }
  }, [email]);

  //Navigate to my-library if user is not authorized to access the presentation
  useEffect(() => {
    if (!emailAccess) {
      dispatch(setEmailAccess(true));
      navigate("/emails/my-library");
    }
  }, [emailAccess, email]);

  return (
    <>
      {email._id === id &&
        (roles?.[email.role]?.[component] ? children : <Navigate to={-1} />)}
    </>
  );
};

export default React.memo(AccessControl);
