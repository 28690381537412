import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//Icons
import { IoAdd, IoCreate, IoCloudUploadOutline } from "react-icons/io5";

//MUI Componenets
import { Grid, Typography } from "@mui/material";

//APIs
import { fetchLandingPage } from "src/store/actions/microsites";

import Content from "src/layouts/Content";
import DropDown from "src/components/ui/DropDown";
import Pagination from "src/components/ui/Pagination";
import LandingPageCard from "src/components/microsites/LandingPageCard";
import Permission from "src/components/Permission"
//Dialogs
import MicrositesMyLibraryDialogs from "src/components/dialogs/microsites/MicrositesMyLibrary-Dialogs";

const MyLibrary = () => {
  const dispatch = useDispatch();
  const {
    list: microsites,
    count,
    loadingMsg,
  } = useSelector((state) => state.microsites);

  const [searchParams] = useSearchParams();
  const [details, setDetails] = useState({});
  const [micrositeId, setMicrositeId] = useState("");

  //Dialogs State
  const [dialog, setDialog] = useState({});

  const limit = 10;
  useEffect(() => {
    const pageNo = Math.round(Number(searchParams.get("page"))) || 1;
    const skip = (pageNo - 1) * limit;

    const params = {};
    searchParams.forEach((value, key) => {
      if (key !== "page") params[key] = value;
    });

    params.skip = skip;
    params.limit = limit;

    dispatch(fetchLandingPage(params));
  }, [searchParams]);

  const dropDownData = useMemo(() => {
    return [
      {
        label: "Create New",
        icon: <IoCreate />,
        permissionPath: "microsites.create.createViaTemplate",
        onClick: () => {
          openDialog("", "createNew");
        },
      },
      {
        label: "Upload From",
        icon: <IoCloudUploadOutline />,
        permissionPath: "microsites.create.uploadZipFile",
        onClick: () => {
          openDialog("", "uploadNew");
        },
      },
    ];
  }, []);

  //microsite info
  const showDetails = useCallback((landingPage) => {
    setDialog({ info: true });
    setDetails(landingPage);
  }, []);

  //open dialogs function
  const openDialog = useCallback((id, name) => {
    setMicrositeId(id);
    setDialog({ [name]: true });
  }, []);

  return (
    <>
      {/* Dialogs */}
      <MicrositesMyLibraryDialogs
        dialog={dialog}
        microsite={details}
        micrositeId={micrositeId}
        onClose={() => setDialog({})}
      />
      {/* Dialogs */}

      <Content
        title="Microsites"
        // subtitle={!loadingMsg && "New Microsites"}
        headerAction={
          <Permission permissionPath="microsites.create">
            <DropDown
              dropText="Add New"
              tooltip="options"
              startIcon={<IoAdd />}
              items={dropDownData}
            />
          </Permission>
        }
      >
        <Grid container spacing={3} display="flex" mb={8}>
          {microsites && !loadingMsg ? (
            microsites.map((microsite) => {
              return (
                <LandingPageCard
                  key={microsite._id}
                  microsite={microsite}
                  showDetails={showDetails}
                  openDialog={openDialog}
                  role="owner"
                  id={microsite._id}
                />
              );
            })
          ) : (
            <Typography variant="h1" pl={3} pt={2}>
              {loadingMsg}
            </Typography>
          )}
        </Grid>
        <Pagination
          count={count}
          itemsPerPage={limit}
          enableParams
          onChange={(value) => {
            if (
              !searchParams.get("search") &&
              !searchParams.get("language") &&
              !searchParams.get("country")
            ) {
              dispatch(fetchLandingPage(value));
            }
          }}
        />
      </Content>
    </>
  );
};
export default React.memo(MyLibrary);
