import Button from "@mui/material/Button";
import PermissionComp from "src/components/Permission";

const ContentAction = (props) => {
  return (
    <PermissionComp permissionPath={props.permissionPath}>
      <Button
        variant={props.variant}
        onClick={props.onClick}
        startIcon={props.icon}
        className={props.className}
        sx={props.sx}
        component={props.component}
        to={props.to}
      >
        {props.text}
      </Button>
    </PermissionComp>
  );
};

export default ContentAction;

ContentAction.defaultProps = {
  component: "button",
};
