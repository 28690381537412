import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Content from "src/layouts/Content";
import { Box, Grid, Typography } from "@mui/material";
import CampaignForm from "./CampaignForm";
import { useDispatch, useSelector } from "react-redux";
import { fetchCampaignById } from "src/store/actions/campaign";

export default function CreateCampaign() {
  const [loading, setLoading] = useState(true);
  const campaignDetails = useSelector((state) => state.campaign.campaignDetail);

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    dispatch(fetchCampaignById(id)).then(() => {
      setLoading(false);
    });
  }, [dispatch]);

  const onFinshHandler = () => {
    navigate("/campaign/list");
  };

  return (
    <Grid container gap={4}>
      <Grid item xs={12}>
        <Content classes="compaign-stepper" title="Update Campaign">
          {loading ? (
            <Box className="campaign-loader">
              <Typography variant="body3">Loading...</Typography>
            </Box>
          ) : (
            <CampaignForm
              campaignDetails={campaignDetails}
              editCampaignId={id}
              onFinish={onFinshHandler}
            />
          )}
        </Content>
      </Grid>
    </Grid>
  );
}
