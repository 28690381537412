import React from "react";
import { Grid, Typography, Box, IconButton, TextField } from "@mui/material";
import { IoSearch } from "react-icons/io5";

const MyTemplates = () => {
  return (
    <Grid container>
      <Grid item sm={8} md={8} display="flex" alignItems="center">
        <Typography variant="h3" color={"text.primary"}>
          Choose a template
        </Typography>
      </Grid>
      <Grid item sm={4} md={4} display="flex">
        <Box className="SearchBox">
          <IconButton
            color="primary"
            component="button"
            className="searchButton"
          >
            <IoSearch />
          </IconButton>
          <TextField placeholder="Search Here.." fullWidth />
        </Box>
      </Grid>
    </Grid>
  );
};

export default React.memo(MyTemplates);
