import React, { useState, useEffect } from "react";
import {
  IconButton,
  Button,
  DialogContent,
  DialogTitle,
  Box,
  FormControl,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import {
  convertRgbAToHexAplha,
  convertHexToRgba,
} from "src/services/editor.service";
import { ImBin } from "react-icons/im";
import Control from "./Control";
import { IoPencilSharp, IoAddCircle } from "react-icons/io5";
import Dialog from "src/components/dialogs/Main";
import Row from "src/components/properties/Row";
import "src/styles/properties.scss";
import Select_Box from "src/components/common/Select_Box";
import { drawGraph } from "../editorLogics/components";
const Graph = (props) => {
  const { elementId, updateHistory, undoRedoChange } = props;
  const graph = document.getElementById(elementId);

  const [isOpen, setisOpen] = useState(false);
  const [isAnyChange, setisAnyChange] = useState(false);
  // graph style
  const [graphData, setGraphData] = useState({
    type: "bar",
    xValues: [],
    datasets: [],
    animation: 0,
  });

  // closing dialog
  const closeHandler = () => {
    setisOpen(false);
    if (isAnyChange) {
      updateHistory();
      setisAnyChange(false);
    }
  };

  // Getting Element's Style
  const graphConfig = JSON.parse(graph.getAttribute("data-graph"));
  useEffect(() => {
    setGraphData({ ...graphConfig, animation: 0 });
  }, [elementId, undoRedoChange]);

  // updating data
  useEffect(() => {
    graph.setAttribute("data-graph", JSON.stringify(graphData));
    drawGraph(graph, graphData).update();
  }, [graphData]);

  // changing slide type
  const inputHandler = (e) => {
    const { name, value } = e.target;
    setGraphData((prev) => ({ ...prev, [name]: value, animation: 2000 }));
    updateHistory();
  };
  // adding Row
  const addRow = () => {
    const color = "rgba(39 ,143 ,29 , 1)";
    const xValue = "new Row";
    const yValue = Math.ceil(Math.random() * 50);

    const newDataSets = graphData.datasets.slice(); //copying data set
    newDataSets.forEach((obj, i) => {
      obj.data.push(yValue);
      obj.backgroundColor.push(color);
    });
    setGraphData((prev) => ({
      ...prev,
      xValues: [...prev.xValues, xValue],
      datasets: newDataSets,
    }));
  };

  // deleting row
  const deleteRow = (index) => {
    const clonedXValues = graphData.xValues.slice();
    const newDataSets = graphData.datasets.slice(); //copying data set

    // deleting the index which is called
    clonedXValues.splice(index, 1);
    newDataSets.forEach((obj) => {
      obj.data.splice(index, 1);
      obj.backgroundColor.splice(index, 1);
    });

    setGraphData((prev) => ({
      ...prev,
      datasets: newDataSets,
      xValues: clonedXValues,
    }));
  };

  // adding column
  const addColumn = () => {
    const dataToAdd = JSON.parse(
      JSON.stringify(graphData.datasets[graphData.datasets.length - 1])
    );
    setGraphData((prev) => ({
      ...prev,
      datasets: [...prev.datasets, dataToAdd],
    }));
  };

  // deleting column
  const deleteColumn = (rowIndex) => {
    const newDataSets = graphData.datasets.slice(); //copying data set
    newDataSets.splice(rowIndex, 1);
    setGraphData((prev) => ({
      ...prev,
      datasets: newDataSets,
    }));
  };

  let timeout;
  const updateGraphData = (e, i, rowIndex) => {
    // getting input values
    const { name, value } = e.target;
    // cloning to update it's data
    const clonedXValues = graphData.xValues.slice();
    const newDataSets = graphData.datasets.slice(); //copying data to modify it!
    // function to update state value
    const setUpdatedData = () => {
      setGraphData((prev) => ({
        ...prev,
        datasets: newDataSets,
        xValues: clonedXValues,
        animation: 0,
      }));
    };
    // updating color
    clearTimeout(timeout);
    if (name === "backgroundColor") {
      newDataSets.forEach((obj) => {
        obj.backgroundColor[i] = convertHexToRgba(value);
      });
      timeout = setTimeout(() => {
        setUpdatedData();
      }, 500);
      // updating name
    } else if (name === "name") {
      clonedXValues[i] = value;
      setUpdatedData();
      // updating values
    } else if (name === "data") {
      newDataSets[rowIndex].data[i] = value;

      setUpdatedData();
    }
    setisAnyChange(true);
  };

  const graphType = [
    { label: "Bar", value: "bar" },
    { label: "Pie", value: "pie" },
    { label: "PolarArea", value: "polarArea" },
    { label: "Radar", value: "radar" },
    { label: "Line", value: "line" },
  ];
  return (
    <>
      <Control
        heading="Graph Data"
        className="graph-property"
        isCollapse={true}
        divider={false}
        expanded={true}
      >
        <Box className="global-btn">
          <Button fullWidth variant="contained" onClick={() => setisOpen(true)}>
            Edit <IoPencilSharp className="icon" size="10px" />
          </Button>
        </Box>
        <Dialog
          className="graph-property control graph-dialog"
          isOpen={isOpen}
          handleClose={closeHandler}
          modalWidth="md"
          background="white"
        >
          <DialogTitle>Edit Graph Data</DialogTitle>
          <DialogContent>
            <table cellSpacing={0}>
              <tbody>
                {graphData.xValues.map((name, i) => {
                  const color = convertRgbAToHexAplha(
                    graphData.datasets[0].backgroundColor[i]
                  ).hex;
                  return (
                    <tr key={i}>
                      <td className="background">
                        <input
                          name="backgroundColor"
                          onChange={(e) => updateGraphData(e, i)}
                          value={color}
                          type="color"
                          className="bgColor mini"
                        />
                      </td>
                      <td className="name">
                        <input
                          name="name"
                          onChange={(e) => updateGraphData(e, i)}
                          value={name}
                        />
                      </td>
                      {graphData.datasets.map((dataset, rowIndex) => (
                        <td key={rowIndex}>
                          {i == 0 && (
                            <Tooltip title="Delete Column" arrow>
                              <IconButton
                                onClick={() => deleteColumn(rowIndex)}
                                disabled={graphData.datasets.length <= 1}
                                className="delete-column"
                              >
                                <ImBin color="inherit" size={11} />
                              </IconButton>
                            </Tooltip>
                          )}
                          <input
                            name="data"
                            onChange={(e) => updateGraphData(e, i, rowIndex)}
                            type="number"
                            value={dataset.data[i]}
                          />
                        </td>
                      ))}
                      <td>
                        <Tooltip title="Delete Row" arrow>
                          <IconButton
                            onClick={() => deleteRow(i)}
                            className="delete-icon"
                            disabled={graphData.xValues.length <= 1}
                          >
                            <ImBin color="inherit" size={14} />
                          </IconButton>
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Box className="graph-buttons">
              <Button
                variant="contained"
                endIcon={<IoAddCircle />}
                color="secondary"
                onClick={addRow}
              >
                Add Row
              </Button>
              <Button
                endIcon={<IoAddCircle />}
                variant="contained"
                color="secondary"
                onClick={addColumn}
              >
                Add Column
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </Control>

      <Control
        heading="Graph properties"
        isCollapse={true}
        divider={false}
        expanded={false}
      >
        <Row
          label="Graph Type"
          element={
            <Select_Box
              name="type"
              value={graphData.type}
              onChange={inputHandler}
              options={graphType}
            />
          }
        />
      </Control>
    </>
  );
};

export default Graph;
