import { createSlice } from "@reduxjs/toolkit";
import {
  cloneLandingPage,
  createLandingPage,
  exportLandingPage,
  fetchLandingPage,
  fetchLandingPageById,
  fetchTemplates,
  fetchLandingpageHtml,
  downloadVersionSource,
  importLandingPage,
  deleteLandingPage,
  uploadNewVersion,
} from "../actions/microsites";
import { downloadbyUrl, checkIsLock } from "src/services/utils";
import { checkIfReviewed, review } from "../actions/reviews";

const initialState = {
  list: [],
  count: 0,
  config: { id: null, requestTime: null },
  templates: [],
  landingPage: { country: "", lang: "" },
  landingPageAccess: true,
  landingpageHtml: null,
  loadingMsg: "Loading ...",
  preview: { micrositeId: "", isOpen: false, isReviewed: true },
};

const micrositeSlice = createSlice({
  name: "micrositeSlice",
  initialState,
  reducers: {
    setLandingPageAccess(state, action) {
      state.landingPageAccess = action.payload;
    },
    setLandingPagePreview(state, action) {
      state.preview = { ...action.payload, isReviewed: true };
    },
    clearLandingPageData(state, action) {
      if (!location.pathname.includes("edit")) {
        state.landingpageHtml = null;
      }
    },
    setLandingPageStatus(state, action) {
      const index = state.list.findIndex(
        (landingPage) => landingPage._id === action.payload.id
      );
      if (index !== -1) {
        state.list[index].status = action.payload.status;
        if (action.payload.options) {
          state.list[index].isLock = action.payload.options.isLock;
        }
      }
    },
  },
  extraReducers: (builder) => {
    //Create New Landing Page
    builder.addCase(createLandingPage.fulfilled, (state, action) => {
      state.loadingMsg = "";
      state.list.unshift(action.payload);
      if (state.count >= 12) {
        state.list.pop();
      }
      state.count++;
    });

    //Fetch Templates
    builder.addCase(fetchTemplates.fulfilled, (state, action) => {
      state.templates = action.payload;
    });

    //Fetch Landing Pages : Rejected
    builder.addCase(fetchLandingPage.pending, (state, action) => {
      state.landingPage = { country: "", lang: "" };
      (state.config = { id: null, requestTime: null }),
        (state.loadingMsg = "Loading...");
    });

    //Fetch Landing Pages : Fulfilled
    builder.addCase(fetchLandingPage.fulfilled, (state, action) => {
      const { response, count } = action.payload;

      const landingPages = response.map((landingPage) => {
        landingPage.isLock = checkIsLock(landingPage.status);
        return landingPage;
      });
      state.list = landingPages;
      state.count = count;
      if (response.length === 0) {
        state.loadingMsg = "No Microsites Found !";
      } else {
        state.loadingMsg = "";
      }
    });

    //Fetch Landing Pages : Rejected
    builder.addCase(fetchLandingPage.rejected, (state, action) => {
      state.loadingMsg = "An Error Occured !";
    });

    //Delete Landing Page
    builder.addCase(deleteLandingPage.fulfilled, (state, action) => {
      const index = state.list.findIndex(
        (landingPage) => landingPage._id === action.payload
      );
      if (index !== -1) {
        state.list.splice(index, 1);
      }
      if (state.list.length === 0) {
        state.loadingMsg = "No Microsites Found !";
      }
    });

    //Fetch Landing Page By Id
    builder.addCase(fetchLandingPageById.fulfilled, (state, action) => {
      const landingPage = action.payload;
      landingPage.isLock = checkIsLock(landingPage.status);
      state.landingPage = landingPage;

      state.config = {
        requestTime: Date.now(),
        id: action.payload._id,
      };
    });

    //Clone Landing Page
    builder.addCase(cloneLandingPage.fulfilled, (state, action) => {
      state.loadingMsg = "";
      state.list.unshift(action.payload);
      if (state.count >= 12) {
        state.list.pop();
      }
      state.count++;
    });

    //Import Landing Page
    builder.addCase(importLandingPage.fulfilled, (state, action) => {
      state.list.unshift(action.payload);
      state.loadingMsg = "";
      if (state.count >= 12) {
        state.list.pop();
      }
      state.count++;
    });

    //Export Landing Page
    builder.addCase(exportLandingPage.fulfilled, (state, action) => {
      downloadPdfByImages(action.payload);
    });

    //Download Version Source
    builder.addCase(downloadVersionSource.fulfilled, (state, action) => {
      downloadbyUrl(action.payload);
    });

    //getting html
    builder.addCase(fetchLandingpageHtml.fulfilled, (state, action) => {
      const html = new DOMParser().parseFromString(
        action.payload.html,
        "text/html"
      );
      const dropzone = html.body.querySelector("#dropzone");
      if (dropzone && dropzone.innerHTML) {
        state.landingpageHtml = action.payload.html;
      } else {
        state.landingpageHtml =
          '<div class="item-loading">No Data in This Microsite</div>';
      }
    });

    //Check if user has reviewed
    builder.addCase(checkIfReviewed.fulfilled, (state, action) => {
      state.preview.isReviewed = action.payload;
    });

    //Review
    builder.addCase(review.fulfilled, (state, action) => {
      state.preview.isReviewed = true;
    });

    builder.addCase(uploadNewVersion.fulfilled, (state, action) => {
      const index = state.list.findIndex(
        (microsite) => microsite._id === action.payload.id
      );
      if (index !== -1) {
        state.list[index].version = action.payload.version;
      }
    });
  },
});

export const {
  setLandingPageAccess,
  setLandingPagePreview,
  clearLandingPageData,
  setLandingPageStatus,
} = micrositeSlice.actions;
export default micrositeSlice.reducer;
