import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoSend, IoCloseSharp } from "react-icons/io5";
import {
  Typography,
  Autocomplete,
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
  Button,
  Box,
} from "@mui/material";
import Main from "./Main";
import "../../styles/presentations/sendForReview.scss";
import { uiActions } from "src/store/reducers/ui-slice";

const SendForReview = (props) => {
  const { isOpen, handleClose, onInput, onSend, type } = props;
  const dispatch = useDispatch();

  const initialState = {
    assignedTo: [],
    deadline: "",
    description: "",
    singleApproved: false,
  };

  const [data, setdata] = useState(initialState);
  const [userInputValue, setuserInputValue] = useState("");
  const [deadlineDate, setDeadlineDate] = useState();
  const [assinUser, setAssinUser] = useState([]);
  const [open, setOpen] = useState(false);

  const { list: userList, loading } = useSelector((state) => state.user);
  const loginUserEmail = useSelector((state) => state.auth.userData.email);
  //remove current login user and the user has developer role
  const listNotCurrentUser = userList.filter( listItem => listItem.email !== loginUserEmail && listItem.role !== 'developer');

  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    if (e.target.name === "deadline") {
      const selectedDate = new Date(value);
      selectedDate.setHours(23);
      selectedDate.setMinutes(59);
      selectedDate.setSeconds(59);

      const reviewDeadline = selectedDate.getTime();
      setDeadlineDate(value);
      setdata((prev) => ({
        ...prev,
        [name]: reviewDeadline,
      }));
    } else {
      setdata((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const inputOpenHandler = () => {
    if (onInput) {
      onInput();
    }
    setOpen(true);
  };
  const inputCloseHandler = () => {
    setOpen(false);
  };

  const usersInputHandler = (userInputValue) => {
    setuserInputValue(userInputValue);
  };

  const usersChangeHanlder = (users) => {
    console.log(users)
    setAssinUser(users);
    const assignedTo = users.map((user) => user._id);
    setdata((prev) => ({ ...prev, assignedTo }));
  };

  const submitHandler = () => {
    if (!data.deadline) {
      return dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Select Deadline!",
          message: "Please Select Review Deadline",
        })
      );
    }
    onSend({ ...data, type });
    setAssinUser([]);
    setDeadlineDate("");
    setdata(initialState);
    handleClose();
  };

  const closeReview = () => {
    setAssinUser([]);
    setdata(initialState);
    handleClose();
  };

  // prevent user to select past date
  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  return (
    <Main
      background="white"
      className="user-dialog"
      modalWidth="md"
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <Typography variant="h1">Send For Review</Typography>
      <Box className="user-content">
       
        <Box className="horizontal-line"></Box>
        <Typography className="user-label" variant="body2">
          Users
        </Typography>
        <Autocomplete
          disablePortal
          multiple
          open={open}
          onOpen={inputOpenHandler}
          onClose={inputCloseHandler}
          loading={loading}
          onChange={(_, val) => usersChangeHanlder(val)}
          onInputChange={(_, val) => usersInputHandler(val)}
          options={listNotCurrentUser}
          value={assinUser}
          isOptionEqualToValue={(option, value) => value._id === option._id}
          getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
          renderOption={(props, option) => (
            <Box component="li" {...props}  key={option._id}>
              <Box>
                <Typography className="option-label">
                  {option.firstName} {option.lastName}
                </Typography>
                <Typography>{option.email}</Typography>
              </Box>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              value={userInputValue}
              variant="standard"
              className="user-input"
              classes={{ input: "search-input" }}
              placeholder="Search for a user"
            />
          )}
          filterSelectedOptions
        />

        <Grid container className="marginY">
          <Grid item xs={3}>
            <Typography className="user-label">Review Deadline :</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="deadline"
              onChange={onChangeHandler}
              value={deadlineDate}
              className="user-input"
              variant="standard"
              type="date"
              inputProps={{ min: disablePastDate() }}
            />
          </Grid>
        </Grid>

        <Grid container className="marginY">
          <Grid item xs={3}>
            <Typography className="user-label">Description : </Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              name="description"
              onChange={onChangeHandler}
              value={data.description}
              fullWidth
              className="user-input"
              variant="standard"
            />
          </Grid>
        </Grid>

        <FormControlLabel
          control={
            <Checkbox name="singleApproved" onChange={onChangeHandler} />
          }
          classes={{ label: "user-label" }}
          value={data.singleApproved}
          label="A Single Approval is sufficient"
        />
      </Box>
      <Box className="buttons">
        <Button
          startIcon={<IoCloseSharp />}
          className="btn"
          variant="outlined"
          onClick={closeReview}
        >
          Cancel
        </Button>
        <Button
          startIcon={<IoSend />}
          className="btn"
          variant="contained"
          onClick={submitHandler}
          disabled={!assinUser.length}
        >
          Send
        </Button>
      </Box>
    </Main>
  );
};

export default React.memo(SendForReview);
