import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchReviewDetail } from "src/store/actions/reviews";
import { setLandingPagePreview } from "src/store/reducers/microsite-slice";
import Details from "../reviews/Details";

const ReviewDetail = () => {
  const type = "landingpage";
  const { id } = useParams();
  const dispatch = useDispatch();
  const { reviewDetail } = useSelector((state) => state.reviews);
  const { micrositeId, isOpen, reviewId, isReviewed, versionId } = useSelector(
    (state) => state.microsites.preview
  );

  useEffect(() => {
    dispatch(fetchReviewDetail({ id, type }));
  }, []);

  const preview = () => {
    dispatch(
      setLandingPagePreview({
        isOpen: true,
        micrositeId: reviewDetail?.landingPageId?._id,
        reviewId: id,
      })
    );
  };

  const versionPreview = (versionId) => {
    dispatch(
      setLandingPagePreview({
        isOpen: true,
        micrositeId: reviewDetail?.landingPageId?._id,
        versionId,
      })
    );
  };

  return (
    <Details
      preview={preview}
      detail={reviewDetail.landingPageId}
      actionPreview={versionPreview}
      review={reviewDetail}
    />
  );
};

export default ReviewDetail;
