import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Avatar,
  Stack,
  Button,
  Grid,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl,
} from "@mui/material";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

import { uiActions } from "src/store/reducers/ui-slice";
import { changePassword } from "src/store/actions/auth";

import Main from "src/components/dialogs/Main";
import Input from "src/components/ui/formElements/Input";
import "../../../src/styles/profile.scss";
import { updateUserData } from "src/store/actions/auth";

const MyAccount = () => {
  const { userData } = useSelector((state) => state.auth);
  console.log(userData,"userData")

  const dispatch = useDispatch();
  const { email } = userData;
  const [isOpen, setIsOpen] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [password, setPassword] = useState({
    new: "",
    confirm: "",
  });
  const [showPassword, setShowPassword] = useState({
    current: false,
    new: false,
    reNew: false,
  });

  const userDataUpdate = () => {
    dispatch(updateUserData({ userInfo, userId: userData._id }));
  };

  useEffect(() => {
    const { firstName, lastName } = userData;
    setUserInfo({ firstName, lastName });
  }, [userData]);

  const handleChangeInfo = (e) => {
    setUserInfo((pre) => ({ ...pre, [e.target.name]: e.target.value }));
  };

  const handleClickShowPassword = (input) => {
    setShowPassword({ ...showPassword, [input]: !showPassword[input] });
  };

  const handleChangePassword = (e) => {
    setPassword((pre) => ({ ...pre, [e.target.name]: e.target.value }));
  };

  const passwordChange = () => {
    if (password.new.trim().length < 8) {
      return dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Invalid",
          message: "Invalid New Password",
        })
      );
    } else if (password.confirm.trim().length < 8) {
      return dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Invalid",
          message: "Invalid Confirm Password",
        })
      );
    }

    if (password.new !== password.confirm) {
      return dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Invalid",
          message: "Both password are different.",
        })
      );
    }

    dispatch(changePassword({ password: password.new }));
    setIsOpen(false);
  };

  return (
    <>
      <Main isOpen={isOpen} fullWidth={false}>
        <Stack width="20rem" gap={3}>
          <FormControl variant="outlined">
            <InputLabel>New Password</InputLabel>
            <OutlinedInput
              name="new"
              onChange={handleChangePassword}
              type={showPassword.new ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleClickShowPassword("new")}
                    edge="end"
                  >
                    {showPassword.new ? <MdVisibilityOff /> : <MdVisibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel>Re-enter Password</InputLabel>
            <OutlinedInput
              name="confirm"
              onChange={handleChangePassword}
              type={showPassword.reNew ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleClickShowPassword("reNew")}
                    edge="end"
                  >
                    {showPassword.reNew ? (
                      <MdVisibilityOff />
                    ) : (
                      <MdVisibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Stack direction="row" justifyContent="flex-end">
            <Button
              onClick={() => {
                setIsOpen(false);
                setShowPassword({
                  current: false,
                  new: false,
                  reNew: false,
                });
              }}
            >
              Cancel
            </Button>
            <Button onClick={passwordChange}>Change</Button>
          </Stack>
        </Stack>
      </Main>
      <Grid container className="profileContainer">
        <Grid className="profileMain" item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={2}>
              <Avatar
                alt="Profile Photo"
                src="https://img.freepik.com/premium-vector/man-avatar-profile-round-icon_24640-14044.jpg?w=360"
              />
            </Grid>
            {userInfo && (
              <>
                <Grid item xs={3}>
                  <Input
                    name="firstName"
                    onChange={handleChangeInfo}
                    value={userInfo.firstName}
                    labelTag="First Name"
                  />
                </Grid>
                <Grid item xs={3}>
                  <Input
                    onChange={handleChangeInfo}
                    value={userInfo.lastName}
                    name="lastName"
                    labelTag="Last Name"
                  />
                </Grid>
              </>
            )}
          </Grid>

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={2}></Grid>
            <Grid item xs={3}>
              <Input labelTag="Email" value={email} disabled />
            </Grid>
            <Grid item xs={3}>
              <Button variant="contained" onClick={() => setIsOpen(true)}>
                Change Password
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            alignItems="center"
            className="fieldsContainer"
          >
            <Grid item xs={3}>
              <Button variant="contained" onClick={userDataUpdate}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MyAccount;
