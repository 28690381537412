import React from "react";

import Assets from "src/pages/digitalAssets/Assets";
import Main from "./Main";

import "../../styles/choose_asset.scss";
import "../../styles/assets.scss";
import { IconButton } from "@mui/material";
import { IoClose } from "react-icons/io5";

const ChooseAsset = (props) => {
  const { show, close } = props;
  return (
    <Main className="chooseAsset" isOpen={show} handleClose={close}>
      {/* <IconButton color="primary" className="btnCloseChooseAsset" onClick={close}>
        <IoClose />
      </IconButton> */}
      {show && (
        <Assets
          paginationParams={false}
          assetFormat={props.assetFormat}
          handleClose={close}
          updateHistory={props.updateHistory}
          setSrc={props.setSrc}
          type={props.type}
          show={show}
        />
      )}
    </Main>
  );
};

export default ChooseAsset;
