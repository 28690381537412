import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
} from "@mui/material";

//Icons for status field
import { AiOutlineClose } from "react-icons/ai";
import { BsCheck2 } from "react-icons/bs";
import { dateFormat } from "src/services/utils";

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const Tables = ({ rows, headCells, reviewStart, initialRows = 10, Action, disableVersion }) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initialRows);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    if (isAsc) {
      setOrder("desc");
    } else if (order === "desc") {
      setOrderBy("");
      setOrder("asc");
    } else {
      setOrderBy(property);
      setOrder("asc");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <>
      <TableContainer>
        <Table aria-labelledby="tableTitle">
          {/* Table Header Starts*/}
          <TableHead>
            <TableRow>
              {headCells.map((headCell, index, array) => (
                <TableCell
                  sx={{ textOverflow: "ellipsis",fontWeight:700 }}
                  key={headCell.id}
                  padding="normal"
                  align="left"
                  
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.sort ? (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  ) : (
                    headCell.label
                  )}
                </TableCell>
              ))}
              {Action && (
                <TableCell
                  sx={{ textOverflow: "ellipsis" }}
                  align="right"
                  padding="normal"
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          {/* Table Header Endes*/}

          {/* Table Body Starts*/}
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow padding="normal" hover tabIndex={-1} key={row._id}>
                    {!disableVersion && <TableCell align="left">{row?.version?.version}</TableCell>}
                    <TableCell align="left">{`${row.user?.firstName} ${row.user?.lastName}`}</TableCell>
                    <TableCell align="left">
                      {dateFormat(reviewStart)}
                    </TableCell>
                    <TableCell align="left">
                      {dateFormat(row.reviewedAt)}
                    </TableCell>
                    <TableCell align="left">
                      {row.status ? "Approved" : "Declined"}
                    </TableCell>
                    {Action && (
                      <TableCell sx={{ paddingRight: 0 }} align="right">
                        <Action versionId={row.version?.versionId} />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          {/* Table Body Starts*/}
        </Table>
      </TableContainer>
      {/* Table Pagination*/}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, { label: "All", value: rows.length }]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default Tables;
