import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../interfaces/api.interface";
import HttpService from "../../services/http.service";
import { uiActions } from "../reducers/ui-slice";
import { setPresentationStatus } from "../reducers/presentations-slice";
import { setEmailTemplateStatus } from "../reducers/emails-slice";
import { setLandingPageStatus } from "../reducers/microsite-slice";
import { setSharedStatus } from "../reducers/sharing-slice";
import { updateAssetStatus } from "../reducers/assets-slice";

// Send Presentation For Review
export const sendForReview = createAsyncThunk(
  "sendForReview",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.reviews.sendForReview(id),
        data,
        thunkAPI
      );
      if (response.data) {
        const updateStatus = {
          presentation: setPresentationStatus,
          email: setEmailTemplateStatus,
          landingpage: setLandingPageStatus,
          assets: updateAssetStatus,
        };

        const setStatus = updateStatus[data.type];

        if (setStatus) {
          //update status of module
          thunkAPI.dispatch(
            setStatus({ id, status: "inReview", options: { isLock: true } })
          );

          thunkAPI.dispatch(
            setSharedStatus({
              id,
              status: "inReview",
              options: { isLock: true },
            })
          );
        }

        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Added !",
            message: "Added For Review!",
          })
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

// Fetch All Presentation Assigned For Review
export const fetchReviews = createAsyncThunk(
  "fetchReviews",
  async (data, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.reviews.fetchReviews(data),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const filterReview = createAsyncThunk(
  "filterReview",
  async (data, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.reviews.applyFilter(),
        data,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

// Fetch Review Detail
export const fetchReviewDetail = createAsyncThunk(
  "fetchReviewDetail",
  async ({ id, type }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.reviews.fetchReviewDetails(id, type),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const checkIfReviewed = createAsyncThunk(
  "checkIfReviewed",
  async ({ id, type }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.reviews.checkIfReviewed(id, type),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const review = createAsyncThunk(
  "review",
  async ({ id, data, type }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.reviews.review(id, type),
        data,
        thunkAPI
      );
      await thunkAPI.dispatch(fetchReviewDetail({ id, type }));
      return { type, response: response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const cancelReview = createAsyncThunk(
  "cancelReview",
  async ({ id, type }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.reviews.cancelReview(id, type),
        undefined,
        thunkAPI
      );
      if (response.data) {
        const updateStatus = {
          presentation: setPresentationStatus,
          email: setEmailTemplateStatus,
          landingpage: setLandingPageStatus,
        };

        const setStatus = updateStatus[type];

        if (setStatus) {
          //update status of module
          thunkAPI.dispatch(
            setStatus({ id, status: "draft", options: { isLock: false } })
          );
          thunkAPI.dispatch(
            setSharedStatus({ id, status: "draft", options: { isLock: false } })
          );
        }
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
