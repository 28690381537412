import React, { useEffect, useState } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import Control from "../Control";
import Row from "src/components/properties/Row";
import Select_Box from "src/components/common/Select_Box";

const ImageProperties = (props) => {
  let { elementId, updateHistory, bgColorCheckbox, undoRedoChange, src } =
    props;
  const [backgroundImageProperties, setBackgroundImageProperties] = useState({
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left top",
    backgroundAttachment: "scroll",
    backgroundBlendMode: "multiply",
  });

  const element = document.getElementById(elementId);
  useEffect(() => {
    let bgSize = element.style.backgroundSize;
    let bgRepeat = element.style.backgroundRepeat;
    let bgPosition = element.style.backgroundPosition;
    let bgAttachement = element.style.backgroundAttachment;
    let bgBlendMode = element.style.backgroundBlendMode;
    if (bgSize || bgRepeat || bgPosition || bgAttachement || bgBlendMode) {
      if (bgBlendMode === undefined || bgBlendMode === "") {
        bgBlendMode = "multiply";
      }
      setBackgroundImageProperties({
        backgroundSize: bgSize.length === 0 ? "cover" : bgSize,
        backgroundRepeat: bgRepeat.length === 0 ? "no-repeat" : bgRepeat,
        backgroundPosition: bgPosition.length === 0 ? "left top" : bgPosition,
        backgroundAttachment:
          bgAttachement.length === 0 ? "scroll" : bgAttachement,
        backgroundBlendMode: bgBlendMode.length === 0 ? "multiply" : bgBlendMode,
      });
    }
  }, [elementId, undoRedoChange]);

  useEffect(() => {
    element.style.backgroundSize = backgroundImageProperties.backgroundSize;
    element.style.backgroundRepeat = backgroundImageProperties.backgroundRepeat;
    element.style.backgroundPosition =
      backgroundImageProperties.backgroundPosition;
    element.style.backgroundAttachment =
      backgroundImageProperties.backgroundAttachment;
    element.style.backgroundBlendMode =
      backgroundImageProperties.backgroundBlendMode;
  }, [backgroundImageProperties]);

  const handleChange = (e) => {
    let { name, value } = e.target;
    setBackgroundImageProperties({
      ...backgroundImageProperties,
      [name]: value,
    });
    updateHistory();
  };

  const backgroundSize = [
    { label: "auto", value: "auto" },
    { label: "cover", value: "cover" },
    { label: "contain", value: "contain" },
    { label: "initial", value: "initial" },
  ];

  const backgroundRepeat = [
    { label: "repeat", value: "repeat" },
    { label: "repeat-x", value: "repeat-x" },
    { label: "repeat-y", value: "repeat-y" },
    { label: "no-repeat", value: "no-repeat" },
    { label: "initial", value: "initial" },
  ];

  const backgroundPosition = [
    { label: "left top", value: "left top" },
    { label: "left center", value: "left center" },
    { label: "left bottom", value: "left bottom" },
    { label: "right top", value: "right top" },
    { label: "right center", value: "right center" },
    { label: "right bottom", value: "right bottom" },
    { label: "center top", value: "center top" },
    { label: "center center", value: "center center" },
    { label: "center bottom", value: "center bottom" },
    { label: "initial", value: "initial" },
  ];

  const backgroundAttachment = [
    { label: "scroll", value: "scroll" },
    { label: "fixed", value: "fixed" },
    { label: "local", value: "local" },
    { label: "initial", value: "initial" },
  ];

  const backgroundBlendMode = [
    { label: "normal", value: "normal" },
    { label: "multiply", value: "multiply" },
    { label: "screen", value: "screen" },
    { label: "overlay", value: "overlay" },
    { label: "darken", value: "darken" },
    { label: "lighten", value: "lighten" },
  ];
  
  return (
    <>
      <Control heading="Image Properties" headingVariant="p" divider={false}>
        <Row
          label="Size"
          element={
            <Select_Box
              name="backgroundSize"
              value={backgroundImageProperties["backgroundSize"]}
              onChange={handleChange}
              options={backgroundSize}
              disabled={!src}
            />
          }
        />

        <Row
          label="Repeat"
          element={
            <Select_Box
              name="backgroundRepeat"
              value={backgroundImageProperties["backgroundRepeat"]}
              onChange={handleChange}
              options={backgroundRepeat}
              disabled={!src}
            />
          }
        />

        <Row
          label="Position"
          element={
            <Select_Box
              name="backgroundPosition"
              value={backgroundImageProperties["backgroundPosition"]}
              onChange={handleChange}
              options={backgroundPosition}
              disabled={!src}
            />
          }
        />

        <Row
          label="Attachment"
          element={
            <Select_Box
              name="backgroundAttachment"
              value={backgroundImageProperties["backgroundAttachment"]}
              onChange={handleChange}
              options={backgroundAttachment}
              disabled={!src}
            />
          }
        />

        <Row
          label="Blend Mode"
          element={
            <Select_Box
              name="backgroundBlendMode"
              value={backgroundImageProperties["backgroundBlendMode"]}
              onChange={handleChange}
              options={backgroundBlendMode}
              disabled={!bgColorCheckbox.color || !src}
            />
          }
        />
      </Control>
    </>
  );
};

export default ImageProperties;
