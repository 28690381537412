import { createSlice } from "@reduxjs/toolkit";
import { deleteUser, getAllCompanyUsers, getUser } from "../actions/users";
const initialState = {
  list: [],
  loading: false,
  userToEdit: {
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    mobile: "",
    phone: "",
    fax: "",
    lang: "",
    timezone: "",
    title: "",
    company: {
      companyId: "",
      name: "",
      UID: "",
      assetReview: null,
    },
    manager: {
      name: "",
      userId: "",
    },
    address: {
      street: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
    },
  },
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: (builder) => {
    // Get All Users by company id
    builder.addCase(getAllCompanyUsers.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getAllCompanyUsers.fulfilled, (state, action) => {
      state.list = action.payload;
      state.loading = false;
    });

    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.list = state.list.filter((u) => u._id !== action.payload.id);
    });

    builder.addCase(getAllCompanyUsers.rejected, (state, action) => {});

    builder.addCase(getUser.fulfilled, (state, action) => {
      state.userToEdit = action.payload;
    });
  },
});
export default usersSlice.reducer;
