import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Box, Tooltip } from "@mui/material";
import { IoCubeOutline, IoGridOutline, IoLayersOutline, IoInformationCircleOutline } from "react-icons/io5";

import Toolbar from "./toolBar/Toolbar";
import Layers from "../../../common/Layers";
import { microsite_toolbar_items } from "./toolBar/_toolbar_components";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Leftbar = (props) => {
  const columnTabIndex = 1;
  const columnClass = "cs-col";
  const [value, setValue] = useState(columnTabIndex);
  const { dropzoneRef, requestHierarchyUpdate } = props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const confirmDragStart = () => {
    if (!dropzoneRef.current.querySelector("." + columnClass)) {
      setValue(columnTabIndex);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        {/* Header */}
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className="three-width"
        >
          <Tooltip title="Components" arrow>
            <Tab icon={<IoGridOutline />} {...a11yProps(0)} className="btnTab" />
          </Tooltip>

          <Tooltip title="Layouts" arrow>
            <Tab icon={<IoCubeOutline />} {...a11yProps(1)} className="btnTab"/>
          </Tooltip>

          <Tooltip title="Hierarchy" arrow>
            <Tab icon={<IoLayersOutline />} {...a11yProps(3)} className="btnTab"/>
          </Tooltip>
        </Tabs>
      </Box>
      {/* Body */}
      <TabPanel value={value} index={0} className="tabPanel">
        <Toolbar
          requestHierarchyUpdate={requestHierarchyUpdate}
          items={microsite_toolbar_items.components}
          component={props.component}
          zoom={props.zoom}
          dropzoneRef={dropzoneRef}
          onItemDragStart={confirmDragStart}
          info="Add an item to the columns."
        />
      </TabPanel>
      <TabPanel value={value} index={1} className="tabPanel">
        <Toolbar
          requestHierarchyUpdate={requestHierarchyUpdate}
          className="layouts-icon"
          items={microsite_toolbar_items.layouts}
          component={props.component}
          dropzoneRef={dropzoneRef}
          info={<><IoInformationCircleOutline /> Please ensure that you use a layout for components as normal components cannot be directly appended. </>}
        />
      </TabPanel>
      <TabPanel value={value} index={2} className="tabPanel">
        <Layers />
      </TabPanel>
    </Box>
  );
};
export default Leftbar;
