import React, { useState, useEffect } from "react";
import { IconButton } from "@mui/material";
import { AiOutlineColumnHeight, AiOutlineColumnWidth } from "react-icons/ai";
import Control from "./Control";
import "src/styles/properties.scss";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import {
  useDeFormattedStyle,
  applyControlCss,
  covertStyleStrToObject,
} from "src/services/editor.service";
import Row from "src/components/properties/Row";
import { HISTORY_ACTIONS } from "src/data/contants";
import { useEditorHistory } from "src/hooks/editor/history";
import { csRenderFunctional, useSetLayerHeight } from "../editorLogics/components";

const Styles = (props) => {
  const { elementId, undoRedoChange } = props;

  const { updateHistory: editorUpdateHistory } = useEditorHistory();

  const [magnet, setMagnet] = useState(false);
  const [previousHeight, setPreviousHeight] = useState("");

  let elementStyle = null;
  if (document.getElementById(elementId)) {
    const currentElement = document
      .getElementById(elementId)
      .getAttribute("style");
    elementStyle = useDeFormattedStyle(covertStyleStrToObject(currentElement));
  }
  //default styling
  const [styles, setStyles] = useState({
    left: "0",
    top: "0",
    height: "0",
    width: "0",
    transform: "10",
    "border-radius": "0",
    "z-index": "10",
  });

  //replace default value with current style of active element
  useEffect(() => {
    setStyles((prev) => ({ ...prev, ...elementStyle }));
  }, [elementId, undoRedoChange]);

  const updateHistory = (action = HISTORY_ACTIONS.STYLED_ACTIONS.common) => {
    editorUpdateHistory({
      action,
      id: elementId,
    });
  };

  const inputHandler = (e) => {
    let { name, value } = e.target;
    const layer = $(".cs-layer.active");

    applyControlCss(elementId, name, value);

    //set layer height width
    if (magnet) {
      if (name == "width") {
        useSetLayerHeight(layer);
      } else if (name == "height") {
        const layerWidth = layer.outerWidth();
        applyControlCss(elementId, "width", layerWidth + 1);

        if (previousHeight > value) {
          applyControlCss(elementId, "width", layerWidth - 1);
        } else if (previousHeight < value) {
          applyControlCss(elementId, "width", layerWidth + 1);
        }
        setPreviousHeight(value);
      }
    }

    // To reRender  resize carousel and coverflow
    const csComponent = layer.find(".editable");
    const clsArr = layer.attr("class").split(" ");
    let elType = clsArr.find((c) => c.includes("component"));
    if (
      elType &&
      (elType.includes("carousel") || elType.includes("coverflow"))
    ) {
      csRenderFunctional(csComponent);
    }

    if (name === "z-index") {
      if (+value > 1500) {
        value = "1500";
      }

      if (elType && elType.includes("popup")) {
        layer[0].nextSibling.style.zIndex = layer[0].style.zIndex - 1;
      }
    }

    setStyles((prev) => ({ ...prev, [name]: value }));
  };

  const getHandle = () => {
    let size = {};
    const element = document.getElementById(elementId);

    if (element.querySelector(".ui-resizable-e")) {
      size.width = true;
    } else {
      size.width = false;
    }
    if (element.querySelector(".ui-resizable-s")) {
      size.height = true;
    } else {
      size.height = false;
    }

    return size;
  };

  const maxHeightHandler = () => {
    const element = document.getElementById(elementId);
    element.style.top = "0";
    element.style.height = "100%";
    element.style.height = element.clientHeight + "px";
    setStyles((pre) => ({
      ...pre,
      top: "0",
      height: element.clientHeight.toString(),
    }));
    updateHistory(HISTORY_ACTIONS.STYLED_ACTIONS.resized);
  };

  const maxWidthHandler = () => {
    const element = document.getElementById(elementId);
    element.style.left = "0";
    element.style.width = "100%";
    element.style.width = element.clientWidth + "px";

    setStyles((pre) => ({
      ...pre,
      left: "0",
      width: element.clientWidth.toString(),
    }));
    useSetLayerHeight($(element));
    setStyles((pre) => ({
      ...pre,
      height: element.clientHeight.toString(),
    }));
    updateHistory(HISTORY_ACTIONS.STYLED_ACTIONS.resized);
  };

  return (
    <Control
      heading="Layer Styles"
      className="styles"
      divider={false}
      isCollapse={true}
      expanded={true}
    >
      <Row
        label="Position-X"
        element={
          <input
            type="number"
            name="left"
            className="input"
            value={styles["left"]}
            onChange={inputHandler}
            onBlur={() => {
              updateHistory(HISTORY_ACTIONS.STYLED_ACTIONS.dragged);
            }}
          />
        }
      />

      <Row
        label="Position-Y"
        element={
          <input
            type="number"
            name="top"
            className="input"
            value={styles["top"]}
            onChange={inputHandler}
            onBlur={() => {
              updateHistory(HISTORY_ACTIONS.STYLED_ACTIONS.dragged);
            }}
          />
        }
      />

      <Row
        label="Width"
        element={
          <>
            {getHandle().width && (
              <IconButton
                onClick={maxWidthHandler}
                sx={{ fontSize: "inherit", color: "#2cc1d6", padding: "2px" }}
              >
                <AiOutlineColumnWidth />
              </IconButton>
            )}
            <input
              type="number"
              name="width"
              className="input"
              value={styles["width"]}
              onChange={inputHandler}
              onBlur={() => {
                updateHistory(HISTORY_ACTIONS.STYLED_ACTIONS.resized);
              }}
            />
          </>
        }
      />

      {getHandle().height && (
        <Row
          label="Height"
          element={
            <>
              <IconButton
                onClick={maxHeightHandler}
                sx={{ fontSize: "inherit", color: "#2cc1d6", padding: "2px" }}
              >
                <AiOutlineColumnHeight />
              </IconButton>
              <input
                type="number"
                name="height"
                className="input"
                value={styles["height"]}
                onChange={inputHandler}
                onBlur={() => {
                  updateHistory(HISTORY_ACTIONS.STYLED_ACTIONS.resized);
                }}
              />
            </>
          }
        />
      )}

      <Row
        label="Rotate"
        element={
          <input
            type="number"
            name="transform"
            className="input"
            value={styles["transform"]}
            onChange={inputHandler}
            onBlur={() => {
              updateHistory(HISTORY_ACTIONS.STYLED_ACTIONS.rotated);
            }}
          />
        }
      />

      <Row
        label="Radius"
        element={
          <input
            type="number"
            name="border-radius"
            className="input"
            value={styles["border-radius"]}
            onChange={inputHandler}
            onBlur={() => {
              updateHistory(HISTORY_ACTIONS.STYLED_ACTIONS.borderChange);
            }}
          />
        }
      />

      <Row
        label="Z-Index"
        element={
          <input
            type="number"
            name="z-index"
            className="input"
            min="0"
            value={styles["z-index"]}
            onChange={inputHandler}
            onBlur={() => {
              updateHistory(HISTORY_ACTIONS.STYLED_ACTIONS.zIndexChange);
            }}
          />
        }
      />
    </Control>
  );
};

export default Styles;
