import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "@mui/material";
import { checkIfReviewed } from "src/store/actions/reviews";
import { review } from "src/store/actions/reviews";
import MicrositePreview from "src/pages/microsites/MicrositePreview";
import {
  clearLandingPageData,
  setLandingPagePreview,
} from "src/store/reducers/microsite-slice";

const MicrositePreviewDialog = (props) => {
  const type = "landingpage";
  const dispatch = useDispatch();
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [isLoadingDeclined, setIsLoadingDeclined] = useState(false);

  const { micrositeId, isOpen, reviewId, isReviewed, versionId } = useSelector(
    (state) => state.microsites.preview
  );
  const approveOrDecline = (status) => {
    status ? setIsLoadingApprove(true) : setIsLoadingDeclined(true);
    if (reviewId) {
      const data = { status };
      dispatch(review({ id: reviewId, data, type })).then((res) =>{
        status ? setIsLoadingApprove(false) : setIsLoadingDeclined(false)
      })
    }
  };

  useEffect(() => {
    if (isOpen) {
      clearData();
    }
  }, [isOpen]);

  const handleClose = () => {
    dispatch(setLandingPagePreview({ micrositeId, isOpen: false }));
    clearData();
  };

  useEffect(() => {
    if (reviewId) {
      dispatch(checkIfReviewed({ id: reviewId, type }));
    }
  }, [reviewId]);

  // To clear editor's data
  const clearData = () => {
    dispatch(clearLandingPageData());
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          height: "100%",
        },
      }}
      fullScreen
      fullWidth
    >
      <MicrositePreview
        reviewMicrosite={approveOrDecline}
        micrositeId={micrositeId}
        handleClose={handleClose}
        isReviewed={isReviewed}
        versionId={versionId}
        isLoadingApprove={isLoadingApprove}
        isLoadingDeclined={isLoadingDeclined}
      />
    </Dialog>
  );
};

export default MicrositePreviewDialog;
