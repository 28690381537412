import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  MenuItem,
  Select,
} from "@mui/material";
import ChooseAsset from "src/components/dialogs/ChooseAsset";
import Control from "./Control";
import pdfPng from "src/assets/images/pdf.png";
import Row from "src/components/properties/Row";
import "src/styles/properties.scss";
import Check_Box from "src/components/common/Check_Box";
import Select_Box from "src/components/common/Select_Box";
import { renderSitemap, siteMapActions } from "../editorLogics/components";

const Actions = ({ elementId, updateHistory, undoRedoChange }) => {
  const element = document.getElementById(elementId);
  const popups = document.querySelectorAll(".cs-popup");

  const { bucketURL } = useSelector(
    (state) => state.presentations.presentation
  );

  // Assets Dialog code
  const [isOpen, setIsOpen] = useState(false);
  const toggleDialog = () => {
    setIsOpen(!isOpen);
  };
  // to get assets src
  const [src, setSrc] = useState("");
  // Getting slide data for slide navigation acion
  let { presentationSlides: slides } = useSelector(
    (state) => state.presentations
  );
  slides = JSON.parse(JSON.stringify(slides));
  const activeSlideIndex = slides.findIndex(
    (slide) => slide.id == useParams().slideId
  );

  const [actions, setActions] = useState({
    enabled: true,
    action: "navigation",
    event: "tap",
    navigateTo: "otherSlide",
    navigationSlide: {
      name: "slide1",
      thumbnail: "",
      index: 0,
    },
    openPopup: "",
  });

  // Getting Dropzone Popups
  const popupMenu = [];
  if (popups.length) {
    popups.forEach((element, i) => {
      if (!element.dataset.popupRef) {
        element.dataset.popupRef = "popup-" + Math.ceil(Math.random() * 1000);
      }
      popupMenu.push({
        label: `${i + 1}- ${element.dataset.label}`,
        value: element.dataset.popupRef,
      });
    });

    if (!popupMenu.find((data) => data.value === actions.openPopup)) {
      setActions((prev) => ({ ...prev, openPopup: popupMenu[0].value }));
    }
  }
  if (slides.length && !slides[0].thumbnail.includes("http")) {
    slides.forEach((slide) => {
      slide.thumbnail = bucketURL + "/" + slide.thumbnail;
    });
  }
  // Getting Element Data
  useEffect(() => {
    const {
      action,
      actionEvent: event,
      slideIndex,
      navigateTo,
      openPopup,
    } = element.dataset;
    if (action) {
      setActions((prev) => ({
        ...prev,
        enabled: true,
        navigationSlide:
          slideIndex !== undefined
            ? {
              ...slides[slideIndex],
              index: slideIndex,
            }
            : slides[slides.length - 1],
        navigateTo: navigateTo ? navigateTo : prev.navigateTo,
        openPopup: openPopup ? openPopup : prev.openPopup,
        action,
        event,
      }));
      setSrc(element.dataset.pdf);
    } else setActions((prev) => ({ ...prev, enabled: false }));
  }, [elementId, undoRedoChange]);

  const applydataAttributes = () => {
    // Deleting all data attributes if any
    delete element.dataset.action;
    delete element.dataset.actionEvent;
    delete element.dataset.openPopup;
    delete element.dataset.slideIndex;
    delete element.dataset.navigateTo;

    if (actions.enabled) {
      // compulsory attributes if actions are enabled
      element.dataset.action = actions.action;
      element.dataset.actionEvent = actions.event;

      // if user have seected pdf file and actions have openPDf
      if (src && src.includes("pdf")) {
        if (actions.action === "openPdf") {
          element.dataset.pdf = src;
        } else {
          element.dataset.pdf = null;
          setSrc("");
        }
      }

      if (actions.action === "navigation") {
        element.dataset.slideIndex = actions.navigationSlide.index;
        element.dataset.navigateTo = actions.navigateTo;
      } else if (actions.action === "openPopup" && actions.openPopup) {
        element.dataset.openPopup = actions.openPopup;
      }
    } else setSrc("");
  };

  useEffect(() => {
    applydataAttributes();
  }, [actions, src]);

  const toggleActions = (e) => {
    const enabled = e.target.checked;
    setActions((prev) => ({ ...prev, enabled }));
    if (!enabled) {
      setActions((prev) => ({
        ...prev,
        action: "navigation",
        event: "tap",
        navigateTo: "otherSlide",
        navigationSlide: {
          name: "slide1",
          thumbnail: "",
          index: 0,
        },
        openPopup: "",
      }));
    }
    updateHistory();
  };

  const actionsHandler = (e) => {
    const { name, value } = e.target;
    // Changing slide data for navigation
    if (name === "navigateTo") {
      let index = slides.length - 1;
      if (value === "previousSlide") {
        index = activeSlideIndex - 1;
      } else if (value === "nextSlide") {
        index = activeSlideIndex + 1;
      }
      if (value == 'otherSlide') {
        selectNavigateSlide()
      }

      const navigationSlide = slides[index];
      setActions((prev) => ({
        ...prev,
        navigationSlide: {
          ...navigationSlide,
          index,
        },
      }));
    }

    setActions((prev) => ({ ...prev, [name]: value }));
    updateHistory();
  };

  const selectNavigateSlide = () => {
    siteMapActions.slides = slides;
    // the function will call from sitemap modal
    siteMapActions.switchSlide = (index) => {
      const slideToNavigate = slides[index];
      setActions((prev) => ({
        ...prev,
        navigationSlide: {
          ...slideToNavigate,
          index,
        },
      }));
      updateHistory();
    };
    // opening modal
    renderSitemap(null, activeSlideIndex, actions.navigationSlide.index);
    siteMapActions.openModal();
  };

  const actionDropdown = [
    { label: "Navigation", value: "navigation" },
    { label: "Open Pdf", value: "openPdf" },
    { label: "Site Map", value: "siteMap" },
    { label: "Open Popup", value: "openPopup" },
  ];

  const navigateToDropdown = [
    {
      label: "  Previous slide",
      value: "previousSlide",
      disabled: !slides[activeSlideIndex - 1],
    },
    {
      label: " Next Slide",
      value: "nextSlide",
      disabled: !slides[activeSlideIndex + 1],
    },
    {
      label: "Other Slide",
      value: "otherSlide",
    },
  ];

  const eventDropdown = [
    { label: "Tap", value: "tap" },
    { label: "Hold", value: "hold" },
  ];

  return (
    <Control
      heading="Actions"
      className="actions"
      divider={false}
      isCollapse={true}
      expanded={false}
    >
      <Row
        label="Enable"
        component="h3"
        element={
          <Check_Box
            color="secondary"
            size="medium"
            checked={actions.enabled}
            onChange={toggleActions}
          />
        }
      />

      {/* if actions are enabled */}
      {actions.enabled && (
        <>
          <Row
            label="Action"
            element={
              <Select_Box
                name="action"
                value={actions.action}
                onChange={actionsHandler}
                options={actionDropdown}
              />
            }
          />

          {/* Dynamic Rendering data */}
          {actions.action === "openPdf" && (
            <>
              {src && src.includes("pdf") && (
                <Button fullWidth className="selected-btn">
                  <a href={src} target="_blank">
                    <Grid container alignItems="center">
                      <Grid item xs={3}>
                        <img src={pdfPng} width="36px" />
                      </Grid>
                      <Grid item xs={9}>
                        <label style={{ cursor: "pointer" }}>
                          PDF File Selected
                        </label>
                      </Grid>
                    </Grid>
                  </a>
                </Button>
              )}
              <Button
                className="selected-btn"
                variant="contained"
                fullWidth
                onClick={toggleDialog}
              >
                {src ? "Change" : "Select PDF"}
              </Button>
            </>
          )}

          {actions.action === "navigation" && (
            <>
              <Row
                label="Navigate To"
                element={
                  <Select_Box
                    name="navigateTo"
                    value={actions.navigateTo}
                    onChange={actionsHandler}
                    options={navigateToDropdown}
                  />
                }
              />

              <Button
                className="selected-btn"
                sx={{ textAlign: "left" }}
                fullWidth
                disabled={actions.navigateTo !== "otherSlide"}
                onClick={selectNavigateSlide}
              >
                <Row
                  label={actions.navigationSlide.name}
                  element={<img src={actions.navigationSlide.thumbnail} />}
                />
              </Button>
            </>
          )}

          {actions.action === "openPopup" && (
            <>
              {popupMenu.length ? (
                <Row
                  label="Select Popup"
                  element={
                    <Select_Box
                      name="openPopup"
                      value={actions.openPopup}
                      onChange={actionsHandler}
                      options={popupMenu}
                    />
                  }
                />
              ) : (
                <label className="error">No Popup were found on dropzone</label>
              )}
            </>
          )}
          <Row
            label="Event"
            element={
              <Select_Box
                name="event"
                value={actions.event}
                onChange={actionsHandler}
                options={eventDropdown}
              />
            }
          />
        </>
      )}

      <ChooseAsset
        assetFormat="document"
        setSrc={setSrc}
        updateHistory={updateHistory}
        show={isOpen}
        close={toggleDialog}
      />
    </Control>
  );
};

export default Actions;
