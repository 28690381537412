import React, { useEffect, useState, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import { IconButton } from "@mui/material";
import { IoTrash } from "react-icons/io5";
import Confirm from "src/components/dialogs/Confirm";
import { editorActions } from "src/store/reducers/editor-slice";
import "jquery-ui-dist/jquery-ui";
import "src/styles/layers.scss";
import { HISTORY_ACTIONS } from "src/data/contants";
import { useEditorHeirarchy } from "src/hooks/editor/heirarchy.controller";
import { useEditorHistory } from "src/hooks/editor/history";

const Layers = () => {
  const dispatch = useDispatch();
  const { requestHierarchyUpdate } = useEditorHeirarchy();
  const { updateHistory } = useEditorHistory();
  const activeEditable = useSelector((state) => state.editor.activeEditable);
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState("");
  const hierarchy = useSelector((state) => state.editor.hierarchy);

  const deleteLayer = (e) => {
    updateHistory({ id: id, action: HISTORY_ACTIONS.deleted });
    dispatch(editorActions.deActiveElement());
    // As popup has a sibling overlay
    // So deleting it conditionally
    if (
      $("#" + id)
        .attr("class")
        .includes("popup")
    ) {
      $("#" + id)
        .next(".overlay")
        .remove();
      $("#" + id).remove();
    } else {
      $("#" + id).remove();
    }

    // reading layer and setting Hierarchy
    requestHierarchyUpdate();
    return true;
  };

  const openDialog = (e) => {
    e.stopPropagation();
    setId(e.currentTarget.closest("li").dataset.target);
    $(id).trigger("click");
    setIsOpen(true);
  };
  const closeDialog = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const layers = document.getElementById("layers");
    const activeId = activeEditable.elementId;

    if (layers) {
      const preActive = layers.querySelector(".activeLayer");
      if (preActive) {
        preActive.classList.remove("activeLayer");
      }

      const activeLayer = layers.querySelector(
        `[data-target='${activeId}'] span`
      );
      if (activeLayer) {
        activeLayer.classList.add("activeLayer");
      }
    }
  }, [activeEditable]);

  function toggleHierarchyItem(event) {
    event.stopPropagation();
    const target = event.currentTarget;

    const { target: targetId, component } = target.dataset;

    target.classList.toggle("open");
    target.classList.toggle("show");

    const children = target.childNodes;
    children.forEach((child) => {
      if (child.className.includes("collapsed")) {
        child.classList.toggle("show");
      }
    });

    if (!component.includes("popup")) {
      const overlays = document.querySelectorAll(".overlay");
      overlays.forEach((overlay) => overlay.click());
    }
    document.getElementById(targetId).click();
    console.log(target);
  }

  function renderHierarchy(hierarchy) {
    return hierarchy?.map((item, index) => (
      <li
        key={index}
        data-target={item?.id}
        data-component={item?.type}
        onClick={toggleHierarchyItem}
        className={item.children.length > 0 ? "collapseable" : "none"}
      >
        <span>{item?.type}</span>
        {item?.name.includes("Layer") && (
          <IconButton className="deleteLayerBtn" onClick={openDialog}>
            <IoTrash className="trash" />
          </IconButton>
        )}
        <ul className="collapsed">
          {item?.children.length > 0 && renderHierarchy(item.children)}
        </ul>
      </li>
    ));
  }

  return (
    <>
      <Confirm
        isOpen={isOpen}
        handleClose={closeDialog}
        handleConfirm={deleteLayer}
        subtitle="you want to delete this layer"
      />

      {hierarchy.length != 0 ? (
        <ul id="layers">{renderHierarchy(hierarchy)}</ul>
      ) : (
        <p className="notFound-message">No Layers Found On Canvas</p>
      )}
    </>
  );
};
export default memo(Layers);
