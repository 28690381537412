import React, { useEffect, useState } from "react";
import { convertRgbAToHexAplha } from "src/services/editor.service";
import Control from "./Control";
import Row from "src/components/properties/Row";

const RangeSlider = (props) => {
  const [valueInput, setValueInput] = useState(0);
  const [trackBgColor, setTrackBgColor] = useState("rgba(0,0,0,0)");
  const [thumbBgColor, setThumbBgColor] = useState("rgba(0,0,0,0)");

  const { elementId, updateHistory, undoRedoChange } = props;

  useEffect(() => {
    const left = document.getElementById(elementId).children[1].style.left;
    if (left) {
      setValueInput(left.split("calc(")[1].split("%")[0]);
    } else {
      setValueInput(0);
    }
  }, [elementId, undoRedoChange]);

  useEffect(() => {
    const defaultTrackBgColor = document.querySelector(`#${elementId} .track`)
      .style.background;
    const trackrgba = convertRgbAToHexAplha(defaultTrackBgColor).hex;
    setTrackBgColor(trackrgba);
    const defaultThumbBgColor = document.querySelector(`#${elementId} .thumb`)
      .style.background;
    const thumbrgba = convertRgbAToHexAplha(defaultThumbBgColor).hex;
    setThumbBgColor(thumbrgba);
  }, [elementId, undoRedoChange]);

  let timeout;
  const inputHandler = (e) => {
    let { name, value } = e.target;
    e.target.onblur = () =>
      updateHistory({
        id: elementId,
      });
    if (name === "number") {
      if (value < 0) {
        value = 0;
        setValueInput(value);
      }
      if (value > 100) {
        value = 100;
        setValueInput(value);
      } else {
        setValueInput(value);
      }

      const width = `${(value / 100) * 36}px`;
      const left = `calc(${value}% - ${width})`;
      document.getElementById(elementId).children[1].style.left = left;
    }
    clearTimeout(timeout);
    if (name === "track color") {
      document.querySelector(`#${elementId} .track`).style.background = value;
      timeout = setTimeout(() => {
        setTrackBgColor(value);
        updateHistory();
      }, 300);
    }
    if (name === "thumb color") {
      document.querySelector(`#${elementId} .thumb`).style.background = value;
      timeout = setTimeout(() => {
        setThumbBgColor(value);
        updateHistory();
      }, 300);
    }
  };
  return (
    <>
      <Control
        heading="Slider Properties"
        isCollapse={true}
        divider={false}
        expanded={true}
      >
        <Row
          label="Value"
          element={
            <input
              type="number"
              className="input"
              name="number"
              min={0}
              max={100}
              value={valueInput}
              onInput={inputHandler}
            />
          }
        />

        <Row
          label="Track Color"
          element={
            <input
              type="color"
              className="color"
              name="track color"
              value={trackBgColor}
              onInput={inputHandler}
            />
          }
        />

        <Row
          label="Thumb Color"
          element={
            <input
              type="color"
              className="color"
              name="thumb color"
              value={thumbBgColor}
              onInput={inputHandler}
            />
          }
        />
      </Control>
    </>
  );
};
export default RangeSlider;
