import React from "react";
import { FormControl, MenuItem, Select } from "@mui/material";

const Select_Box = (props) => {
  const { name, value, onChange, classes, options, disabled } = props;
  return (
    <FormControl disabled={disabled}>
      <Select
        name={name}
        value={value}
        onChange={onChange}
        classes={{
          select: "select_box_input",
          icon: "select_box_icon",
          ...classes,
        }}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value} disabled={option.disabled}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Select_Box;
