import React from "react";
import { csBlock, csColumn } from "../../../elements/Elements";

export const GridIcons = (props) => {
  const iconSnippet = csColumn(props.icon);
  return <div dangerouslySetInnerHTML={{ __html: iconSnippet }}></div>;
};

export const BlockIcons = (props) => {
  const iconSnippet = csBlock(props.icon);
  return <div dangerouslySetInnerHTML={{ __html: iconSnippet.html }}></div>;
};
