import React, { useEffect, useState } from "react";
import {
  Grid,
  Slider,
  Typography,
  Box,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import Control from "../Control";
import { convertRgbAToHexAplha } from "src/services/editor.service";
import Row from "src/components/properties/Row";
import "src/styles/properties.scss";

const GradientBackground = (props) => {
  let { elementId, updateHistory, selector, undoRedoChange } = props;
  const [gradientProperties, setGradientProperties] = useState({
    firstcolor: "#fffffF",
    firstcolorper: 50,
    secondcolor: "#ffffff",
    secondcolorper: 50,
    degree: 180,
    type: "linear-gradient",
  });

  const element = selector
    ? document.getElementById(elementId).querySelectorAll(`${selector}`)
    : [document.getElementById(elementId)];

  useEffect(() => {
    const elementBg = element[0].style.background;
    let backgroundType;
    if (elementBg.indexOf("linear-gradient") === 0) {
      backgroundType = "linear-gradient";
    } else if (elementBg.indexOf("radial-gradient") === 0) {
      backgroundType = "radial-gradient";
    }
    if (backgroundType) {
      const firstcolor = elementBg.split("(")[2].split("%")[0].split(") ")[0];
      const color1per = +elementBg.split("(")[2].split("%")[0].split(") ")[1];
      const secondcolor = elementBg.split("(")[3].split("%)")[0].split(") ")[0];
      const color2per = +elementBg.split("(")[3].split("%)")[0].split(") ")[1];

      let degree = elementBg.split("(")[1].split(",")[0].split("deg")[0];
      if (degree === "rgb") {
        degree = 180;
      }
      const color1 = convertRgbAToHexAplha(firstcolor).hex;
      const color2 = convertRgbAToHexAplha(secondcolor).hex;

      setGradientProperties({
        firstcolor: color1,
        firstcolorper: color1per,
        secondcolor: color2,
        secondcolorper: color2per,
        degree,
        type: backgroundType,
      });
    }
  }, [elementId, undoRedoChange]);

  let timeout;
  const colorHandler = (e) => {
    clearTimeout(timeout);
    let { name, value } = e.target;
    if (name === "firstcolor") {
      if (gradientProperties.type === "radial-gradient") {
        element.forEach((elem) => {
          elem.style.background = `radial-gradient(${value} ${gradientProperties.firstcolorper}%,${gradientProperties.secondcolor} ${gradientProperties.secondcolorper}%)`;
        });
      } else {
        element.forEach((elem) => {
          elem.style.background = `linear-gradient(${
            gradientProperties.degree + "deg"
          },${value} ${gradientProperties.firstcolorper}%,${
            gradientProperties.secondcolor
          } ${gradientProperties.secondcolorper}%)`;
        });
      }
      timeout = setTimeout(() => {
        setGradientProperties({ ...gradientProperties, [name]: value });
      }, 400);
    } else if (name === "secondcolor") {
      if (gradientProperties.type === "radial-gradient") {
        element.forEach((elem) => {
          elem.style.background = `radial-gradient(${gradientProperties.firstcolor} ${gradientProperties.firstcolorper}%,${value} ${gradientProperties.secondcolorper}%)`;
        });
      } else {
        element.forEach((elem) => {
          elem.style.background = `linear-gradient(${
            gradientProperties.degree + "deg"
          },${gradientProperties.firstcolor} ${
            gradientProperties.firstcolorper
          }%,${value} ${gradientProperties.secondcolorper}%)`;
        });
      }
      timeout = setTimeout(() => {
        setGradientProperties({ ...gradientProperties, [name]: value });
      }, 400);
    }
    updateHistory();
  };

  const colorPercentHandler = (e) => {
    let { name, value } = e.target;
    if (name === "firstcolorper") {
      if (gradientProperties.type === "radial-gradient") {
        element.forEach((elem) => {
          elem.style.background = `radial-gradient(${gradientProperties.firstcolor} ${value}%,${gradientProperties.secondcolor} ${gradientProperties.secondcolorper}%)`;
        });
      } else {
        element.forEach((elem) => {
          elem.style.background = `linear-gradient(${
            gradientProperties.degree + "deg"
          },${gradientProperties.firstcolor} ${value}%,${
            gradientProperties.secondcolor
          } ${gradientProperties.secondcolorper}%)`;
        });
      }
      setGradientProperties({ ...gradientProperties, [name]: value });
    } else if (name === "secondcolorper") {
      if (gradientProperties.type === "radial-gradient") {
        element.forEach((elem) => {
          elem.style.background = `radial-gradient(${gradientProperties.firstcolor} ${gradientProperties.firstcolorper}%,${gradientProperties.secondcolor} ${value}%)`;
        });
      } else {
        element.forEach((elem) => {
          elem.style.background = `linear-gradient(${
            gradientProperties.degree + "deg"
          },${gradientProperties.firstcolor} ${
            gradientProperties.firstcolorper
          }%,${gradientProperties.secondcolor} ${value}%)`;
        });
      }
      setGradientProperties({ ...gradientProperties, [name]: value });
    }
    updateHistory();
  };

  const colorDegree = (e) => {
    let { name, value } = e.target;
    if (value < 0) {
      value = 0;
      setGradientProperties({ ...gradientProperties, [name]: value });
    } else if (value > 360) {
      value = 360;
      setGradientProperties({ ...gradientProperties, [name]: value });
    } else {
      setGradientProperties({ ...gradientProperties, [name]: value });
    }
    element.forEach((elem) => {
      elem.style.background = `linear-gradient(${value + "deg"},${
        gradientProperties.firstcolor
      } ${gradientProperties.firstcolorper}%,${
        gradientProperties.secondcolor
      } ${gradientProperties.secondcolorper}%)`;
    });
    updateHistory();
  };

  const backgroundType = (e) => {
    let { name, value } = e.target;
    setGradientProperties((prev) => ({ ...prev, type: value }));
    element.forEach((elem) => {
      elem.style.background = `${value}(${gradientProperties.firstcolor} ${gradientProperties.firstcolorper}%,${gradientProperties.secondcolor} ${gradientProperties.secondcolorper}%)`;
    });
    updateHistory();
  };
  return (
    <>
      <Control className="background" divider={false}>
        <Row
          label="Type"
          leftsx={3}
          rightsx={9}
          element={
            <FormControl sx={{ justifyContent: "flex-end" }}>
              <RadioGroup
                row
                onChange={backgroundType}
                value={gradientProperties.type}
              >
                <FormControlLabel
                  value="linear-gradient"
                  control={<Radio size="small" />}
                  label="Linear"
                />
                <FormControlLabel
                  value="radial-gradient"
                  control={<Radio size="small" />}
                  label="Radial"
                />
              </RadioGroup>
            </FormControl>
          }
        />

        {gradientProperties.type === "linear-gradient" ||
        gradientProperties.type === "radial-gradient" ? (
          <>
            <Box className="row-property">
              <Grid container>
                <Grid item md={4}>
                  <Typography>Color1</Typography>
                </Grid>
                <Grid item md={2}>
                  <input
                    type="color"
                    name="firstcolor"
                    className="color"
                    style={{ width: "80%" }}
                    value={gradientProperties.firstcolor}
                    onInput={colorHandler}
                  />
                </Grid>
                <Grid item md={6}>
                  <Slider
                    className="slider"
                    defaultValue={50}
                    name="firstcolorper"
                    valueLabelDisplay="auto"
                    step={10}
                    marks
                    min={0}
                    max={100}
                    size="small"
                    value={gradientProperties.firstcolorper}
                    onChange={colorPercentHandler}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box className="row-property">
              <Grid container>
                <Grid item md={4}>
                  <Typography>Color2</Typography>
                </Grid>
                <Grid item md={2}>
                  <input
                    type="color"
                    name="secondcolor"
                    className="color"
                    style={{ width: "80%" }}
                    value={gradientProperties.secondcolor}
                    onInput={colorHandler}
                  />
                </Grid>
                <Grid item md={6}>
                  <Slider
                    className="slider"
                    defaultValue={50}
                    name="secondcolorper"
                    valueLabelDisplay="auto"
                    step={10}
                    marks
                    min={0}
                    max={100}
                    size="small"
                    value={gradientProperties.secondcolorper}
                    onChange={colorPercentHandler}
                  />
                </Grid>
              </Grid>
            </Box>
            {gradientProperties.type === "linear-gradient"?(
              <Row
                label="Degree"
                element={
                  <input
                    type="number"
                    name="degree"
                    className="input"
                    min="0"
                    max="360"
                    value={gradientProperties.degree}
                    onInput={colorDegree}
                  />
                }
              />
            ):null}
          </>
        ) : null}
      </Control>
    </>
  );
};

export default GradientBackground;
