import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Editor from "src/components/editor/microsites/Editor";
import AccessControl from "src/components/microsites/AccessControl";
import { getHTMLFromBody, setBodyinHTML } from "src/services/editor.service";
import {
  fetchLandingpageHtml,
  saveMicrosite,
} from "src/store/actions/microsites";
import { getNewAssetsAndHTML } from "src/services/editor.service";
const MicrositeEditor = () => {
  const dispatch = useDispatch();
  const { landingpageHtml, landingPage } = useSelector(
    (state) => state.microsites
  );
  const { bucketURL, sourceTemplate, name } = landingPage;
  const { id: micrositeId } = useParams();
  const baseUrl = bucketURL + "/" + sourceTemplate + "/";
  
   //move to s3 presentation bucket url
   const moveToUrl = (elementType, fileName) => {
    return `${baseUrl}assets/${elementType}/${fileName}`;
  };

  const onSaveMicrosite = (saveDataDiv, image, thumbnail) => {
    const headLinks = `
    <link rel="stylesheet" href="lib/css/animate.min.css" />
    <link rel="stylesheet" href="lib/css/components.css" />
    <link rel="stylesheet" href="lib/css/flipster.css" />
    <link rel="stylesheet" href="lib/css/grid.css" />
    <link rel="stylesheet" href="lib/css/app.css" />

    <script src="lib/js/jquery_min.js"></script>
    <script src="lib/js/components.js"></script>
    <script src="lib/js/chat_min.js"></script>
    <script src="lib/js/flipster_min.js"></script>`;

    const bodyScript = `<script src="lib/app.js"></script>`;
    
    //remove height from mirsoite dropzone before save
    const dropzoneDiv = saveDataDiv.querySelector('#dropzone');
    dropzoneDiv.style.height = null
    const {
      assets,
      defaultAssets,
      HTML: assetsHtml,
    } = getNewAssetsAndHTML(saveDataDiv, moveToUrl, baseUrl);

    //microsite html appending head links and body 
    const dropzoneHTML = setBodyinHTML(
      null,
      headLinks,
      bodyScript,
      null,
      assetsHtml
    );
    
    const data = {
      HTML: dropzoneHTML,
      thumbnail,
      image,
      assets,
      defaultAssets,
    };
       
    return dispatch(
      saveMicrosite({
        id: micrositeId,
        data,
      })
    );
  };
  
  useEffect(() => {
    dispatch(fetchLandingpageHtml({micrositeId}));
  }, []);
  
  return (
    <AccessControl component="edit">
      <Editor
        type="landingpage"
        component="microsite"
        heading={name}
        editorHtmlData={getHTMLFromBody(setBodyinHTML(landingpageHtml))}
        assetsBaseUrl={baseUrl}
        serverUrl={"baseUrl"}
        defaultZoom={100}
        onSave={onSaveMicrosite}
      />
    </AccessControl>
  );
};
export default React.memo(MicrositeEditor);
