import React from "react";
import Control from "./Control";
import UnitBoxes from "./unitBoxes/UnitBoxes";
import "src/styles/properties.scss";

const Margin = ({ elementId, updateHistory, undoRedoChange }) => {
  return (
    <Control
      className="paddingProperties"
      heading="Margin"
      isCollapse={true}
      divider={false}
      expanded={false}
    >
      <UnitBoxes
        selector={`#${elementId}`}
        property="margin"
        updateHistory={updateHistory}
        undoRedoChange={undoRedoChange}
      />
    </Control>
  );
};

export default Margin;
