import {
  getHTMLFromBody,
  getLayersEditableChildren,
  insertHtmlToDropzone,
  editableActions,
  setLayerElementIds,
  deActiveElementOnOtherSideClick,
} from "src/services/editor.service";
import { csRenderFunctional, useSetLayerHeight } from "../editorLogics/components";
import { getRandomId } from "src/services/utils";
import { Sortable } from "../editorLogics/sortable";
import { HISTORY_ACTIONS } from "src/data/contants";

export const attachlisteners = ({
  element,
  activeElement,
  updateHistory,
  actionState,
  requestHierarchyUpdate,
  forCapture,
}) => {
  const isDropzone = element.classList.contains("cs-slide");
  // Redering Main element (can be dropzone or a layer)
  element.classList.add("editable");
  element.classList.remove("reset");

  if (isDropzone) {
    // <----------------------Dropzone------------------------>
    element.addEventListener("dblclick", (e) => {
      e.stopPropagation();
      editableActions.clickHandler({ element, activeElement });
    });
  } else {
    // <----------------------Layer------------------------>
    // Click listener
    const addEventListeners = (element, isLayer) => {
      element.addEventListener("click", (e) => {
        e.stopPropagation();
        editableActions.clickHandler({ e, element, activeElement });
      });
      // mouse listeners
      element.addEventListener("mouseover", (e) => {
        e.stopPropagation();
        editableActions.mouseOverHandler({ e, element });
      });
      element.addEventListener("mouseout", (e) => {
        editableActions.mouseOutHandler({ e, element });
      });
    
      //Note: here the sortable code is responsible to dragging item inside dropzone,
      //while dragging from toolbar to dropzone, you can get code in Toolbar.jsx line 91 makeItemDraggable()
      if (isLayer) {
        const isColumnLayout = element.classList.contains("column-component");
        if(isColumnLayout){
          //make layout component sortable
          new Sortable(element, {
            preventedContainers: "toolbar",
            containers: "col-appendable,cs-slide",
            onSort: (itemDetails) => {
              setTimeout(() => {
                updateHistory({
                  action: HISTORY_ACTIONS.sorted,
                  actionState,
                  id: "", // since extraInfo is passing, id isn't required
                  extraInfo: itemDetails,
                });
                requestHierarchyUpdate();
              }, 300);
            },
          });
        }else{
          // on dropzone the cs-tabContent cant accept cs-tabContent, 
          //so we prevented tab componet inside tab component
          const isTabComponent = element.classList.contains("tabGroup-component");
          new Sortable(element, {
            containers: isTabComponent? "col-appendable": "col-appendable,cs-tabContent",
            preventedContainers: "toolbar,cs-slide",
            onSort: (itemDetails) => {
              setTimeout(() => {
                updateHistory({
                  action: HISTORY_ACTIONS.sorted,
                  actionState,
                  id: "", // since extraInfo is passing, id isn't required
                  extraInfo: itemDetails,
                });
                requestHierarchyUpdate();
              }, 300);
            },
          });
        }
        
      }
    };
    // rendering layer 2nd param, isLayer?
    addEventListeners(element, true);

    // Redering it's nested children if they are editable if not a dropzone
    const nestedEditables = getLayersEditableChildren(element);
    nestedEditables.forEach((nestedEditable) => {
      nestedEditable.classList.add("editable");
      nestedEditable.classList.remove("reset");
      addEventListeners(nestedEditable);
      csRenderFunctional(nestedEditable, forCapture);
    });

    requestHierarchyUpdate();
  }
};

export const applyPadding = (el) => {
  const layer = $(el);
  const editable = layer.find(".editable");
  const padding = 10;
  //now apply padding class on editable element
  const setPadding = (element) => {
    // if element has not styled padding
    if (!element.attr("style")?.includes("padding")) {
      if (element.find(".editable").length && !element.hasClass("cs-layer")) {
        element.css("padding", padding);
        //console.log(element)
      }
    }
  };
  // set for layer and child elements
  setPadding(layer);
  setTimeout(() => {
    useSetLayerHeight(layer);
  }, 250);

  if (editable.length || layer.hasClass("cs-col")) {
    setPadding(editable); // do not set for last nested editable
    setTimeout(() => {
      useSetLayerHeight(layer);
    }, 250);
  }
};

export const csRenderMicrositeEditable = ({
  element,
  updateHistory,
  activeElement,
  actionState,
  requestHierarchyUpdate,
  forCapture,
}) => {
  // applying unique id
  if (!element.classList.contains("cs-slide")) {
    setLayerElementIds(element);
    applyPadding(element)
    if (!element.id) {
      element.id = getRandomId();
    }
  }

  // if not have status then append it first
  if (!element.querySelector(".status")) {
    element.insertAdjacentHTML(
      "beforeend",
      "<div class='status'><span></span></div>"
    );
  }
  // Preventing default for anchor tags
  const a = element.tagName === "A" ? element : element.querySelector("a");
  if (a) {
    a.addEventListener("click", (e) => {
      e.preventDefault();
    });
  }

  // Preventing default for image tags
  const img =
    element.tagName === "IMG" ? element : element.querySelector("img");
  if (img) {
    img.addEventListener("click", (e) => {
      e.preventDefault();
    });
  }

  // removing unnecessary stylings
  element.style.transform = "";

  // Adding listeners
  attachlisteners({
    element,
    activeElement,
    updateHistory,
    actionState,
    requestHierarchyUpdate,
    forCapture,
  });
};

export const renderEditableElements = ({
  layersContainer,
  activeElement = () => {},
  updateHistory = () => {},
  actionState,
  requestHierarchyUpdate = () => {},
  forCapture = false,
}) => {
  const csLayers = layersContainer.querySelectorAll(".cs-layer");

  // Rendering layersContainer
  csRenderMicrositeEditable({
    element: layersContainer,
    actionState,
    activeElement,
    updateHistory,
    requestHierarchyUpdate,
    forCapture,
  });
  // Rendering Layers
  csLayers.forEach((layer, i) => {
    // now bnding funcationality to layers
    csRenderMicrositeEditable({
      element: layer,
      actionState,
      activeElement,
      updateHistory,
      requestHierarchyUpdate,
      forCapture,
    });
  });

  requestHierarchyUpdate();
};

export const renderMicrosite = ({
  dropzone,
  assetsBaseUrl,
  editorHtmlData,
  activeElement,
  updateHistory,
  actionState,
  requestHierarchyUpdate,
  deActiveElement,
} = {}) => {
  // Appending HTML
  insertHtmlToDropzone(dropzone, editorHtmlData);
  // Rendering with functionalities
  renderEditableElements({
    layersContainer: dropzone,
    activeElement,
    updateHistory,
    actionState,
    requestHierarchyUpdate,
  });

  return deActiveElementOnOtherSideClick(deActiveElement);
};
