import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchEmailHtml, saveEmail } from "src/store/actions/emails";
import AccessControl from "src/components/emails/AccessControl";
import { getHTMLFromBody, setBodyinHTML } from "src/services/editor.service";
import { setEmailData } from "src/store/reducers/emails-slice";
import $ from "jquery";
import Editor from "src/components/editor/email/Editor";

const EmailEditor = () => {
  const dispatch = useDispatch();
  const { id: emailId } = useParams();
  const { emailData, email } = useSelector((state) => state.emails);

  // Fetching Email's Data
  useEffect(() => {
    dispatch(fetchEmailHtml({ emailId }));
  }, []);

  const saveEmailData = (saveDataDiv, image, thumbnail) => {
    const dropzoneHTML = setBodyinHTML(
      getHTMLFromBody(saveDataDiv.innerHTML),
      false,
      false,
      true
    );

    const data = {
      HTML: dropzoneHTML,
      thumbnail,
      assets: [],
      image,
    };
    return dispatch(
      saveEmail({
        id: emailId,
        data,
      })
    );
  };

  return (
    <AccessControl component="edit">
      <Editor
        type="email"
        component="email"
        heading={email.name}
        editorHtmlData={emailData}
        serverUrl={"baseUrl"}
        defaultZoom={100}
        onSave={saveEmailData}
      />
    </AccessControl>
  );
};
export default React.memo(EmailEditor);
