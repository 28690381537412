import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setSharedWith } from "src/store/reducers/sharing-slice";
import { getAllCompanyUsers } from "src/store/actions/users";
import { cancelReview, sendForReview } from "src/store/actions/reviews";
import {
  exportEmail,
  deleteEmail,
  uploadNewVersion,
  cloneEmail,
} from "src/store/actions/emails";
import { share } from "src/store/actions/sharing";

import { downloadPdfByImages } from "src/services/utils";

//Dialogs
import ExportEmail from "./Export";
import UploadNewVersion from "../UploadNewVersion";
import DetailsDrawer from "../DetailsDrawer";
import SharingSettings from "../SharingSettings";
import SendForReview from "../SendForReview";
import Confirm from "../Confirm";
import ReviewDelete from "../ReviewDelete";
const EmailMyLibraryDialogs = (props) => {
  const dispatch = useDispatch();
  const type = "email";
  const { company, _id: userId } = useSelector((state) => state.auth.userData);

  const { onClose, dialog, emailId, email } = props;
  //on close function for closing dialogs

  //upload new email function
  const onUploadNewVersion = useCallback((data) => {
    dispatch(uploadNewVersion(data));
  }, []);

  //export email
  const onExport = useCallback(() => {
    downloadPdfByImages([`${email.bucketURL}/${email.image}`])
  }, [email]);

  //get all company users
  const getCompanyUsers = useCallback(() => {
    dispatch(getAllCompanyUsers(company.companyId));
  }, [company]);

  const emailShare = useCallback(
    (sharedWith, accessLevel) => {
      const userIds = sharedWith.map((user) => {
        return { userId: user._id, role: accessLevel };
      });

      const shared = sharedWith.map((user) => ({
        userId: user,
        role: accessLevel,
      }));

      dispatch(setSharedWith(shared));
      dispatch(
        share({
          id: emailId,
          data: { sharedWith: userIds, accessLevel },
          type,
        })
      );
    },
    [emailId]
  );

  //assign email fro review
  const assignForReview = useCallback(
    (data) => {
      dispatch(sendForReview({ id: emailId, data }));
    },
    [emailId]
  );

  const emailDelete = useCallback(() => {
    dispatch(deleteEmail(emailId));
    onClose();
  }, [emailId]);

  const cancelReviewEmail = useCallback(() => {
    dispatch(cancelReview({ id: emailId, type }));
    onClose();
  }, [emailId]);

  const handleClone = useCallback(() => {
    dispatch(cloneEmail(emailId));
    onClose();
  }, [emailId]);

  return (
    <>
      <ExportEmail
        isOpen={dialog.export}
        exportAs="pdf"
        handleExport={onExport}
        handleClose={onClose}
        id={emailId}
      />
      <UploadNewVersion
        title="Upload New Version"
        close={onClose}
        show={dialog.uploadNewVersion}
        onUpload={onUploadNewVersion}
        id={emailId}
      />
      <DetailsDrawer
        type="email"
        open={dialog.info}
        onClose={onClose}
        details={email || {}}
      />
      <SharingSettings
        userId={userId}
        onInput={getCompanyUsers}
        isOpen={dialog.share}
        handleClose={onClose}
        onShare={emailShare}
      />
      <SendForReview
        type="email"
        userId={userId}
        onInput={getCompanyUsers}
        isOpen={dialog.review}
        handleClose={onClose}
        onSend={assignForReview}
      />
      <Confirm
        isOpen={dialog.delete}
        subtitle="Are you sure you want to delete this email template?"
        handleClose={onClose}
        handleConfirm={emailDelete}
      />
           <ReviewDelete
       isOpen={dialog.reviewdelete}
        subtitle="Content under review or already reviewed cannot be deleted. Thank you for your understanding!"
        handleClose={onClose}
        
      />
      <Confirm
        isOpen={dialog.cancelReview}
        subtitle="Are you sure you want to cancel this review?"
        handleClose={onClose}
        handleConfirm={cancelReviewEmail}
      />
      <Confirm
        isOpen={dialog.clone}
        subtitle="Are you sure you want to clone this email template?"
        handleClose={onClose}
        handleConfirm={handleClone}
      />
    </>
  );
};

export default EmailMyLibraryDialogs;
