import React, { useState, useEffect } from "react";
import Control from "./Control";
import { Box, IconButton } from "@mui/material";
import {
  FiAlignLeft,
  FiAlignCenter,
  FiAlignRight,
  FiAlignJustify,
} from "react-icons/fi";
import {
  RiBold,
  RiItalic,
  RiUnderline,
  RiSuperscript,
  
  RiSubscript,
  RiTextSpacing,
} from "react-icons/ri";
import { CgFormatLineHeight } from "react-icons/cg";
import { VscCaseSensitive } from "react-icons/vsc";
import { BsTextareaT, BsCodeSlash } from "react-icons/bs";
import { HiOutlineClipboardCheck } from "react-icons/hi";
import SelectBox from "src/components/ui/formElements/SelectBox";
import "src/styles/properties.scss";
import { converObjectToStyleStr } from "src/services/editor.service";
const Text = () => {
  const [textStyle, setTextStyle] = useState({
    color: "#000000",
    "font-family": "Arial",
    "font-weight": "normal",
    "font-size": "16px",
    "font-style": "inherit",
    "text-align": "center",
  });

  useEffect(() => {
    const element = document.querySelector(".editable.active");
    element.setAttribute("style", converObjectToStyleStr(textStyle));
  }, [textStyle]);

  const fontFamilies = [
    { label: "Arial", value: "Arial" },
    { label: "Lato", value: "Lato" },
  ];
  const fontWeights = [
    { label: "Bold", value: "600" },
    { label: "Semibold", value: "500" },
    { label: "normal", value: "normal" },
  ];
  const fontSizes = [
    { label: "16", value: "16px" },
    { label: "24", value: "24px" },
  ];

  const updateTextStyle = (property, value) => {
    setTextStyle({ ...textStyle, [property]: value });
  };

  //Button Handlers
  const buttonHandler = (property, value) => {
    updateTextStyle(property, value);
  };

  //Select Handler
  const selectHandler = (e) => {
    const { name, value } = e.target;
    updateTextStyle(name, value);
  };

  return (
    <Control heading="Text" className="text" divider={true}>
      <div className="font-family-color">
        <input
          type="color"
          name="color"
          id="color"
          className="font-color"
          value={textStyle["color"]}
          onInput={selectHandler}
        />
        <SelectBox
          options={fontFamilies}
          value={textStyle["font-family"]}
          onChange={selectHandler}
          inputProps={{
            name: "font-family",
          }}
        />
      </div>
      <div className="font-weight-bold">
        <SelectBox
          options={fontWeights}
          value={textStyle["font-weight"]}
          onChange={selectHandler}
          inputProps={{
            name: "font-weight",
          }}
        />
        <SelectBox
          options={fontSizes}
          value={textStyle["font-size"]}
          onChange={selectHandler}
          inputProps={{
            name: "font-size",
          }}
        />
      </div>
      <div className="textEditor">
        <div className="iconBtns">
          <IconButton
            className="icon"
            onClick={() => {
              buttonHandler("font-weight", "500");
            }}
          >
            <RiBold />
          </IconButton>
          <IconButton
            className="icon"
            onClick={() => {
              buttonHandler("font-weight", "500");
            }}
          >
            <RiItalic />
          </IconButton>
          <IconButton
            className="icon"
            onClick={() => {
              buttonHandler("font-weight", "500");
            }}
          >
            <RiUnderline />
          </IconButton>
          <IconButton
            className="icon"
            onClick={() => {
              buttonHandler("font-weight", "500");
            }}
          >
            <VscCaseSensitive />
          </IconButton>
          <IconButton
            className="icon"
            onClick={() => {
              buttonHandler("font-weight", "500");
            }}
          >
            <RiSuperscript />
          </IconButton>
          <IconButton
            className="icon"
            onClick={() => {
              buttonHandler("font-weight", "500");
            }}
          >
            <RiSubscript />
          </IconButton>
          <IconButton
            className="icon"
            onClick={() => {
              buttonHandler("font-weight", "500");
            }}
          >
            <CgFormatLineHeight />
          </IconButton>
          <IconButton
            className="icon"
            onClick={() => {
              buttonHandler("font-weight", "500");
            }}
          >
            <RiTextSpacing />
          </IconButton>
          <IconButton
            className="icon"
            onClick={() => {
              buttonHandler("font-weight", "500");
            }}
          >
            <FiAlignLeft />
          </IconButton>
          <IconButton
            className="icon"
            onClick={() => {
              buttonHandler("font-weight", "500");
            }}
          >
            <FiAlignCenter />
          </IconButton>
          <IconButton
            className="icon"
            onClick={() => {
              buttonHandler("font-weight", "500");
            }}
          >
            <FiAlignRight />
          </IconButton>
          <IconButton
            className="icon"
            onClick={() => {
              buttonHandler("font-weight", "500");
            }}
          >
            <FiAlignJustify />
          </IconButton>
          <IconButton
            className="icon"
            onClick={() => {
              buttonHandler("font-weight", "500");
            }}
          >
            <HiOutlineClipboardCheck />
          </IconButton>
          <IconButton
            className="icon"
            onClick={() => {
              buttonHandler("font-weight", "500");
            }}
          >
            <BsTextareaT />
          </IconButton>
          <IconButton
            className="icon"
            onClick={() => {
              buttonHandler("font-weight", "500");
            }}
          >
            <BsCodeSlash />
          </IconButton>
        </div>
      </div>
    </Control>
  );
};

export default Text;
