import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import ChooseAsset from "src/components/dialogs/ChooseAsset";
import Control from "./Control";
import { Box } from "@mui/material";
import { MdOutlineUpload } from "react-icons/md";
import { IoCloudUpload } from "react-icons/io5";
import { useRef } from "react";
import { HISTORY_ACTIONS } from "src/data/contants";
import Image from "src/components/ui/Image";
import { posterOfVideo } from "src/services/utils";

const AssetUpload = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { elementId, updateType } = props;
  const imgRef = useRef();
  let element = props.element || document.getElementById(elementId);

  const toggleDialog = () => {
    setIsOpen(!isOpen);
  };

  const updateAssetHandler = (src) => {
    const updateSRC = ({
      src,
      el = element,
      setSrc = true,
      setDataSrc = true,
    }) => {
      if (setSrc) {
        el.src = src;
      }else{
        el.removeAttribute('src')
      }

      if (setDataSrc) {
        el.dataset.src = src;
      }else{
        el.removeAttribute('data-src')
      }
    };

    const updateBackground = (url) => {
      element.style.backgroundImage = `url(${url})`;
      props.onSrcUpdate(url);
    };
    
    const updateActions = {
      image(src) {
        updateSRC({ src });
      },

      background(src) {
        updateBackground(src);
      },

      audio(src) {
        if (element.nodeName === "AUDIO") {
          updateSRC({ src });
          updateSRC({ src, el: element.closest(".cs-video") });
        } else {
          const childAudio = element.querySelector("audio");
          if (childAudio) {
            updateSRC({ src, el: childAudio });
            updateSRC({ src, el: element });
          }
        }
      },

      video(src) {
        if (element.nodeName === "VIDEO") {
          updateSRC({ src });
          updateSRC({ src, el: childVideo.closest(".cs-video") });
        } else {
          const videoTag = element.querySelector("video");
          if (videoTag) {
            updateSRC({ src, el: element, setSrc: false, setDataSrc: false });// set cs-video 
            updateSRC({ src, el: videoTag, setSrc: true, setDataSrc: true }); // set video tag inside cs-video element
            this.poster(src);
            // videoTag.play()
          }
        }
      },

      list(src) {
        try {
          const li = element.querySelectorAll("li");
          const { externalData } = props;
          li.forEach((element) => {
            const img = `<img style = "${externalData.imageStyles}" src = "${src}" />`;
            const text = element.querySelector("span");
            element.innerHTML = img;
            element.insertAdjacentElement("beforeend", text);
          });
        } catch (error) {
          console.log(error);
        }
      },

      poster(src) {
        let poster = posterOfVideo(src)
        if (element.nodeName === "VIDEO") {
          element.poster = poster;
          element.dataset.poster = poster;
        } else {
          const childVideo = element.querySelector("video");
          if (childVideo) {
            childVideo.poster = poster;            
            childVideo.dataset.poster = poster;
          }
        }
      },
    };

    updateActions[updateType] &&
      (() => {
        updateActions[updateType](src);
        props.updateHistory({
          action:
            updateType === "image"
              ? HISTORY_ACTIONS.imageChange
              : "updateHistory",
        });
        imgRef.current && (imgRef.current.src = src);
      })();
    
  };

  const getSrc = () => {
    const previews = {
      image: (el) => {
        return el.src;
      },
      poster: (el) => {
        if (el?.poster) {
          return el.poster;
        } else {
          const videoPoster = el.querySelector("video")?.poster;
          if (videoPoster) {
            return videoPoster;
          }
        }
      },
      background: (el) => {
        const ImageBackground = el.style.backgroundImage;
        if (ImageBackground !== "none") {
          return ImageBackground.substring(5, ImageBackground.length - 2);
        }
      },

      list: (el) => {
        return el.querySelector("img")?.src;
      },

      default:
        "https://createmart-seed-folder.s3.amazonaws.com/placeholders/image-comp/cs-default-image.jpg",
    };

    return (
      (element && previews[updateType] && previews[updateType](element)) ||
      previews.default
    );
  };

  return (
    <Control
      heading={props.isCollapse !== undefined ? "" : "SelectAsset"}
      className="image"
      divider={false}
      expanded={true}
      isCollapse={props.isCollapse === undefined ? true : props.isCollapse}
    >
      <Box className="global-btn">
        {["image", "background", "poster", "list"].includes(updateType) ? (
          <Box className="image-bg">
            <Button className="upload" onClick={toggleDialog}>
              <IoCloudUpload className="upload-icon" />
            </Button>
            <img
              ref={imgRef}
              width="100%"
              onError={() => {
                imgRef.current.style.display = "none";
              }}
              src={getSrc()}
            />
            {/* <img ref={imgRef} width="100%"  src={getSrc()} /> */}
          </Box>
        ) : (
          <Button fullWidth variant="contained" onClick={toggleDialog}>
            Upload {updateType !== "src" ? updateType : props.assetFormat}
            <MdOutlineUpload className="icon" size="18px" />
          </Button>
        )}
      </Box>
      <ChooseAsset
        assetFormat={props.assetFormat}
        updateHistory={props.updateHistory}
        show={isOpen}
        setSrc={updateAssetHandler}
        type="edit"
        close={toggleDialog}
      />
    </Control>
  );
};

export default AssetUpload;

AssetUpload.defaultProps = {
  assetFormat: "image",
  updateType: "image",
};
