import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid, Box, Stack, IconButton, Tooltip } from "@mui/material";
import {
  IoEye,
  IoPencil,
  IoDocument,
  IoGrid,
  IoCopyOutline,
  IoEllipsisVerticalSharp,
} from "react-icons/io5";
import DropDown from "src/components/ui/DropDown";
import AccessController from "../common/AccessController";
import PermissionComp from "../Permission";
import { hasPermission } from "src/utils/Utils";
const CardControls = (props) => {
  const { dropDownData } = props;
  const [editUrl, setEditUrl] = useState({
    url: "",
    tooltip: "",
  });
  //to close dropdown on click we need to return true on each dropdown handler
  useEffect(() => {
    let url = "";
    let tooltip = "";
    const slideId = props.slideId();
    if (slideId) {
      //If Slide Exists
      url = `/presentation/${props.id}/edit/${slideId}`;
      tooltip = "Edit";
    } else {
      tooltip = "No Slides To Edit";
    }

    setEditUrl({
      url,
      tooltip,
    });
  }, [props.slideId]);

  const onEdit = (e) => {
    if (!editUrl.url) {
      e.preventDefault();
    }
  };
  const permissionChapter = hasPermission('presentation.chapter');
  //other handler will be create here
  return (
    <>
      <AccessController role={props.role} component="view">
        <Tooltip title="View" arrow>
          <IconButton variant="outlined" onClick={props.previewPresentation}>
            <IoEye />
          </IconButton>
        </Tooltip>
      </AccessController>
      <AccessController
        role={props.role}
        component="edit"
        status={props.status}
      >
        <Tooltip title={editUrl.tooltip} arrow>
          <Link
            to={editUrl.url}
            onClick={onEdit}
            className={editUrl.url ? "" : "disabled"}
            style={{ marginRight: 0 }}
          >
            <IconButton variant="outlined">
              <IoPencil />
            </IconButton>
          </Link>
        </Tooltip>
      </AccessController>

      <AccessController role={props.role} component="clone">
          <PermissionComp permissionPath="presentation.create.clone">
            <Tooltip title="Clone Presentation" arrow>
              <IconButton onClick={props.clonePresentation} variant="outlined">
                <IoCopyOutline />
              </IconButton>
            </Tooltip>
          </PermissionComp>
      </AccessController>

      <AccessController role={props.role} component="detail">
        <Tooltip title="Details" arrow>
          <IconButton onClick={props.toggleDetails} variant="outlined">
            <IoDocument />
          </IconButton>
        </Tooltip>
      </AccessController>
      <AccessController role={props.role} component="structure">
        <Link
              to={`/presentation/${props.id}/structure`}
              style={{ flex: 1, marginRight: 0 }}
            >
              <Tooltip title="Structure" arrow>
                <IconButton variant="outlined">
                  <IoGrid />
                </IconButton>
              </Tooltip>
          </Link>
      </AccessController>
      <AccessController role={props.role} component="more">
        <DropDown
          id={props.id}
          tooltip="More"
          dropIcon={<IoEllipsisVerticalSharp />}
          items={dropDownData}
        />
      </AccessController>
      {/* <Grid item md={8}>
        <Stack direction="row" spacing="1">
          <AccessController role={props.role} component="view">
            <Tooltip title="View">
              <IconButton
                variant="outlined"
                onClick={props.previewPresentation}
              >
                <IoEye />
              </IconButton>
            </Tooltip>
          </AccessController>
          <AccessController
            role={props.role}
            component="edit"
            status={props.status}
          >
            <Tooltip title={editUrl.tooltip}>
              <span>
                <Link
                  to={editUrl.url}
                  onClick={onEdit}
                  className={editUrl.url ? "" : "disabled"}
                >
                  <IconButton variant="outlined">
                    <IoPencil />
                  </IconButton>
                </Link>
              </span>
            </Tooltip>
          </AccessController>
          <AccessController role={props.role} component="clone">
            <Tooltip title="Clone Presentation">
              <IconButton onClick={props.clonePresentation} variant="outlined">
                <IoCopyOutline />
              </IconButton>
            </Tooltip>
          </AccessController>
          <AccessController role={props.role} component="detail">
            <Tooltip title="Details">
              <IconButton onClick={props.toggleDetails} variant="outlined">
                <IoDocument />
              </IconButton>
            </Tooltip>
          </AccessController>
          <AccessController role={props.role} component="structure">
            <Link to={`/presentation/${props.id}/structure`}>
              <Tooltip title="Structure">
                <IconButton variant="outlined">
                  <IoGrid />
                </IconButton>
              </Tooltip>
            </Link>
          </AccessController>
        </Stack>
      </Grid> */}

      {/* <AccessController role={props.role} component="more">
        <Grid item md={4}>
          <Box style={{ textAlign: "right" }}>
            <DropDown
              id={props.id}
              tooltip="More"
              dropIcon={<IoEllipsisVerticalSharp />}
              items={dropDownData}
            />
          </Box>
        </Grid>
      </AccessController> */}

      {/* Dialogs */}
    </>
  );
};
export default React.memo(CardControls);
