import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import Control from "./Control";
import ChooseAsset from "src/components/dialogs/ChooseAsset";
import "src/styles/properties.scss";
import Row from "src/components/properties/Row";

const Rating = (props) => {
  const { elementId, updateHistory, undoRedoChange } = props;

  const fillStar =
    "https://createmart-seed-folder.s3.amazonaws.com/placeholders/rating-comp/star.png";
  const notfillStar =
    "https://createmart-seed-folder.s3.amazonaws.com/placeholders/rating-comp/star_nofill.png";

  const ratingContainer = document.getElementById(elementId);
  const images = ratingContainer.querySelectorAll("img");
  const [starProperties, setStarProperties] = useState({
    fillCount: 0,
    size: 0,
    starsCount: 0,
    gap: 0,
    justifyContent: "",
    padding: 0,
  });

  useEffect(() => {
    let count = 0;
    const size = getComputedStyle(images[0]).width;
    const gap = getComputedStyle(images[0]).marginRight;
    const justifyContent = getComputedStyle(ratingContainer).justifyContent;
    images.forEach((image) => {
      image.style.width = size;
      if (image.src === fillStar) {
        count++;
      }
    });
    setStarProperties((prev) => ({
      ...prev,
      justifyContent,
      fillCount: count,
      size: parseInt(size),
      starsCount: images.length,
      gap: parseInt(gap),
    }));
  }, [elementId, undoRedoChange]);

  const updateTextHistory = () => {
    updateHistory({
      id: elementId,
    });
  };

  const fillStarsHandler = (event) => {
    const { value } = event.target;
    if (!value) {
      setStarProperties((prev) => ({ ...prev, fillCount: 0 }));
      for (let i = 0; i < images.length; i++) {
        images[i].src = notfillStar;
      }
    } else if (value >= 0 && value <= images.length) {
      setStarProperties((prev) => ({ ...prev, fillCount: value }));
      for (let i = 0; i < images.length; i++) {
        if (i + 1 <= value) {
          images[i].src = fillStar;
        } else {
          images[i].src = notfillStar;
        }
      }
    }
    event.target.onblur = updateTextHistory;
  };

  const starsSizeHandler = (event) => {
    const { value } = event.target;
    if (!value) {
      images.forEach((image) => {
        image.style.setProperty("width", 30 + "px");
      });
      setStarProperties((prev) => ({ ...prev, size: 30 }));
    } else if (value >= 30) {
      images.forEach((image) => {
        image.style.setProperty("width", value + "px");
      });
      setStarProperties((prev) => ({ ...prev, size: value }));
    }
    event.target.onblur = updateTextHistory;
  };

  const starsCountHandler = (event) => {
    const { value } = event.target;
    if (!value) {
      for (let i = images.length; i > 1; i--) {
        images[i - 1].remove();
      }
      setStarProperties((prev) => ({ ...prev, starsCount: 1 }));
      setStarProperties((prev) => ({ ...prev, fillCount: 1 }));
    } else if (value >= 1) {
      if (value > images.length) {
        const diff = value - images.length;
        for (let i = 0; i < diff; i++) {
          const img = document.createElement("img");
          img.setAttribute(
            "style",
            `width: ${getComputedStyle(images[0]).width}; `
          );
          img.setAttribute(
            "style",
            `margin: 0px ${getComputedStyle(images[0]).marginRight}`
          );
          img.setAttribute("src", notfillStar);
          ratingContainer.append(img);
        }
      } else {
        for (let i = images.length; i > value; i--) {
          images[i - 1].remove();
        }
      }
      if (+starProperties.fillCount > +value) {
        setStarProperties((prev) => ({ ...prev, fillCount: value }));
      }
      setStarProperties((prev) => ({ ...prev, starsCount: value }));
    }
    event.target.onblur = updateTextHistory;
  };

  const starsGapHandler = (event) => {
    const { value } = event.target;
    const images = ratingContainer.querySelectorAll("img");
    if (!value) {
      images.forEach((image) => {
        image.style.margin = `0px 0px`;
      });
      setStarProperties((prev) => ({ ...prev, gap: 0 }));
    } else if (value >= 0) {
      images.forEach((image) => {
        image.style.margin = `0px ${value}px`;
      });
      setStarProperties((prev) => ({ ...prev, gap: value }));
    }
    event.target.onblur = updateTextHistory;
  };

  return (
    <Control
      heading={"Rating"}
      headingSize={"h1"}
      divider={false}
      expanded={true}
      isCollapse={true}
    >
      <Row
        label="Stars Count"
        element={
          <input
            type="number"
            className="input"
            value={starProperties.starsCount}
            onChange={starsCountHandler}
          />
        }
      />
      <Row
        label="Stars Size"
        element={
          <input
            type="number"
            className="input"
            value={starProperties.size}
            onChange={starsSizeHandler}
          />
        }
      />
      <Row
        label="Fill Stars"
        element={
          <input
            type="number"
            className="input"
            value={starProperties.fillCount}
            onChange={fillStarsHandler}
          />
        }
      />
      <Row
        label="Stars Gap"
        element={
          <input
            type="number"
            className="input"
            value={starProperties.gap}
            onChange={starsGapHandler}
          />
        }
      />
    </Control>
  );
};

export default Rating;
