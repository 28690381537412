export const toDataURL = ({ src, type }) => {
  src = src + "?ts=" + Date.now();
  return new Promise((resolve) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        let result = reader.result
        if(type === 'image'){
          result = result.replace('data:application/octet-stream;base64', 'data:image/jpeg;base64')
        }
        resolve(result);
      };

      reader.readAsDataURL(xhr.response);
    };

    xhr.onerror = function (e) {
      resolve(src);
    };
    if (!location.href.includes("https")) {
      src = src.replace("https", "http");
    }
    xhr.open("GET", src);
    xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    xhr.responseType = "blob";
    xhr.send();
  });
};

export const useConvertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    async function reduceFileSize(base64Str) {
      let resized_base64 = await new Promise((resolve) => {
        let img = new Image();
        img.src = base64Str;
        img.onload = () => {
          let canvas = document.createElement("canvas");
          let width = img.width;
          let height = img.height;

          const MAX_WIDTH = 150,
            MAX_HEIGHT = 150;
          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          let ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          resolve(canvas.toDataURL()); // this will return base64 image results after resize
        };
      });
      return resized_base64;
    }

    reader.onload = async () => {
      const image = reader.result;
      const thumb = await reduceFileSize(image);
      resolve({
        image,
        thumb,
        name: file.name,
      });
    };

    reader.onerror = (err) => {
      console.error(reader);
      reject(err);
    };
  });
};
