import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";

import {
  Autocomplete,
  Box,
  TextField,
  IconButton,
  ListItem,
} from "@mui/material";
import { IoSearch } from "react-icons/io5";

import { clearSearchedResult } from "src/store/reducers/global-search-slice";
import { globalSearch } from "src/store/actions/globalSearch";

const groups = {
  presentation:"Presentations",
  sharedPresentation:"Shared Presentation",
  email:"Email Templates",
  sharedEmail:"Shared Email Templates",
  landingPage:"Microsites",
  sharedLandingPage:"Shared Microsites",
}

const SearchBox = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [makeRequest, setMakeRequest] = useState(true);
  const [searchParams] = useSearchParams();
  const langParams = searchParams.get("language");
  const countryParams = searchParams.get("country");

  const [keyword, setKeyword] = useState("");
  const { allData, searchedResults, loading } = useSelector(
    (state) => state.globalSearch
  );
  
  // useEffect(() => {
  //   const params = {};
  //   const timeOut = setTimeout(() => {
  //     if (makeRequest) {
  //       if (keyword) {
  //         params.keyword = keyword;
  //         if (langParams) {
  //           params.language = langParams;
  //         }
  //         if (countryParams) {
  //           params.country = countryParams;
  //         }
  //         if (Object.keys(params).length) {
  //           dispatch(
  //             globalSearch({
  //               data: { ...params },
  //               queryParams: { limit: 12, skip: 0 },
  //             })
  //           );
  //         }
  //       }
  //     } else {
  //       setMakeRequest(true);
  //     }
  //   }, 500);
  //   return () => {
  //     clearTimeout(timeOut);
  //   };
  // }, [keyword]);

  useEffect(() => {
    const params = {};
    let timeoutId;
  
    if (makeRequest) {
      if (keyword) {
        params.keyword = keyword;
        if (langParams) {
          params.language = langParams;
        }
        if (countryParams) {
          params.country = countryParams;
        }
        if (Object.keys(params).length) {
          clearTimeout(timeoutId); // Clear previous timeout
          timeoutId = setTimeout(() => {
            dispatch(
              globalSearch({
                data: { ...params },
                queryParams: { limit: 12, skip: 0 },
              })
            );
          }, 300);
        }
      }
    } else {
      setMakeRequest(true);
    }
  
    return () => {
      clearTimeout(timeoutId); // Clear the timeout on component unmount or when keyword changes
    };
  }, [keyword]);

  
  useEffect(() => {
    if (searchParams.get("search")) {
      setKeyword(searchParams.get("search"));
      setMakeRequest(false);
    }
  }, [searchParams]);

  const filterOptions = () => {
    return allData;
  };

  const doSearch = () => {
    if (keyword) {
      let params = {};
      params.search = keyword;
      if (langParams) {
        params.language = langParams;
      }
      if (countryParams) {
        params.country = countryParams;
      }
      navigate({
        pathname: "global/search",
        search: createSearchParams({
          ...params,
        }).toString(),
      });
    }
  };
  const searchFieldHandler = (event, value) => {
    console.log(event, value);
    if (value?._id) {
      if (value.type === "landingPage" || value.type === "sharedLandingPage") {
        return navigate(`microsite/${value._id}/details`);
      }else if (value.type === "email" || value.type === "sharedEmail") {
        return navigate(`email/${value._id}/details`);
      }else if (value.type ==="presentation" || value.type === 'sharedPresentation'){
        return navigate(`presentation/${value._id}/details`);
      }
      //navigate(`${value.type}/${value._id}/details`);
    }
  };
  const submitHandler = (event) => {
    event.preventDefault();
  };

  const onBlur = async (event) => {
    await dispatch(clearSearchedResult());
    setIsLoading(false);
  };

  const textChangeHandler = async (_, value) => {
    if (searchedResults?.length) {
      await dispatch(clearSearchedResult());
    }
    setKeyword(value);
  };
  return (
    <form onSubmit={submitHandler}>
      <Box className="SearchBox" sx={props.sx}>
        <IconButton
          type="submit"
          color="primary"
          aria-label="upload picture"
          component="button"
          className="searchButton"
        >
          <IoSearch />
        </IconButton>
        <Autocomplete
          classes={{inputRoot:"search-input-root"}}
          onBlur={onBlur}
          loading={loading}
          value={keyword}
          onChange={searchFieldHandler}
          onInputChange={textChangeHandler}
          groupBy={(option) => groups[option.type]}
          getOptionLabel={(option) => option.name || keyword}
          filterOptions={filterOptions}
          disableClearable
          options={[]}
          renderInput={(props) => (
            <TextField placeholder="Search Presentation, Email & Microsite" {...props} />
          )}
          fullWidth
          renderOption={(props, option) => {
            const filterProps = {...props , key: option._id}
           return <Box component="li" {...filterProps} sx={{ '& > img': { mr: 1.5, flexShrink: 0 } }}>
              <img
                width="50px"
                loading="lazy"
                src={`${option.bucketURL}/${option.thumbnail}`}
                srcSet={`${option.bucketURL}/${option.thumbnail}`}
                alt=""
              />
              {option.name}
            </Box>
          }}
        />
      </Box>
    </form>
  );
};

export default SearchBox;
