import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { IoCloudUploadOutline } from "react-icons/io5";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Box,
  Grid,
  Button,
  Autocomplete,
  TextField,
  Tabs,
  Tab,
} from "@mui/material";
import Content from "src/layouts/Content";
import Card from "src/components/ui/Card";
import Pagination from "src/components/ui/Pagination";
import Input from "src/components/ui/formElements/Input";
import SelectBox from "src/components/ui/formElements/SelectBox";
import AssetTableRow from "src/components/ditigalAssets/AssetTableRow";
import ContentAction from "src/components/ui/formElements/ContentAction";
import { uiActions } from "../../store/reducers/ui-slice";
//Dialogs
import Upload from "src/components/dialogs/Upload";
import Preview from "src/components/dialogs/AssetsPreview";
import SendForReview from "src/components/dialogs/SendForReview";

import {
  fetchAssets,
  addToFavourite,
  deleteAsset,
} from "../../store/actions/assets";
import { removeUploaded } from "../../store/reducers/assets-slice";

import { assetsFormats, assetTypes } from "src/config";

import "../../styles/assets.scss";
import { getAllCompanyUsers } from "src/store/actions/users";
import { sendForReview } from "src/store/actions/reviews";
import CheckBox from "src/components/common/Check_Box";

const Assets = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const assetId = useRef();
  const { assetFormat, handleClose, setSrc, paginationParams } = props;
  const [searchParams] = useSearchParams();
  const {
    list: assets,
    status: assetStatus,
    count,
    uploaded: uploadedAsset,
  } = useSelector((state) => state.assets);
  const {
    company,
    _id: userId,
    assetReview,
  } = useSelector((state) => state.auth.userData);

  const [isFavourite, setIsFavourite] = useState(false);
  const [type, setType] = useState("all");
  const formatList =
    assetsFormats[assetFormat]?.format || assetsFormats[type]?.format;
  const [keyword, setKeyword] = useState("");
  const [format, setFormat] = useState(formatList[0]);
  const [previewData, setPreviewData] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenReview, setIsOpenReview] = useState(false);
  const [preview, setPreview] = useState(false);

  const [isFilterd, setIsFilterd] = useState(false);
  const isOnEditor = location.pathname.includes("edit");
  const assetType = assetFormat || type;
  const status = isOnEditor && assetReview ? "reviewed" : "all";

  //for pagination
  const limit = 10;
  const page = searchParams.get("page") || 1;
  const skip = (page - 1) * limit;

  //initial fetch assets
  useEffect(() => {
    const filters = {
      limit,
      skip,
      type: assetType,
      format: format.value,
      status,
      isFavourite,
    };
    dispatch(fetchAssets(filters));
  }, [dispatch]);

  useEffect(() => {
    if (uploadedAsset && isOnEditor) {
      setSelectedAsset(uploadedAsset.URL);
    }
  }, [uploadedAsset]);

  //remove uploded assset for setting selected asset
  useEffect(() => {
    if (uploadedAsset) {
      dispatch(removeUploaded());
    }
  }, [uploadedAsset]);

  // useEffect(() => {
  //   const type = assetFormat || "all";
  //   // if data filered then run useEffect
  //   if (isFilterd) {
  //     dispatch(
  //       fetchAssets({ limit, skip: 0, type, format: format.value, keyword: "", status, isFavourite })
  //     );
  //   }
  //   setIsFilterd(false)
  // }, [isFilterd]);

  const getCompanyUsers = () => {
    dispatch(getAllCompanyUsers(company.companyId));
  };

  const openSendForReview = (id) => {
    setIsOpenReview(true);
    assetId.current = id;
  };

  const onSendForReview = (data) => {
    dispatch(sendForReview({ id: assetId.current, data }));
  };

  const handleApply = () => {
    setIsFilterd(true);
    removeQueryParam("page");
    dispatch(
      fetchAssets({
        limit,
        skip: 0,
        type: assetType,
        format: format.value,
        keyword,
        status,
        isFavourite,
      })
    );
  };

  const handleCancel = () => {
    setIsFilterd(false);
    removeQueryParam("page");
    setFormat(formatList[0]);
    setType("all");
    setKeyword("");
    setIsFavourite(false);
    dispatch(
      fetchAssets({
        limit,
        skip: 0,
        type: "all",
        format: format.value,
        keyword: "",
        status,
        isFavourite: false,
      })
    );
  };

  const toggleUpload = () => {
    setIsOpen(!isOpen);
  };

  const togglePreview = () => {
    setPreview(!preview);
  };

  const favouriteHandler = (id) => {
    dispatch(addToFavourite(id));
  };

  const previewHandler = (src, format) => {
    setPreviewData({ src, format });
    togglePreview();
  };

  const downloadHandler = async (src, title) => {
    dispatch(uiActions.showLoading());
    try {
      if (src.includes("images")) {
        const response = await fetch(src);
        const blobImage = await response.blob();
        const href = URL.createObjectURL(blobImage);
        const anchorElement = document.createElement("a");
        anchorElement.href = href;
        anchorElement.download = title;
        anchorElement.click();
        window.URL.revokeObjectURL(href);
      } else {
        const anchorElement = document.createElement("a");
        anchorElement.href = src;
        anchorElement.download = title;

        anchorElement.click();

        window.URL.revokeObjectURL(src);
      }
      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Downlad",
          message: "downloaded successfully",
        })
      );
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Download Error",
          messge: "Something went wrong!!",
        })
      );
    }
    dispatch(uiActions.hideLoading());
  };

  const deleteHandler = (id) => {
    dispatch(deleteAsset(id));
  };

  // function runs when asset is selected
  const assetSelectHandler = (url) => {
    setSelectedAsset(url);
  };

  // This function works when asset is applied
  const applyAssetHandler = () => {
    if (setSrc) {
      setSrc(selectedAsset);
    }

    if (handleClose) {
      handleClose();
      setSelectedAsset(null);
    }
  };

  // filter assets type input handler
  const typeHandler = (e) => {
    setType(e.target.value);
    setFormat(formatList[0]);
  };

  const handleIsFavourite = (event) => {
    setIsFavourite(event.target.checked);
  };

  function removeQueryParam(paramKey) {
    // Get the current URL
    const currentUrl = window.location.href;

    // Create a URL object
    const url = new URL(currentUrl);

    // Get the search parameters
    const searchParams = new URLSearchParams(url.search);

    // Remove the specified parameter
    searchParams.delete(paramKey);

    // Update the URL with the modified search parameters
    url.search = searchParams.toString();

    // Replace the current URL with the updated one (removing the page parameter)
    window.history.replaceState({}, "", url.toString());
  }

  const isApplyButtonDisable =
    keyword.length === 0 &&
    assetType === "all" &&
    format?.value === "all" &&
    !isFavourite;

  const content = (
    <Content
      title={isOnEditor ? "Choose Asset" : "Assets"}
      // subtitle={!isOnEditor && "Assets"}
      contentClass={isOnEditor && "chooseAsset"}
      headerAction={
        !assetReview && (
          <ContentAction
          text="Upload Asset"
          icon={<IoCloudUploadOutline />}
          variant="contained"
          onClick={toggleUpload}
        />
        )
      }
    >
      <Upload
        title="Upload Asset"
        show={isOpen}
        close={toggleUpload}
        type={assetFormat || "all"}
      />

      <Preview
        src={previewData?.src}
        format={previewData?.format}
        show={preview}
        close={togglePreview}
      />

      <SendForReview
        isOpen={isOpenReview}
        type="assets"
        userId={userId}
        onInput={getCompanyUsers}
        handleClose={() => setIsOpenReview(false)}
        onSend={onSendForReview}
      />
      <Box className="inlineForm">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={6} md={3}>
            <Box className="ControlWrap">
              <Input
                type="text"
                placeholder="Search Assets"
                labelTag="Keyword"
                onChange={(e) => setKeyword(e.target.value)}
                value={keyword}
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Box className="ControlWrap">
              <SelectBox
                value={assetType}
                options={assetTypes}
                onChange={typeHandler}
                labelTag="Type"
                disabled={isOnEditor}
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Box className="ControlWrap">
              <label className="labelTag">Format</label>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={
                  assetsFormats[assetFormat]?.format ||
                  assetsFormats[type]?.format
                }
                value={format}
                onChange={(event, selected) =>
                  selected ? setFormat(selected) : setFormat(formatList[0])
                }
                renderInput={(params) => <TextField {...params} />}
                labelTag="asdsd"
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <CheckBox
              label="Favorite"
              checked={isFavourite}
              onChange={handleIsFavourite}
            ></CheckBox>
          </Grid>

          <Grid item xs={3} textAlign="end">
            {isFilterd && (
              <Button
                className="cancelBtn"
                variant="text"
                onClick={handleCancel}
              >
                Clear Filter
              </Button>
            )}
            <Button
              color="secondary"
              disabled={isApplyButtonDisable}
              variant="contained"
              onClick={handleApply}
            >
              Apply Filter
            </Button>
          </Grid>
        </Grid>
      </Box>

      <br />
      {/* Assets Table */}
      <Grid className="assetTable" style={{ mt: 2 }}>
        <Grid item xs={12}>
          <TableContainer className="assetContainer" component={Paper}>
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className="tableHead">PREVIEW</TableCell>
                  <TableCell className="tableHead columnSize">TITLE</TableCell>
                  <TableCell className="tableHead columnSize">FORMAT</TableCell>
                  <TableCell className="tableHead columnSize">
                    UPDATE DATE
                  </TableCell>
                  <TableCell className="tableHead columnSize">STATUS</TableCell>
                  {!isOnEditor && (
                    <TableCell className="tableHead" sx={{ width: "10%" }}>
                      ACTIONS
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {!assetStatus ? (
                  assets.map((asset) => {
                    return (
                      <AssetTableRow
                        key={asset._id}
                        asset={asset}
                        Actions={!isOnEditor}
                        isSelected={isOnEditor && selectedAsset === asset.URL}
                        onAssetSelect={() => assetSelectHandler(asset.URL)}
                        onFavourite={() => favouriteHandler(asset._id)}
                        onPreview={() =>
                          previewHandler(asset.URL, asset.format)
                        }
                        onSendForReview={openSendForReview}
                        onDownload={() =>
                          downloadHandler(asset.URL, asset.title)
                        }
                        onDelete={() => deleteHandler(asset._id)}
                      />
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      sx={{
                        textAlign: "center",
                        fontSize: "24px",
                        marginX: "20px",
                      }}
                    >
                      {assetStatus}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {isOnEditor && (
            <Box className="chooseBtns">
              <Button
                color="secondary"
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={applyAssetHandler}
                disabled={!selectedAsset}
              >
                Apply
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
      <Pagination
        // enableParams={paginationParams}
        count={count}
        onChange={(value) => {
          setSelectedAsset(null);
          dispatch(
            fetchAssets({
              ...value,
              type: assetType,
              format: format.value,
              keyword,
              status,
              isFavourite,
            })
          );
        }}
      />
    </Content>
  );
  return isOnEditor ? content : <Card classes="screen">{content}</Card>;
};
export default Assets;

Assets.defaultProps = {
  paginationParams: true,
};
