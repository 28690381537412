import React from "react";
import { Box, Typography } from "@mui/material";
import "src/styles/properties.scss";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { MdExpandMore } from "react-icons/md";
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Control = ({
  heading,
  headingSize,
  headingVariant,
  children,
  className,
  divider,
  isCollapse,
  expanded,
  iconHeading,
}) => {
  return (
    <>
      {isCollapse ? (
        <Accordion
          defaultExpanded={expanded}
          className={`control_accordian ${className}`}
        >
          <AccordionSummary
            className="accordian_heading"
            classes={{ content: "accordian_content" }}
            expandIcon={<MdExpandMore />}
          >
            <Box className="head">
              {heading ? (
                <Typography component={headingVariant} variant={headingVariant}>
                  {heading}
                </Typography>
              ) : (
                iconHeading
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails className="accordian_details">
            <Box className="control">
              <Box className={"body " + className}>{children}</Box>
              {divider && <Box className="divider"></Box>}
            </Box>
          </AccordionDetails>
        </Accordion>
      ) : (
        <Box className="control">
          <Box className="head">
            <Typography component={headingVariant} variant={headingVariant}>
              {heading}
            </Typography>
          </Box>
          <Box className={"body " + className}>{children}</Box>
          {divider && <Box className="divider"></Box>}
        </Box>
      )}
    </>
  );
};
Control.defaultProps = {
  headingVariant: "h2",
};
export default Control;
