import React from "react";
import { Grid, Box, Button, Tabs, Tab } from "@mui/material";
import Card from "src/components/ui/Card";
import Table from "./Table";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoEye } from "react-icons/io5";
import { Data } from "./Data";
import DropDown from "src/components/ui/DropDown";
import Detail from "src/components/common/Detail";
import "../../styles/presentations/details.scss";
import CircularLoading from "src/components/ui/CircularLoading";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

const Details = (props) => {
  const { review, preview, detail, actionPreview, disableVersion } = props;
  const tableData = [...Data.previewHeadData];

  if (!disableVersion) {
    tableData.unshift({
      id: "version",
      numeric: false,
      sort: true,
      label: "Version",
    });
  }
  return (
    <>
      {detail?.status ? (
        <Box className="details">
          <Detail
            detail={detail || {}}
            Action={
              <Grid item xs={3} className="clonegrid">
                <Button variant="contained" onClick={preview}>
                  Preview
                </Button>
              </Grid>
            }
          />
          <Box className="sub-details">
          <Table
                disableVersion={disableVersion}
                headCells={tableData}
                Action={({ versionId }) => {
                  const items = [
                    {
                      icon: <IoEye size={17} />,
                      label: "Preview",
                      onClick: () => {
                        actionPreview(versionId);
                      },
                    },
                  ];

                  return (
                    <DropDown
                      tooltip="More"
                      items={items}
                      dropIcon={<BsThreeDotsVertical size={17} />}
                    />
                  );
                }}
                rows={review.reviewedBy}
                reviewStart={review.createdAt}
              />
          </Box>
        </Box>
      ) : (
        <CircularLoading />
      )}
    </>
  );
};
export default Details;
