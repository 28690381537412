import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IoSearch, IoArrowBack } from "react-icons/io5";
import { Grid, Typography, Box, IconButton, TextField } from "@mui/material";

import CardItem from "src/components/common/CardItem";

//actions
import {
  fetchTemplatesByCategory,
  fetchTemplateCategory,
} from "src/store/actions/emails";
import { setTemplateCategoryMsg } from "src/store/reducers/emails-slice";

import defaultImage from "src/assets/images/template-thumb.png";

const Templates = ({ selectTemplate, setEmail }) => {
  const dispatch = useDispatch();

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isOnTemplates, setIsOnTemplate] = useState(false);

  const openCategory = (template) => {
    setSelectedCategory(template);
    setIsOnTemplate(true);
    dispatch(
      fetchTemplatesByCategory({
        categoryId: template._id,
        type: "email",
      })
    );
  };

  const goBack = () => {
    dispatch(setTemplateCategoryMsg());
    setEmail((pre) => ({ ...pre, templateId: "" }));
    setIsOnTemplate(false);
  };

  const { templatesCategories, templates, templatesMsg } = useSelector(
    (state) => state.emails
  );

  useEffect(() => {
    dispatch(fetchTemplateCategory("email"));
  }, []);

  return (
    <Grid container>
      <Grid item sm={8} md={8} display="flex" alignItems="center">
        {isOnTemplates ? (
          <Typography variant="h3" color={"text.primary"}>
            <IconButton onClick={goBack}>
              <IoArrowBack />
            </IconButton>{" "}
            {selectedCategory.name}
          </Typography>
        ) : (
          <Typography variant="h3" color={"text.primary"}>
            Choose a template type
          </Typography>
        )}
      </Grid>
      <Grid item sm={4} md={4} display="flex">
        {/* <Box className="SearchBox">
          <IconButton
            color="primary"
            component="button"
            className="searchButton"
          >
            <IoSearch />
          </IconButton>
          <TextField placeholder="Search Here.." fullWidth />
        </Box> */}
      </Grid>
      {templatesMsg ? (
        <Typography variant="h3">{templatesMsg}</Typography>
      ) : isOnTemplates ? (
        templates.map((template) => {
          return (
            <Grid
              item
              key={template._id}
              xs={12}
              md={2}
              className="custom-width"
            >
              <CardItem
                classes="presentationTemplateCard"
                id={template._id}
                image={template.image !== "" ? template.image : defaultImage}
                title={template.name}
                onClick={(e) => {
                  selectTemplate(e, template._id);
                }}
              />
            </Grid>
          );
        })
      ) : (
        templatesCategories.map((template, index) => {
          return (
            <Grid
              item
              key={template._id}
              xs={12}
              md={2}
              className="custom-width"
            >
              <CardItem
                classes="presentationTemplateCard"
                id={template._id}
                image={defaultImage}
                title={template.name}
                onClick={() => openCategory(template)}
              />
            </Grid>
          );
        })
      )}
    </Grid>
  );
};

export default React.memo(Templates);
