import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Button, Divider, Grid, TextField, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import {
  connectPresentation,
  getReviewedPre,
  getSFPresentations,
  salesforceLogin,
} from "src/store/actions/sf";
import Card from "src/components/ui/Card";
import SalesforcePresentationCard from "src/components/integration/SalesforcePresentationCard";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import MainDialog from "../../components/dialogs/Main";
import cookie from "react-cookies";
import Content from "src/layouts/Content";
import Tooltip from "@mui/material";
import {Popper} from "@mui/material";
const Salesforce = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  const [openedPresentation, setopenedPresentation] = useState({
    SF_presentation: null,
    SF_presentationName: null,
    csPresentation: null,
  });
  const {
    reviewed: { list },
  } = useSelector((state) => state.presentations);


  const sfPresentations = useSelector(
    (state) => state.presentations.sfPresentations.filter((presentation)=> !presentation.Download_Url__c)
  );
  const { isSfIntegrated } = useSelector((state) => state.auth);
  const openPopup = (id, name) => {
    console.log(name)
    setIsOpen(true);
    setopenedPresentation({ ...openedPresentation, SF_presentation: id, SF_presentationName:name });
  };
  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (isSfIntegrated) {
      const sfConfig = cookie.load("sf-config");
      dispatch(getReviewedPre());
      dispatch(getSFPresentations({ instanceURL:sfConfig.instanceUrl }));
    }
  }, []);
  
  const isFormCompleted =  !openedPresentation.csPresentation || !email || !password
  const sumbitHandler = async (event) => {
    event.preventDefault();
      let data = {
        ...openedPresentation,
        email: email,
        password: password,
      }
      dispatch(
        connectPresentation(data)
      );
      setIsOpen(false);
  };
  
  return isSfIntegrated ? (
    <Content title="Sales force Presentation">
      <MainDialog
        fullWidth={false}
        handleClose={() => {
          setIsOpen(false);
          setopenedPresentation({});
        }}
        isOpen={isOpen}
      >
        
        <Typography component="h4" variant="h4">Sales Force Presentation</Typography>
        <p  style={{marginTop:'0px'}}>{openedPresentation?.SF_presentationName}</p>
        <Typography component="h4" variant="h4">Connect With</Typography>
        <Box
          onSubmit={sumbitHandler}
          component="form"
          display="flex"
          width="20rem"
          flexDirection="column"
          gap={2}
        >
          <Autocomplete
            onChange={(_, value) => {
              if (value) {
                setopenedPresentation({
                  ...openedPresentation,
                  csPresentation: value._id,
                });
              }
            }}
            onInputChange={(_, value) => {
              if (!value) {
                setopenedPresentation({
                  ...openedPresentation,
                  csPresentation: '',
                });
              }
            }}
            disablePortal
            options={list}
            name="presentation"
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                name="presentation"
                helperText={errors.presentation && errors.presentation }
                error={errors.presentation ? true : false}
                placeholder={!openedPresentation.csPresentation && "Reviewed Presentations"}
                {...params}
              />
            )}
            ListboxProps={{
              style: { maxHeight: "150px" }, // Optional: Set the maximum height of the list
            }}
            renderOption={(props, option) => {
              const filterProps = {...props , key: option._id}
             return <Box component="li" {...filterProps} sx={{ '& > img': { mr: 1.5, flexShrink: 0 } }}>
                <img
                  width="50px"
                  loading="lazy"
                  src={`${option.bucketURL}/${option.thumbnail}`}
                  srcSet={`${option.bucketURL}/${option.thumbnail}`}
                />
                {option.name}
              </Box>
            }}
          />
          
          <TextField
            name="email"
            type="email"
            placeholder="Email"
            helperText={errors.email && errors.email}
            error={errors.email ? true : false}
            value={email}
            onChange={(e)=>{
              setEmail(e.target.value)
            }}
            variant="outlined"
            required
            />
          <TextField
            name="password"
            type="password"
            placeholder="Password"
            helperText={errors.password ? errors.password : " "}
            error={errors.password ? true : false}
            value={password}
            onChange={(e)=>{
              setPassword(e.target.value)
            }}
            required
          />
          <Button type="submit" variant="contained" disabled={isFormCompleted}>
            Connect
          </Button>
        </Box>
      </MainDialog>
      <Card classes="screen">
        <Grid container className="details"  sx={{padding:'0px'}}>
          {sfPresentations.length === 0 ? (
            <Typography variant="h2" pl={3} pt={2}>
              No salesforce presentation found for connect
            </Typography>
          ) : ( sfPresentations.map((presentation) => (
            <Grid key={presentation.Id} item xs={12}>
              <SalesforcePresentationCard
                presentation={presentation}
                Action={
                  <Button
                    size="medium"
                    variant="contained"
                    onClick={() => openPopup(presentation.Id, presentation.Name)}
                  >
                    Connect
                  </Button>
                }
                showStatus={false}
              />
            </Grid>
          )))}
        </Grid>
      </Card>
    </Content>
  ) : (
    <Navigate to="/integration" />
  );
};

export default Salesforce;
