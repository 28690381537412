import React,{useState}  from "react";
import { useDateFormat } from "src/hooks/useDateFormat";

import DropDown from "../ui/DropDown";
import {
  IoStar,
  IoEllipsisVerticalSharp,
  IoTrash,
  IoEye,
  IoDownload,
} from "react-icons/io5";
import { AiFillLike } from "react-icons/ai";

import {
  TableRow,
  TableCell,
  IconButton,
  Grid,
  Typography,
} from "@mui/material";

import { statusTypes, getThumbnailByFormat, posterOfVideo } from "src/services/utils";
import "../../styles/assets.scss";

const AssetTableRow = (props) => {
  const { asset } = props;
  const {
    isFavourite,
    URL: url,
    title,
    format,
    thumbnail,
    updatedAt,
    status,
    _id,
  } = asset;
  const {
    Actions,
    isSelected,
    onAssetSelect,
    onFavourite,
    onPreview,
    onDownload,
    onDelete,
    onSendForReview,
  } = props;

  const [videoPoster, setVideoPoster]=useState(posterOfVideo(url))

  const dropDownData = [
    { label: "Preview", icon: <IoEye />, onClick: onPreview },
    { label: "Download", icon: <IoDownload />, onClick: onDownload },
    { label: "Delete", icon: <IoTrash />, onClick: onDelete },
  ];

  if(status === "draft" || status === "declined"){
    dropDownData.push({
      label: "Send For Review",
      icon: <AiFillLike />,
      onClick: () => onSendForReview(_id),
    })
  }

  const assetThumbnail = getThumbnailByFormat(format, thumbnail);

  const errorImage = (err)=>{
    setVideoPoster(assetThumbnail)
  }

  return (
    <TableRow
      className={`tableRow ${isSelected && "selectedAsset"}`}
      onClick={onAssetSelect}
    >
      <TableCell scope="row">
        <Grid container alignItems="center">
          <Grid item md={3}>
            <img
              className="assetImage"
              data-url={url}
              src={format == 'mp4' ? videoPoster:assetThumbnail}
              // src={assetThumbnail}
              onError={errorImage}
              alt={title}
            />
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        <Typography className="columnTextColor">{title}</Typography>
      </TableCell>
      <TableCell>
        <Typography className="columnTextColor">
          {format.toUpperCase()}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className="columnTextColor">
          {useDateFormat(updatedAt)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className="columnTextColor">
          {statusTypes[status]}
        </Typography>
      </TableCell>
      {Actions && (
        <TableCell>
          <IconButton
            className={`columnTextColor icon ${isFavourite && "favouriteIcon"}`}
            onClick={onFavourite}
          >
            <IoStar />
          </IconButton>
          <DropDown
            iconClass="columnTextColor icon"
            dropIcon={<IoEllipsisVerticalSharp />}
            items={dropDownData}
            tooltip="options"
          />
        </TableCell>
      )}
    </TableRow>
  );
};

export default AssetTableRow;
