import React from "react";
import AccessControl from "../../components/presentations/AccessControl";

const Localize = () => {
  return (
    <AccessControl component="localize">
      <div>Localize</div>
    </AccessControl>
  );
};

export default Localize;
