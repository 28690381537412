import React, { useEffect, useLayoutEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { canSendRequest } from "src/services/utils";
import { fetchLandingPageById } from "src/store/actions/microsites";
import { setLandingPageAccess } from "src/store/reducers/microsite-slice";

const AccessControl = ({ children, component }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { landingPageAccess, landingPage, config } = useSelector(
    (state) => state.microsites
  );

  const { id } = useParams();
  const { roles } = useSelector((state) => state.roles);

  // API call to get the presentation
  useLayoutEffect(() => {
    if (canSendRequest(config, id)) {
      dispatch(fetchLandingPageById(id));
    }
  }, [landingPage]);

  //Navigate to my-library if user is not authorized to access the presentation
  useEffect(() => {
    if (!landingPageAccess) {
      dispatch(setLandingPageAccess(true));
      navigate("/microsites/my-library");
    }
  }, [landingPageAccess, landingPage]);
  return (
    <>
      {landingPage._id === id &&
        (roles?.[landingPage.role]?.[component] ? (
          children
        ) : (
          <Navigate to={-1}/>
        ))}
    </>
  );
};

export default React.memo(AccessControl);
