import { useState } from "react";
import { Box, Grid, Typography, Button, IconButton } from "@mui/material";
import Input from "src/components/ui/formElements/Input";
import SwitchButton from "src/components/ui/formElements/SwitchButton";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import InputFields from "../ui/formElements/InputFields";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { passwordValidator } from "src/utils/Utils";
import { useDispatch } from "react-redux";
import { activateAccount } from "src/store/actions/auth";

const CreatePassword = (props) => {
  // const [confirmPassword, setConfirmPassword] = useState("");
  // const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const dispatch = useDispatch();

  const forgotHandler = () => { };



  const defaultValues = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    reset,
    resetField,
    formState: { errors },
  } = defaultValues;

  const password = watch("password");
  const confirmPassword = watch("confirmPassword");

  const submitHandler = () => {
    console.log(props.token)
    let token = props.token
    if (!passwordValidator(password)) {
      setError("password", { type: "manual", multiError: true, message: "Password must be 8 or more characters, must contain at least one uppercase letter, one lower case, one special character & one number." })
      return;
    }
    if (password !== confirmPassword) {
      setError("confirmPassword", { type: "manual", message: "Password & Confirm password must match" })
      return;
    }
    if (token) {
      dispatch(activateAccount({ password, confirmPassword, token }));
    }
  }

  return (
    <form>
      <Box className="loginForm">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputFields
              fieldName="password"
              label="Password"
              control={control}
              rules={{
                required: "Password is required",
              }}
              error={errors?.password}
              helperText={<ul className="builts" style={{
                marginBottom: "2em",
                marginLeft: "0px",
                paddingLeft: "0px",
              }}>
                <li>Password must be 8 or more characters</li>
                <li>Must contain at least one uppercase letter</li>
                <li>Must contain at least one lowercase letter</li>
                <li>Must contain at least one special character</li>
                <li>Must contain at least one number</li>
              </ul>}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                  </IconButton>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputFields
              fieldName="confirmPassword"
              label="Confirm Password"
              control={control}
              rules={{
                required: "Confirm Password is required",
              }}
              error={errors?.confirmPassword}
              type={showConfirmPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                  </IconButton>
                ),
              }}
            />
          </Grid>
        </Grid>
        {/* <Box className="ControlWrap">
          <Input
            className="border"
            labelTag="Password"
            label="Password"
            type="password"
            name="password"
            value={password}
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </Box>
        <Box className="ControlWrap">
          <Input
            className="border"
            labelTag="Confirm Password"
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            value={confirmPassword}
            placeholder="Password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </Box> */}

        <Box className="pt-4">
          <Button type="submit"
            variant="contained"
            fullWidth
            onClick={handleSubmit(submitHandler)}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </form >
  );
};
export default CreatePassword;
