import React from "react";
import { TableRow, TableCell, Grid, Typography } from "@mui/material";
import DropDown from "../ui/DropDown";
import { useDateFormat } from "src/hooks/useDateFormat";
import { statusTypes } from "src/services/utils";
import { IoEllipsisVerticalSharp } from "react-icons/io5";

const ReviewItem = (props) => {
  const {
    reviewId,
    thumbnail,
    title,
    ownerName,
    updatedAt,
    status,
    dropDown,
    data,
  } = props;
  return (
    <TableRow className="tableRow">
      <TableCell scope="row">
        <Grid container alignItems="center">
          <Grid item md={3}>
            <img className="assetImage" src={thumbnail} alt={title} />
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        <Typography className="columnTextColor">{title}</Typography>
      </TableCell>
      <TableCell>
        <Typography className="columnTextColor">{ownerName}</Typography>
      </TableCell>
      <TableCell>
        <Typography className="columnTextColor">
          {useDateFormat(updatedAt)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          sx={{ textTransform: "capitalize" }}
          className="columnTextColor"
        >
          {statusTypes[status]}
        </Typography>
      </TableCell>
      <TableCell>
        <DropDown
          id={reviewId}
          data={data}
          iconClass="columnTextColor icon"
          dropIcon={<IoEllipsisVerticalSharp />}
          items={dropDown}
          tooltip="More"
        />
      </TableCell>
    </TableRow>
  );
};

export default ReviewItem;
