import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Grid, Typography, Checkbox } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import { BsCheck2 } from "react-icons/bs";

import Content from "src/layouts/Content";
import Input from "src/components/ui/formElements/Input";
import SelectBox from "src/components/ui/formElements/SelectBox";
import Main from "../Main";

import {
  createLandingPage,
  fetchTemplates,
} from "src/store/actions/microsites";
import { uiActions } from "src/store/reducers/ui-slice";
import CardItem from "src/components/common/CardItem";
import { countries, languages } from "src/data/data";

const AddNew = (props) => {
  const dispatch = useDispatch();
  const { templates } = useSelector((state) => state.microsites);
  const { show, close, type } = props;

  useEffect(() => {
    dispatch(fetchTemplates(type));
  }, []);

  const intialState = {
    name: "",
    image: "N/A",
    category: "abc",
    lang: "",
    country: "",
    region: "pak",
    version: "0.001",
    targetSystem: "",
    templateId: "",
    startDate: "",
    endDate: "",
    ApprovalSystemExtId: "",
    originalSlides: true,
    status: "draft",
  };
  const [landingPage, setLandingPage] = useState(intialState);

  const handleChange = (e) => {
    const key = e.target.name;
    let value = e.target.value;
    if (e.target.type === "checkbox") {
      value = e.target.checked;
    }
    setLandingPage((pre) => ({ ...pre, [key]: value }));
  };

  const selectTemplate = (e, id) => {
    const cards = document.querySelectorAll(".templateContainer .cardItem");
    setLandingPage({ ...landingPage, templateId: id });
    cards.forEach((card) => card.classList.remove("success-checkmark"));
    e.currentTarget.classList.add("success-checkmark");
  };

  const addNewLandingPage = (e) => {
    e.preventDefault();
    if (!landingPage.templateId) {
      return dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Select Template",
          message: "Please Select Template to Create Landing Page Template",
        })
      );
    }
    dispatch(createLandingPage(landingPage));
    close();
    setLandingPage(intialState);
  };

  const closeDialog = () => {
    close();
    resetForm(); // Reset the form on close
  };
   // Function to reset form state
   const resetForm = () => {
    setLandingPage(intialState);
  };
  return (
    <Main className="previewDialog" isOpen={show} handleClose={closeDialog}>
      <Content title="Create New Landing Page">
        <form onSubmit={addNewLandingPage}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box className="ControlWrap">
                <Input
                  type="text"
                  // label="Name"
                  placeholder="Enter text here.."
                  name="name"
                  labelTag="Name"
                  value={landingPage.name}
                  onChange={handleChange}
                  required
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box className="ControlWrap">
                <SelectBox
                  // label="Country"
                  name="country"
                  labelTag="Country"
                  onChange={handleChange}
                  renderValue="Select Country"
                  value={landingPage.country}
                  options={countries}
                  required
                  displayEmpty
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className="ControlWrap">
                <SelectBox
                  // label="Language"
                  name="lang"
                  labelTag="Language"
                  renderValue="Select Language"
                  value={landingPage.lang}
                  onChange={handleChange}
                  options={languages}
                  required
                  displayEmpty
                />
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            my={0.6}
            justifyContent="space-between"
            alignItems="center"
            mt={2}
            mb={2}
          ></Grid>
          <Grid
            container
            spacing={3}
            className="templateContainer"
            sx={{ overflowY: "scroll" }}
          >
            <Box sx={{ width: "100%", display: "flex" }}>
              {templates &&
                templates.map((template) => {
                  return (
                    <Grid
                      item
                      key={template._id}
                      xs={12}
                      md={2}
                      className="custom-width"
                    >
                      <CardItem
                        classes="presentationTemplateCard m-15"
                        id={template._id}
                        image={
                          template.image !== "" ? template.image : defaultImage
                        }
                        title={template.name}
                        onClick={(e) => {
                          selectTemplate(e, template._id);
                        }}
                      />
                    </Grid>
                  );
                })}
            </Box>
          </Grid>
          <div className="button-div">
          <Grid container mt={2}>
            {/*<Grid item md={10}>
               <Box display="flex" alignItems="center">
                <Checkbox
                  color="secondary"
                  defaultChecked
                  name="originalSlides"
                  onChange={handleChange}
                />
                <Typography textTransform="capitalize" color="#2D3748">
                  keep slides from orignal template
                </Typography>
              </Box> 
            </Grid>*/}
            {/* <Grid item md={2} display="flex" justifyContent="space-between"> */}
            <div className="custom-btn">
              <Button sx={{ mr: 2 }}
                variant="outlined"
                className="capitalize"
                onClick={closeDialog}
                startIcon={<AiOutlineClose size={13} />}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className="capitalize"
                type="submit"
                startIcon={<BsCheck2 size={13} />}
              >
                Create
              </Button>
              </div>
            </Grid>
            </div>
          {/* </Grid> */}
        </form>
      </Content>
    </Main>
  );
};
export default React.memo(AddNew);
