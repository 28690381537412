import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

const Hidden = ({ mobile = false, tablet = false, desktop = false, children }) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(min-width:601px) and (max-width: 960px)');
  const isDesktop = useMediaQuery('(min-width:961px)');

  if ((mobile && isMobile) || (tablet && isTablet) || (desktop && isDesktop)) {
    return null; // Hide the content on the specified devices
  }

  return <React.Fragment>{children}</React.Fragment>; // Show the content on other devices
};

export default Hidden;