import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../interfaces/api.interface";
import HttpService from "../../services/http.service";
import { uiActions } from "../reducers/ui-slice";

export const fetchAssets = createAsyncThunk(
  "fetchAssets",
  async (page, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.Assets.fetchAssets(page),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const fetchAssetsById = createAsyncThunk(
  "fetchAssetsById",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.Assets.fetchAssetsById(id),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue();
    }
  }
);

export const uploadAsset = createAsyncThunk(
  "uploadAsset",
  async (assets, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.Assets.uploadAssets(),
        assets,
        thunkAPI
      );
      if (response.data) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Uploaded!",
            message: "Asset Uploaded successfully",
          })
        );
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addToFavourite = createAsyncThunk(
  "addToFavourite",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.Assets.addToFavourite(id),
        undefined,
        thunkAPI
      );
      
      if (response.data) {
        if(response.data?.isFavourite){
          thunkAPI.dispatch(
            uiActions.showNotification({
              status: "success",
              title: "Added!",
              message: "This asset has been added to your favorites",
            })
          );
        }else{
          thunkAPI.dispatch(
            uiActions.showNotification({
              status: "success",
              title: "Removed!",
              message: "The asset has been removed from your favorites",
            })
          );
        }
        return id;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteAsset = createAsyncThunk(
  "deleteAsset",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.Assets.deleleAsset(id),
        undefined,
        thunkAPI
      );
      if (response.data) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Deleted!",
            message: "Asset Deleted successfully",
          })
        );
        return id;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getAsset = createAsyncThunk("getAsset", async (id, thunkAPI) => {
  try {
    const response = await HttpService.call(
      api.Assets.getAsset(id),
      undefined,
      thunkAPI
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    return thunkAPI.rejectWithValue();
  }
});
