import React from "react";
import { Grid } from "@mui/material";
import CardItem from "src/components/common/CardItem";

const ProjectCard = (props) => {
  const {
    Controls,
    targetDevice,
    image,
    title,
    subtitle,
    description,
    status,
    id,
    onDoubleClick,
    targetSystem,
    isShared
  } = props;

  return (
    <Grid item xs={6} sm={4} md={3} lg={2.4} xlg={2.4}>
      {/* we need div as a wrapper (MUI requirement) */}
      <div>
        <CardItem
          id={id}
          image={image}
          title={title}
          subtitle={subtitle}
          description={description}
          classes="presentationCard"
          status={status}
          controls={Controls}
          targetDevice={targetDevice}
          targetSystem={targetSystem}
          onDoubleClick={onDoubleClick}
          isShared={isShared}
        />
      </div>
    </Grid>
  );
};

export default React.memo(ProjectCard);
