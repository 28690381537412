import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setSharedWith } from "src/store/reducers/sharing-slice";
import { getAllCompanyUsers } from "src/store/actions/users";
import { cancelReview, sendForReview } from "src/store/actions/reviews";

import {
  exportLandingPage,
  importLandingPage,
  uploadNewVersion,
  deleteLandingPage,
  cloneLandingPage,
} from "src/store/actions/microsites";

import { downloadPdfByImages } from "src/services/utils";

import { share } from "src/store/actions/sharing";

//Dialogs
import AddNew from "./AddNew";
import ExportLandingPage from "./Export";
import UploadNewVersion from "../UploadNewVersion";
import DetailsDrawer from "../DetailsDrawer";
import SharingSettings from "../SharingSettings";
import SendForReview from "../SendForReview";
import UploadNew from "../UploadNew";
import Confirm from "../Confirm";
import ReviewDelete from "../ReviewDelete";
const MicrositesMyLibraryDialogs = (props) => {
  const dispatch = useDispatch();
  const type = "landingpage";
  const { company, _id: userId } = useSelector((state) => state.auth.userData);

  const { onClose, dialog, micrositeId, microsite } = props;

  //on close function for closing dialogs

  //upload new microsite function
  const onUploadNewVersion = useCallback((data) => {
    dispatch(uploadNewVersion(data));
  }, []);

  //import new microsite
  const onUploadNew = useCallback((data) => {
    dispatch(importLandingPage(data));
  }, []);

  //export microsite
  const onExport = useCallback(() => {
    downloadPdfByImages([`${microsite.bucketURL}/${microsite.image}`]);
  }, [microsite]);

  //get all company users
  const getCompanyUsers = useCallback(() => {
    dispatch(getAllCompanyUsers(company.companyId));
  }, [company]);

  const cancelReviewEmail = useCallback(() => {
    dispatch(cancelReview({ id: micrositeId, type }));
    onClose();
  }, [micrositeId]);

  const micrositeShare = useCallback(
    (sharedWith, accessLevel) => {
      const userIds = sharedWith.map((user) => {
        return { userId: user._id, role: accessLevel };
      });

      const shared = sharedWith.map((user) => ({
        userId: user,
        role: accessLevel,
      }));

      dispatch(setSharedWith(shared));
      dispatch(
        share({
          id: micrositeId,
          data: { sharedWith: userIds, accessLevel },
          type,
        })
      );
    },
    [micrositeId]
  );

  //assign microsite fro review
  const assignForReview = useCallback(
    (data) => {
      dispatch(sendForReview({ id: micrositeId, data }));
    },
    [micrositeId]
  );

  const micrositeDelete = useCallback(async() => {
    await dispatch(deleteLandingPage(micrositeId));
    onClose();
  }, [micrositeId]);

  const handleClone = useCallback(async () => {
    await dispatch(cloneLandingPage(micrositeId));
    onClose();
  }, [micrositeId]);

  return (
    <>
      <AddNew show={dialog.createNew} close={onClose} type={type} />
      <ExportLandingPage
        isOpen={dialog.export}
        exportAs="pdf"
        handleExport={onExport}
        handleClose={onClose}
        id={micrositeId}
      />
      <UploadNewVersion
        title="Upload New Version"
        close={onClose}
        show={dialog.uploadNewVersion}
        onUpload={onUploadNewVersion}
        id={micrositeId}
      />
      <DetailsDrawer
        type="microsite"
        open={props.dialog.info}
        onClose={onClose}
        details={microsite || {}}
      />
      <SharingSettings
        userId={userId}
        onInput={getCompanyUsers}
        isOpen={dialog.share}
        handleClose={onClose}
        onShare={micrositeShare}
      />
      <SendForReview
        type={type}
        userId={userId}
        onInput={getCompanyUsers}
        isOpen={dialog.review}
        handleClose={onClose}
        onSend={assignForReview}
      />
      <UploadNew
        show={dialog.uploadNew}
        close={onClose}
        title="Upload From"
        type="Microsite"
        onUpload={onUploadNew}
        fieldsWidth={4}
      />
      <Confirm
        isOpen={dialog.delete}
        subtitle="Are you sure you want to delete this landing page?"
        handleClose={onClose}
        handleConfirm={micrositeDelete}
      />
           <ReviewDelete
       isOpen={dialog.reviewdelete}
        subtitle="Content under review or already reviewed cannot be deleted. Thank you for your understanding!"
        handleClose={onClose}
        
      />
      <Confirm
        isOpen={dialog.cancelReview}
        subtitle="Are you sure you want to cancel this review?"
        handleClose={onClose}
        handleConfirm={cancelReviewEmail}
      />
      <Confirm
        isOpen={dialog.clone}
        subtitle="Are you sure you want to clone this microsite?"
        handleClose={onClose}
        handleConfirm={handleClone}
      />
    </>
  );
};

export default MicrositesMyLibraryDialogs;
