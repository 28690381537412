import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  addActiveClassToElement,
  findElementType,
} from "src/services/editor.service";
import { editorActions } from "src/store/reducers/editor-slice";
import { getElementData } from "./shared-logic";

export const useElementStatus = () => {
  const dispatch = useDispatch();

  // To Active an Element
  const activeElement = useCallback((element) => {
    const id = element?.id;

    if (id) {
      const elementDetails = getElementData({ id });
      const elementType = findElementType(element.getAttribute("class"));
      const payload = {
        elementId: id,
        elementDetails,

        elementType,
      };
      addActiveClassToElement(element);
      dispatch(editorActions.activeElement(payload));
      return payload;
    }
  }, []);

  // to deactive element
  const deActiveElement = useCallback(() => {
    let element = document.querySelector(".editable.active");
    if (element) {
      addActiveClassToElement(element, false);
      dispatch(editorActions.deActiveElement());
    }
  }, []);

  return { activeElement, deActiveElement };
};
