export const HISTORY_ACTIONS = {
  appended: "appended",
  updated: "updated",
  deleted: "deleted",
  imageChange: "image-changed",
  sorted: "sorted",
  gridResized: "grid-resized",
  containerChanged: "container-change",

  STYLED_ACTIONS: {
    dragged: "dragged",
    resized: "resized",
    rotated: "rotated",
    borderChange: "border-change",
    zIndexChange: "z-index-change",
    common: "common-style-changed",
  },
};

export const HISTORY_LIMIT = 30;
