import { createSlice } from "@reduxjs/toolkit";

import {
  fetchAssets,
  uploadAsset,
  addToFavourite,
  deleteAsset,
} from "../actions/assets";

const initialState = {
  list: [],
  count: 0,
  status: null,
  uploaded: null,
  isAssetsFetched: false,
};
const assetsSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {
    updateStatus(state, action) {
      state.status = action.payload;
    },
    updateAssetStatus(state, action) {
      const { id, status } = action.payload;

      const index = state.list.findIndex((asset) => asset._id === id);

      if (index !== -1) {
        state.list[index].status = status;
      }
    },
    removeUploaded(state, action) {
      state.uploaded = null;
    },
  },
  extraReducers: {
    [fetchAssets.pending]: (state, action) => {
      state.status = "Fetching Assets ...";
    },
    [fetchAssets.fulfilled]: (state, action) => {
      const { response, count } = action.payload;
      state.list = response;
      if (state.list.length === 0) {
        state.status = "No Assets Available";
      } else {
        state.status = "";
      }
      state.count = count;
      state.isAssetsFetched = true;
    },
    [fetchAssets.rejected]: (state, action) => {
      state.status = "An Error Occured";
    },
    [uploadAsset.fulfilled]: (state, action) => {
      state.list.unshift(action.payload);
      state.uploaded = action.payload;
      state.status = "";
    },
    [addToFavourite.fulfilled]: (state, action) => {
      const favoriteIndex = state.list.findIndex(
        (asset) => asset._id === action.payload
      );
      if (favoriteIndex !== -1) {
        state.list[favoriteIndex].isFavourite =
          !state.list[favoriteIndex].isFavourite;
      }
    },
    [deleteAsset.fulfilled]: (state, action) => {
      const deleteIndex = state.list.findIndex(
        (asset) => asset._id === action.payload
      );
      if (deleteIndex !== -1) {
        state.list.splice(deleteIndex, 1);
      }
    },
  },
});

export const assetActions = assetsSlice.actions;
export const { removeUploaded,updateAssetStatus } = assetsSlice.actions;
export default assetsSlice.reducer;
