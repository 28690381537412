import React, { useEffect, useState } from "react";
import { Autocomplete, Checkbox, TextField, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";

const LangAndCountrySelect = ({ onSelect, label, options, placeholder }) => {
  const [searchParams] = useSearchParams();
  const [selectedValue, setSelectedValue] = useState([]);
  const params = searchParams.get(label);
  const navigate = useNavigate();

  useEffect(() => {
    if (params) {
      const defaultValue = [];
      for (let i = 0; i < params.split(",").length; i++) {
        const dataToBePushed = options.find(
          (current) => current.value === params.split(",")[i]
        );
        if (dataToBePushed) {
          defaultValue.push(dataToBePushed);
        }
      }
      setSelectedValue(defaultValue);
    } else {
      setSelectedValue([]);
    }
  }, [params]);

  // const handleChange = (_, obj, reason) => {
  //   if (reason === "clear") {
  //     console.log('clearing' . obj)
  //     onSelect(obj, label);
  //     const url = window.location.href;
  //     if (url.includes("presentations")) {
  //       navigate("presentations/my-library");
  //     } else if (url.includes("emails")) {
  //       navigate("emails/my-library");
  //     } else {
  //       navigate("microsites/my-library");
  //     }
  //   }
  //   setSelectedValue(obj);
  // };
  const handleChange = (_, obj, reason) => {
    console.log(reason);
    if (reason === "clear") {
      const selectedValues = obj.map((item) => item.value).join(",");
      const searchParamsCopy = new URLSearchParams(searchParams); // Create a copy of searchParams
      if(selectedValues.length){
        searchParamsCopy.set(label, selectedValues); // Update the parameter with the new selected values
      }else(
        searchParamsCopy.delete(label) // Update the parameter with the new selected values
      )
      navigate(`?${searchParamsCopy.toString()}`, { replace: true }); // Replace the current URL with the updated parameters
    }
    setSelectedValue(obj);
  };

  const onClose = () => {
    onSelect(selectedValue, label);
  };

  return (
    <Autocomplete
      classes={{ inputRoot: "languageAndCountrySelect" }}
      multiple
      onChange={handleChange}
      options={options}
      disableCloseOnSelect
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      onClose={onClose}
      renderTags={(value) => {
        return (
          <div>
            <Typography component="p" variant="p">
              {/* {placeholder} */}
              Selected: {value.length}
            </Typography>
          </div>
        );
      }}
      getOptionLabel={(option) => option?.label}
      value={selectedValue}
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props} style={{ padding: "0px" }}>
            <Checkbox checked={selected} size="small" />
            {option.label}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          className="LangAndCountryInput"
        />
      )}
    />
  );
};

export default LangAndCountrySelect;
