import React, { useState, useEffect } from "react";
import Content from "src/layouts/Content";
import Card from "src/components/ui/Card";
import Input from "src/components/ui/formElements/Input";
import {
  Grid,
  Button,
  Box,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAccounts,
  fetchContactsByAccount,
  fetchGroupById,
  updateGroup,
} from "src/store/actions/campaign";
import { uiActions } from "src/store/reducers/ui-slice";

const UpdateGroup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [contacts, setContacts] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const campaignGroupetail = useSelector((state) => state.campaign.groupDetail);
  const [groupDetails, setGroupDetails] = useState(campaignGroupetail);

  useEffect(() => {
    dispatch(fetchGroupById(id));
    dispatch(fetchAccounts()).then((res) => {
      setAccounts(res.payload);
    });
  }, [dispatch]);

  useEffect(() => {
    setGroupDetails(campaignGroupetail);
    if (campaignGroupetail.accountId._id) {
      dispatch(fetchContactsByAccount(campaignGroupetail.accountId._id)).then(
        (res) => {
          setContacts(res.payload);
        }
      );
    }
  }, [campaignGroupetail]);

  const accountHandler = (event, newValue) => {
    if (newValue) {
      if (!(newValue._id === groupDetails.accountId._id)) {
        dispatch(fetchContactsByAccount(newValue._id)).then((res) => {
          setGroupDetails((pre) => ({ ...pre, users: [] }));
          setContacts(res.payload);
        });
        setGroupDetails((pre) => ({
          ...pre,
          accountId: { name: newValue.name, _id: newValue._id },
        }));
      }
    } else {
      setGroupDetails((pre) => ({
        ...pre,
        accountId: { name: "", _id: "" },
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGroupDetails((pre) => ({ ...pre, [name]: value }));
  };

  const submitUpdateGroup = (e) => {
    e.preventDefault();
    const usersData = [];
    groupDetails.users.map((user) => {
      if (user.userId) {
        return usersData.push({
          userId: user.userId,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
        });
      } else {
        return usersData.push({
          userId: user._id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
        });
      }
    });
    const data = {
      name: e.target.name.value,
      accountId: groupDetails.accountId._id,
      users: usersData,
    };
    const id = groupDetails._id;
    dispatch(updateGroup({ id, data })).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        navigate("/campaign/groups");
      } else {
        dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Group Error!",
            message: `Group Name Already Exists`,
          })
        );
      }
    });
  };

  return (
    <Grid container gap={4}>
      <Grid item xs={12}>
        <Content title="Update group">
          <Card classes="campaign">
            <form onSubmit={submitUpdateGroup}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Input
                    name="name"
                    className="input_box"
                    labelTag="Name *"
                    value={groupDetails.name}
                    onChange={handleChange}
                    required={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography>Select Account *</Typography>
                  <Autocomplete
                    options={accounts}
                    getOptionLabel={(option) => {
                      if (option.hasOwnProperty("_id")) {
                        return option.name;
                      }
                      return option;
                    }}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        <Box>
                          <Typography className="option-label">
                            {option.name}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    value={
                      accounts?.length !== 0 ? groupDetails.accountId.name : ""
                    }
                    onChange={accountHandler}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Search for a Account"
                        inputProps={{
                          ...params.inputProps,
                        }}
                        required={true}
                      />
                    )}
                    isOptionEqualToValue={(option, value) => {
                      if (value) {
                        return option.name === value;
                      } else {
                        return true;
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography className="user-label" variant="body2">
                    Contact *
                  </Typography>
                  <Autocomplete
                    multiple={true}
                    filterSelectedOptions={true}
                    options={contacts}
                    getOptionLabel={(option) =>
                      `${option.firstName} ${option.lastName}`
                    }
                    renderOption={(props, option) => (
                      <Box component="li" {...props} key={option._id}>
                        <Box>
                          <Typography className="option-label">
                            Contact Name : {option.firstName} {option.lastName}
                          </Typography>
                          <Typography>Email : {option.email}</Typography>
                        </Box>
                      </Box>
                    )}
                    onChange={(event, newValue) => {
                      setGroupDetails((pre) => ({ ...pre, users: newValue }));
                    }}
                    value={contacts?.length !== 0 ? groupDetails.users : []}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Search for a Contact"
                        inputProps={{
                          ...params.inputProps,
                          required: groupDetails.users.length === 0,
                        }}
                        required={true}
                      />
                    )}
                    isOptionEqualToValue={(option, value) => {
                      if (value.userId) {
                        return option._id === value.userId;
                      }
                      return option._id === value._id;
                    }}
                  />
                </Grid>

                <Grid item md={12} className="d-flex btn-align">
                  <Box>
                    <Button
                      variant="contained"
                      sx={{ textTransform: "capitalize" }}
                      type="submit"
                      className="campaignBtn"
                    >
                      Update Group
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Content>
      </Grid>
    </Grid>
  );
};

export default UpdateGroup;
