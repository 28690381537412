import { useElementStatus } from "src/hooks/editor/element.controller";
import { useUndoRedo } from "./undo-redo";
import { useEditorHistoryUpdater } from "./history-updater";

export const useEditorHistory = ({ csRenderEditable } = {}) => {
  // To Update History
  const { updateHistory } = useEditorHistoryUpdater();
  const { activeElement, deActiveElement } = useElementStatus();
  const { undo, redo, disabledButtons } = useUndoRedo({ csRenderEditable });

  return {
    updateHistory,
    activeElement,
    deActiveElement,
    undo,
    redo,
    disabledButtons,
  };
};
