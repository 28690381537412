import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  Typography,
} from "@mui/material";
import publishImage from "src/assets/images/temp/publish-image.png";
import { IoInformationCircle } from "react-icons/io5";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import CustomAlert from "src/components/ui/CustomAlert";
import "../../styles/presentations/publish.scss";
import AccessControl from "../../components/presentations/AccessControl";

const Publish = () => {
  return (
    <AccessControl component="publish">
      <Grid container className="publishBox" rowGap={4}>
        {/* Left Side */}
        <Grid className="publish-grid" sm={12} md={4.7} item>
          <Card className="publish-card">
            <CardMedia
              component="img"
              height="250"
              width="250"
              className="card-image"
              image={publishImage}
              alt="Paella dish"
            />
            <CardContent className="p-0 pt-35">
              <Grid container>
                <Grid item xs={6}>
                  <Typography
                    className="fs-18"
                    lineHeight={"normal"}
                    variant="h2"
                  >
                    Parturient Venenatis Etiam
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className={"card-hdng"}>Created On</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className={"card-hdng"}>Version</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className={"card-hdng"}>Language</Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <Chip label="draft" size="small" className="chip" />
                </Grid>
                <Grid item xs={2}>
                  <Typography className={"card-txt"}>08.01.2020</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className={"card-txt"}>0.3.0</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className={"card-txt"}>English</Typography>
                </Grid>
              </Grid>

              <Grid container className="margin-top">
                <Grid item xs={6}>
                  <Typography className={"card-hdng"}>Created By</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className={"card-hdng"}>Last Updated</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className={"card-hdng"}>Status</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className={"card-hdng"}>Country</Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <Typography className={"card-txt"}>
                    Mikael ibrahimovicz
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className={"card-txt"}>18.05.2020</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className={"card-txt"}>Drafted</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className={"card-txt"}>Global (GBL)</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Right Side */}
        <Grid className="publish-grid centered" sm={12} md={7.1} item>
          <Grid container gap={4}>
            <Grid item xs={12}>
              <Typography variant="body1" className="publish-txt">
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                amet sint. Velit officia consequat duis enim velit mollit.
                Exercitation veniam consequat sunt nostrud amet.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="publish-txt">
                Aliquam pulvinar vestibulum blandit. Donec sed nisl libero.
                Fusce dignissim luctus sem eu dapibus. Pellentesque vulputate
                quam a quam volutpat, sed ullamcorper erat commodo. Vestibulum
                sit amet ipsum vitae mauris mattis vulputate lacinia nec neque.
                Aenean quis consectetur nisi, ac interdum elit. Aliquam sit amet
                luctus elit, id tempus purus.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="publish-txt">
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                amet sint. Velit officia consequat duis enim velit mollit.
                Exercitation veniam consequat sunt nostrud amet.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomAlert
                action={
                  <Button
                    variant="contained"
                    color="secondary"
                    className="alert-btn"
                  >
                    view Guide
                  </Button>
                }
                icon={<IoInformationCircle color="#2CC1D6" />}
              >
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                amet sint. Velit officia consequat.
              </CustomAlert>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Footer */}
      <Box className="publish-buttons">
        <Button
          className="btn"
          size="small"
          startIcon={<IoIosArrowBack />}
          variant="outlined"
        >
          Back
        </Button>
        <Button
          className="btn"
          size="small"
          endIcon={<IoIosArrowForward />}
          variant="contained"
        >
          Next
        </Button>
      </Box>
    </AccessControl>
  );
};

export default Publish;
