import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import Card from "src/components/ui/Card";
import Content from "src/layouts/Content";
import UsersTable from "./UsersTable";
import { headCells } from "./headcells";
import { deleteUser, getAllCompanyUsers } from "src/store/actions/users";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "src/styles/users/style.scss";
import Confirm from "src/components/dialogs/Confirm";
import { logout } from "src/store/actions/auth";
import Permission from "src/components/Permission"
const Users = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { list, loading } = useSelector((state) => state.user);
  const { company, _id: loginUser } = useSelector((state) => state.auth.userData);

  const [deleteUserData, setDeleteUserData] = useState(null);
  const isDeleteModalOpen = Boolean(deleteUserData);

  useEffect(() => {
    const companyId = company.companyId;
    if (companyId) {
      dispatch(getAllCompanyUsers(companyId));
    }
  }, [company]);

  const navigateToCreateUser = () => {
    navigate("create");
  };

  const deleteUserHandler = () => {
    dispatch(deleteUser(deleteUserData?._id)).then(res=>{
      if(res.payload && deleteUserData?._id == loginUser){
        dispatch(logout())
      }
    });
      closeDeleteModalHandler();
  };

  const deleteConfirmationHandler = (userData) => {
    setDeleteUserData(userData);
  };

  const closeDeleteModalHandler = () => {
    setDeleteUserData(false);
  };

  return (
    <Content
      contentClass="users"
      title="Users"
      headerAction={
        <Permission permissionPath="userSettings.maxNumberOfUsers" currentValue={list.length} >
        <Button onClick={navigateToCreateUser} variant="contained">
          Create User
        </Button>
        </Permission>
      }
    >
      {loading ? (
        "Loading Users..."
      ) : (
        <UsersTable
          onDelete={deleteConfirmationHandler}
          rows={list}
          fetchStatus={{ error: null, status: "fulfilled" }}
          headCells={headCells}
        />
      )}
      <Confirm
        title={"Are you sure want to Deactive ?"}
        subtitle={`${
          deleteUserData
            ? `${deleteUserData.firstName} ${deleteUserData.lastName}s`
            : ""
        } Account will be Deactive !`}
        handleConfirm={deleteUserHandler}
        handleClose={closeDeleteModalHandler}
        isOpen={isDeleteModalOpen}
      />
    </Content>
  );
};

export default Users;
