import { createSlice } from "@reduxjs/toolkit";
import {
  fetchReviews,
  filterReview,
  fetchReviewDetail,
  review,
} from "../actions/reviews";
const reviewDetailState = {
  assignedTo: [],
  description: "",
  status: false,
  presentationId: {
    name: "",
    image: "N/A",
    language: "",
    country: "",
    startDate: null,
    endDate: null,
    status: "",
    createdBy: {
      firstName: "",
      lastName: "",
    },
    createdAt: "",
  },
  deadline: "",
  assignBy: "",
  reviewedBy: [],
  comments: [],
  updatedAt: "",
  createdAt: "",
};

const types = {
  presentation: "presentationId",
  email: "emailId",
  landingpage: "landingPageId",
  assets: "assetsId",
};

const initialState = {
  reviews: [],
  reviewDetail: reviewDetailState,
  isReviewed: false,
  count: 0,
  loadingMsg: "Loading ...",
};
const reviewSlice = createSlice({
  name: "reviewSlice",
  initialState,
  extraReducers: (builder) => {
    //Get Reviews : Pending
    builder.addCase(fetchReviews.pending, (state, action) => {
      state.loadingMsg = "Loading ...";
    });

    //Get Reviews : Fulfilled
    builder.addCase(fetchReviews.fulfilled, (state, action) => {
      const { count, response } = action.payload;
      state.reviews = response;
      state.count = count;
      if (response.length === 0) {
        state.loadingMsg = "No Reviews Assigned !";
      } else {
        state.loadingMsg = "";
      }
    });

    //Get Reviews : Rejected
    builder.addCase(fetchReviews.rejected, (state, action) => {
      state.loadingMsg = "An Error Occured !";
    });

    //Get Review Detail : Pending
    builder.addCase(fetchReviewDetail.pending, (state, action) => {
      state.reviewDetail = reviewDetailState;
    });

    //Get Review Detail : Fulfilled
    builder.addCase(fetchReviewDetail.fulfilled, (state, action) => {
      state.reviewDetail = action.payload;
    });

    //Review Presentation
    builder.addCase(review.fulfilled, (state, action) => {
      const { type, response } = action.payload;
      const review = response;
      const key = types[type];
      delete review[key];
      const index = state.reviews.findIndex((item) => item._id === review._id);
      if (index !== -1) {
        state.reviews[index] = {
          [key]: state.reviews[index]?.[key],
          ...review,
        };
      }
    });
  },
});

export default reviewSlice.reducer;
