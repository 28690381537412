import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../interfaces/api.interface";
import HttpService from "../../services/http.service";
import { uiActions } from "../reducers/ui-slice";

export const createLandingPage = createAsyncThunk(
  "createLandingPage",
  async (data, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.microsites.create(),
        data,
        thunkAPI
      );
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Created!",
          message: "Microsite Created!",
        })
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const fetchLandingPage = createAsyncThunk(
  "fetchLandingPage",
  async (params, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.microsites.fetch(params),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const fetchLandingPageById = createAsyncThunk(
  "fetchLandingPageById",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.microsites.fetchById(id),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateLandingPage = createAsyncThunk(
  "updateLandingPage",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.microsites.updateById(id),
        data,
        thunkAPI
      );
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Updated!",
          message: "Microsite Updated!",
        })
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const fetchTemplates = createAsyncThunk(
  "fetchTemplates",
  async (type, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.microsites.fetchTemplates(type),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const cloneLandingPage = createAsyncThunk(
  "cloneLandingPage",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.microsites.clone(id),
        undefined,
        thunkAPI
      );
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "success",
          title: "CLoned!",
          message: "Microsite Cloned!",
        })
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const downloadSource = createAsyncThunk(
  "downloadSource",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.microsites.downloadSource(id),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const downloadVersionSource = createAsyncThunk(
  "downloadVersionSource",
  async ({ id, versionId }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.microsites.downloadVersionSource(id, versionId),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const exportLandingPage = createAsyncThunk(
  "exportLandingPage",
  async ({ id, exportAs }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.microsites.export(id, exportAs),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const fetchLandingpageHtml = createAsyncThunk(
  "fetchLandingpageHtml",
  async (data, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.microsites.getHtmlById(data),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const saveMicrosite = createAsyncThunk(
  "saveMicrosite",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.microsites.saveHtml(id),
        data,
        thunkAPI
      );
      if (response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Saved!",
            message: "Microsite Saved!",
          })
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

//upload new version
export const uploadNewVersion = createAsyncThunk(
  "uploadNewVersion",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.microsites.uploadNewVersion(id),
        data,
        thunkAPI
      );
      if (response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Uploaded !",
            message: "New Version Uploaded !",
          })
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const importLandingPage = createAsyncThunk(
  "importLandingPage",
  async (data, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.microsites.importNew(),
        data,
        thunkAPI
      );
      if (response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Imported !",
            message: "Landing Imported Successfully",
          })
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteLandingPage = createAsyncThunk(
  "deleteLandingPage",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.microsites.delete(id),
        undefined,
        thunkAPI
      );
      if (response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Deleted!",
            message: "Microsite Deleted!",
          })
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
