import { Box, Grid, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Input from "src/components/ui/formElements/Input";
import InputFields from "../ui/formElements/InputFields";
import { useDispatch } from "react-redux";
import { forgotPassword } from "src/store/actions/auth";
import { emailValidator } from "src/services/editor.service";
const EmailForm = (props) => {

  const dispatch = useDispatch();
  const defaultValues = useForm({
    defaultValues: {
      email: "",
    },
  });

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    reset,
    resetField,
    formState: { errors },
  } = defaultValues;

  const resetHandler = (e) => {

    const email = watch("email");
    if (!emailValidator(email)) {
      setError("email", { type: "manual", message: "Email is Invalid" })
      return;
    }
    dispatch(forgotPassword(email))
      .then(res => res.error && setError("email", { type: "manual", message: "Email not found" }));
  };

  return (
    <form>
      <Box className="loginForm">
        {/* <Box className="ControlWrap">
          <Input
            labelTag="Username or Email"
            label="Email or Username"
            type="email"
            name="email"
            placeholder="Email or Username"
            required={true}
            className="login-input"
          />
        </Box> */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputFields
              fieldName="email"
              type="email"
              label="Email"
              control={control}
              rules={{
                required: "Email is required",
              }}
              error={errors?.email}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item></Grid>
          {props.backLink && (
            <Grid item>
              <Link className="link-txt" to="/login">
                Back to Login
              </Link>
            </Grid>
          )}
        </Grid>
        <Box className="pt-4">
          <Button type="submit" variant="contained" color="btnColor" fullWidth sx={{ letterSpacing: "1.2px" }} onClick={handleSubmit(resetHandler)}>
            {props.btnText}
          </Button>
        </Box>
      </Box>
    </form>
  );
};
export default EmailForm;
