import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../interfaces/api.interface";
import HttpService from "../../services/http.service";

import { uiActions } from "../reducers/ui-slice";

export const createEmail = createAsyncThunk(
  "createEmail",
  async (data, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.emails.create(),
        data,
        thunkAPI
      );
      if (response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Email created!",
            message: "Email created successfully",
          })
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const cloneEmail = createAsyncThunk("cloneEmail", async (id, thunkAPI) => {
  try {
    const response = await HttpService.call(
      api.emails.clone(id),
      undefined,
      thunkAPI
    );
    if (response) {
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Cloned !",
          message: "Email cloned successfully",
        })
      );
    }
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue();
  }
});

// export email
export const exportEmail = createAsyncThunk(
  "exportEmail",
  async (data, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.emails.exportEmail(data),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const downloadSource = createAsyncThunk(
  "downloadSource",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.emails.downloadSource(id),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const downloadVersionSource = createAsyncThunk(
  "downloadVersionSource",
  async ({ id, versionId }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.emails.downloadVersionSource(id, versionId),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {Q
      return thunkAPI.rejectWithValue();
    }
  }
);

export const fetchEmails = createAsyncThunk(
  "fetchEmails",
  async (params, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.emails.fetch(params),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const fetchEmailById = createAsyncThunk(
  "fetchEmailById",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.emails.fetchById(id),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateEmail = createAsyncThunk(
  "updateEmail",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.emails.update(id),
        data,
        thunkAPI
      );
      if (response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Updataed !",
            message: "Email Updated Successfully",
          })
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const saveEmail = createAsyncThunk(
  "saveEmail",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.emails.saveEmail(id),
        data,
        thunkAPI
      );
      if (response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Saved !",
            message: "Email Saved Successfully",
          })
          
        );
       
      }
      return {...response.data, ...data};
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const fetchEmailHtml = createAsyncThunk(
  "fetchEmailHtml",
  async ({emailId,versionId}, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.emails.fetchEmailHtml(emailId,versionId),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const fetchTemplateCategory = createAsyncThunk(
  "fetchTemplateCategory",
  async (type, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.fetchTemplateCategory(type),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const fetchTemplatesByCategory = createAsyncThunk(
  "fetchTemplatesByCategory",
  async ({ categoryId, type }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.fetchTemplatesByCategory(categoryId, type),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const importEmail = createAsyncThunk(
  "importEmail",
  async (data, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.emails.importEmail(),
        data,
        thunkAPI
      );
      if (response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Imported !",
            message: "Email Imported Successfully",
          })
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const uploadNewVersion = createAsyncThunk(
  "uploadNewVersion",
  async ({id,data}, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.emails.uploadNewVersion(id),
        data,
        thunkAPI
      );
      if (response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Uploaded !",
            message: "New Version Uploaded Successfully",
          })
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteEmail = createAsyncThunk(
  "deleteEmail",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.emails.deleteEmail(id),
        undefined,
        thunkAPI
      );
      if (response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Deleted !",
            message: "Email Deleted Successfully",
          })
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const sendTestEmail = createAsyncThunk(
  "sendTestEmail",
  async (data, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.emails.sendTestEmail(),
        data,
        thunkAPI
      );
      if (response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Email Sent",
            message: "Email Sent Successfully",
          })
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
