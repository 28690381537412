import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import PreviewPresentation from "src/components/dialogs/presentations/PresentationPreviewDialog";
import MicrositePreviewDialog from "./components/dialogs/microsites/MicrostitePreviewDialog";
import PreviewEmailDialog from "./components/dialogs/emails/PreviewEmailDialog";
import Notification from "./components/ui/Notification";
import Loading from "./components/ui/Loading";
import routes from "./routes/index";
import "./styles/styles.scss";
import { saveUserData } from "./store/reducers/auth-slice";
import { userVerify } from "./store/actions/users";
import { logout } from "./store/actions/auth";

const App = () => {
  const { notification, isLoading } = useSelector((state) => state.ui);
  const { user } = useSelector((state) => state.auth);
  const routing = useRoutes(routes);
  const dispatch = useDispatch();

  const handleFocus = () => {
    if (user) {
      dispatch(userVerify())
      const isRemember = JSON.parse(localStorage.getItem("user"))
      if (isRemember && isRemember?.rememberMe) {
        dispatch(saveUserData(isRemember));
      }
    }
  }

  useEffect(() => {
    // window.addEventListener('focus', handleFocus);
    handleFocus()
  }, [])

  return (
    <>
      <PreviewEmailDialog />
      <PreviewPresentation />
      <MicrositePreviewDialog />
      {isLoading && <Loading />}
      {notification && (
        <Notification
          status={notification.status}
          title={notification.title}
          message={notification.message}
        />
      )}
      {routing}
    </>
  );
};
export default App;
