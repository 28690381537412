
import Main from "./Main";
import { IoCheckmarkOutline} from "react-icons/io5";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import Button from "../Button";
import React, { useCallback } from "react";

const ReviewDelete = (props) => {
  


  return (
    <Main
      isOpen={props.isOpen}
      handleClose={props.handleClose}
      background="#fff"
      modalWidth={"sm"}
      zIndex={1305}
    >
      <Box textAlign="center" p={3}>
        <Typography
          variant="h3"
          component="h3"
          sx={{ fontSize: "16px", color: "#444", fontWeight: "normal" }}
          mb={2}
        >
          {props.subtitle}
        </Typography>
        <Stack
          direction="row"
          justifyContent={"flex-end"}
          alignItems="center"
          spacing={2}
          mt={1}
        >
         <Button
              variant="contained"
              color="success"
              endIcon={<IoCheckmarkOutline />}
              onClick={() => {
                props.handleClose();
              }}
            >
              {props.cancelText}
            </Button>
         
    
        </Stack>
      </Box>
    </Main>
  );
};

export default React.memo(ReviewDelete);

ReviewDelete.defaultProps = {
 
  cancelText: "Okay",
};
