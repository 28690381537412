import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { hasPermission } from "../utils/Utils";
import Alert from "@mui/material/Alert";
import { AlertTitle } from "@mui/material";
import { useSelector } from "react-redux";
// Reusable component combining any child component with Tooltip
const PermissionComp = ({
  permissionPath,
  children,
  currentValue,
  alert,
  tooltipMessage = "Contact your Administrator or upgrade your package to use this feature.",
  ...props
}) => {

  const { permissions } = useSelector((state) => state.auth);
  let hasPerm = true;
  if (permissionPath) {
    const permissionPathArr = permissionPath.split(',')
    if (permissionPathArr.length > 1) {
      if (permissionPathArr.some(path => !hasPermission(path, permissions))) {
        hasPerm = false
      }
    } else {
      hasPerm = hasPermission(permissionPath, permissions)
    }
  }
  let disabled = true;

  //if permission false and alert true then return msg
  if (alert && !hasPerm && typeof hasPerm === "boolean") {
    return (
      <Alert severity="warning" color="warning">
        <AlertTitle sx={{ color: "inherit", fontWeight: "bold" }}>
          Access Denied
        </AlertTitle>{" "}
        {tooltipMessage}
      </Alert>
    );
  }

  //permission value is boolean true or object then return children
  if ((hasPerm && typeof hasPerm === "boolean") || typeof hasPerm === "object") {
    return children;
    // return React.cloneElement(children, { ...props });
  }

  //permission value type is number then check currentValue
  if (hasPerm && typeof hasPerm === "number") {
    disabled = currentValue >= hasPerm;
  }

  return (
    <Tooltip title={!hasPerm ? tooltipMessage : ""} arrow>
      <span>{React.cloneElement(children, { disabled })}</span>
    </Tooltip>
  );
};

export default PermissionComp;
