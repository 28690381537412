import React, { useState, useEffect } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import { convertRgbAToHexAplha } from "src/services/editor.service";
import Control from "./Control";
import Row from "src/components/properties/Row";
import "src/styles/properties.scss";
import $ from "jquery";
import Select_Box from "src/components/common/Select_Box";

const Typography = ({
  elementId,
  targetClass,
  heading = "Text Styles",
  updateHistory,
  table,
}) => {
  const [textStyle, setTextStyle] = useState({
    color: "#fff",
    fontFamily: "Arial",
    fontWeight: "normal",
    fontSize: "20px",
    fontStyle: "normal",
    textTransform: "none",
    textDecoration: "none",
    textAlign: "center",
  });
  const [option, setOption] = useState("thead div");
  let element;
  if (targetClass) {
    element = document.querySelectorAll(`#${elementId} .${targetClass}`)[0];
  } else if (table) {
    element = document.querySelectorAll(`#${elementId} ${option}`)[0];
  } else {
    element = document.getElementById(elementId);
  }

  useEffect(() => {
    let {
      color,
      fontFamily,
      fontWeight,
      fontSize,
      fontStyle,
      textTransform,
      textDecoration,
      textAlign,
    } = "";

    if (table || targetClass) {
      ({
        color,
        fontFamily,
        fontWeight,
        fontSize,
        fontStyle,
        textTransform,
        textDecoration,
        textAlign,
      } = element.style);
    } else {
      ({
        color,
        fontFamily,
        fontWeight,
        fontSize,
        fontStyle,
        textTransform,
        textDecoration,
        textAlign,
      } = element.style);
    }

    const getComputed = (property) => {
      return getComputedStyle(element).getPropertyValue(property);
    };

    const elementSTyle = {
      color: color ? color : getComputed("color"),
      fontFamily: fontFamily ? fontFamily : getComputed("font-family"),
      fontWeight: fontWeight ? fontWeight : 'normal',
      fontSize: fontSize ? fontSize : getComputed("font-size"),
      fontStyle: fontStyle ? fontStyle : getComputed("font-style"),
      textTransform: textTransform
        ? textTransform
        : getComputed("text-transform"),
      textDecoration: textDecoration
        ? textDecoration
        : getComputed("text-decoration").split(" ")[0],
      textAlign: textAlign ? textAlign : getComputed("text-align"),
    };
    if (!elementSTyle.color.includes("#")) {
      elementSTyle.color = convertRgbAToHexAplha(elementSTyle.color).hex;
    }
    if (elementSTyle.textAlign === "start") {
      elementSTyle.textAlign = "left";
    }
    setTextStyle(elementSTyle);
  }, [elementId, option]);
  
  let timeout;
  const updateStyle = (property, value) => {
    if (property === "color") {
      timeout = setTimeout(() => {
        setTextStyle({ ...textStyle, [property]: value });
      }, 400);
    } else setTextStyle({ ...textStyle, [property]: value });
    if (targetClass) {
      document
        .getElementById(elementId)
        .querySelectorAll(`.${targetClass}`)
        .forEach((el) => {
          el.style[property] = value;
        });
    } else if (table) {
      document
        .getElementById(elementId)
        .querySelectorAll(option)
        .forEach((el) => {
          el.style[property] = value;
        });
    } else {
      element.style[property] = value;
    }
  };

  const inputHandler = (e) => {
    clearTimeout(timeout);
    let { name, value } = e.target;
    if (name === "color") {
      if (table) {
        $(element).each(function () {
          this.style.color = value;
        });
      } else {
        element.style.color = value;
      }
      updateStyle(name, value);
    } else if (name === "fontSize") {
      if (value < 8) {
        value = 8;
        updateStyle(name, `${value}px`);
      } else if (value > 200) {
        value = 200;
        updateStyle(name, `${value}px`);
      } else {
        updateStyle(name, `${value}px`);
      }
    } else {
      updateStyle(name, value);
    }
    updateHistory({
      id: elementId,
    });
  };
  
  useEffect
  return (
    <>
      <Control
        className="inputProperty"
        heading={heading}
        isCollapse={true}
        divider={false}
        expanded={false}
      >
        {table && (
          <Row
            label="Scope"
            element={
              <Select_Box
                value={option}
                onChange={(e) => setOption(e.target.value)}
                options={[
                  { label: "Head", value: "thead div" },
                  { label: "Body", value: "tbody div" },
                ]}
              />
            }
          />
        )}
        <Row
          label="Color"
          element={
            <input
              type="color"
              className="color"
              name="color"
              value={textStyle["color"]}
              onInput={inputHandler}
            />
          }
        />

        <Row
          label="Font Family"
          element={
            <Select_Box
              name="fontFamily"
              value={textStyle["fontFamily"]}
              onChange={inputHandler}
              options={[
                { label: "Arial", value: "Arial" },
                { label: "Lato", value: "lato" },
              ]}
            />
          }
        />

        <Row
          label="Font Weight"
          element={
            <Select_Box
              name="fontWeight"
              value={textStyle["fontWeight"]}
              onChange={inputHandler}
              options={[
                { label: "Bold", value: "bold" },
                { label: "Semi Bold", value: "semibold", disabled:true },
                { label: "Normal", value: "normal"},
                { label: "Light", value: "light", disabled:true },
              ]}
            />
          }
        />

        <Row
          label="Font Size"
          element={
            <input
              name="fontSize"
              type="number"
              className="input"
              value={textStyle["fontSize"].split("px")[0]}
              onChange={inputHandler}
              onBlur={() => updateHistory()}
            />
          }
        />

        <Row
          label="Font Style"
          element={
            <Select_Box
              name="fontStyle"
              value={textStyle["fontStyle"]}
              onChange={inputHandler}
              options={[
                { label: "normal", value: "normal" },
                { label: "italic", value: "italic" },
              ]}
            />
          }
        />

        <Row
          label="Text Transform"
          element={
            <Select_Box
              name="textTransform"
              value={textStyle["textTransform"]}
              onChange={inputHandler}
              options={[
                { label: "none", value: "none" },
                { label: "uppercase", value: "uppercase" },
                { label: "lowercase", value: "lowercase" },
                { label: "capitalize", value: "capitalize" },
              ]}
            />
          }
        />

        <Row
          label="Text Decoration"
          element={
            <Select_Box
              name="textDecoration"
              value={textStyle["textDecoration"]}
              onChange={inputHandler}
              options={[
                { label: "none", value: "none" },
                { label: "overline", value: "overline" },
                { label: "line-through", value: "line-through" },
                { label: "underline", value: "underline" },
              ]}
            />
          }
        />

        <Row
          label="Text Align"
          element={
            <Select_Box
              name="textAlign"
              value={textStyle["textAlign"]}
              onChange={inputHandler}
              options={[
                { label: "initial", value: "initial" },
                { label: "right", value: "right" },
                { label: "left", value: "left" },
                { label: "center", value: "center" },
              ]}
            />
          }
        />
      </Control>
    </>
  );
};

export default Typography;
