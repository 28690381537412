import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

//Icons
import { IoAdd, IoCreate, IoCloudUploadOutline } from "react-icons/io5";

//APIs
import { fetchEmails } from "src/store/actions/emails";

//MUI Componenets
import { Grid, Typography } from "@mui/material";

import Content from "src/layouts/Content";
import DropDown from "src/components/ui/DropDown";
import Pagination from "src/components/ui/Pagination";
import EmailCard from "src/components/emails/EmailsCard";

//Dialogs
import EmailMyLibraryDialogs from "src/components/dialogs/emails/EmailMyLibrary-Dialogs.jsx";

const MyLibrary = () => {
  const dispatch = useDispatch();
  const {
    list: emails,
    count,
    loadingMsg,
  } = useSelector((state) => state.emails);

  const [searchParams] = useSearchParams();
  const [details, setDetails] = useState({});
  const [emailId, setEmailId] = useState("");

  //Dialogs State
  const [dialog, setDialog] = useState({});

  const limit = 10;
  useEffect(() => {
    const pageNo = Math.round(Number(searchParams.get("page"))) || 1;
    const skip = (pageNo - 1) * limit;
    
    const params = {};
    searchParams.forEach((value, key) => {
      if(key !== "page") params[key] = value;
    });

    params.skip = skip;
    params.limit = limit;

    dispatch(fetchEmails(params));
  }, [searchParams]);

  const dropDownData = useMemo(() => {
    return [
      {
        label: "Create New",
        icon: <IoCreate />,
        onClick: () => {
          openDialog("", "createNew");
        },
        permissionPath:'emailTemplates.create.createViaTemplate'
      },
      {
        label: "Upload From",
        icon: <IoCloudUploadOutline />,
        onClick: () => {
          openDialog("", "uploadNew");
        },
        permissionPath:'emailTemplates.create.uploadZipFile'
      },
    ];
  }, []);

  //email info
  const showDetails = useCallback((email) => {
    setDialog({ info: true });
    setDetails(email);
  }, []);

  //open dialogs function
  const openDialog = useCallback((id, name) => {
    setEmailId(id);
    setDialog({ [name]: true });
  }, []);

  return (
    <>
      {/* Dialogs */}
      <EmailMyLibraryDialogs
        dialog={dialog}
        email={details}
        emailId={emailId}
        onClose={() => setDialog({})}
      />
      {/* Dialogs */}

      <Content
        title="Email Templates"
        // subtitle={!loadingMsg && "New Emails"}
        headerAction={
          <DropDown
            dropText="Add New"
            tooltip="options"
            startIcon={<IoAdd />}
            items={dropDownData}
          />
        }
      >
        <Grid container spacing={3} display="flex" mb={8}>
          {emails && !loadingMsg ? (
            emails.map((email) => {
              return (
                <EmailCard
                  key={email._id}
                  email={email}
                  openDialog={openDialog}
                  showDetails={showDetails}
                  setEmail={setDetails}
                  role="owner"
                />
              );
            })
          ) : (
            <Typography variant="h1" pl={3} pt={2}>
              {loadingMsg}
            </Typography>
          )}
        </Grid>
        <Pagination
          count={count}
          itemsPerPage={limit}
          enableParams
          onChange={(value) => {
            if (
              !searchParams.get("search") &&
              !searchParams.get("language") &&
              !searchParams.get("country")
            ) {
              dispatch(fetchEmails(value));
            }
          }}
        />
      </Content>
    </>
  );
};
export default React.memo(MyLibrary);
