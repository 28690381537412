import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../components/ui/Logo";
import LogoLarge from "../assets/images/logo.png";
import Copyright from "../components/ui/Copyright";
import { forgotPassword } from "src/store/actions/auth";
import EmailForm from "src/components/login/EmailForm";
import { Grid, Box, Typography } from "@mui/material";
import { uiActions } from "src/store/reducers/ui-slice";
import personImage from "../assets/images/login-bg-2.png";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const isUser = useSelector((state) => state.auth.isUser);

  const resetHandler = (e) => {
    // e.preventDefault();
    let email = e.email;
    dispatch(forgotPassword(email));
  };

  useEffect(() => {
    if (isUser) {
      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Token Sent",
          message: "A reset email has been sent to you.",
        })
      );
    }
  }, [isUser]);

  return (
    <Grid container className="login-page" spacing={2}>
      <Grid item md={7} sm={12}>
        <Box className="login-left">
          <Box id="login-background"></Box>
          <img src={personImage} className="person-image" />
        </Box>
      </Grid>
      <Grid
        item
        md={5}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Box className="login-form-container">
          <Logo logoSrc={LogoLarge} />
          <Typography component="h1" variant="h1" className="login-heading">
            Forgot Your Password?
          </Typography>
          <EmailForm
            btnText="reset"
            backLink={true}
            submitHandler={resetHandler}
          />
          <Copyright />
        </Box>
      </Grid>
    </Grid>
  );
};
export default ForgotPassword;
