import $ from "jquery";

export const rotatable = (
  elment,
  { start = () => {}, rotate = () => {}, stop = () => {} }
) => {
  // removing old handle

  var dropzone = document.getElementById("dropzone");
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M15.25 18.48V15a.75.75 0 1 0-1.5 0v4c0 .97.78 1.75 1.75 1.75h4a.75.75 0 1 0 0-1.5h-2.6a8.75 8.75 0 0 0-2.07-15.53.75.75 0 1 0-.49 1.42 7.25 7.25 0 0 1 .91 13.34zM8.75 5.52V9a.75.75 0 0 0 1.5 0V5c0-.97-.78-1.75-1.75-1.75h-4a.75.75 0 0 0 0 1.5h2.6a8.75 8.75 0 0 0 2.18 15.57.75.75 0 0 0 .47-1.43 7.25 7.25 0 0 1-1-13.37z"></path></svg>`;
  const handle = $(document.createElement("div"));
  handle.append(svg);
  handle.addClass("ui-rotatable");
  handle.on("click", (e) => {
    e.stopPropagation();
  });

  const rotatable = $(elment);
  rotatable.find(".ui-rotatable").remove();
  let mouseDown = false;
  let handleHidden = false;

  function mousemove(evt) {
    if (mouseDown == true) {
      const tooltip = $(".rotate-tooltip");
      const arrowGuide = $(".arrow-guide");
      const zoom = dropzone.style.zoom;
      let box = rotatable[0];
      let boxBoundingRect = box.getBoundingClientRect();
      let center_x = boxBoundingRect.left + boxBoundingRect.width / 2;
      let center_y = boxBoundingRect.top + boxBoundingRect.height / 2;

      const mouse_x = evt.pageX / zoom;
      const mouse_y = evt.pageY / zoom;
      const tooltipDistance = 50;
      const arrowDistance = 20;

      const radians = Math.atan2(mouse_x - center_x, mouse_y - center_y);
      const degree = Math.round(radians * (180 / Math.PI) * -1 + 180);

      tooltip.css({
        left: mouse_x * zoom + tooltipDistance + "px",
        top: mouse_y * zoom + tooltipDistance * 0.8 + "px",
      });

      arrowGuide.css({
        transform: "rotate(" + degree + "deg)",
        left: mouse_x * zoom - arrowDistance + "px",
        top: mouse_y * zoom - arrowDistance + "px",
      });

      rotatable.css("-moz-transform", "rotate(" + degree + "deg)");
      rotatable.css("-webkit-transform", "rotate(" + degree + "deg)");
      rotatable.css("-o-transform", "rotate(" + degree + "deg)");
      rotatable.css("-ms-transform", "rotate(" + degree + "deg)");
      tooltip.html(`${degree}&#176`);
      $(".arrow-guide").fadeIn(200);
      document.body.classList.add("cursor-none");
      rotate(rotatable);
      if (!handleHidden) {
        tooltip.fadeIn(200);
        handle.fadeOut(200);
        handleHidden = true;
      }
    }
  }

  handle.on("mousedown", function (e) {
    e.stopPropagation();
    const arrowGuide = new Image();
    const tooltip = document.createElement("div");
    const editor = document.querySelector(".editorCard.main");

    // if not have arrowGuide in editor then append the new one
    if (!editor.querySelector(".arrow-guide")) {
      arrowGuide.src =
        "https://static.canva.com/web/images/e8752eb4551722c4146c4fbc758bd06a.png";
      arrowGuide.classList.add("arrow-guide");
      editor.append(arrowGuide);
    }

    // if not have tooltip in editor then append the new one
    if (!editor.querySelector(".rotate-tooltip")) {
      tooltip.classList.add("rotate-tooltip");
      editor.append(tooltip);
    }

    mouseDown = true;
    $(document).on("mousemove", mousemove);
    document.body.classList.add("no-user-select");
    start(rotatable);
  });
  $(document).on("mouseup", function (e) {
    if (mouseDown) {
      stop(rotatable);
      handle.fadeIn(200);
      handle.css("display", "");
      $(".rotate-tooltip").fadeOut(200);
      $(".arrow-guide").fadeOut(200);
      handleHidden = false;
      document.body.classList.remove("cursor-none");
      document.body.classList.remove("no-user-select");
    }
    mouseDown = false;
  });

  elment.append(handle);
};
