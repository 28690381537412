import React from "react";
//Icons
import {
  IoHome,
  IoMail,
  IoRocket,
  IoCube,
  IoPeople,
  IoCubeOutline,
  IoGrid,
  IoGlobe,
  IoSettingsSharp,
  IoOpen,
} from "react-icons/io5";
import { BsFillEyeFill } from "react-icons/bs";
import { IoIosAlbums, IoMdContacts } from "react-icons/io";
import { BiDesktop } from "react-icons/bi";
import { FaBars, FaUserAlt } from "react-icons/fa";
import { FaSalesforce } from "react-icons/fa";
import { HiPencil, HiTemplate, HiUserGroup } from "react-icons/hi";
import {
  MdFindInPage,
  MdPending,
  MdPreview,
  MdAccountBox,
  MdCampaign,
} from "react-icons/md";

export const navData = [
  {
    label: "Presentations",
    url: "/presentations",
    icon: <IoHome />,
    tabs: [
      { label: "My Library", path: "my-library", icon: <IoCube /> },
      {
        label: "Shared With Me",
        path: "shared-with-me",
        icon: <IoPeople />,
      },
    ],
    subNav: [
      {
        icon: <BsFillEyeFill />,
        label: "View",
        url: `/presentation/:id/view/:slideId`,
        onClick: (setState) => {
          setState(true);
        },
      },
      {
        icon: <HiPencil />,
        label: "Edit",
        url: `/presentation/:id/edit/:slideId`,
      },
      {
        icon: <IoGrid />,
        label: "Structure",
        url: "/presentation/:id/structure",
      },
      {
        icon: <FaBars />,
        label: "Details",
        url: "/presentation/:id/details",
      },
      {
        icon: <IoGlobe />,
        label: "Localize",
        disabled: true,
        url: "/presentation/:id/localize",
      },
      {
        icon: <IoSettingsSharp />,
        label: "Settings",
        url: "/presentation/:id/settings",
        tabs: [
          { label: "Presentation", path: "details", icon: <BiDesktop /> },
          {
            label: "Slide",
            path: "slide",
            icon: <IoCubeOutline />,
          },
          {
            label: "Chapters",
            path: "chapters",
            icon: <IoIosAlbums />,
          },
        ],
      },
      // {
      //   icon: <IoOpen />,
      //   label: "Publish",
      //   disabled: true,
      //   url: "/presentation/:id/publish",
      // },
    ],
  },

  {
    label: "Email Templates",
    url: "/emails",
    icon: <IoMail />,
    tabs: [
      { label: "My Library", path: "my-library", icon: <IoCube /> },
      {
        label: "Shared With Me",
        path: "shared-with-me",
        icon: <IoPeople />,
      },
    ],
    subNav: [
      {
        icon: <BsFillEyeFill />,
        label: "View",
        url: `/email/:id/view`,
        onClick: (setState) => {
          setState(true);
        },
      },
      {
        icon: <HiPencil />,
        label: "Edit",
        url: `/email/:id/edit`,
      },
      {
        icon: <FaBars />,
        label: "Details",
        url: "/email/:id/details",
      },
      {
        icon: <IoSettingsSharp />,
        label: "Settings",
        url: "/email/:id/settings",
        tabs: [
          { label: "email", path: "details", icon: <BiDesktop /> },
          {
            label: "Slide",
            path: "slide",
            icon: <IoCubeOutline />,
          },
          {
            label: "Chapters",
            path: "chapters",
            icon: <IoIosAlbums />,
          },
        ],
      },
      // {
      //   icon: <IoOpen />,
      //   label: "Publish",
      //   disabled: true,
      //   url: "/email/:id/publish",
      // },
    ],
  },

  {
    label: "Microsites",
    url: "/microsites",
    icon: <HiTemplate />,
    tabs: [
      { label: "My Library", path: "my-library", icon: <IoCube /> },
      {
        label: "Shared With Me",
        path: "shared-with-me",
        icon: <IoPeople />,
      },
    ],
    subNav: [
      {
        icon: <BsFillEyeFill />,
        label: "View",
        url: `/microsite/:id/view`,
        onClick: (setState) => {
          setState(true);
        },
      },
      {
        icon: <HiPencil />,
        label: "Edit",
        url: `/microsite/:id/edit`,
      },
      {
        icon: <FaBars />,
        label: "Details",
        url: "/microsite/:id/details",
      },
      {
        icon: <IoSettingsSharp />,
        label: "Settings",
        url: "/microsite/:id/settings",
        tabs: [
          { label: "microsite", path: "details", icon: <BiDesktop /> },
          {
            label: "Slide",
            path: "slide",
            icon: <IoCubeOutline />,
          },
          {
            label: "Chapters",
            path: "chapters",
            icon: <IoIosAlbums />,
          },
        ],
      },
      // {
      //   icon: <IoOpen />,
      //   label: "Publish",
      //   disabled: true,
      //   url: "/microsite/:id/publish",
      // },
    ],
  },

  {
    label: "Digital Assets",
    url: "/assets",
    icon: <IoRocket />,
    tabs: [
      { label: "My Assets", path: "my-assets", icon: <IoCube /> },
      { label: "Vault", path: "Vault", icon: <IoPeople /> },
    ],
  },

  {
    label: "Reviews",
    url: "/reviews",
    icon: <MdFindInPage />,
    tabs: [
      { label: "Presentation", path: "presentation", icon: <BiDesktop /> },
      { label: "Email Templates", path: "email", icon: <IoMail /> },
      { label: "Landing Page", path: "landingpage", icon: <HiTemplate /> },
      { label: "Assets", path: "assets", icon: <IoCube /> },
    ],
  },

  {
    label: "Email Campaign",
    url: "/campaign",
    icon: <IoMail />,
    subNav: [
      {
        icon: <MdCampaign />,
        label: "Campaigns",
        url: `/campaign`,
      },
      {
        icon: <HiUserGroup />,
        label: "Groups",
        url: `/campaign/groups`,
      },
      {
        icon: <IoMdContacts />,
        label: "Contacts",
        url: "/campaign/contacts",
      },
      {
        icon: <MdAccountBox />,
        label: "accounts",
        url: "/campaign/accounts",
      },
    ],
  },
  {
    label: "Settings",
    url: "/settings",
    icon: <FaUserAlt />,
    subNav: [
      {
        icon: <FaUserAlt />,
        label: "Users",
        url: `/settings/users`,
      },
      {
        icon: <FaSalesforce />,
        label: "Integration",
        url: "/settings/integration",
      },
    ],
  },
];
export const nav = {
  presentation: navData[0],
  email: navData[1],
  microsites: navData[2],
  assets: navData[3],
  reviews: navData[4],
  campaign: navData[5],
  settings: navData[6],
};
