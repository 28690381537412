import React from "react";
import { useDispatch } from "react-redux";
import AccessControl from "src/components/presentations/AccessControl";
import { getSlide } from "src/store/actions/presentations";
import PreviewPage from "./PreviewPage";

const PresentationPreview = () => {
  const dispatch = useDispatch();

  const getSlideDispatch = (presentationId, slideId) => {
    dispatch(
      getSlide({
        presentationId,
        slideId,
      })
    );
  };

  return (
    <>
      <AccessControl component="view">
        <PreviewPage
          viewType="presentationView"
          getSlideDispatch={getSlideDispatch}
        />
      </AccessControl>
    </>
  );
};

export default PresentationPreview;
