import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  previewPresentation,
  previewPresentationHTML,
  sendAnalytics,
} from "src/store/actions/presentations";
import csKPI from "src/services/kpi.service";

import PreviewPage from "./PreviewPage";

const PreviewPresentation = () => {
  const slideRef = useRef();
  const isLiked = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [KPI, setKPI] = useState(null);
  const [presentationId, setPresentationId] = useState(null);

  const token = searchParams.get("token");

  const dispatchAnalytics = () => {
    dispatch(
      sendAnalytics({
        token: `Bearer ${token}`,
        KPIData: {
          summary: KPI.getSummary,
          details: KPI.getDetails,
        },
      })
    );
  };

  useEffect(() => {
    dispatch(previewPresentation(token))
      .then(({ payload }) => {
        setKPI(() => new csKPI(payload));
        setPresentationId(payload._id);
      })
      .catch((error) => {
        navigate(-1);
      });
  }, []);

  useEffect(() => {
    if (KPI) {
      window.addEventListener("beforeunload", dispatchAnalytics);
    }
  }, [KPI]);

  const onSwipe = (e, slideIndex, isLastSlide) => {
    const slide = slideRef.current.itemsRef[slideIndex];
    const { top, height } = slide.getBoundingClientRect();
    const { pageY } = e;

    isLiked.current = pageY - top < height / 2;

    if (isLastSlide) {
      return KPI.setLikeDisLike(isLiked.current);
    }
  };

  const onSlideChange = (presentationId, slideId, slide) => {
    KPI.setLikeDisLike(isLiked.current);
    dispatch(
      previewPresentationHTML({
        token,
        slideId,
      })
    ).then((res) => {
      KPI.setSlideTimeDuration(slide.name, slide.chapterName, slideId);
      isLiked.current = null;
    });
  };

  return (
    presentationId && (
      <PreviewPage
        onlyPreview
        onSwipe={onSwipe}
        slideRef={slideRef}
        presentationId={presentationId}
        getSlideDispatch={onSlideChange}
      />
    )
  );
};

export default PreviewPresentation;
