import React, { useEffect, useState } from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import Control from "./Control";
import SelectBox from "src/components/ui/formElements/SelectBox";
import Row from "src/components/properties/Row";
import Select_Box from "src/components/common/Select_Box";

const ColumnProperty = (props) => {
  const [colSize, setcolSize] = useState({
    xs: "col-xs-12",
    md: "col-md-6",
  });

  const { elementId, updateHistory, undoRedoChange } = props;
  const element = document.getElementById(elementId);
  const disableDelete =
    element.closest(".cs-row").querySelectorAll(".cs-col").length <= 1;

  // Getting Element's data

  const getMediaQueryClass = (queryClass) => {
    const elementClasses = [...element.classList];
    return elementClasses.find((cl) => cl.includes(queryClass));
  };

  useEffect(() => {
    let mdClass = getMediaQueryClass("md");
    let xsClass = getMediaQueryClass("xs");

    // if class not found then add new one
    if (!mdClass) {
      mdClass = "col-md-6";
      element.classList.add(mdClass);
    }
    if (!xsClass) {
      xsClass = "col-xs-12";
      element.classList.add(xsClass);
    }

    setcolSize({ md: mdClass, xs: xsClass });
  }, [elementId, undoRedoChange]);

  // Column width
  const getwidthclasses = (cl) => {
    const arr = [];
    for (var i = 1; i <= 12; i++) {
      arr.push({
        label: i,
        value: "col-" + cl + "-" + i,
      });
    }
    return arr;
  };

  const mdClasses = getwidthclasses("md");
  const xsClasses = getwidthclasses("xs");

  const columnWidthHandler = (e) => {
    const { name, value: mediaClass } = e.target;
    setcolSize((prev) => ({ ...prev, [name]: mediaClass }));
    // remove old matching media query class
    const oldQueryClass = getMediaQueryClass(name);
    element.classList.remove(oldQueryClass);
    // adding new one
    element.classList.add(mediaClass);
    updateHistory();
  };

  const deleteColumnHandler = () => {
    element.parentElement.click();
    updateHistory();
    element.remove();
  };
  return (
    <Box className="column-property">
      <Control
        heading={"Column Properites"}
        className="bgControl"
        headingSize={"h1"}
        divider={false}
        isCollapse={true}
        expanded
      >
        <Typography variant="h3">Current Column media Query</Typography>
        <Row
          label="Mobile screen"
          element={
            <Select_Box
              name="xs"
              value={colSize.xs}
              options={xsClasses}
              onChange={columnWidthHandler}
            />
          }
        />

        <Row
          label="Desktop screen"
          element={
            <Select_Box
              name="md"
              value={colSize.md}
              options={mdClasses}
              onChange={columnWidthHandler}
            />
          }
        />

        <Button
          className="add-btn"
          fullWidth
          variant="contained"
          onClick={deleteColumnHandler}
          color="warning"
          disabled={disableDelete}
        >
          Delete Column
        </Button>
        {disableDelete && (
          <Typography className="delete-warning">
            Row must have at least 1 column
          </Typography>
        )}
        <Typography></Typography>
      </Control>
    </Box>
  );
};

export default ColumnProperty;
