import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../interfaces/api.interface";
import HttpService from "../../services/http.service";
import { uiActions } from "../reducers/ui-slice";

export const login = createAsyncThunk(
  "login",
  async ({ email, password }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.login(),
        { email, password },
        thunkAPI,
        "",
        true
      );
      if (response?.data?.jwt) {
        // Storing data in cookie
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Authorized!",
            message: "Welcome to CreateSmart",
          })
        );
      }
      return { user: response.data };
    } catch (error) {
      // console.log('catch' , error)
      return thunkAPI.rejectWithValue();
    }
  }
);

export const logout = createAsyncThunk("logout", async (_, thunkAPI) => {
  try {
    const response = await HttpService.call(api.logout(), _, thunkAPI);
    if (response.data) {
      return response.data;
    }
    return false;
  } catch (error) {
    thunkAPI.dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Something Wrong",
        message: "Please try again",
      })
    );
    return thunkAPI.rejectWithValue();
  }
});

export const forgotPassword = createAsyncThunk(
  "forgotPassword",
  async (email, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.forgotPassword(),
        { email },
        thunkAPI,
        false
      );
      return response.data;
    } catch (error) {
      // thunkAPI.dispatch(
      //   uiActions.showNotification({
      //     status: "warning",
      //     title: "No Such User",
      //     message: "There is no user with such email",
      //   })
      // );
      return thunkAPI.rejectWithValue();
    }
  }
);

export const newActivationToken = createAsyncThunk(
  "newActivationToken",
  async (email, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.newActivationToken(),
        { email },
        thunkAPI
      );
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "No Such User",
          message: "There is no user with such email",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);

export const activateAccount = createAsyncThunk(
  "activateAccount",
  async (data, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.activateAccount(),
        data,
        thunkAPI,
        false
      );
      return response.data;
    } catch (error) {
      // thunkAPI.dispatch(
      //   uiActions.showNotification({
      //     status: "error",
      //     title: "Something Wrong",
      //     message: "Please try again",
      //   })
      // );
      return thunkAPI.rejectWithValue();
    }
  }
);

export const changePassword = createAsyncThunk(
  "changePassword",
  async (data, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.changePassword(),
        data,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Something Wrong",
          message: "Please try again",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);


export const updateUserData = createAsyncThunk(
  "updateUserData",
  async ({ userInfo, userId }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.user.updateUser(userId),
        userInfo,
        thunkAPI
      );
      if (response.data) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Udated!",
            message: "User Details Updated Successfully",
          })
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
