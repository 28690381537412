import React, { useState } from "react";
import { useDispatch } from "react-redux";

//Icons
import { MdMoreVert } from "react-icons/md";
import { TiCancel } from "react-icons/ti";
import { BsFillReplyFill } from "react-icons/bs";
import { IoMdSave } from "react-icons/io";
import { IoTrash } from "react-icons/io5";
import { BiPencil } from "react-icons/bi";

//MUI Components
import {
  CardContent,
  Typography,
  CardHeader,
  Collapse,
  Button,
  Box,
} from "@mui/material";

//APIs
import { editReply, deleteReply } from "src/store/actions/comments";
import { dateFormat } from "src/services/utils";
import { uiActions } from "src/store/reducers/ui-slice";

import Confirm from "../dialogs/Confirm";
import DropDown from "../ui/DropDown";
import IsOwner from "./IsOwner";

const CommentReply = (props) => {
  const dispatch = useDispatch();
  const { reply, id, commentId, replyId, type } = props;
  const [replyEdit, setReplyEdit] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [newReply, setNewReply] = useState("");

  const handleReplyEdit = () => {
    setReplyEdit(true);
    setNewReply(reply.reply);
  };

  const edit = () => {
    if (!newReply.trim().length) {
      return dispatch(
        uiActions.showNotification({
          title: "Empty reply",
          message: "Reply can't be empty",
          status: "error",
        })
      );
    }
    dispatch(
      editReply({ id: commentId, replyId, data: { reply: newReply }, type })
    );
    setReplyEdit(false);
  };

  const deleteCommentReply = () => {
    dispatch(deleteReply({ id: commentId, replyId, type }));
    setReplyEdit(false);
    return true;
  };

  const cancelEdit = () => {
    setReplyEdit(false);
  };

  const dropDownData = [
    {
      label: "Delete",
      icon: <IoTrash />,
      onClick: () => setIsOpenConfirm(true),
    },
    {
      label: "Edit",
      icon: <BiPencil />,
      onClick: handleReplyEdit,
    },
  ];

  return (
    <>
      <Confirm
        title="Are you sure?"
        subtitle="Are you sure you want to delete this reply?"
        isOpen={isOpenConfirm}
        handleClose={() => setIsOpenConfirm(false)}
        handleConfirm={deleteCommentReply}
      />
      <Box>
        <Typography component="hr" color="text.secondary" />
        <CardContent className="p-0">
          <Box>
            <CardHeader
              className="p-0"
              avatar={<BsFillReplyFill />}
              title={reply.user?.firstName + " " + reply.user?.lastName}
              titleTypographyProps={{ sx: { fontSize: "13px" }, noWrap: true }}
              subheader={dateFormat(reply.replyAt)}
              subheaderTypographyProps={{ sx: { fontSize: "10px" } }}
              action={
                <IsOwner id={reply.user._id}>
                  <DropDown
                    tooltip="Options"
                    items={dropDownData}
                    dropIcon={<MdMoreVert size={17} />}
                  />
                </IsOwner>
              }
            />
            {!replyEdit && (
              <>
                <CardContent className="replyBox">
                  <Typography variant="body2" color="text.secondary" pb={0}>
                    {reply.reply}
                  </Typography>
                </CardContent>
              </>
            )}
            <Collapse
              sx={{ marginTop: "10px" }}
              in={replyEdit}
              timeout="auto"
              unmountOnExit
            >
              <textarea
                value={newReply}
                onChange={(e) => setNewReply(e.target.value)}
                className="textBox"
              ></textarea>
              <Box className="actionButtons" my={1}>
                <Button onClick={edit} startIcon={<IoMdSave size={12} />}>
                  SAVE
                </Button>
                <Button onClick={cancelEdit} startIcon={<TiCancel size={12} />}>
                  CANCEL
                </Button>
              </Box>
            </Collapse>
          </Box>
        </CardContent>
      </Box>
    </>
  );
};

export default React.memo(CommentReply);
