import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Button, Box } from "@mui/material";
import { useParams } from "react-router-dom";

import { updateLandingPage, fetchLandingPageById } from "src/store/actions/microsites";
import { canSendRequest } from "src/services/utils";
import Content from "src/layouts/Content";
import Card from "src/components/ui/Card";
import Input from "src/components/ui/formElements/Input";
import SelectBox from "src/components/ui/formElements/SelectBox";
import AccessControl from "src/components/microsites/AccessControl";

import "../../styles/presentations/settings.scss";
import { uiActions } from "src/store/reducers/ui-slice";
import { countries, languages } from "src/data/data";
const Settings = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { landingPage, config } = useSelector((state) => state.microsites);
  const [settings, setSettings] = useState(landingPage);
  const handleChange = (e) => {
    const key = e.target.name;
    let val = e.target.value;
    setSettings((prev) => ({
      ...prev,
      [key]: val,
    }));
  };

  useEffect(() => {
    setSettings(landingPage);
  }, [landingPage]);

  const updateSetting = () => {
    if (!settings?.name?.trim()?.length) {
      return dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Invalid Name",
          message: "Microsite name can't be empty",
        })
      );
    }
    dispatch(updateLandingPage({ id, data: settings })).then(()=>{
      dispatch(fetchLandingPageById(id));
    })
    
  };
  
  return (
    <AccessControl component="settings">
      <Grid container gap={4}>
        <Grid item xs={12}>
          <Content title="General Settings">
            <Card classes="box-style">
              <Grid container spacing={3} className="margin-bottom">
                <Grid item xs={6} md={4}>
                  <Input
                    onChange={handleChange}
                    value={settings.name}
                    name="name"
                    className="bordered"
                    labelTag="Name"
                    disabled={landingPage.isLock}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <SelectBox
                    onChange={handleChange}
                    value={settings.country}
                    labelTag="Country"
                    name="country"
                    defaultValue="pakistan"
                    classes="bordered"
                    options={countries}
                    disabled={landingPage.isLock}
                  />
                </Grid>

                <Grid item xs={6} md={4}>
                  <SelectBox
                    onChange={handleChange}
                    value={settings.lang}
                    labelTag="Language"
                    defaultValue="en"
                    classes="bordered"
                    name="lang"
                    options={languages}
                    disabled={landingPage.isLock}
                  />
                </Grid>

                {!landingPage.isLock && (
                  <Grid
                    item
                    xs={6}
                    md={12}
                    display="flex"
                    className="d-flex btn-align"
                  >
                    <Box>
                      <Button
                        variant="contained"
                        onClick={updateSetting}
                        sx={{ textTransform: "capitalize" }}
                        disabled={!settings?.name?.trim()?.length}
                      >
                        Update
                      </Button>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Card>
          </Content>
        </Grid>
      </Grid>
    </AccessControl>
  );
};
export default Settings;
