import React, { useState } from "react";
import SelectBox from "../ui/formElements/SelectBox";
const SelectDevice = (props) => {
  const [device, setDevice] = useState(props.value);

  const handleDeviceChange = (event) => {
    setDevice(event.target.value);
  };

  return (
    <SelectBox
      options={devices}
      label={props.label}
      inputLabel={props.inputLabel}
      placeholder={props.placeholder}
      classes={props.classes}
      labelTag={props.labelTag}
      value={device}
      onChange={handleDeviceChange}
      autoWidth={true}
      disabled={props.disabled}
    />
  );
};

export default React.memo(SelectDevice);
