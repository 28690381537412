import React from "react";
import { Box } from "@mui/material";
import "../../styles/sidebar.scss";
import underline from "../../assets/images/logo-underline.png";
import SidebarItems from "./SidebarItems";
import {IconButton} from "@mui/material";
import { IoReturnDownBack } from "react-icons/io5";
import { Link } from "react-router-dom";
const Sidebar = ({ items, classes, logo, type, menuToggleHandler }) => {
  return (
    <div className={`sidebar ${classes}`}>
      <Box className="toggleButton">
      <IconButton
        onClick={() => {
          menuToggleHandler();
        }}
        size="small"
        sx={{position:'absolute', top:'0px', right:'15px'}}
      >
        <IoReturnDownBack color="black"></IoReturnDownBack>
      </IconButton>
      </Box>
      <Box className="logo-container">
        <Link to={`/${type ? type : "presentations"}/my-library`}>
          <img src={logo} className="logo" />
        </Link>
        <img src={underline} className="logo-underline" />
      </Box>
      <ul className="menu">
        <SidebarItems items={items} />
      </ul>
    </div>
  );
};

export default Sidebar;
