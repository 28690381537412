import React, { useEffect } from "react";
import Content from "src/layouts/Content";
import ContentAction from "src/components/ui/formElements/ContentAction";
import { IoAdd } from "react-icons/io5";
import { Link } from "react-router-dom";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ViewAccount from "./ViewAccount";
import { useDispatch, useSelector } from "react-redux";
import { fetchAccounts } from "src/store/actions/campaign";
import "../../../styles/campaign/campaign.scss";

const Accounts = () => {
  const dispatch = useDispatch();
  //get all accounts
  const campaignAccounts = useSelector((state) => state.campaign.accounts);
  useEffect(() => {
    dispatch(fetchAccounts());
  }, [dispatch]);

  return (
    <>
      <Content
        title="Accounts"
        headerAction={
          <>
            <ContentAction
              text="Create Account"
              variant="contained"
              className="campaignBtn"
              icon={<IoAdd />}
              component={Link}
              to="create"
            />
          </>
        }
      ></Content>
      <Grid style={{ mt: 2 }}>
        <Grid item xs={12}>
          <TableContainer component={Paper} className="campaignTable">
            <Table aria-label="simple table" stickyHeader>
              <TableHead className="tableHead">
                <TableRow>
                  <TableCell className="tableHeading">Name</TableCell>
                  <TableCell className="tableHeading">Updated At</TableCell>

                  <TableCell className="tableHeading tableAction">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="tableBody">
                {campaignAccounts &&
                  campaignAccounts.map((data) => {
                    return (
                      <ViewAccount
                        key={data._id}
                        id={data._id}
                        name={data.name}
                        updatedAt={data.updatedAt}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default Accounts;
