import HttpService from "../../services/http.service";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { uiActions } from "../reducers/ui-slice";
import { api } from "../../interfaces/api.interface";

//Fetch All Comments of Presentation
export const fetchComments = createAsyncThunk(
  "fetchComments",
  async ({ id, type }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.comments.fetchComments(id, type),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

// Comment on Presentation Slide
export const addComment = createAsyncThunk(
  "addComment",
  async ({ id, data, type }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.comments.addComment(id, type),
        data,
        thunkAPI
      );
      if (response.data) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Added !",
            message: "Added New Comment!",
          })
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

// Comment on Presentation Slide
export const editComment = createAsyncThunk(
  "editComment",
  async ({ id, data, type }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.comments.editComment(id, type),
        data,
        thunkAPI
      );
      if (response.data) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Updated !",
            message: "Comment Edited!",
          })
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

// Comment on Presentation Slide
export const deleteComment = createAsyncThunk(
  "deleteComment",
  async ({ id, type }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.comments.deleteComment(id, type),
        undefined,
        thunkAPI
      );
      if (response.data) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Deleted !",
            message: "Comment Deleted Successfully!",
          })
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

// Add Reply on Comment
export const addReply = createAsyncThunk(
  "addReply",
  async ({ id, data, type }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.comments.addReply(id, type),
        data,
        thunkAPI
      );
      if (response.data) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Added !",
            message: "Added New Reply!",
          })
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

// Edit Reply on Comment
export const editReply = createAsyncThunk(
  "editReply",
  async ({ id, replyId, data, type }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.comments.editReply(id, replyId, type),
        data,
        thunkAPI
      );
      if (response.data) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Updated !",
            message: "Reply Edited!",
          })
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

// Delete Reply on Comment
export const deleteReply = createAsyncThunk(
  "deleteReply",
  async ({ id, replyId, type }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.comments.deleteReply(id, replyId, type),
        undefined,
        thunkAPI
      );
      if (response.data) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Deleted !",
            message: "Reply Deleted Successfully!",
          })
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

// Change Status of Comment
export const changeStatus = createAsyncThunk(
  "changeStatus",
  async ({ id, data, type }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.comments.changeStatus(id, type),
        data,
        thunkAPI
      );
      console.log(data);
      if (response.data) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Updated !",
            message: `Comment ${data.status
              .charAt(0)
              .toUpperCase()}${data.status.slice(1)} Successfully!`,
          })
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
