import React, { useState, useEffect } from "react";
import { Box, Card, Grid, Button, CardMedia, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import salesForceImg from "src/assets/images/logo-salesforce.png";
import { salesforceLogin } from "src/store/actions/sf";
import { IoInformationCircle, IoDocumentTextOutline } from "react-icons/io5";
import CustomAlert from "src/components/ui/CustomAlert";
import Input from "src/components/ui/formElements/Input";
import "../../styles/presentations/publish.scss";
import { useNavigate } from "react-router-dom";
import { checkSFAuth } from "src/store/reducers/auth-slice";
import Permission from "src/components/Permission"
const openDocs = () => {
  const url = "https://www.salesforce.com/in/";
  window.open(url, "_blank", "noopener,noreferrer");
};

const Interation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isSfIntegrated } = useSelector((state) => state.auth);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (isSfIntegrated) {
      navigate("salesforce");
    }
  }, [isSfIntegrated]);

  useEffect(() => {
    dispatch(checkSFAuth());
  }, []);

  const handleChange = (e) => {
    setCredentials((pre) => ({ ...pre, [e.target.name]: e.target.value }));
  };

  const submitForm = () => {
    dispatch(salesforceLogin(credentials));
  };

  return (
    <>
      <Grid container className="publishBox" rowGap={4}>
        {/* Left Side */}
        <Grid className="publish-grid" sm={12} md={4.7} item>
          <Card className="publish-card">
             <Permission permissionPath="salesforceIntegration" alert>
              <></>
             </Permission>
            <Box display="flex" justifyContent="center" mt={2}>
              <CardMedia
                component="img"
                height="250"
                width="250"
                image={salesForceImg}
                alt="Salesforce Logo"
                sx={{ objectFit: "contain", width: "160px", height: "113px" }}
              />
            </Box>
            <Box mt={5}>
              <Box className="ControlWrap">
                <Input
                  className="border"
                  labelTag="Username or Email"
                  label="Username or Email"
                  type="email"
                  name="email"
                  value={credentials.email}
                  placeholder="Username or Email"
                  onChange={handleChange}
                  required
                />
              </Box>
              <Box className="ControlWrap" mt={4}>
                <Input
                  className="border"
                  labelTag="Password"
                  name="password"
                  label="Password"
                  type="password"
                  value={credentials.password}
                  placeholder="Password"
                  onChange={handleChange}
                  required
                />
              </Box>
              <Box mt={4}>
              <Permission permissionPath="salesforceIntegration">
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  onClick={submitForm}
                >
                  LOGIN
                </Button>
                </Permission>
              </Box>
       
            </Box>
          </Card>
        </Grid>

        {/* Right Side */}
        <Grid
          className="publish-grid centered"
          sm={12}
          md={7.1}
          item
          flexDirection="column"
        >
          <Grid container gap={4} flex={1} alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body1" className="publish-txt">
                Salesforce is the world’s #1 customer relationship management
                (CRM) platform. We help your marketing, sales, commerce, service
                and IT teams work as one from anywhere — so you can keep your
                customers happy everywhere.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="publish-txt">
                Salesforce unites your marketing, sales, commerce, service, and
                IT teams from anywhere with Customer 360 — one integrated CRM
                platform that powers our entire suite of connected apps. With
                Customer 360, you can focus your employees on what’s important
                right now: stabilising your business, reopening, and getting
                back to delivering exceptional customer experiences.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="publish-txt">
                When you buy Salesforce, you’re not just buying a CRM platform;
                you’re joining an inclusive community of over 10 million
                innovators, disruptors, and community shapers we call
                Trailblazers. With free online learning from Trailhead, they’re
                learning the skills to improve their companies and careers.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <CustomAlert
                action={
                  <Button
                    variant="contained"
                    color="secondary"
                    className="alert-btn"
                  >
                    View Guide
                  </Button>
                }
                icon={<IoInformationCircle color="#2CC1D6" />}
              >
                Need Guide ?
              </CustomAlert>
              {/* Footer */}
            </Grid>
          </Grid>

          <Grid item xs={12} alignSelf="flex-end">
            <Button
              size="small"
              className="buttons"
              endIcon={<IoDocumentTextOutline size={17} />}
              variant="outlined"
              onClick={openDocs}
            >
              Open Docs
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default React.memo(Interation);
