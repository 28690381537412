export const defaultImage =
  "https://createmart-seed-folder.s3.amazonaws.com/placeholders/image-comp/cs-default-image.jpg";
export const carouselImage1 =
  "https://createmart-seed-folder.s3.amazonaws.com/placeholders/carousel-comp/cs-default-carousel-image-01.jpg";
export const carouselImage2 =
  "https://createmart-seed-folder.s3.amazonaws.com/placeholders/carousel-comp/cs-default-carousel-image-02.jpg";
export const carouselImage3 =
  "https://createmart-seed-folder.s3.amazonaws.com/placeholders/carousel-comp/cs-default-carousel-image-03.jpg";
export const defaultAudio =
  "https://createmart-seed-folder.s3.amazonaws.com/placeholders/audio-comp/cs-default.mp3";
export const defaultVideo =
  "https://createmart-seed-folder.s3.amazonaws.com/placeholders/video-comp/cs-default.mp4";
export const defaultVideoThumb = "https://createmart-seed-folder.s3.amazonaws.com/placeholders/video-comp/video-thumb.jpg";
export const defaultStar =
  "https://createmart-seed-folder.s3.amazonaws.com/placeholders/rating-comp/star_nofill.png";
export const defaultfacebook =
  "https://createmart-seed-folder.s3.amazonaws.com/placeholders/social-comp/facebook.png";