import React from "react";
import { Box, Typography } from "@mui/material";
import ToolItem from "./ToolItem";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import {
  getNewLayerZIndex,
  setLayerPositionToCenter,
} from "src/services/editor.service";
import { components } from "../../../elements/Components";
import { getRandomId } from "src/services/utils";
import {
  applyPadding,
  csRenderPresentationEditable,
} from "../../renderPresentation";
import {
  getSnippet,
  useSetLayerHeight,
} from "src/components/editor/editorLogics/components";
import { HISTORY_ACTIONS } from "src/data/contants";
import { useEditorHistory } from "src/hooks/editor/history";
import { useElementStatus } from "src/hooks/editor/element.controller";
import PermissionComp from "src/components/Permission";
import { hasPermission } from "src/utils/Utils";

const Toolbar = (props) => {
  const { requestHierarchyUpdate, dropzoneRef } = props;
  const dropzone = dropzoneRef.current;
  const { updateHistory } = useEditorHistory();
  const { activeElement } = useElementStatus();

  const proportionalComponents = [
    // "carousel-component",
    "coverflow-component",
    "graph-component",
  ];

  const getDimensionedComponent = (layerHtml) => {
    const layer = $(layerHtml);
    const layerClasses = [...layer.get(0).classList];
    const componentClass = layerClasses.find((cls) =>
      cls.includes("-component")
    );
    let minTargetDeviceWidth = 720;
    let defaultFontsize = 16;

    const { width } = dropzone.style;
    const dropzoneWidth = parseInt(width);
    const layerWidth = parseInt(layer.css("width"));
    const layerHeight = parseInt(layer.css("height"));
    // how much the differnce according to target device
    const diff =
      dropzoneWidth >= minTargetDeviceWidth
        ? dropzoneWidth / minTargetDeviceWidth
        : 1;

    // Applying updated Width
    const updatedWidth = layerWidth * diff + "px";
    const updatedHeight = layerHeight * diff + "px";
    layer.css({
      width: updatedWidth,
    });
    if (proportionalComponents.includes(componentClass)) {
      layer.css({
        height: updatedHeight,
      });
    }

    // Now Applying Updated Fonts Sizes
    layer.find("*").each(function () {
      let element;
      // Checking if element have any text node
      let innerContents = $(this).contents();
      if (
        innerContents.length &&
        innerContents[0].nodeType === 3 &&
        innerContents[0]
      ) {
        element = innerContents[0].parentElement;
        // or an input elment
      } else if (this.nodeName === "INPUT") {
        element = this;
      }
      if (element && element.nodeName !== "LI") {
        let newWidth = defaultFontsize * diff;
        if (element.style.fontSize) {
          defaultFontsize = parseInt(element.style.fontSize);
        }

        if (element.nodeName === "svg") {
          element.style.width = newWidth;
        }

        element.style.fontSize = newWidth + "px";
      }
    });
    return layer.get(0);
  };

  const getLayerSnippet = (type, id, columnLayout) => {
    const layerSnippet = components[type](id, props.component, columnLayout);
    let layerElement = getSnippet(layerSnippet);
    return layerElement;
  };

  // appned Layer to dropzone on click
  const appendToolItem = (_, componentType, columnLayout) => {
    // appending orignal layer
    const layerId = getRandomId();
    let layer = getLayerSnippet(componentType, layerId, columnLayout);
    dropzone.append(layer);

    // replacing layer with updated dimensions according to device
    const dimensionedLayer = getDimensionedComponent(layer);
    layer.replaceWith(dimensionedLayer);
    layer = dimensionedLayer;
    layer.style.zIndex = getNewLayerZIndex(dropzone);
    layer.style.position = 'absolute';

    // rendering it's functionality
    csRenderPresentationEditable({
      activeElement,
      updateHistory,
      element: layer,
      requestHierarchyUpdate,
    });

    // doing active after append
    const activeEditable = activeElement(layer);
    // make it center
    setLayerPositionToCenter(layer);
    applyPadding(layer);
    useSetLayerHeight(layer);

    // now element is completely rendered and it can be push to history
    updateHistory({
      action: HISTORY_ACTIONS.appended,
      id: layer.id,
      activeEditable,
    });
    requestHierarchyUpdate();
  };

  return (
    <>
      {props.info && (
        <Typography className="info">Select Layout and Continue !</Typography>
      )}
      <Box className={`toolbar ${props.className ? props.className : ""}`}>
        {props.items.map((item, index) => {
          const toolItemPermission = hasPermission(item?.permissionPath);
          return (
            <PermissionComp permissionPath={item.permissionPath}>
              <ToolItem
              type={item.type}
              caption={item.caption}
              icon={item.icon}
              layout={item.layout}
              onItemClick={toolItemPermission? appendToolItem : ()=>{return false}}
              className="click"
              key={index}
            />
            </PermissionComp>
          );
        })}
      </Box>
    </>
  );
};
export default Toolbar;
