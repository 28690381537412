import { createSlice } from "@reduxjs/toolkit";
import { globalSearch } from "../actions/globalSearch";

const initialState = {
  allData: [],
  searchedResults: [],
  loading: false,
};

const globalSearchSlice = createSlice({
  name: "globalSearchSlice",
  initialState: initialState,
  reducers: {
    clearSearchedResult(state) {
      state.allData = [];
      state.searchedResults = [];
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    // Global Search
      builder.addCase(globalSearch.pending, (state) => {
      //clear previus data result
      state.allData = [];
      state.searchedResults = [];
      state.loading = true;
    });
    builder.addCase(globalSearch.fulfilled, (state, action) => {
      state.searchedResults = action.payload;
      action.payload.presentation.data.forEach(
        (obj) => (obj.type = "presentation")
      );
      action.payload.presentation.sharedData.forEach((obj) => (obj.type = "sharedPresentation"));

      action.payload.email.data.forEach((obj) => (obj.type = "email"));
      action.payload.email.sharedData.forEach((obj) => (obj.type = "sharedEmail"));

      action.payload.landingPage.data.forEach(
        (obj) => (obj.type = "landingPage")
      );
      action.payload.landingPage.sharedData.forEach(
        (obj) => (obj.type = "sharedLandingPage")
      );

      state.allData = [
        ...action.payload.presentation.data,
        ...action.payload.presentation.sharedData,
        ...action.payload.email.data,
        ...action.payload.email.sharedData,
        ...action.payload.landingPage.data,
        ...action.payload.landingPage.sharedData
      ];
      state.loading = false;
    });
  },
});

export const { clearSearchedResult } = globalSearchSlice.actions;

export default globalSearchSlice.reducer;
