import { createSlice } from "@reduxjs/toolkit";
import {
  fetchComments,
  addComment,
  editComment,
  changeStatus,
  deleteComment,
  addReply,
  editReply,
  deleteReply,
} from "../actions/comments";

const initialState = {
  comments: [],
};
const commentsSlice = createSlice({
  name: "commentsSlice",
  initialState,
  extraReducers: (builder) => {
    //Get Comments of Slide : Pending
    builder.addCase(fetchComments.pending, (state, action) => {
      state.comments = [];
    });

    //Get Comments of Slide : Fulfilled
    builder.addCase(fetchComments.fulfilled, (state, action) => {
      state.comments = action.payload;
    });

    //Add New Comment on Presentation Slide
    builder.addCase(addComment.fulfilled, (state, action) => {
      state.comments.unshift(action.payload);
    });

    //Add New Comment on Presentation Slide
    builder.addCase(editComment.fulfilled, (state, action) => {
      const index = state.comments.findIndex(
        (comment) => comment._id === action.payload._id
      );
      state.comments[index].comment = action.payload.comment;
    });

    //Add New Comment on Presentation Slide
    builder.addCase(deleteComment.fulfilled, (state, action) => {
      const index = state.comments.findIndex(
        (comment) => comment._id === action.payload
      );
      if (index !== -1) {
        state.comments.splice(index, 1);
      }
    });

    //Add Reply on Comment of Presentation Slide
    builder.addCase(addReply.fulfilled, (state, action) => {
      const index = state.comments.findIndex(
        (comment) => comment._id === action.payload.commentId
      );
      if (index !== -1) {
        const reply = action.payload.update;
        reply.user = action.payload.user;
        state.comments[index].reply.push(reply);
      }
    });

    //Add Reply on Comment of Presentation Slide
    builder.addCase(editReply.fulfilled, (state, action) => {
      const index = state.comments.findIndex(
        (comment) => comment._id === action.payload._id
      );
      if (index !== -1) {
        const replies = state.comments[index].reply;
        const newReplies = action.payload.reply;
        const currentReplies = [];
        for (let i = 0; i < replies.length; i++) {
          const obj = {
            ...newReplies[i],
            user: replies[i].user,
          };
          currentReplies.push(obj);
        }
        state.comments[index].reply = currentReplies;
      }
    });

    //Delte Reply on Comment of Presentation Slide
    builder.addCase(deleteReply.fulfilled, (state, action) => {
      const index = state.comments.findIndex(
        (comment) => comment._id === action.payload.commentId
      );
      if (index !== -1) {
        const replyIndex = state.comments[index].reply.findIndex(
          (reply) => reply._id === action.payload.replyId
        );
        if (replyIndex !== -1) {
          state.comments[index].reply.splice(replyIndex, 1);
        }
      }
    });

    //Change Status Of Comment
    builder.addCase(changeStatus.fulfilled, (state, action) => {
      const index = state.comments.findIndex(
        (comment) => comment._id === action.payload._id
      );
      if (index !== -1) {
        state.comments[index].status = action.payload.status;
      }
    });
  },
});

export default commentsSlice.reducer;
