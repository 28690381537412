import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Grid, MenuItem, Select, Typography, FormControl } from "@mui/material";
import { fetchCampaigns, getUsersAnalytics } from "src/store/actions/campaign";
import AnalyticsReports from "./AnalyticsReports";
import Timeline from "./Timeline2";
import Table from "./AnalyticsTable";

import "src/styles/campaign/analytics.scss";
import { clearAnalyticsData } from "src/store/reducers/campaign-slice";
import ClosingSlides from "./ClosingSlides";
import Card from "./SummaryCards/Card";
import { Chip } from "@mui/material";
const Analytics = () => {
  const dispatch = useDispatch();
  const { id: campaignId } = useParams();
  const { campaigns, analytics } = useSelector((state) => state.campaign);
  const [loading, setLoading] = useState(false);
  const [analyticsLoading, setAnalyticsLoading] = useState(false);
  const [currentCampaign, setcurrentCampaign] = useState(null);
  const currentCampaignUsers = currentCampaign?.users || [];
  const [selectedUserDropdown, setSelectedUserDropdown] = useState("");

  const getCurrentCampaign = () => {
    const currentCampaign = campaigns.find((c) => c._id === campaignId);
    if (currentCampaign) {
      setcurrentCampaign(currentCampaign);
    }
  };

  useEffect(() => {
    if (campaigns.length) {
      getCurrentCampaign();
      setLoading(false);
    } else {
      setLoading(true);
      dispatch(fetchCampaigns()).then(() => setLoading(false));
    }
  }, [campaignId]);

  useEffect(() => {
    return () => dispatch(clearAnalyticsData());
  }, []);

  useEffect(() => {
    if (campaigns) {
      getCurrentCampaign();
    }
    console.log(analytics)
  }, [campaigns]);

  useEffect(() => {
    if (currentCampaignUsers[0] && !selectedUserDropdown) {
      setSelectedUserDropdown(currentCampaignUsers[0].userId);
    }
  }, [currentCampaignUsers]);

  const usersChangeHandler = (e) => {
    const userId = e.target.value;
    setSelectedUserDropdown(userId);
  };

  useEffect(() => {
    if (selectedUserDropdown) {
      fetchUserAnalytics(selectedUserDropdown);
    }
  }, [selectedUserDropdown]);

  const fetchUserAnalytics = async (userId) => {
    setAnalyticsLoading(true);
    await dispatch(getUsersAnalytics({ campaignId, userId }));
    setAnalyticsLoading(false);
  };

  const userComponent = (
    <FormControl size="small" variant="standard">
      <Select
        fullWidth
        onChange={usersChangeHandler}
        value={selectedUserDropdown}
      >
        {currentCampaignUsers.map((user) => {
          return (
            <MenuItem key={user.userId} value={user.userId}>
              {user.firstName + " " + user.lastName}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  const timelineHeader = (
    <Grid container spacing={3}>
      <Grid item xs={5}>
        Timeline
      </Grid>
      <Grid item xs={7} textAlign="end">
        {userComponent}
      </Grid>
    </Grid>
  );
  return (
    <>
      {currentCampaign && currentCampaignUsers.length && !loading ? (
        <>
          <Grid container spacing={3} className="analytics" alignItems='stretch'>
            {/* Left Section */}
            <Grid item xs={9}>
              {/* <Grid container spacing={2} mb={2}>
                <Grid item xs={4}>
                  <Card heading="Top Watch Slide" className="green textCenter">
                    <p>
                      Blank 2 Stay <Chip label="2 Minutes" color="primary" />
                    </p>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card heading="Close Count" className="pink">
                    <ul>
                      <li>
                        Slide 1 <span>2 Times</span>
                      </li>
                      <li>
                        Slide 2 <span>1 Times</span>
                      </li>
                      <li>
                        Slide 3 <span>6 Times</span>
                      </li>
                    </ul>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card heading="Visited User" className="blue textCenter">
                    <h2>10/2</h2>
                  </Card>
                </Grid>
              </Grid> */}
              <Grid container>
                <Grid item xs={12}>
                  <Card heading="Summary" className="green autoHeight">
                    <AnalyticsReports />
                  </Card>
                </Grid>
              </Grid>
            </Grid>

            {/* Right Section */}
            <Grid item xs={3} >
              <Card heading={timelineHeader} className="green autoHeight">
                {analyticsLoading ? (
                  "Loading User Analytics..."
                ) : (
                  <Timeline data={analytics} />
                  //<Table data={analytics} />
                )}
              </Card>
              {/* <div className="analytics-header">
                <Grid container alignItems="center">
                  <Grid item xs={7}>
                    <h2>Timeline</h2>
                  </Grid>
                  <Grid item xs={5}>
                  {userComponent}
                  </Grid>
                </Grid>
              </div> */}
              {/* Body */}
              {/* <div className="analytics-body">
                {analyticsLoading ? (
                  "Loading User Analytics..."
                ) : (
                  // <Timeline data={analytics} />
                  <Table data={analytics} />
                )}
              </div> */}
            </Grid>
          </Grid>
        </>
      ) : (
        <Typography variant="h2">
          {loading
            ? "Loading users..."
            : currentCampaign
            ? `${currentCampaign.name || ""} campaign has no users !`
            : "Campaign not Found, make sure you've entered correct URL !"}
        </Typography>
      )}
    </>
  );
};

export default Analytics;
