import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  Button,
  IconButton,
  Grid,
  Box,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { IoAdd, IoPencil, IoTrash } from "react-icons/io5";

import $ from "jquery";
import "jquery-ui-dist/jquery-ui";

import Control from "./Control";
import { convertRgbAToHexAplha } from "src/services/editor.service";
import { setLayerElementIds } from "src/services/editor.service";
import imageSrc from "src/assets/default-assets/cs-default-image.jpg";
import "src/styles/properties.scss";
import Row from "src/components/properties/Row";
import Select_Box from "src/components/common/Select_Box";
import { csRenderPresentationEditable } from "../presentation/renderPresentation";

import { csList, csText } from "../elements/Elements";
import { MdOutlineModeEdit } from "react-icons/md";
import { useElementStatus } from "src/hooks/editor/element.controller";
import { useEditorHeirarchy } from "src/hooks/editor/heirarchy.controller";
import { useSetLayerHeight } from "../editorLogics/components";

const Tabgroup = (props) => {
  // Getting Elements
  const { elementId, updateHistory } = props;

  const { activeElement } = useElementStatus();
  const { requestHierarchyUpdate } = useEditorHeirarchy();

  const element = document.getElementById(elementId);
  const tabs = element.querySelector(".tabs");
  const tabBtns = element.querySelectorAll(".tabLinks");
  const tabContents = element.querySelectorAll(".cs-tabContent");

  //   States , effects , etc.

  const dispatch = useDispatch();
  const [type, setType] = useState("horizontal");
  const [textType, setTextType] = useState("paragraph");
  const [btns, setBtns] = useState();
  const [editTab, setEditTab] = useState(false);
  const [btnText, setBtnText] = useState("");
  const [tabId, setTabId] = useState();
  const [tabBack, setTabBack] = useState("");
  const [headerWidth, setHeaderWidth] = useState("23");
  const [headerBackground, setHeaderBackground] = useState("#ffffff");
  const [tabTextColor, setTabTextColor] = useState("#ffffff");
  const [tabsLength, setTabsLength] = useState();

  const Types = [
    { label: "Horizontal", value: "horizontal" },
    { label: "Vertical", value: "vertical" },
  ];

  useEffect(() => {
    element.closest(".cs-layer").dataset.state = "updated";

    const btnBackColor = convertRgbAToHexAplha(
      element.querySelector(".activeBtn").style.backgroundColor
    ).hex;

    const headerBackground = convertRgbAToHexAplha(
      tabs.style.backgroundColor
    ).hex;

    const btnTextColor = convertRgbAToHexAplha(
      element.querySelector(".activeBtn").style.color
    ).hex;
    if (element.style.display == "flex") {
      setType("vertical");
    } else {
      setType("horizontal");
    }
    setBtns(getTabButtons());
    setTabsLength(tabs.children.length);
    setHeaderBackground(headerBackground);
    setTabBack(btnBackColor);
    setTabTextColor(btnTextColor);

    const headerWidth = tabs.style.width.split("%")[0];
    if (type == "vertical") setHeaderWidth(headerWidth);

    // Setting color of active tab in properties
    element.getElementsByClassName("tabLinks");
    const active = [...element.getElementsByClassName("tabLinks")].filter(
      (tab) => tab.classList.contains("activeBtn")
    )[0];
    setTimeout(() => {
      document
        .querySelector(".tabGroup")
        .querySelector(`[data-target=${active.dataset.target}]`)
        .classList.add("activeTab");
    }, 50);

    const tabContent = element.querySelector(".cs-tabContent");
    if (tabContent.querySelector(".cs-text")) {
      setTextType("paragraph");
    } else {
      setTextType("list");
    }
  }, [elementId]);

  //   Functionality
  const getTabButtons = () => {
    const btns = [];
    $(tabs)
      .children()
      .each(function (index, btn) {
        btns[index] = { text: btn.textContent };
      });

    $(element)
      .children(".cs-tabContent")
      .each(function (index, tabCon) {
        btns[index].target = tabCon.id;
        element.querySelectorAll(".tabLinks")[index].dataset.target = tabCon.id;
      });

    updateHistory();
    return btns;
  };

  const directionHandler = (e) => {
    const direction = e.target.value;
    setType(direction);

    if (direction == "vertical") {
      element.style.display = "flex";
      tabs.style.display = "flex";
      tabs.style.width = headerWidth + "%";
      tabs.style.flexDirection = "column";
      tabContents.forEach((tabCon) => {
        tabCon.style.borderTop = "1px solid #ccc";
        tabCon.style.height = "100%";
      });
    } else {
      element.style.display = "";
      tabs.style.display = "";
      tabs.style.width = "100%";
      tabs.style.flexDirection = "";
      tabContents.forEach((tabCon) => {
        tabCon.style.borderTop = "";
        tabCon.style.height = "calc(100% - 107px)";
      });
    }
    updateHistory();
  };

  const openTabHandler = (evt) => {
    tabEditHandler(evt);
    const tabId = evt.currentTarget.dataset.target;

    tabContents.forEach((tabCon) => {
      tabCon.style.display = "none";
    });
    tabBtns.forEach((tabBtn) => {
      tabBtn.classList.remove("activeBtn");
      tabBtn.style.backgroundColor = "";
      tabBtn.style.color = "";
    });

    element.querySelector(`#${tabId}`).style.display = "flex";
    element.querySelector(`[data-target=${tabId}]`).classList.add("activeBtn");
    element.querySelector(".activeBtn").style.backgroundColor = tabBack;
    element.querySelector(".activeBtn").style.color = tabTextColor;

    // Changing tab color of active tab in properties

    // Step 1 - removing from inactive tab

    setTimeout(() => {
      document
        .querySelector(".tabGroup")
        .querySelector(".activeTab")
        .classList.remove("activeTab");
    }, 50);

    // Step 2 - adding to the active tab
    element.getElementsByClassName("tabLinks");
    const active = [...element.getElementsByClassName("tabLinks")].filter(
      (tab) => tab.classList.contains("activeBtn")
    )[0];
    setTimeout(() => {
      document
        .querySelector(".tabGroup")
        .querySelector(`[data-target=${active.dataset.target}]`)
        .classList.add("activeTab");
    }, 50);

    updateHistory();
  };

  const addTabHandler = () => {
    const btnLength = tabs.children.length + 1;
    const tabItem = `<button class="tabLinks" data-target="">Tab ${btnLength}</button>`;
    tabs.innerHTML += tabItem;

    const tabContent = `<div class="cs-tabContent editable padding"></div>`;

    element.innerHTML += tabContent;
    setLayerElementIds(element.parentElement);
    setBtns(getTabButtons());
    setTabsLength(tabs.children.length);

    const tabLink = element.getElementsByClassName("tabLinks");
    const lastTab = tabLink.length - 1;
    tabLink[lastTab].style.fontSize = tabLink[0].style.fontSize;

    csRenderPresentationEditable({
      element,
      activeElement,
      requestHierarchyUpdate,
      updateHistory,
    });

    useSetLayerHeight($(element).closest(".cs-layer"));

    updateHistory();
  };

  const tabDeleteHandler = (e) => {
    setBtns(getTabButtons());
    setEditTab(false);
    const tabId = e.currentTarget.dataset.target;
    const tab = element.querySelector(`[data-target=${tabId}]`);
    const tabContent = element.querySelector(`#${tabId}`);
    const tabControls = e.target.closest(".tabButton");

    if (tab && tabContent) {
      tab.remove();
      tabContent.remove();
      $(tabControls).remove("button");
    }

    setBtns(getTabButtons());

    // Making some other element Active
    if (tabContent && tabContent.style.display == "flex") {
      $(element).children(".cs-tabContent")[0].style.display = "flex";
      if ($(element).find(".tabLinks").length) {
        $(element).find(".tabLinks")[0].classList.add("activeBtn");
        $(element).find(".tabLinks")[0].style.backgroundColor = tabBack;
        $(element).find(".tabLinks")[0].style.color = tabTextColor;
      }
    }

    setTabsLength(tabs.children.length);

    updateHistory();
  };

  const tabEditHandler = (e) => {
    const id = e.currentTarget.dataset.target;
    setTabId(id);
    setEditTab(true);
    setTimeout(() => {
      setBtnText(element.querySelector(`[data-target=${id}]`).textContent);
    }, 50);
  };

  const btnEditHandler = (e) => {
    setBtnText(e.target.value);
    const tab = element.querySelector(`[data-target=${tabId}]`);
    tab.textContent = e.target.value;
    setBtns(getTabButtons());
    updateHistory();
  };

  const btnBackEditHandler = (e) => {
    setTabBack(e.target.value);
    element.querySelector(".activeBtn").style.backgroundColor = e.target.value;
    element.setAttribute("data-active-btn-backcolor", e.target.value);

    updateHistory();
  };

  const btnTextEditHandler = (e) => {
    setTabTextColor(e.target.value);
    element.querySelector(".activeBtn").style.color = e.target.value;
    element.setAttribute("data-active-btn-color", e.target.value);
    updateHistory();
  };

  const headerWidthHandler = (e) => {
    const { value } = e.target;

    if (value < 15) {
      setHeaderWidth(15);
    } else if (value > 100) {
      setHeaderWidth(100);
    }

    setHeaderWidth(value);
    tabs.style.width = value + "%";
    updateHistory();
  };
  const headerBackHandler = (e) => {
    const { value } = e.target;
    setTabTextColor(value);
    tabs.style.backgroundColor = value;
    updateHistory();
  };

  const textTypeHandler = (e) => {
    const { value } = e.target;
    setTextType(value);

    if (value == "paragraph") {
      tabContents.forEach((tabContent) => {
        const text = csText("Enter Your Text Here");

        const csList = tabContent.querySelector(".cs-list");
        const div = document.createElement("div");
        div.innerHTML = text;
        const element = div.querySelector(".cs-text");
        csList.replaceWith(element);
      });
    } else {
      tabContents.forEach((tabContent) => {
        const list = csList();
        const csText = tabContent.querySelector(".cs-text");
        const div = document.createElement("div");
        div.innerHTML = list;
        const ul = div.querySelector("ul");
        csText.replaceWith(ul);
      });
    }

    csRenderPresentationEditable({
      element,
      activeElement,
      requestHierarchyUpdate,
      updateHistory,
    });
  };

  return (
    <Control
      heading="Tab Group"
      className="tabGroup"
      isCollapse={true}
      divider={false}
      expanded={true}
    >
      {/* Getting All Buttons and setting them accordingly */}
      {btns &&
        btns.map((value, index) => (
          <div className="tabButton" key={index}>
            <Button
              key={index}
              data-target={value.target}
              onClick={openTabHandler}
              className="tabBtn"
            >
              {value.text}
            </Button>

            {/* Edit Button */}

            <IconButton
              color="primary"
              data-target={value.target}
              key={index + "ind"}
              onClick={tabEditHandler}
            >
              <MdOutlineModeEdit />
            </IconButton>

            {/* Delete Button */}
            <IconButton
              color="primary"
              data-target={value.target}
              key={index + "ex"}
              onClick={tabDeleteHandler}
              disabled={tabsLength <= 3 ? true : false}
            >
              <IoTrash />
            </IconButton>
          </div>
        ))}

      {/* Edit Controls */}

      {editTab && (
        <Row
          label="Button Text :"
          leftsx="5"
          rightsx="7"
          element={
            <input
              type="text"
              className="input fullwidth-input"
              maxLength={20}
              value={btnText}
              onChange={btnEditHandler}
              onKeyDown={(e) => (e.key == "Enter" ? setEditTab(false) : "")}
            />
          }
        />
      )}

      {/* Adding more tabs */}

      <Box className="global-btn">
        <Button variant="contained" onClick={addTabHandler}>
          Add Tab <IoAdd className="icon" size="18px" />
        </Button>
      </Box>

      {/* Setting for tabgroup vertical and horizontal */}
      <Row
        label="Direction"
        element={
          <Select_Box
            value={type}
            onChange={directionHandler}
            options={[
              { label: "Horizontal", value: "horizontal" },
              { label: "Vertical", value: "vertical" },
            ]}
          />
        }
      />

      {/* <Row
        label="Text Type"
        element={
          <Select_Box
            value={textType}
            onChange={textTypeHandler}
            options={[
              { label: "Paragraph", value: "paragraph" },
              { label: "List", value: "list" },
            ]}
          />
        }
      /> */}

      {/* Setting Tabs Width*/}
      {type == "vertical" && (
        <Row
          label="Header Width"
          element={
            <input
              type="number"
              name="number"
              className="input"
              value={headerWidth}
              min={15}
              max={100}
              onInput={headerWidthHandler}
            />
          }
        />
      )}
      {/* Setting Tabs Background Color */}

      <Row
        label="Header Background"
        element={
          <input
            type="color"
            name="color"
            className="color"
            value={headerBackground}
            onInput={headerBackHandler}
          />
        }
      />

      {/* Setting Tabs Background Color */}

      <Row
        label="Tab Background"
        element={
          <input
            type="color"
            name="color"
            className="color"
            value={tabBack}
            onInput={btnBackEditHandler}
          />
        }
      />

      {/* Setting Tabs Text Color */}

      <Row
        label="Tab Text Color"
        element={
          <input
            type="color"
            name="color"
            className="color"
            value={tabTextColor}
            onInput={btnTextEditHandler}
          />
        }
      />
    </Control>
  );
};

export default Tabgroup;
