import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { canSendRequest } from "src/services/utils";
import { getPresentaionById } from "src/store/actions/presentations";
import { useParams } from "react-router-dom";

import { Box, Typography } from "@mui/material";

import { IoCubeOutline } from "react-icons/io5";
import { BiDesktop } from "react-icons/bi";
import { IoIosAlbums } from "react-icons/io";

// Settings pages
import DetailsSettings from "./Details";
import SlideSettings from "./Slide";
import ChapterSettings from "./Chapters";

import "./tabs.scss";
import AccessControl from "../../../components/presentations/AccessControl";
import Card from "src/components/ui/Card";
import PermissionComp from "src/components/Permission";

const tabs = [
  { label: "Presentation", path: "details", icon: <BiDesktop /> },
  {
    label: "Slide",
    path: "slide",
    icon: <IoCubeOutline />,
  },
  {
    label: "Chapters",
    path: "chapters",
    icon: <IoIosAlbums />,
  },
];

const Setting = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [component, setComponenet] = useState("details");


  return (
    <AccessControl component="settings">
      <Box className="pageTabs">
        {tabs.map((tab, index) => {
          return (
            <Box
              key={index}
              className={tab.path === component ? "active tab" : "tab"}
              onClick={() => setComponenet(tab.path)}
            >
              {tab.icon}&nbsp;
              <Typography variant="body1" component="span">
                {tab.label}
              </Typography>
            </Box>
          );
        })}
      </Box>
      <Card classes="screen">
      <PermissionComp permissionPath="presentation" alert>
        {component === "details" ? (
          <DetailsSettings />
        ) : component === "slide" ? (
          <SlideSettings />
        ) : (
          <ChapterSettings />
        )}
      </PermissionComp>
      </Card>
      
    </AccessControl>
  );
};

export default Setting;
