const Data = {
  previewHeadData: [
    
    {
      id: "user",
      objKey: "firstName",
      sort: true,
      label: "Reviewers",
    },
    {
      id: "reviewStartDate",
      numeric: true,
      sort: true,
      label: "Review start date",
    },
    {
      id: "reviewCompletionDate",
      numeric: true,
      sort: true,
      label: "Review completion date",
    },
    {
      id: "status",
      numeric: true,
      sort: true,
      label: "Status",
    },
  ],

  previewRowsData: [
    {
      version: "0.0.1",
      reviewers: "11.04.2020, 17:30",
      reviewStartDate: "Theresa Webb",
      reviewCompletionDate: "Theresa Webb",
      status: "Draft",
    },
    {
      version: "0.0.1",
      reviewers: "11.04.2020, 17:30",
      reviewStartDate: "Theresa Webb",
      reviewCompletionDate: "Theresa Webb",
      status: "Draft",
    },
    {
      version: "0.0.1",
      reviewers: "11.04.2020, 17:30",
      reviewStartDate: "Theresa Webb",
      reviewCompletionDate: "Theresa Webb",
      status: "Draft",
    },
    {
      version: "0.0.1",
      reviewers: "11.04.2020, 17:30",
      reviewStartDate: " Webb",
      reviewCompletionDate: " Webb",
      status: "Draft",
    },
    {
      version: "0.0.1",
      reviewers: "11.04.2020, 17:30",
      reviewStartDate: "Theresa Webb",
      reviewCompletionDate: "Theresa Webb",
      status: "Draft",
    },
    {
      version: "0.0.1",
      reviewers: "11.04.2020, 17:30",
      reviewStartDate: "Theresa Webb",
      reviewCompletionDate: "Theresa Webb",
      status: "Draft",
    },
    {
      version: "0.0.1",
      reviewers: "11.04.2020, 17:30",
      reviewStartDate: "Theresa Webb",
      reviewCompletionDate: "Theresa Webb",
      status: "Draft",
    },
    {
      version: "0.0.1",
      reviewers: "11.04.2020, 17:30",
      reviewStartDate: "Theresa Webb",
      reviewCompletionDate: "Theresa Webb",
      status: "Draft",
    },
    {
      version: "0.0.1",
      reviewers: "11.04.2020, 17:30",
      reviewStartDate: "Theresa Webb",
      reviewCompletionDate: "Theresa Webb",
      status: "Draft",
    },
  ],
};
export { Data };
