import React, { useState } from "react";
import { useDateFormat } from "src/hooks/useDateFormat";
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import { TableRow, TableCell, Typography, IconButton, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import Confirm from "src/components/dialogs/Confirm";
import { useDispatch } from "react-redux";
import { deleteCampaign } from "src/store/actions/campaign";
import { BsFillFileBarGraphFill } from "react-icons/bs";
import Permission from "src/components/Permission"
const ViewCampaign = (props) => {
  const { id, name, timezone, updatedAt, date, time, weekDays, status } = props;
  const dispatch = useDispatch();
  const [showConfirm, setShowConfirm] = useState(false);
  return (
    <>
      <Confirm
        title="Are you sure you want to delete?"
        isOpen={showConfirm}
        handleConfirm={() => {
          dispatch(deleteCampaign(id));
          setShowConfirm(false);
        }}
        handleClose={() => {
          setShowConfirm(false);
        }}
      />
      <TableRow>
        <TableCell>
          <Typography>{name}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{timezone}</Typography>
        </TableCell>
        {date ? (
          <TableCell>
            <Typography>{date}</Typography>
          </TableCell>
        ) : (
          <TableCell>
            <Typography>{weekDays.map((weekday) => weekday + ", ")}</Typography>
          </TableCell>
        )}
        <TableCell>
          <Typography>{time}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{status}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{useDateFormat(updatedAt)}</Typography>
        </TableCell>
        <TableCell>
        <Tooltip title="Edit" arrow>
          <IconButton
            component={Link}
            to={`/campaign/update/${id}`}
            color="primary"
            sx={{ padding: "4px" }}
          >
            <MdModeEditOutline size="18" />
          </IconButton>
          </Tooltip>
          <Permission permissionPath="campaign.analytics">
          <Tooltip title="Analytics" arrow>
          <IconButton
            onClick={() => setShowConfirm(true)}
            color="primary"
            sx={{ padding: "4px" }}
            component={Link}
            to={`/campaign/analytics/${id}`}
          >
            <BsFillFileBarGraphFill size="18" />
          </IconButton>
          </Tooltip>
          </Permission>
          <Tooltip title="Delete" arrow>
          <IconButton
            onClick={() => setShowConfirm(true)}
            color="primary"
            sx={{ padding: "4px" }}
          >
            <MdDelete size="18" />
          </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ViewCampaign;
