import React from "react";
import { useDateFormat } from "src/hooks/useDateFormat";
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import { TableRow, TableCell, Typography, IconButton, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import Confirm from "src/components/dialogs/Confirm";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteGroup } from "src/store/actions/campaign";

const ViewGroups = (props) => {
  const { id, name, updatedAt } = props;
  const dispatch = useDispatch();
  const [showConfirm, setShowConfirm] = useState(false);
  return (
    <>
      <Confirm
        title="Are you sure you want to delete?"
        isOpen={showConfirm}
        handleConfirm={() => {
          dispatch(deleteGroup(id));
          setShowConfirm(false);
        }}
        handleClose={() => {
          setShowConfirm(false);
        }}
      />
      <TableRow>
        <TableCell>
          <Typography>{name}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{useDateFormat(updatedAt)}</Typography>
        </TableCell>

        <TableCell>
        <Tooltip title="Edit" arrow>
          <IconButton
            component={Link}
            to={`update/${id}`}
            color="primary"
            sx={{ padding: "4px" }}
          >
            <MdModeEditOutline size="18" />
          </IconButton>
          </Tooltip>
          <Tooltip title="Delete" arrow>
          <IconButton
            onClick={() => setShowConfirm(true)}
            color="primary"
            sx={{ padding: "4px" }}
          >
            <MdDelete size="18" />
          </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ViewGroups;
