import {
  csLayer,
  csImage,
  csText,
  csContainer,
  csPopup,
  csButton,
  csLink,
  csSlider,
  csTable,
  csFlipCard,
  csCheckboxes,
  csRadioContainer,
  csAudio,
  csInput,
  csGraph,
  csCoverFlow,
  csVideo,
  csCarousel,
  csExtendedCard,
  csTabGroup,
  csGrid,
  csList,
  csRating,
  csDivider,
  csSocialIcons,
  csColumn,
  csBlock,
} from "./Elements";

export const components = {
  ["cs-text"]: (id, eType) => {
    return csLayer(id, "200", "text", csText(), eType);
  },
  ["cs-image"]: (id, eType) => {
    return csLayer(id, "200", "image", csImage(eType), eType);
  },
  ["cs-card"]: (id, eType) => {
    return csLayer(id, "200", "card", csText() + csImage(), eType);
  },
  ["cs-container"]: (id, eType) => {
    return csLayer(id, "300", "container", '', eType);
  },
  ["cs-popup"]: (id, eType) => {
    return csLayer(id, "300", "popup", csPopup(), eType, "background-color:#fff");
  },
  ["cs-button"]: (id, eType) => {
    return csLayer(id, "200", "button", csButton(), eType);
  },
  ["cs-link"]: (id, eType) => {
    return csLayer(id, "200", "link", csLink(), eType);
  },
  ["cs-checkboxes"]: (id, eType) => {
    return csLayer(id, "200", "checkbox", csCheckboxes(), eType);
  },
  ["cs-table"]: (id, eType) => {
    return csLayer(id, "300", "table", csTable(), eType);
  },
  ["cs-flipCard"]: (id, eType) => {
    return csLayer(id, "500", "flipCard", csFlipCard(), eType);
  },
  ["cs-radio"]: (id, eType) => {
    return csLayer(id, "200", "radio", csRadioContainer(), eType);
  },
  ["cs-slider"]: (id, eType) => {
    return csLayer(id, "200", "slider", csSlider(), eType);
  },
  ["cs-audio"]: (id, eType) => {
    return csLayer(id, "400", "audio", csAudio(), eType);
  },
  ["cs-input"]: (id, eType) => {
    return csLayer(id, "200", "input", csInput(), eType);
  },
  ["cs-graph"]: (id, eType) => {
    return csLayer(id, "400", "graph", csGraph(), eType);
  },
  ["cs-coverflow"]: (id, eType) => {
    return csLayer(id, "500", "coverflow", csCoverFlow(), eType);
  },
  ["cs-video"]: (id, eType) => {
    return csLayer(id, "500", "video", csVideo(), eType);
  },
  ["cs-carousel"]: (id, eType) => {
    return csLayer(id, "533", "carousel", csCarousel(), eType);
  },
  ["cs-extendedCard"]: (id, eType) => {
    return csLayer(id, "500", "extendedCard", csExtendedCard(), eType);
  },
  ["cs-tabGroup"]: (id, eType) => {
    return csLayer(id, "600", "tabGroup", csTabGroup(), eType);
  },
  ["cs-grid"]: (id, eType) => {
    return csLayer(id, "600", "grid", csGrid(), eType);
  },
  ["cs-list"]: (id, eType) => {
    return csLayer(id, "200", "list", csList(), eType);
  },
  ["cs-rating"]: (id, eType) => {
    return csLayer(id, "200", "rating", csRating(), eType);
  },
  ["cs-divider"]: (id, eType) => {
    return csLayer(id, "200", "divider", csDivider(), eType);
  },
  ["cs-social"]: (id, eType) => {
    return csLayer(id, "200", "social", csSocialIcons(), eType);
  },
  ["cs-column"]: (id, eType, columnLayout) => {
    return csLayer(id, "200", "column", csColumn(columnLayout), eType);
  },
  ["cs-block"]: (id, eType, blockLayout) => {
    const { html, style = "" } = csBlock(blockLayout, eType);
    return csLayer(id, "200", "block", html, eType, style);
  },
};
