import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../interfaces/api.interface";
import HttpService from "../../services/http.service";

import { uiActions } from "../reducers/ui-slice";

// Sharing Settings
export const getSettings = createAsyncThunk(
  "getSettings",
  async ({ id, type }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.sharing.getSettings(id, type),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const fetchShared = createAsyncThunk(
  "fetchShared",
  async (params, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.sharing.fetch(params),
        undefined,
        thunkAPI
      );
      return {data : response.data, type: params.type};
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const share = createAsyncThunk(
  "share",
  async ({ id, data, type }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.sharing.share(id, type),
        data,
        thunkAPI
      );
      if (response.data) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Shared!",
            message: `${type.charAt(0).toUpperCase()}${type.slice(
              1
            )} Shared Successfully`,
          })
        );
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const removeSharingUser = createAsyncThunk(
  "removeSharingUser",
  async ({ id, userId, type }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.sharing.removeSharingUser(id, userId, type),
        thunkAPI
      );
      if (response.data) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Shared!",
            message: `${type.charAt(0).toUpperCase()}${type.slice(
              1
            )} Shared Successfully`,
          })
        );
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
