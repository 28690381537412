import React, { useEffect, useState } from "react";
import { IconButton, Typography, Box, Button } from "@mui/material";
import { IoMdAdd, IoMdRemoveCircleOutline } from "react-icons/io";
import Control from "./Control";
import { gridActions } from "src/services/editor.service";
import Row from "src/components/properties/Row";
import { attachlisteners } from "../email/renderEmail";
import { useElementStatus } from "src/hooks/editor/element.controller";
import { useEditorHeirarchy } from "src/hooks/editor/heirarchy.controller";

const GridProperty = (props) => {
  const { activeElement } = useElementStatus();
  const { requestHierarchyUpdate } = useEditorHeirarchy();

  const [gridItems, setgridItems] = useState([]);

  const { elementId, updateHistory, undoRedoChange } = props;
  const element = document.getElementById(elementId);

  const updatedGridState = () => {
    const gridItems = [];
    const gridElements = [...element.querySelector("tr").children];
    gridElements.forEach((el, i) => {
      gridItems.push({ name: `Grid Item ${i + 1}` });
    });
    setgridItems(gridItems);
    return gridElements;
  };

  // Getting Element's data
  useEffect(() => {
    updatedGridState();
  }, [elementId, undoRedoChange]);

  const onGridItemsChange = (element) => {
    const layer = element.closest(".cs-layer");
    gridActions.resizeable(element);
    attachlisteners({
      actionState: 0,
      activeElement,
      requestHierarchyUpdate,
      element: layer,
      updateHistory,
    });
    requestHierarchyUpdate();
  };

  // To delete item
  const removeItem = (index) => {
    const availableItems = JSON.parse(JSON.stringify(gridItems));
    const updatedItems = availableItems.filter((_, i) => i !== index);
    const items = updatedGridState();
    const elementToRemove = items[index];
    let leavedWidth;
    if (elementToRemove) {
      leavedWidth = parseFloat(elementToRemove.style.width);
      elementToRemove.remove();
    }

    // Giving width of removed element to remaining items
    items.forEach((el) => {
      const itemWidth = parseFloat(el.style.width);
      const remaingItemsWidth = 100 - leavedWidth;
      const itemShare = (itemWidth * leavedWidth) / remaingItemsWidth;
      el.style.width = itemWidth + itemShare + "%";
    });

    updatedGridState();
    onGridItemsChange(element);
    updateHistory();
  };

  const addItem = () => {
    const items = updatedGridState();
    const itemsLength = items.length;
    let newItemWidth = 100 / (itemsLength + 1);
    // reducing other items width for new item space
    items.forEach((el) => {
      const itemWidth = parseFloat(el.style.width);
      const itemShare = (itemWidth * newItemWidth) / 100;
      el.style.width = itemWidth - itemShare + "%";
    });
    const id = (Math.random() + 1).toString(36).substring(7);
    const tr = element.querySelector("tr");
    const item = `<td class="cs-grid-item editable" id="grid-item-${id}" style='width:${newItemWidth}%;position:relative'></td>`;
    tr.insertAdjacentHTML("beforeend", item);
    const itemRef = tr.lastChild;
    // csRenderEmailEditable({
    //   element: itemRef,
    //   activeElement,
    //   actionState,
    //   requestHierarchyUpdate,
    // });
    onGridItemsChange(element);
    updatedGridState();
    applyPadding(element.querySelector(":last-child"));
    gridActions.resizeable(element);
    updateHistory();
  };

  const highlightElement = (i) => {
    const item = updatedGridState()[i];
    if (item) {
      item.classList.add("hover");
    }
  };
  const deHighlightElement = () => {
    const item = element.querySelector(".cs-grid-item.hover");
    if (item) {
      item.classList.remove("hover");
    }
  };

  const activeElementItem = (i) => {
    const item = updatedGridState()[i];
    if (item) {
      item.click();
    }
  };

  return (
    <Box className="grid-property">
      <Control
        heading={"Grid Items"}
        className="bgControl"
        headingSize={"h1"}
        divider={false}
        isCollapse={true}
        expanded
      >
        {gridItems.map((item, i) => {
          return (
            <Box
              key={i}
              onMouseOver={() => {
                highlightElement(i);
              }}
              onMouseLeave={deHighlightElement}
            >
              <Row
                leftsx={10}
                rightsx={2}
                button={
                  <Button
                    fullWidth
                    sx={{ justifyContent: "flex-start" }}
                    onClick={() => {
                      activeElementItem(i);
                    }}
                  >
                    <Typography>{item.name}</Typography>
                  </Button>
                }
                element={
                  <IconButton
                    size="small"
                    className="removeIcon"
                    disabled={gridItems.length <= 1}
                    onClick={() => removeItem(i)}
                  >
                    <IoMdRemoveCircleOutline size={15} />
                  </IconButton>
                }
              />
            </Box>
          );
        })}
        <Box className="global-btn">
          <Button
            fullWidth
            variant="contained"
            onClick={addItem}
            disabled={gridItems.length >= 2}
          >
            Add Grid Item <IoMdAdd className="icon" size="18px" />
          </Button>
        </Box>
      </Control>
    </Box>
  );
};

export default GridProperty;
