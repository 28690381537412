import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  roles: {
    owner: {
      view: true,
      edit: true,
      clone: true,
      detail: true,
      structure: true,
      more: true,
      publish: true,
      details: true,
      localize: true,
      settings: true,
    },
    editorRU: {
      view: true,
      edit: true,
      clone: true,
      detail: true,
      structure: true,
      more: true,
      details: true,
    },
    viewer: {
      view: true,
      details: true,
    },
  },
  publish: {
    owner: true,
    publisher: true,
    editorWithPusliher: true,
  },
};
const rolesSlice = createSlice({
  name: "roles",
  initialState,
});
export default rolesSlice.reducer;
