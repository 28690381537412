import React, { useEffect, useState } from "react";
import { Card, Grid, Stack, TextField, Tooltip, Typography } from "@mui/material";
import Button from "src/components/Button";
import { Box } from "@mui/material";
import SelectBox from "src/components/ui/formElements/SelectBox";
import { countries, languages, timezones } from "src/data/data";
import "src/styles/users/form.scss";
import Content from "src/layouts/Content";
import InputFields from "src/components/ui/formElements/InputFields";
import InputPhoneField from "src/components/ui/formElements/InputPhoneField";
import { useForm } from "react-hook-form";
import SelectFields from "src/components/ui/formElements/SelectFields";
import AddressView from "src/layouts/address/Address";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { createUser, getUser, updateUser } from "src/store/actions/users";

const UsersForm = () => {
  const { id  } = useParams();

  const { company } = useSelector((state) => state.auth.userData);

  const dispatch = useDispatch();
  const navigate = useNavigate();
   // Setting Default Values For Form
   const defaultValues = useForm({
    defaultValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      mobile: "",
      phone: "",
      fax: "",
      lang: "",
      timezone: "",
      title: "",
      company: {
        companyId: company? company.companyId:"",
        name: "",
        UID: "",
        assetReview: null,
      },
  
      address: {
        street: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
      },
      role: "",
    },
  });

  // Initialization For React-Hook-Form

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    reset,
    resetField,
    formState: { errors },
  } = defaultValues;

  const { isLoading, notification } = useSelector((state) => state.ui);
  const [doNavigate, setDoNavigate] = useState(false);
  
  const companyId = watch("company.companyId");

  useEffect(() => {
    if(id)
      dispatch(getUser({ id, companyId })).then(res=>{
        reset(res.payload)
    })
  }, []);

  useEffect(() => {
    if (notification?.status === "success" && doNavigate) {
      navigate(-1);
    }
    clearErrors();
    if (notification?.message === "User already exists") {
      setError(
        "email",
        { message: notification?.message },
        { shouldFocus: true }
      );
    }
  }, [notification]);

  const onSubmitHandler = (data) => {
    if(id){
      dispatch(updateUser({ data, id }));
    }else{
      dispatch(createUser(data))
    }
    setDoNavigate(true);
  };

  const roles = [
    { label: "Admin", value: "admin" },
    { label: "Developer", value: "developer" },
    { label: "Reviewer", value: "reviewer" },
  ];

  return (
    

    <Content title={`${id?'Edit':'Create'} User`}>
    <form>
      <Card className="form-card">
        <Box className="form-heading">
          <Typography variant="h1">PERSONAL DETAILS:</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <InputFields
              fieldName="firstName"
              type="text"
              label="First Name"
              control={control}
              rules={{
                required: "First Name is required",
              }}
              error={errors?.firstName}
            />
          </Grid>

          <Grid item xs={3}>
            <InputFields
              fieldName="middleName"
              type="text"
              label="Middle Name"
              control={control}
              error={errors?.middleName}
            />
          </Grid>

          <Grid item xs={3}>
            <InputFields
              fieldName="lastName"
              type="text"
              label="Last Name"
              control={control}
              rules={{
                required: "Last Name is required",
              }}
              error={errors?.lastName}
            />
          </Grid>

          <Grid item xs={3}>
                <InputFields
                  fieldName="email"
                  type="email"
                  label="Email"
                  control={control}
                  rules={{
                    pattern: {
                      value:
                        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/gi,
                      message: "Incorrect Email",
                    },
                    required: "Email is required",
                  }}
                  error={errors.email}
                  disabled={id ? true : false}
                />
              </Grid>
              <Grid item xs={3}>
              <InputPhoneField
                    fieldName="mobile"
                    type="tel"
                    label="Mobile"
                    control={control}
                    rules={{
                      minLength: {
                        value: 11,
                        message: "Mobile should have at least 11 characters",
                      },
                      maxLength: {
                        value: 16,
                        message: "Mobile limit 16 characters",
                      },
                      required: "Mobile is required",
                    }}
                    error={errors?.mobile}
                    phone
                  />
            </Grid>
            <Grid item xs={3}>
              <InputPhoneField
                    fieldName="phone"
                    type="tel"
                    label="Phone"
                    control={control}
                    rules={{
                      minLength: {
                        value: 11,
                        message: "Phone should have at least 11 characters",
                      },
                      maxLength: {
                        value: 16,
                        message: "Phone limit 16 characters",
                      },
                      required: "Phone is required",
                    }}
                    error={errors?.phone}
                    phone
                  />
            </Grid>
            <Grid item xs={3}>
              <InputFields
                fieldName="fax"
                type="text"
                min={0}
                label="Fax"
                control={control}
                rules={{
                  pattern: {
                    value: /^[0-9]*$/gi,
                    message: "Fax Number Should Not Contain Alphabets",
                  },
                }}
                error={errors?.fax}
              />
            </Grid>
            <Grid item xs={3}>
              <SelectFields
                fieldName="role"
                label="Role"
                control={control}
                options={roles.map((lang) => {
                  return { label: lang.label, value: lang.value };
                })}
                rules={{
                  required: "Role is required",
                }}
                error={errors?.role}
                withSearch
              />
            </Grid>

            <Grid item xs={4}>
                  <SelectFields
                    fieldName="timezone"
                    label="Timezone"
                    control={control}
                    options={timezones.map(({label,value}) => {
                      return { label, value };
                    })}
                    withSearch
                  />
                </Grid>
                <Grid item xs={4}>
                  <SelectFields
                    fieldName="lang"
                    label="Language"
                    control={control}
                    options={
                      languages.map((lang) => {
                            return {
                              label: lang.label,
                              value: lang.value,
                            };
                        })
                    }
                    withSearch
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputFields
                    fieldName="title"
                    type="text"
                    label="Title"
                    control={control}
                  />
                </Grid>
        </Grid>

      </Card>

      <AddressView control={control} errors={errors} watch={watch} resetField={resetField}/>
      <Stack direction="row" justifyContent="flex-end">
        <Button
          onClick={handleSubmit(onSubmitHandler)}
          type="submit"
          variant="contained"
          disabled={isLoading}
        >
          Save
        </Button>
      </Stack>
    </form>
  </Content>
  );
};

export default UsersForm;
