import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../interfaces/api.interface";
import HttpService from "../../services/http.service";

export const fetchVersions = createAsyncThunk(
  "fetchVersions",
  async ({ id, type }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.fetchVersions(id, type),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
