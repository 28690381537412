import { createSlice } from "@reduxjs/toolkit";
import {
  fetchEmails,
  createEmail,
  fetchEmailById,
  updateEmail,
  fetchEmailHtml,
  fetchTemplateCategory,
  fetchTemplatesByCategory,
  cloneEmail,
  importEmail,
  exportEmail,
  downloadSource,
  downloadVersionSource,
  deleteEmail,
  uploadNewVersion,
  saveEmail,
} from "../actions/emails";
import { checkIfReviewed, review } from "../actions/reviews";
import { downloadbyUrl, downloadPdfByImages, checkIsLock } from "src/services/utils";

const emailInitial = {
  status: "",
  isDeleted: false,
  _id: "",
  name: "",
  image: "",
  category: "",
  lang: "",
  country: "",
  region: "",
  version: "",
  targetSystem: "",
  startDate: null,
  endDate: null,
  ApprovalSystemExtId: "",
  bucketURL: "",
  sourceTemplate: "",
  UID: 0,
  createdBy: "",
  updatedBy: "",
  createdAt: "",
  updatedAt: "",
  thumbnail: "",
};

const initialState = {
  list: [],
  currentEmailId: null,
  email: emailInitial,
  templates: [],
  templatesCategories: [],
  templatesMsg: "",
  count: 0,
  config: { id: null, requestTime: null },
  emailAccess: true,
  emailData: null,
  loadingMsg: "Loading ...",
  preview: { emailId: "", isOpen: false, isReviewed: true },
};

const emailsSlice = createSlice({
  name: "emailsSlice",
  initialState,
  reducers: {
    setCurrentEmailId(state, payload) {
      state.currentEmailId = payload.payload;
    },
    setPreviewEmail(state, action) {
      state.preview = { ...action.payload, isReviewed: true };
    },
    setEmailAccess(state, action) {
      state.emailAccess = action.payload;
    },
    setEmailData(state, action) {
      state.emailData = action.payload;
    },
    clearEmailData(state) {
      if (!location.pathname.includes("edit")) {
        state.emailData = null;
      }
    },
    setTemplateCategoryMsg(state) {
      if (state.templatesCategories.length) {
        state.templatesMsg = "";
      } else {
        state.templatesMsg = "No Templates Categories Found";
      }
    },
    setEmailTemplateStatus(state, action) {
      const index = state.list.findIndex(
        (email) => email._id === action.payload.id
      );
      if (index !== -1) {
        state.list[index].status = action.payload.status;
        if (action.payload.options) {
          state.list[index].isLock = action.payload.options.isLock;
        }
      }
    },
  },
  extraReducers: (builder) => {
    // Add Email
    builder.addCase(createEmail.fulfilled, (state, action) => {
      if (state.count >= 12) {
        state.list.pop();
      }
      state.list.unshift(action.payload);
      state.loadingMsg = "";
      state.count++;
    });

    // Clone Email
    builder.addCase(cloneEmail.fulfilled, (state, action) => {
      if (state.count >= 12) {
        state.list.pop();
      }
      state.list.unshift(action.payload);
      state.loadingMsg = "";
      state.count++;
    });

    // Import Email
    builder.addCase(importEmail.fulfilled, (state, action) => {
      if (state.count >= 12) {
        state.list.pop();
      }
      state.list.unshift(action.payload);
      state.loadingMsg = "";
      state.count++;
    });

    // Import Email
    builder.addCase(deleteEmail.fulfilled, (state, action) => {
      const index = state.list.findIndex(
        (email) => email._id === action.payload
      );
      if (index !== -1) {
        state.list.splice(index, 1);
      }
      if (state.list.length === 0) {
        state.loadingMsg = "No Email Templates Found !";
      }
    });

    // Fetch Email : Pending
    builder.addCase(fetchEmails.pending, (state, action) => {
      state.email = emailInitial;
      state.config = { id: null, requestTime: null };
      state.loadingMsg = "Loading ....";
    });

    // Fetch Email : Fulfilled
    builder.addCase(fetchEmails.fulfilled, (state, action) => {
      const { response, count } = action.payload;

      const emailTempates =response.map((email)=>{
          email.isLock = checkIsLock(email.status);
          return email;
      });

      state.count = count;
      state.list = emailTempates;
      if (response.length === 0) {
        state.loadingMsg = "No Email Templates Found !";
      } else {
        state.loadingMsg = "";
      }
    });

    // Fetch Email : Pending
    builder.addCase(fetchEmails.rejected, (state, action) => {
      state.loadingMsg = "An Error Occured !";
    });

    // Fetch Email By Id
    builder.addCase(fetchEmailById.fulfilled, (state, action) => {
      const email = action.payload;
      email.isLock = checkIsLock(email.status);
      state.email = email;
      state.config.id = action.payload._id;
      state.config.requestTime = Date.now();
    });

    // Fetch Email By Id
    builder.addCase(fetchEmailById.rejected, (state, action) => {
      state.emailAccess = false;
    });

    // Fetch Email By Id
    builder.addCase(updateEmail.fulfilled, (state, action) => {
      state.email = { ...state.email, ...action.payload };
    });

    // Saving Email
    builder.addCase(saveEmail.fulfilled, (state, action) => {
      state.emailData = action.payload.HTML;
    });

    builder.addCase(fetchEmailHtml.pending, (state, action) => {
      state.emailData = null;
    });

    builder.addCase(fetchEmailHtml.fulfilled, (state, action) => {
      const html = new DOMParser().parseFromString(
        action.payload.html,
        "text/html"
      );
      const dropzone = html.body.querySelector("#dropzone");
      if (dropzone && dropzone.innerHTML) {
        state.emailData = action.payload.html;
      } else {
        state.emailData =
          '<div class="item-loading">No Data in This Mail</div>';
      }
    });

    // Fetch Templates : Pending
    builder.addCase(fetchTemplateCategory.pending, (state, action) => {
      state.templatesCategories = [];
      state.templatesMsg = "Loading . . . ";
    });

    // Fetch Templates Categories : Fullfilled
    builder.addCase(fetchTemplateCategory.fulfilled, (state, action) => {
      if (!action.payload.length) {
        state.templatesMsg = "No Templates Found";
      } else {
        state.templatesMsg = "";
      }

      state.templatesCategories = action.payload;
    });

    //  Fetch Templates By Category : Pending
    builder.addCase(fetchTemplatesByCategory.pending, (state, action) => {
      state.templates = [];
      state.templatesMsg = "Loading . . . ";
    });

    // Fetch Templates By Category : Fullfilled
    builder.addCase(fetchTemplatesByCategory.fulfilled, (state, action) => {
      if (!action.payload.length) {
        state.templatesMsg = "No Templates Found";
      } else {
        state.templatesMsg = "";
      }

      state.templates = action.payload;
    });

    // Donwload Source
    builder.addCase(downloadSource.fulfilled, (state, action) => {
      downloadbyUrl(action.payload);
    });

    // Donwload Version Source
    builder.addCase(downloadVersionSource.fulfilled, (state, action) => {
      downloadbyUrl(action.payload);
    });

    // Export Email
    builder.addCase(exportEmail.fulfilled, (state, action) => {
      downloadPdfByImages(action.payload);
    });
    //Check if user has reviewed
    builder.addCase(checkIfReviewed.fulfilled, (state, action) => {
      state.preview.isReviewed = action.payload;
    });

    //Review
    builder.addCase(review.fulfilled, (state, action) => {
      state.preview.isReviewed = true;
    });

    builder.addCase(uploadNewVersion.fulfilled, (state, action) => {
      const index = state.list.findIndex(
        (email) => email._id === action.payload.id
      );
      if (index !== -1) {
        state.list[index].version = action.payload.version;
      }
    });
  },
});

export const {
  setEmailAccess,
  setTemplateCategoryMsg,
  setCurrentEmailId,
  clearEmailData,
  setPreviewEmail,
  setEmailData,
  setEmailTemplateStatus,
} = emailsSlice.actions;
export default emailsSlice.reducer;
