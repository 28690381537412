import React, { useState } from 'react';

const Image = ({ src, fallbackSrc, alt, ...props }) => {
  const [imageSrc, setImageSrc] = useState(src);
  const handleImageError = () => {
    // If the image fails to load, set the source to the fallback image
    setImageSrc(fallbackSrc);
  };
  return <img src={imageSrc} onError={handleImageError} alt={alt} {...props} />;
};

export default Image;
