import React, { useEffect, useState } from "react";
//Stlyes
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { clearLandingPageData } from "src/store/reducers/microsite-slice";
import {
  Typography,
  Grid,
  Button,
  Box,
  IconButton,
  Tooltip,
  CircularProgress
} from "@mui/material";
import { BsTablet, BsTabletLandscape } from "react-icons/bs";
import {
  AiOutlineClose,
  AiOutlineMobile,
  AiOutlineDesktop,
} from "react-icons/ai";
import { BiCommentDetail } from "react-icons/bi";
import Comments from "../../components/dialogs/Comments";
import "./MicrositePreview.scss";
import {
  fetchLandingPageById,
  fetchLandingpageHtml,
} from "src/store/actions/microsites";
import { getHTMLFromBody, setBodyinHTML } from "src/services/editor.service";
import { createLink, createScript } from "src/services/editor.service";
import { setLandingPagePreview } from "src/store/reducers/microsite-slice";
import { csRenderFunctional } from "src/components/editor/editorLogics/components";
import PermissionComp from "src/components/Permission";
import { hasPermission } from "src/utils/Utils";
const MicrositePreview = (props) => {
  const dispatch = useDispatch();
  const type = "landingpage";
  const { isLoadingApprove, isLoadingDeclined } = props;
  const [isOpenComments, setIsOpenComments] = useState(false);
  const [ShouldDevicesShow, setShouldDevicesShow] = useState(false);
  const [device, setDevice] = useState("desktop");
  const [iframeWidth, setIframeWidth] = useState(null);
  const { isReviewed } = useSelector((state) => state.microsites.preview);
  const { landingpageHtml, landingPage } = useSelector(
    (state) => state.microsites
  );
  const { list: versions } = useSelector((state) => state.versions);

  const handleCloseComments = () => {
    setIsOpenComments(false);
  };

  const handleOpenComments = () => {
    setIsOpenComments(true);
  };
  const { id } = useParams();
  const micrositeId = id || props.micrositeId;
  const versionId = props.versionId;
  const isOnDialog = id == undefined;
  const [loading, setLoading] = useState(false);

  useEffect(() => {


    if (device === 'desktop') {
      let fullWidth = document.querySelector('.preview-wrapper').offsetWidth;
      console.log(fullWidth)
      setIframeWidth(fullWidth)
    } else {
      setIframeWidth(320)
    }

  }, [device])

  // API call to get the presentation
  useEffect(() => {
    dispatch(fetchLandingPageById(micrositeId));
    const filter = { micrositeId, versionId }
    if (versions?.length > 0) {
      let dataList = [...versions]
      const latest = dataList.sort((a, b) => Number(b.version) - Number(a.version))[0];
      if (latest._id == versionId) {
        delete filter.versionId
      }
    }
    dispatch(fetchLandingpageHtml(filter));
  }, [micrositeId, versionId]);

  useEffect(() => {
    return () => {
      dispatch(setLandingPagePreview({ isOpen: false }));
    };
  }, []);

  useEffect(() => {
    if (landingpageHtml) {
      setLoading(false);
      setShouldDevicesShow(true);
    } else {
      setLoading(true);
    }
    return {};
  }, [landingpageHtml]);

  // To clear editor's data
  const clearData = () => {
    dispatch(clearLandingPageData());
  };

  useEffect(() => {
    return () => {
      clearData();
    };
  }, []);

  const loadingHtml = `<div id="loading-iframe">Rendering in Progress..</div>`;

  const headerIframeTags = `
    <link rel="stylesheet" href="/css/components.css">
    <link rel="stylesheet" href="/css/animate.min.css">
    <link rel="stylesheet" href="/css/grids.css">
    <link rel="stylesheet" href="/css/jquery-ui.css">
    <script defer src="https://cdn.jsdelivr.net/npm/chart.js@3.7.1/dist/chart.min.js"></script>
    <script defer src="https://cdnjs.cloudflare.com/ajax/libs/jquery.flipster/1.1.5/jquery.flipster.min.js"></script>  
    <script src="/js/jquery-1.12.4.min.js"></script>
    <script src="/js/slider.js"></script>
     
    <style>
      @import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
      *{
        margin:0;
        padding:0;
        box-sizing:border-box;
      }
      html{
        height: 100%;
      }
      body{
            display: flex;
          justify-content: center;
          width:100%;
          height: 100%;
          font-family : Lato ,sans-sarif;
      }
      #loading-iframe{
        z-index : 100;
        display : flex;
        justify-content : center;
        align-items : center;
        position : absolute;
        width : 100%;
        height : 100%;
        background-color : white;
      }
      #dropzone{
        width: 100% !important;
        height: auto;
      }
      .item-loading{
        align-self: center;
        font-size: 20px;
        font-weight: 600;
        font-family: lato;
      }

      ::-webkit-scrollbar {
      width: 0.4em;
      height: 0.4em;
    }

    ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    ::-webkit-scrollbar-thumb {
    background-color: #2cc1d6;
    }
    @media(max-width:320px){
    .cs-grid-item{
      width:100% !important;
    }
    }
    </style>
  `;

  const renderFunctionalComponents = (e) => {
    const renderFunction = `
      const layers = document.querySelectorAll('[class*="cs-"]');
      csRenderFunctional(layers);
      layers.forEach((element) => {
        // console.log(element);
        csRenderFunctional(element);
      });
      `;
    // document.getElementById("loading-iframe").remove()
    const renderScript = document.createElement("script");
    renderScript.text = renderFunction;
    const frameDoc = e.target.contentWindow.document;
    frameDoc.body.append(renderScript);
  };

  return (
    <>
      {isOpenComments && (
        <Comments
          type={type}
          isOpen={isOpenComments}
          handleClose={handleCloseComments}
          id={micrositeId}
        />
      )}
      <div className="container-preview">
        <div className="preview-head flex-between">
          <div className="flex-item-sm paddingX">
            <Typography className="w-100" variant="h2">
              {landingPage.name ? landingPage.name : "Please wait..."}
            </Typography>
          </div>
          <div className="flex-item-fit">
            {props.viewType === "presentation" && (
              <>
                <Typography variant="body1" component="span" className="text">
                  Target Device :
                  <Typography
                    variant="body1"
                    component="span"
                    ml={1}
                    sx={{ verticalAlign: "middle" }}
                  >
                    <BsTablet />
                  </Typography>
                </Typography>
              </>
            )}

            {ShouldDevicesShow && (
              <Box container className="zoom-selectbox">
                <Tooltip title="Mobile" arrow>
                  <IconButton
                    className={device == "mobile-sm" ? "activeDevice" : ""}
                    onClick={() => setDevice("mobile-sm")}
                  >
                    <AiOutlineMobile />
                  </IconButton>
                </Tooltip>
                {/* <Tooltip title="Tablet">
                  <IconButton
                    className={device == "tablet" ? "activeDevice" : ""}
                    onClick={() => setDevice("tablet")}
                  >
                    <BsTabletLandscape />
                  </IconButton>
                </Tooltip> */}
                <Tooltip title="Desktop" arrow>
                  <IconButton
                    className={device == "desktop" ? "activeDevice" : ""}
                    onClick={() => setDevice("desktop")}
                  >
                    <AiOutlineDesktop />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </div>
          <div className="flex-item-sm paddingX">
            <div className="w-100 text-end  ">
              <Button
                variant="text"
                color="primary"
                sx={{ marginRight: 3 }}
                onClick={handleOpenComments}
                startIcon={<BiCommentDetail color={"#2E3A94"} size={17} />}
              >
                Comments
              </Button>
              {/* It means the component is dependent on dialog then show close button */}
              {isOnDialog && (
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => props.handleClose()}
                  startIcon={<AiOutlineClose color="#2E3A94" size={17} />}
                >
                  Close
                </Button>
              )}
            </div>
          </div>
        </div>

        {!isReviewed && hasPermission("approvalProcess") && (
          <Grid container>
            <Grid item xs={12} md={12} className="preview-head justify">

              {isLoadingApprove ? (
                <CircularProgress size={20} sx={{ marginLeft: 1 }} />
              ) : (
                <Button
                  variant="text"
                  color="success"
                  size="large"
                  onClick={() => props.reviewMicrosite(true)}
                  loading={true}
                >
                  Approve
                </Button>
              )}

              {isLoadingDeclined ? (
                <CircularProgress size={20} sx={{ marginLeft: 1 }} />
              ) : (
                <Button
                  variant="text"
                  color="error"
                  size="large"
                  onClick={() => props.reviewMicrosite(false)}
                >
                  Declined
                </Button>
              )}
            </Grid>
          </Grid>
        )}

        <div className="preview-wrapper preview-microsite">
          {landingpageHtml ? (
            <iframe
              onLoad={renderFunctionalComponents}
              loading="lazy"
              className={"data " + device}
              srcDoc={setBodyinHTML(
                getHTMLFromBody(landingpageHtml),
                headerIframeTags
              )}
              width={iframeWidth}
            ></iframe>
          ) : (
            <div className="item-loading">loading .....</div>
          )}
        </div>
      </div>
    </>
  );
};

export default MicrositePreview;
