import React, { useState, useEffect } from "react";
import $ from "jquery";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/material";
import { IoMdRemoveCircleOutline, IoMdAdd } from "react-icons/io";
import { convertRgbAToHexAplha } from "src/services/editor.service";
import Control from "./Control";
import "src/styles/properties.scss";
import Row from "src/components/properties/Row";
import { useSetLayerHeight } from "../editorLogics/components";
const Radio = (props) => {
  // References
  const { updateHistory, elementId, undoRedoChange } = props;
  const radioContainer = document.getElementById(elementId);
  let radioTexts = radioContainer.querySelectorAll(".label");
  const fontSize = radioContainer.style.fontSize;
  const elementLayer = radioContainer.closest(".cs-layer");
  let radioRows = radioContainer.querySelectorAll(".radio-row");

  const [text, setText] = useState("");
  const [radios, setradios] = useState([]);
  const [properties, setProperties] = useState({
    color: "",
  });
  const [textColor, setTextColor] = useState("#000000");
  // < ----- GETTING INPUT VALUES ----- >
  useEffect(() => {
    // Clearing old radios
    // getting innerhtml
    let borderColor;
    const radios = [];
    radioRows.forEach((row) => {
      const radio = row.querySelector(".radio");
      const checked = radio.classList.contains("checked");
      borderColor = getComputedStyle(radio.querySelector("svg")).fill;
      borderColor = convertRgbAToHexAplha(borderColor).hex;
      const text = row.querySelector(".label");
      radios.push({
        checked,
        text: text.innerHTML,
        id: Math.random(),
      });
    });
    setradios(radios);
    setTextColor(convertRgbAToHexAplha(radioTexts[0].style.color).hex);

    // getting radio style
    setProperties((prev) => ({ ...prev, color: borderColor }));
  }, [elementId, undoRedoChange]);

  // < ----- EDITING IN HTML----- >
  // Adding radio row
  const addRow = () => {
    setradios((prev) => [...prev, { checked: false, text, id: Math.random() }]);
    setText("");
    updateHistory();
  };
  // removing radio row
  const removeRow = (id) => {
    const filtered = radios.filter((obj) => obj.id !== id);
    setradios(filtered);
    updateHistory();
  };

  // changing color
  let timeout;
  const inputHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    clearTimeout(timeout);
    if (name === "color") {
      timeout = setTimeout(() => {
        setProperties((prev) => ({ ...prev, [name]: value }));
        updateHistory();
      }, 300);
      radioRows.forEach((radio) => {
        radio.querySelector("svg").style.fill = value;
      });
    }
  };

  const textColorHandler = (e) => {
    const value = e.target.value;
    setTextColor(value);
    radioTexts.forEach((text) => (text.style.color = value));
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      updateHistory();
    }, 300);
  };

  // check / uncheck
  const toggleCheck = (e, id) => {
    const updatedCheck = radios.map((check) => {
      return {
        ...check,
        checked: check.id === id ? true : false, //to keep unique
      };
    });
    setradios(updatedCheck);
    updateHistory();
  };

  // updating text
  const updateText = (e, id) => {
    const text = e.target.value;

    const updatedText = radios.map((check) => {
      return {
        ...check,
        text: check.id === id ? text : check.text,
      };
    });
    setradios(updatedText);
    e.target.onblur = () =>
      updateHistory({
        id: elementId,
      });
  };

  // < ----- THEN RENDERING OUTPUT----- >
  // rendering html

  useEffect(() => {
    if (radios.length) {
      radioContainer.innerHTML = "";
      radios.forEach((obj) => {
        const row = `
      <div class="radio-row">
        <div class="radio ${obj.checked && "checked"}">
          <svg width="${fontSize}" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style="
          fill: ${properties.color};
          ">
            <path d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z" fill="inherit"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z" fill="inherit"></path>
          </svg>  
        </div>
        <span class="label" style="color:${textColor};">${obj.text}</span>`;
        radioContainer.innerHTML += row;
      });
      radioRows = radioContainer.querySelectorAll(".radio-row");
      radioTexts = radioContainer.querySelectorAll(".label");
      useSetLayerHeight($(elementLayer));
    }
  }, [radios]);

  return (
    <Box className="radio-container">
      <Control
        heading="Radio List"
        className="radioProperty"
        divider={false}
        isCollapse={true}
        expanded={true}
      >
        {/* Preview for radios */}
        {radios.map((checkRow, i) => {
          return (
            <div className="radio-row" key={i}>
              <div
                onClick={(e) => toggleCheck(e, checkRow.id)}
                className={`radio ${checkRow.checked && "checked"}`}
              >
                {checkRow.checked ? (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <g>
                      <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-3a5 5 0 1 1 0-10 5 5 0 0 1 0 10z" />{" "}
                    </g>
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <title>ionicons-v5-e</title>
                    <path
                      d="M448,256c0-106-86-192-192-192S64,150,64,256s86,192,192,192S448,362,448,256Z"
                      style={{
                        fill: "none",
                        stroke: "#000",
                        strokeMiterlimit: 10,
                        strokeWidth: 32,
                      }}
                    />
                  </svg>
                )}
              </div>
              <input
                onChange={(e) => updateText(e, checkRow.id)}
                className="label"
                style={{ marginLeft: "5px" }}
                value={checkRow.text}
              />
              <IconButton
                size="small"
                className="removeIcon"
                disabled={radios.length === 1}
                onClick={() => removeRow(checkRow.id)}
              >
                <IoMdRemoveCircleOutline size={15} />
              </IconButton>
            </div>
          );
        })}
        <Grid container alignItems="center" className="radio-row addItem">
          <Grid item xs={9}>
            <input
              className="addItem-input"
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Add Row"
            />
          </Grid>
          <Grid item xs={3} className="text-right">
            <Button
              className="addNew-btn"
              size="small"
              variant="contained"
              disabled={!text.length}
              onClick={addRow}
            >
              <IoMdAdd size="20px" />
            </Button>
          </Grid>
        </Grid>
      </Control>

      {/* Radio properties */}
      <Control
        isCollapse={true}
        expanded={false}
        heading="Styles"
        className="radioProperty"
        divider={false}
      >
        <Row
          label="Radio Color"
          element={
            <input
              type="color"
              className="color"
              name="color"
              value={properties.color}
              onInput={inputHandler}
            />
          }
        />
        <Row
          label="Text Color"
          element={
            <input
              type="color"
              className="color"
              name="color"
              value={textColor}
              onInput={textColorHandler}
            />
          }
        />
      </Control>
    </Box>
  );
};

export default Radio;
