import React from "react";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  color: expand ? theme.palette.primary.main : theme.palette.text.secondary,
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default React.memo(ExpandMore);
