import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Slider,
  Button,
  TextareaAutosize,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { Box } from "@mui/material";
import { IoCloudUpload, IoTrashBin, IoAddCircle } from "react-icons/io5";
import { MdExpandMore } from "react-icons/md";
import Control from "./Control";
import ChooseAsset from "src/components/dialogs/ChooseAsset";
import {
  convertHexToRgba,
  convertRgbAToHexAplha,
  findComponentType,
} from "src/services/editor.service";
import SelectBox from "src/components/ui/formElements/SelectBox";

import "src/styles/properties.scss";
import { useSelector } from "react-redux";
import Row from "src/components/properties/Row";
import { IoMdAdd } from "react-icons/io";
import AssetUpload from "./AssetUpload";
import Select_Box from "src/components/common/Select_Box";
import Check_Box from "src/components/common/Check_Box";
import { csRenderFunctional, getDimenssions } from "../editorLogics/components";

const Flipster = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDialog = () => {
    setIsOpen(!isOpen);
  };

  const { targetDevice } = useSelector(
    (state) => state.presentations.presentation
  );
  const deviceDimensions = getDimenssions(props.component, targetDevice);

  // to close an expanded accordian
  const getRandom = () => Math.ceil(Math.random() * 1000 * Math.random());

  const [accordian, setaccordian] = useState(getRandom());
  const [src, setSrc] = useState("");
  // references
  const carouselImage =
    "https://createmart-seed-folder.s3.amazonaws.com/placeholders/carousel-comp/cs-default-carousel-image-01.jpg";

  const { elementId, updateHistory, undoRedoChange } = props;
  const flipster = document.getElementById(elementId);
  const isCoverflow =
    [...flipster.classList]
      .find((c) => c.includes("cs-"))
      .split("cs-")
      .at(1) === "coverflow";

  const targetElem = flipster.querySelector(`li:nth-child(${accordian + 1})`);

  const [items, setitems] = useState([]);
  const [carouselStyles, setcarouselStyles] = useState({
    textAlignment: "flex-end",
    buttons: false,
    buttonStyles: {
      color: "#ffffff",
      width: "16px",
      strokeWidth: "3px",
    },
    overlayColor: "#000000",
    overlayOpacity: 50,
  });
  // Getting Element's configurations
  useEffect(() => {
    getDOMData();
    // setaccordian(getRandom());
  }, [elementId, undoRedoChange]);

  const getDOMData = () => {
    setitems([]);
    const items = flipster.querySelectorAll("li");
    const controlButton = flipster.querySelector(".flipster__button");
    const textContainer = flipster.querySelector(".subtitle");
    // Getting stylings
    const isHaveButtons =
      controlButton !== null && controlButton.style.display !== "none";
    const carouselStyle = {
      textAlignment: textContainer
        ? getComputedStyle(textContainer).justifyContent
        : "flex-end",
      buttons: isHaveButtons,
      buttonStyles: {},
    };
    // Getting list data
    items.forEach((el) => {
      let text = el.querySelector(".text").innerHTML;
      text = text.replaceAll("<br>", "\r\n");
      const img = el.querySelector("img")?.src;

      if (isCoverflow) {
        // getting data
        setitems((prev) => [...prev, { text, img }]);
      } else {
        // getting data
        const overlayBackground = convertRgbAToHexAplha(
          getComputedStyle(textContainer).backgroundColor
        );
        const heading = el.querySelector(".heading").innerHTML;
        setitems((prev) => [...prev, { text, heading, img }]);
        // getting carousel overlay styling
        carouselStyle.overlayColor = overlayBackground.hex;
        carouselStyle.overlayOpacity = overlayBackground.aplha * 120;
      }
    });

    // getting button color
    if (controlButton) {
      const btnSvg = controlButton.querySelector("svg");
      const widthValue = Math.round(
        getComputedStyle(btnSvg).getPropertyValue("width").split("px")[0]
      );
      const computedColor = getComputedStyle(btnSvg).getPropertyValue("color");
      const buttonStyles = {
        color: convertRgbAToHexAplha(computedColor).hex,
        strokeWidth: getComputedStyle(btnSvg).getPropertyValue("stroke-width"),
        width: widthValue + "px",
        buttons: true,
      };
      carouselStyle.buttonStyles = buttonStyles;
    }

    // setting styles to state
    setcarouselStyles((prev) => ({
      ...prev,
      ...carouselStyle,
      buttonStyles: { ...prev.buttonStyles, ...carouselStyle.buttonStyles },
    }));
  };

  //to open accordian and close others
  const accordianHandler = (index) => {
    if (index === accordian) {
      // to close accordian
      setaccordian(getRandom());
    } else setaccordian(index);
  };

  //showing selected accordian element
  useEffect(() => {
    if (targetElem) {
      targetElem.onclick = (e) => e.stopPropagation();
      targetElem.click();
    }
  }, [accordian]);

  const inputHandler = (e, currentIndex) => {
    const { name, value } = e.target;
    // making copy of array
    const temp_state = items.slice();
    temp_state[currentIndex][name] = value;
    // then setting to state
    setitems(temp_state);
    // updating to DOM
    const para = targetElem.querySelector(`.${name}`);
    para.innerText = value;
  };

  // Updating image to DOM and State
  useEffect(() => {
    if (src) {
      const temp_state = items.slice();
      temp_state[accordian].img = src;
      const img = targetElem.querySelector("img");
      img.dataset.src = src;
      img.src = src;
      flipster.querySelector("ul").style.height = "100%";
      setitems(temp_state);
    }
  }, [src]);

  useEffect(() => {
    flipster.dataset.buttonStyles = JSON.stringify(carouselStyles.buttonStyles);
  }, [carouselStyles.buttonStyles]);

  //adding item
  const addItem = () => {
    const temp_state = items.slice();
    temp_state.push({ text: "Text", img: carouselImage });
    setitems(temp_state);
    const prevFirstLi = flipster.querySelector("li");
    const liToAppend = prevFirstLi.cloneNode(true);
    const text = liToAppend.querySelector(".text");
    const heading = liToAppend.querySelector("heading");
    const img = liToAppend.querySelector("img");
    if (text) text.innerText = "New Text";
    if (heading) heading.innerText = "Text";
    if (img) img.src = carouselImage;
    flipster.querySelector("ul").append(liToAppend);
    getDOMData();
    applyFlipster();
    updateHistory();
  };

  //deleting item
  const deleteItem = (i) => {
    const temp_state = items.slice();
    temp_state.splice(i, 1);
    targetElem.remove();
    setaccordian(null);
    setitems(temp_state);
    applyFlipster();
    getDOMData();
    updateHistory();
  };

  const applyFlipster = () => {
    const element = document.getElementById(elementId);
    csRenderFunctional(element);
  };

  let colorTimeout;
  const carouselStylesHandler = (e) => {
    let { name, value, checked } = e.target;

    clearTimeout(colorTimeout);
    const subtitle = flipster.querySelectorAll(".subtitle");

    // setting buttons styles
    let buttonPosition;
    if (name == "width" || name === "strokeWidth" || name === "color") {
      const controlButtons = flipster.querySelectorAll(".flipster__button");
      controlButtons.forEach((el) => {
        el.querySelector("svg").style[name] = value;
        if (name === "width") {
          buttonPosition = `calc(50% - ${el.offsetHeight / 2}px)`;
          el.style.top = buttonPosition;
        }
      });
      const setButtonsStyles = () =>
        setcarouselStyles((prev) => ({
          ...prev,
          buttonStyles: {
            ...prev.buttonStyles,
            [name]: value,
            buttonPosition,
          },
        }));
      if (name === "color") {
        // to prevent state from lagging
        colorTimeout = setTimeout(() => {
          setButtonsStyles();
        }, 200);
      } else {
        setButtonsStyles();
      }
    } else if (name === "alignment") {
      subtitle.forEach((el) => (el.style.justifyContent = value));
      setcarouselStyles((prev) => ({ ...prev, textAlignment: value }));
      // OVERLAY STYLES
    } else if (name === "overlayColor") {
      const background = convertHexToRgba(
        value,
        carouselStyles.overlayOpacity / 120
      );
      subtitle.forEach((el) => (el.style.background = background));
      colorTimeout = setTimeout(() => {
        setcarouselStyles((prev) => ({ ...prev, overlayColor: value }));
      }, 200);
    } else if (name === "overlayOpacity") {
      const background = convertHexToRgba(
        carouselStyles.overlayColor,
        value / 120
      );
      subtitle.forEach((el) => (el.style.background = background));
      setcarouselStyles((prev) => ({ ...prev, overlayOpacity: value }));
    } else if (name === "buttons") {
      const updateButtons = (add = true) => {
        const buttons = flipster.querySelectorAll(".flipster__button");
        if (buttons.length) {
          const action = add ? "" : "none";
          buttons.forEach((btn) => (btn.style.display = action));
        } else {
          if (add) {
            applyFlipster();
          }
        }
      };
      if (checked) {
        flipster.setAttribute("data-buttons", true);
        updateButtons(true);
      } else {
        flipster.removeAttribute("data-buttons");
        flipster.removeAttribute("data-button-styles");
        updateButtons(false);
      }
      setcarouselStyles((prev) => ({ ...prev, [name]: checked }));
      updateHistory();
    }
  };

  const updateBackground = (src) => {
    const temp_state = items.slice();
    temp_state[accordian].img = src;
    const img = targetElem.querySelector("img");
    img.dataset.src = src;
    img.src = src;
    flipster.querySelector("ul").style.height = "100%";
    setitems(temp_state);
    updateHistory();
  };

  const updateTextHistory = () => {
    updateHistory({
      id: elementId,
    });
  };

  return (
    <>
      <Control
        heading={`${props.heading} Items`}
        className="flipster-property"
        isCollapse={true}
        divider={false}
        expanded={true}
      >
        <Box className="borderShow">
          {items.map((data, i) => {
            const images = flipster.querySelectorAll("li img");

            return (
              <Accordion
                key={i}
                expanded={i === accordian}
                onChange={() => accordianHandler(i)}
                className="accordian"
              >
                <AccordionSummary
                  expandIcon={<MdExpandMore />}
                  className="accordian_heading"
                  classes={{ content: "accordian_content" }}
                >
                  <Typography>{data.text}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {!isCoverflow && (
                    <input
                      name="heading"
                      sx={{ mb: 2 }}
                      placeholder="Title"
                      value={data.heading}
                      onChange={(e) => inputHandler(e, i)}
                      onBlur={updateTextHistory}
                    />
                  )}
                  <TextareaAutosize
                    name="text"
                    placeholder="Description"
                    value={data.text}
                    className="textArea"
                    minRows={3}
                    maxRows={8}
                    onChange={(e) => inputHandler(e, i)}
                    onBlur={updateTextHistory}
                  />
                  <AssetUpload
                    element={images[accordian]}
                    updateHistory={updateHistory}
                  />
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Button
                      startIcon={<IoTrashBin size={12} className="icon" />}
                      className="delete-icon"
                      fullWidth
                      color="warning"
                      onClick={() => deleteItem(i)}
                      disabled={items.length <= 3}
                    >
                      Delete
                    </Button>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Box>
        <Box className="global-btn">
          <Button variant="contained" onClick={addItem}>
            Add Item <IoMdAdd className="icon" size="18px" />
          </Button>
        </Box>
      </Control>

      {/* Carousel Styles */}
      {!isCoverflow && (
        <Control
          heading={`${props.heading} Theme`}
          className="flipster-property"
          isCollapse={true}
          divider={false}
          expanded={false}
        >
          {/* Overlay control */}

          <Row
            label="Overlay Color"
            element={
              <input
                type="color"
                name="overlayColor"
                className="color"
                value={carouselStyles.overlayColor}
                onChange={carouselStylesHandler}
              />
            }
          />

          <Row
            label="Overlay Opacity"
            element={
              <Slider
                name="overlayOpacity"
                className="slider"
                size="small"
                valueLabelDisplay="auto"
                value={carouselStyles.overlayOpacity}
                max={100}
                onChange={carouselStylesHandler}
              />
            }
          />

          {/* Text alignment */}
          <Row
            label="Text Alignment"
            element={
              <Select_Box
                name="alignment"
                onChange={carouselStylesHandler}
                value={carouselStyles.textAlignment}
                options={[
                  { label: "Top", value: "flex-start" },
                  { label: "Middle", value: "center" },
                  { label: "Bottom", value: "flex-end" },
                ]}
              />
            }
          />
        </Control>
      )}

      {/*button styles */}
      <Control
        heading="Side Arrows"
        className="flipster-property"
        isCollapse={true}
        divider={false}
        expanded={false}
      >
        <Row
          label="Enable"
          component="h3"
          element={
            <Check_Box
              name="buttons"
              color="secondary"
              size="medium"
              checked={carouselStyles.buttons}
              onChange={carouselStylesHandler}
            />
          }
        />
        {carouselStyles.buttons && (
          <>
            <Row
              label="Color"
              element={
                <input
                  type="color"
                  name="color"
                  className="color"
                  value={carouselStyles.buttonStyles.color}
                  onChange={carouselStylesHandler}
                />
              }
            />

            <Row
              label="Thickness"
              element={
                <Select_Box
                  name="strokeWidth"
                  onChange={carouselStylesHandler}
                  value={carouselStyles.buttonStyles.strokeWidth}
                  options={[
                    { label: "normal", value: "3px" },
                    { label: "Bold", value: "5px" },
                  ]}
                />
              }
            />

            <Row
              label="Size"
              element={
                <Select_Box
                  name="width"
                  onChange={carouselStylesHandler}
                  value={carouselStyles.buttonStyles.width}
                  options={[
                    { label: "small", value: "16px" },
                    { label: "normal", value: "32px" },
                    { label: "large", value: "48px" },
                  ]}
                />
              }
            />
          </>
        )}
      </Control>
      <ChooseAsset
        setSrc={updateBackground}
        updateHistory={updateHistory}
        assetFormat="image"
        show={isOpen}
        close={toggleDialog}
      />
    </>
  );
};

export default Flipster;
