import React, { useEffect, useState } from "react";
import {
  IconButton,
  Typography,
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { IoMdAdd, IoMdRemoveCircleOutline } from "react-icons/io";
import Control from "./Control";
import Row from "src/components/properties/Row";
import { attachlisteners } from "../microsites/renderMicrosite";
import { useElementStatus } from "src/hooks/editor/element.controller";
import { useEditorHeirarchy } from "src/hooks/editor/heirarchy.controller";
import { HISTORY_ACTIONS } from "src/data/contants";
import { applyPadding } from "../editorLogics/components";
const ColumnRowPropery = (props) => {
  const { activeElement } = useElementStatus();
  const { requestHierarchyUpdate } = useEditorHeirarchy();

  const [columns, setcolumns] = useState([]);
  const [colSize, setcolSize] = useState({
    xs: "col-xs-12",
    md: "col-md-6",
  });

  const { elementId, updateHistory, undoRedoChange } = props;
  const element = document.getElementById(elementId);

  // Getting Element's data
  useEffect(() => {
    const columns = [];
    const gridElements = element.querySelectorAll(".cs-col");
    gridElements.forEach((el, i) => {
      columns.push({ name: `Column ${i + 1}` });
    });
    setcolumns(columns);
  }, [elementId, undoRedoChange]);

  // To delete item
  const removeItem = (index) => {
    const availableItems = JSON.parse(JSON.stringify(columns));
    const updatedItems = availableItems.filter((_, i) => i !== index);
    const items = element.querySelectorAll(".cs-col");
    const elementToRemove = items[index];
    element.removeChild(elementToRemove);
    setcolumns(updatedItems);
    updateHistory();
  };

  const addItem = () => {
    const layer = element.closest(".cs-layer");
    const items = element.querySelectorAll(".cs-grid-item");
    const itemsLength = items.length;
    let newItemWidth = 100 / (itemsLength + 1);
    // reducing other items width for new item space
    items.forEach((el) => {
      const itemWidth = parseFloat(el.style.width);
      const itemShare = (itemWidth * newItemWidth) / 100;
      el.style.width = itemWidth - itemShare + "%";
    });
    const id = (Math.random() + 1).toString(36).substring(7);
    const item = `<div class="cs-col col-appendable ${colSize.md} ${colSize.xs} editable" id="col-${id}"></div>`;
    element.insertAdjacentHTML("beforeend", item);
    applyPadding(element.querySelector(".cs-col:last-child"));
    attachlisteners({
      actionState: 0,
      activeElement,
      requestHierarchyUpdate,
      element: layer,
      updateHistory,
    });
    requestHierarchyUpdate();
    setcolumns((prev) => [...prev, { name: `Column ${itemsLength + 1}` }]);
    updateHistory();
  };

  const getColumnFromIndex = (index) => {
    const column = element.querySelectorAll(".cs-col")[index];
    return column;
  };
  //   to navigate on clicked column property
  const activeColumnProperty = (index) => {
    const clickedButtonColumn = getColumnFromIndex(index);
    deHighlightElement();
    clickedButtonColumn?.click();
  };
  //   For highliting
  const highlightElement = (index) => {
    const hoveringColumn = getColumnFromIndex(index);
    hoveringColumn.classList.add("hover");
  };
  const deHighlightElement = () => {
    const highlightedColumn = element.querySelector(".cs-col.hover");
    highlightedColumn?.classList.remove("hover");
  };

  const columnWidthHandler = (e) => {
    const { name, value } = e.target;
    setcolSize((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Box className="column-property">
      <Control
        heading={"Row Properites"}
        className="bgControl"
        headingSize={"h1"}
        divider={false}
        isCollapse={true}
        expanded
      >
        {columns.map((item, i) => {
          return (
            <Box
              key={i}
              onMouseOver={() => {
                highlightElement(i);
              }}
              onMouseLeave={deHighlightElement}
            >
              <Row
                leftsx={10}
                rightsx={2}
                button={
                  <Button
                    fullWidth
                    sx={{ justifyContent: "flex-start" }}
                    onClick={() => activeColumnProperty(i)}
                  >
                    <Typography>{item.name}</Typography>
                  </Button>
                }
                element={
                  <IconButton
                    size="small"
                    className="removeIcon"
                    disabled={columns.length <= 1}
                    onClick={() => removeItem(i)}
                  >
                    <IoMdRemoveCircleOutline size={15} />
                  </IconButton>
                }
              />
            </Box>
          );
        })}
        <Box className="add-column-section">
          <Typography variant="h3">New Column Media Query</Typography>
          <Row
            label="Mobile screen"
            element={
              <FormControl>
                <Select
                  name="xs"
                  value={colSize.xs}
                  onChange={columnWidthHandler}
                >
                  <MenuItem value="col-xs-1">1</MenuItem>
                  <MenuItem value="col-xs-2">2</MenuItem>
                  <MenuItem value="col-xs-3">3</MenuItem>
                  <MenuItem value="col-xs-4">4</MenuItem>
                  <MenuItem value="col-xs-5">5</MenuItem>
                  <MenuItem value="col-xs-6">6</MenuItem>
                  <MenuItem value="col-xs-7">7</MenuItem>
                  <MenuItem value="col-xs-8">8</MenuItem>
                  <MenuItem value="col-xs-9">9</MenuItem>
                  <MenuItem value="col-xs-10">10</MenuItem>
                  <MenuItem value="col-xs-11">11</MenuItem>
                  <MenuItem value="col-xs-12">12</MenuItem>
                </Select>
              </FormControl>
            }
          />
          <Row
            label="Desktop screen"
            element={
              <FormControl>
                <Select
                  name="md"
                  value={colSize.md}
                  onChange={columnWidthHandler}
                >
                  <MenuItem value="col-md-1">1</MenuItem>
                  <MenuItem value="col-md-2">2</MenuItem>
                  <MenuItem value="col-md-3">3</MenuItem>
                  <MenuItem value="col-md-4">4</MenuItem>
                  <MenuItem value="col-md-5">5</MenuItem>
                  <MenuItem value="col-md-6">6</MenuItem>
                  <MenuItem value="col-md-7">7</MenuItem>
                  <MenuItem value="col-md-8">8</MenuItem>
                  <MenuItem value="col-md-9">9</MenuItem>
                  <MenuItem value="col-md-10">10</MenuItem>
                  <MenuItem value="col-md-11">11</MenuItem>
                  <MenuItem value="col-md-12">12</MenuItem>
                </Select>
              </FormControl>
            }
          />
          <Box className="global-btn">
            <Button
              fullWidth
              variant="contained"
              onClick={addItem}
              disabled={columns.length >= 12}
            >
              Add Column <IoMdAdd className="icon" size="18px" />
            </Button>
          </Box>
        </Box>
      </Control>
    </Box>
  );
};

export default ColumnRowPropery;
