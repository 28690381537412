import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  checkIfReviewed,
  fetchReviewDetail,
  review,
} from "src/store/actions/reviews";
import Preview from "src/components/dialogs/AssetsPreview";
import Details from "../reviews/Details";

const ReviewDetail = () => {
  const type = "assets";
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [isReviewed, setIsReviewed] = useState(true);
  const [preview, setPreview] = useState(null);
  const [format, setFormat] = useState("mp4");
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [isLoadingDeclined, setIsLoadingDeclined] = useState(false);

  const dispatch = useDispatch();
  const { reviewDetail } = useSelector((state) => state.reviews);

  useEffect(() => {
    dispatch(fetchReviewDetail({ id, type })).then((res) => {
      if (res?.payload?.assetsId) {
        setPreview(res?.payload?.assetsId);
        let url = res?.payload?.assetsId.URL;
        const ext = url.split(".").at(-1);
        setFormat(ext);
      }
    });

    dispatch(checkIfReviewed({ id, type })).then((res) => {
      setIsReviewed(res.payload);
    });
  }, []);

  const previewAsset = () => {
    setIsOpen(true);
  };

  const versionPreview = (versionId) => {
    setIsOpen(true);
  };

  const approveOrDecline = (status, option) => {
    option  == 'Approve'?setIsLoadingApprove(true):setIsLoadingDeclined(true);
    const data = { status };
    dispatch(review({ id, data, type })).then((res) =>{
      option  == 'Approve'?
      setIsLoadingApprove(false):
      setIsLoadingDeclined(false)
      setIsReviewed(true);
    })
    .catch(() => setIsLoading(false));
    
  };

  return (
    <>
      <Preview
        isReview
        show={isOpen}
        src={preview?.URL}
        close={() => {
          setIsOpen(false);
        }}
        isReviewed={isReviewed}
        format={format}
        reviewAsset={approveOrDecline}
        isLoadingApprove={isLoadingApprove}
        isLoadingDeclined={isLoadingDeclined}
      />
      <Details
        disableVersion
        preview={previewAsset}
        detail={reviewDetail.assetsId}
        actionPreview={versionPreview}
        review={reviewDetail}
      />
    </>
  );
};

export default ReviewDetail;
