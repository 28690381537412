import { useContext, useEffect, useCallback, useRef } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
export function useBlocker(blocker, when = true) {
  const { navigator } = useContext(NavigationContext);
  const firstTimeSaved = useRef(null)

  useEffect(() => {
    if (!when) return;
    if (!firstTimeSaved.current) {
      firstTimeSaved.current = true
      // console.warn("Unsaved changes will be lost without confirmation because of not have stable version for navigator.block or usePrompt hook in react-router-dom@v6.0.0.")
      blocker()
    }
    if (navigator.block) {
      const unblock = navigator.block((tx) => {
        const autoUnblockingTx = {
          ...tx,
          retry() {
            unblock();
            tx.retry();
          },
        };

        blocker(autoUnblockingTx);
      });
      return unblock;
    }
  }, [navigator, blocker, when]);
}
export function usePrompt(message, when = true, callback) {
  const blocker = useCallback(
    (tx) => {
      if (tx) {
        const goBack = tx.retry;
        callback(goBack);
      } else {
        callback() // warn user to save his data for the first
      }
    },
    [message, when]
  );

  useBlocker(blocker, when);
}
