import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Editor from "src/components/editor/presentation/Editor";
import { getSlide, saveSlide } from "src/store/actions/presentations";
import AccessControl from "../../components/presentations/AccessControl";
import { getNewAssetsAndHTML, updateDomAssetsPath } from "src/services/editor.service";
import $ from "jquery";

const PresentationEditor = () => {
  const dispatch = useDispatch();
  const { id: presentationId, slideId } = useParams();
  const [loading, setLoading] = useState(true);

  // Fetching Slide's Data
  useEffect(async () => {
    await dispatch(getSlide({ presentationId, slideId }));
    setLoading(false);
  }, []);

  // Presentation's Data
  const {
    editorHtmlData,
    presentationSlides: slides,
    presentation,
  } = useSelector((state) => state.presentations);
  const { bucketURL, sourceTemplate, name: presentationName } = presentation;

  const activeSlide = slides.find((slide) => slide.id == slideId);
  const baseUrl = bucketURL + "/" + sourceTemplate + "/";

  //move to s3 presentation bucket url
  const moveToUrl = (elementType, fileName) => {
    return `assets/slides/${activeSlide?.fileId}/${elementType}/${fileName}`;
  };
  // Saving Slide's Data
  const saveSlideData = (saveSlideDiv, image, thumbnail) => {
    const {
      assets,
      defaultAssets,
      uploadedAssets,
      HTML: assetsHtml,
    } = getNewAssetsAndHTML(saveSlideDiv, moveToUrl, baseUrl);
    
    
    const data = {
      HTML: assetsHtml,
      thumbnail,
      assets,
      defaultAssets,
      image,
    };

    return dispatch(
      saveSlide({
        presentationId,
        slideId,
        data,
      })
    ).then(()=> {
      //once slide is saved then update the real dom by changing the asset path from general asset to s3 asset
      updateDomAssetsPath(uploadedAssets)
    })
  };

  return (
    <AccessControl component="edit">
      {activeSlide ? (
        <Editor
          type="presentation"
          component="presentation"
          heading={`${presentationName} / ${activeSlide?.name}`}
          editorHtmlData={editorHtmlData}
          isLoading={loading}
          assetsUrlPinPoint="assets/slides"
          assetsBaseUrl={baseUrl}
          onSave={saveSlideData}
        />
      ) : (
        <>No Slide Data were found !</>
      )}
    </AccessControl>
  );
};
export default React.memo(PresentationEditor);
