import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../store/actions/auth";
import { Box, Grid, Typography } from "@mui/material";
import LogoLarge from "../assets/images/logo.png";
import LoginForm from "src/components/login/LoginForm";
import Logo from "../components/ui/Logo";
import Copyright from "../components/ui/Copyright";
import personImage from "../assets/images/login-bg-2.png";
import "../styles/login.scss";
import { useMediaQuery } from "@mui/material";
import Hidden from "src/components/common/Hidden";
const Login = () => {
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { state: locationState } = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      if (locationState && locationState.visitedLocation) {
        navigate(locationState.visitedLocation);
      } else {
        navigate("/");
      }
    }
  }, [isLoggedIn]);


  const hiddenSx = useMediaQuery("(max-width:600px)");
  return (
    <Grid container className="login-page" spacing={2}>
      <Hidden mobile>
        <Grid item md={7} sm={12}>
          <Box className="login-left">
            <Box id="login-background"></Box>
            <img src={personImage} className="person-image" />
          </Box>
        </Grid>
      </Hidden>
      <Grid
        item
        md={5}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Box className="login-form-container">
          <Logo logoSrc={LogoLarge} />
          <Typography component="h1" variant="h1" className="login-heading">
            Welcome To CreateSmart
          </Typography>
          <LoginForm />
          <Hidden mobile>
            <Copyright />
          </Hidden>
        </Box>
      </Grid>
    </Grid>
  );
};
export default Login;
