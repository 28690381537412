import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../interfaces/api.interface";
import axios from "axios";


const headers = axios.defaults.headers.common["X-CSCAPI-KEY"] = `M0NhajBpRkZXSjJtdVp5M1NiSTZ5OFYxckZvbmd5aEpTM2lFRVhYSQ==`;
const call = (api,data)=>{
  return axios[api.method](api.url, data, { headers })
  .then((response) => {
    return response;
  })
}


export const getCountries = createAsyncThunk(
  "getCountries",
  async (_, thunkAPI) => {
    try {
      const response = await call(api.user.getCountries(),null);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


export const getStates = createAsyncThunk(
  "getStates",
  async (data, thunkAPI) => {
    try {
      const response = await call(api.user.getStates(data), null);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getCities = createAsyncThunk(
  "getCities",
  async (data, thunkAPI) => {
    try {
      const response = await call(api.user.getCities(data), null);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
