import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

//icons
import { VscListUnordered } from "react-icons/vsc";
import { IoEye } from "react-icons/io5";

//components
import Review from "src/components/reviews/Review";
import Preview from "src/components/dialogs/AssetsPreview";
import ReviewItem from "src/components/reviews/ReviewItem";

//utils
import { getThumbnailByFormat, posterOfVideo } from "src/services/utils";
import { checkIfReviewed, review } from "src/store/actions/reviews";

const AssetReviewTable = () => {
  const type = "assets";
  const reviewIdRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [preview, setPreview] = useState(null);
  const [isReviewed, setIsReviewed] = useState(true);
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [isLoadingDeclined, setIsLoadingDeclined] = useState(false);

  const { reviews, count, loadingMsg } = useSelector((state) => state.reviews);

  const handleClose = () => {
    setIsOpen(false);
    setIsReviewed(true);
  };

  const approveOrDecline = (status,option) => {
    option  == 'Approve'?setIsLoadingApprove(true):setIsLoadingDeclined(true);
    if (reviewIdRef.current) {
      const data = { status };
      dispatch(review({ id: reviewIdRef.current, data, type })).then((res) =>{
        option  == 'Approve'?
        setIsLoadingApprove(false):
        setIsLoadingDeclined(false)
        setIsReviewed(true);
      })
      .catch(() => setIsLoading(false));;
    }
  };

  const dropDownData = [
    {
      label: "Preview",
      icon: <IoEye />,
      onClick: (reviewId, data) => {
        dispatch(checkIfReviewed({ id: reviewId, type })).then((res) => {
          setIsReviewed(res.payload);
        });
        setIsOpen(true);
        setPreview({
          src: data.URL,
          format: data.format,
        });
        reviewIdRef.current = reviewId;
      },
    },
    {
      label: "Details",
      icon: <VscListUnordered />,
      onClick: (id) => {
        navigate(`/reviews/${id}/${type}`);
      },
    },
  ];
  return (
    <>
      <Preview
        show={isOpen}
        close={handleClose}
        src={preview?.src}
        format={preview?.format}
        isReviewed={isReviewed}
        reviewAsset={approveOrDecline}
        isReview
        isLoadingApprove={isLoadingApprove}
        isLoadingDeclined={isLoadingDeclined}
      />
      <Review
        type={type}
        subtitle="Assets Reviews"
        count={count}
        loadingMsg={loadingMsg}
      >
        {reviews.map((review) => {
          const detail = review.assetsId;
          if (!detail) {
            return <></>;
          }
          const {
            _id,
            title,
            updatedAt,
            format,
            URL,
            createdBy: { firstName, lastName },
          } = detail;
          const data = {
            _id,
            URL,
            format,
          };
          const thumbnail = posterOfVideo(review.assetsId.URL)
          return (
            <ReviewItem
              data={data}
              title={title}
              status={review.status}
              key={review._id}
              updatedAt={updatedAt}
              thumbnail={thumbnail}
              reviewId={review._id}
              dropDown={dropDownData}
              ownerName={`${firstName} ${lastName}`}
            />
          );
        })}
      </Review>
    </>
  );
};

export default AssetReviewTable;
