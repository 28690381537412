import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

//Icons
import {
  IoCreate,
  IoCloudUploadOutline,
  IoShare,
  IoTrash,
  IoOpen,
} from "react-icons/io5";
import { MdCloudUpload, MdFileDownload } from "react-icons/md";
import { IoIosDownload } from "react-icons/io";
import { BsTablet } from "react-icons/bs";
import { AiFillLike } from "react-icons/ai";

//APIs
import { cloneEmail, downloadSource } from "src/store/actions/emails";
import { setPreviewEmail } from "src/store/reducers/emails-slice";

import ProjectCard from "src/components/common/ProjectCard";
import CardControls from "src/components/emails/CardControls";
import { useNavigate } from "react-router-dom";

const EmailCard = (props) => {
  const { email, showDetails, openDialog, role, setEmail } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { roles } = useSelector((state) => state.roles);

  const openPreviewHandler = useCallback((emailId) => {
    dispatch(setPreviewEmail({ emailId, isOpen: true }));
  }, []);

  const handleClone = useCallback((id) => {
    openDialog(id, "clone");
  }, []);

  const downloadHandler = useCallback((id) => {
    dispatch(downloadSource(id));
  }, []);

  //dropdown on card options
  const cardItemDropData = useCallback((role, status) => {
    const sendReview = {
      label: "Send for Review",
      icon: <AiFillLike />,
      onClick: (id) => {
        openDialog(id, "review");
      },
      permissionPath:'approvalProcess'
    };

    const cancelReview = {
      label: "Cancel Review",
      icon: <AiFillLike />,
      onClick: (id) => {
        openDialog(id, "cancelReview");
      },
      permissionPath: "approvalProcess"
    };

    const publish = {
      label: "Publish",
      icon: <IoOpen />,
      onClick: (id) => navigate(`/emails/${id}/publish`),
    };

    const dropData = [
      {
        label: "Share Email Template",
        icon: <IoShare />,
        onClick: (id) => {
          openDialog(id, "share");
        },
        permissionPath:'share'
      },
      {
        label: "Upload New Version",
        icon: <MdCloudUpload />,
        onClick: (id) => {
          openDialog(id, "uploadNewVersion");
        },
        permissionPath:'emailTemplates.versions'
      },
      {
        label: "Download Source",
        icon: <MdFileDownload />,
        onClick: downloadHandler,
        permissionPath:'emailTemplates.export.downloadSourceCode'
      },
      // {
      //   label: "Export",
      //   icon: <IoIosDownload />,
      //   onClick: (id) => {
      //     setEmail(email);
      //     openDialog(id, "export");
      //   },
      // },

      {
        label: "Delete",
        icon: <IoTrash />,
        onClick: (id) => {
          if (status === "reviewed" || status === "inReview") {
            openDialog(id, "reviewdelete");
          } else {
            openDialog(id, "delete");
          }
        },
      },
    ];

    if (status !== "reviewed") {
      if (status === "inReview") {
        dropData.splice(1, 1);
        dropData.unshift(cancelReview);
      } else {
        dropData.unshift(sendReview);
      }
    }
    if (status == "reviewed") {
      console.log(dropData,status);
        dropData.splice(1, 1);
       
      }

    // Publish Option Code
    // if (roles[role]?.publish) {
    //   dropData.push(publish);
    // }
    return dropData;
  }, []);

  return (
    <ProjectCard
      key={email._id}
      id={email._id}
      image={`${email.bucketURL}/${email.thumbnail}`}
      title={email.name}
      subtitle={`v${email.version} ${email.lang}`}
      description={email.description}
      status={email.status}
      onDoubleClick={() => navigate(`/email/${email._id}/edit`)}
      targetSystem={email.targetSystem}
      Controls={
        <CardControls
          id={email._id}
          status={email.status}
          cloneEmail={() => handleClone(email._id)}
          toggleDetails={() => showDetails(email)}
          dropDownData={cardItemDropData(role, email.status)}
          preview={() => openPreviewHandler(email._id)}
          role={role}
        />
      }
    />
  );
};
export default React.memo(EmailCard);
