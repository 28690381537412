import React from "react";
import { Typography } from "@mui/material";
const ToolItem = (props) => {
  return (
    <div
      className={`toolItem ${props.className}`}
      onClick={(e) => props.onItemClick(e, props.type, props.layout)}
    >
      <div className="preview">
        {props.icon}
        <Typography>{props.caption}</Typography>
      </div>
    </div>
  );
};
export default ToolItem;
