import { toJpeg } from "html-to-image";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import { csRenderFunctional } from "src/components/editor/editorLogics/components";
import { rotatable } from "src/components/editor/editorLogics/rotatable";
import { toDataURL } from "src/hooks/useUrlToBase64";
import { getRandomId } from "./utils";
import { useSetLayerHeight } from "src/components/editor/editorLogics/components";
import { HISTORY_ACTIONS } from "src/data/contants";
import { getElementData } from "src/hooks/editor/shared-logic";
export const covertStyleStrToObject = (strStyle) => {
  //return true;
  if (strStyle != null) {
    const style = {};
    strStyle.split(";").forEach((el) => {
      const [property, value] = el.split(":");
      if (!property) return;
      //helper function use in this file
      const formatStringToCamelCase = (str) => {
        const splitted = str.split("-");
        if (splitted.length === 1) return splitted[0];
        return (
          splitted[0] +
          splitted
            .slice(1)
            .map((word) => word[0].toUpperCase() + word.slice(1))
            .join("")
        );
      };
      const formattedProperty = property.trim();
      style[formattedProperty] = value.trim();
    });

    return style;
  }
};

// export const useShiftKey = () => {
//   document.addEventListener("keydown", function (e) {
//     if (e.key == "Shift") {
//       return false;
//     } else {
//       return true;
//     }
//   });
// };

export const findELementIndex = (array, attr, value) => {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
};
export const converObjectToStyleStr = (cssObject) => {
  let style = "";
  Object.keys(cssObject).map(function (key, index) {
    style += key + ":" + cssObject[key] + ";";
  });
  return style;
};

export const findElementType = (classes) => {
  function findObjectType(classes) {
    if (classes.includes("cs-")) {
      return classes;
    }
  }
  if (classes.includes(" ")) {
    return classes.split(" ").filter(findObjectType).toString();
  }
};

export const activeElement = (element, callback = () => {}) => {
  //make active to clicked element
  $(".editable").removeClass("active hover");
  element.addClass("active");
  callback();
};

export const addActiveClassToElement = (element, addClass = true) => {
  const removeClass = (element) => {
    element.classList.remove("active");
    element.classList.remove("hover");
    element.classList.remove("reset");
  };

  if (element.classList.contains("cs-slide")) {
    removeClass(element);
    element.querySelectorAll(".editable.active").forEach((el) => {
      removeClass(el);
    });
    element.querySelectorAll(".editable.hover").forEach((el) => {
      removeClass(el);
    });
  } else {
    const dropzone = element.closest(".cs-slide");
    removeClass(dropzone);
    dropzone.querySelectorAll(".editable.active").forEach((el) => {
      removeClass(el);
    });
    dropzone.querySelectorAll(".editable.hover").forEach((el) => {
      removeClass(el);
    });
  }

  element.classList.remove("reset");
  if (addClass) {
    element.classList.add("active");
  }
};

// export const useFormateCssProperty = (propertyVal, inputType) => {
//   if (inputType == "number") {
//     return propertyVal + "px";
//   } else {
//     return propertyVal;
//   }
// };

export const convertHexToRgba = (hex, aplha = 1) => {
  hex = hex.replace("#", "");
  // return hex;
  var aRgbHex = hex.match(/.{1,2}/g);
  let r = parseInt(aRgbHex[0], 16);
  let g = parseInt(aRgbHex[1], 16);
  let b = parseInt(aRgbHex[2], 16);
  let a = aplha;
  let aRgb = `rgba(${r},${g},${b},${a})`;
  return aRgb;
};

export const convertRgbAToHexAplha = (rgbaColor) => {
  const rgba = rgbaColor.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
  const hex = `#${(
    (1 << 24) +
    (parseInt(rgba[0]) << 16) +
    (parseInt(rgba[1]) << 8) +
    parseInt(rgba[2])
  )
    .toString(16)
    .slice(1)}`;

  const alpha = rgba[3];
  return {
    hex: hex,
    aplha: alpha,
  };
};

/*this function will set the hight of layer dynamically when layer
will be create or update layer height and width*/

export const setLayerPositionToCenter = (el) => {
  const layer = $(el);
  const mainWrapper = $("#mainWrapper");
  let mainWrapperTop = mainWrapper.scrollTop() / 2;

  const dropzone = $("#dropzone");
  let dropzoneWidth = dropzone.outerWidth() / 2;
  let dropzoneHeight = dropzone.outerHeight() / 2;

  let layerWidth = layer.outerWidth() / 2;
  let layerHeight = layer.outerHeight() / 2;

  let left = dropzoneWidth - layerWidth;
  let top = dropzoneHeight - layerHeight;

  layer.css({ left, top });
};

export const setLayerElementIds = (layer) => {
  layer.querySelectorAll("[class*=cs-], img, video, audio").forEach((element) => {
    if (!element.id) {
      element.id = getRandomId();
    }
  });
};

export const setDuplicateLayerId = (layer) => {
  // FUNCTION TO GENERATE DUPLICATED UNIQUE ID
  const applyUniqueId = (el) => {
    const elementId = $(el).attr("id");
    const randmNum = () => Math.ceil(Math.random() * 10000);
    let generatedId = elementId + "-duplicate-" + randmNum();
    // updating only number if id is already duplicated
    if (elementId && elementId?.includes("duplicate")) {
      const duplicatedId = elementId.split("-");
      duplicatedId[duplicatedId.length - 1] = randmNum();
      generatedId = duplicatedId.join("-");
    }
    $(el).attr("id", generatedId);
  };

  // UPDATING LAYER'S ID
  applyUniqueId(layer);

  // UPDATING EDITABLE CHILDREN'S ID'S
  const editableChilds = layer.find(".editable");
  editableChilds.each((i, el) => {
    applyUniqueId(el);
  });
};

// Test function

const test = (callback = () => {}) => {
  callback();
  console.log("hello test");
};

export const getLayersEditableChildren = (layer) => {
  return layer.querySelectorAll('[class*="cs-"]');
};

//return layer styles for state update
export const getLayerData = (csLayer) => {
  const csLayerStyle = csLayer.attr("style");
  const layerId = csLayer.attr("id");
  //get current layer childern
  const layerElements = [];
  csLayer.find("[class*=cs-]").each(function () {
    const element = $(this);
    //check if elment has style then read it else blank
    let elementStyle = element.attr("style");
    if (typeof elementStyle !== "undefined" && elementStyle !== false) {
      elementStyle = element.attr("style");
    } else {
      elementStyle = "";
    }

    const elementType = findElementType(element.attr("class"));
    const elementId = element.attr("id");
    layerElements.push({
      id: elementId,
      type: elementType,
      style: covertStyleStrToObject(elementStyle),
    });
  });
  return {
    layerId: layerId,
    layerStyle: covertStyleStrToObject(csLayerStyle),
    layerElements: layerElements,
  };
};
export const getFormattedValue = (key, value) => {
  if (key == "transform") {
    value = "rotate(" + value + "deg)";
  } else if (key == "background") {
    value = value;
  } else if (
    key == "z-index" ||
    key == "border" ||
    key == "borderColor" ||
    key == "borderRadius"
  ) {
    value = value;
  } else {
    value = value + "px";
  }
  return value;
};
export const useDeformattedValue = (key, value) => {
  if (key == "transform") {
    if (value.includes("deg")) {
      //if value in deg
      const start = value.indexOf("(") + 1;
      const end = value.indexOf("deg");
      value = value.slice(start, end);
    } else {
      //if value in deg
      const start = value.indexOf("(") + 1;
      const end = value.indexOf("rad");
      value = value.slice(start, end);
    }
  } else if (key == "border") {
    value = value;
  } else {
    value = value.replace("px", "");
  }
  return value;
};

// export const useFormattedStyle = (stylesObject) => {
//   let formattedStyle = {};
//   Object.keys(stylesObject).map(function (key, index) {
//     let value = stylesObject[key];
//     //formattedStyle[key] = value;
//     formattedStyle[key] = getFormattedValue(key, value);
//     //console.log(key, value);
//   });
//   return formattedStyle;
// };

export const useDeFormattedStyle = (stylesObject) => {
  let deFormattedStyle = {};
  if (stylesObject && Object.keys(stylesObject).length !== 0) {
    Object.keys(stylesObject).map(function (key, index) {
      let value = stylesObject[key];
      deFormattedStyle[key] = useDeformattedValue(key, value);
    });
  }
  return deFormattedStyle;
};
export const applyControlCss = (elementId, name, value) => {
  if (elementId) {
    const element = document.getElementById(elementId);
    element.style[name] = getFormattedValue(name, value);
    //console.log(name, getFormattedValue(name, value));
  }
};

let ctrlKeyPress = false;
let isDragging = false;
$(document).on("keydown", function (e) {
  if (e.ctrlKey) {
    ctrlKeyPress = true;
  } else {
    ctrlKeyPress = false;
  }
  isCanDrop(ctrlKeyPress);
});
$(document).on("keyup", function (e) {
  ctrlKeyPress = false;
  isCanDrop(ctrlKeyPress);
});

const isCanDrop = (ctrlKeyPress) => {
  if (ctrlKeyPress) {
    if ($(".active").hasClass("cs-layer")) {
      $(".cs-layer.active").addClass("canDrop");
    } else {
      $(".active").closest(".cs-layer").addClass("canDrop");
    }

    $(".container-component").addClass("highlight-drppable");
  } else {
    $(".cs-layer").removeClass("canDrop");
    $(".container-component").removeClass("highlight-drppable");
  }
};

//element droppable

let droppedEle;
export const makeElementDropable = (dropArea, callback = () => {}) => {
  dropArea.droppable({
    accept: ".canDrop",
    drop: function (event, ui) {
      var zoom = $("#dropzone").css("zoom");
      droppedEle = ui.draggable;
      dropArea = $(event.target);
      const droppedEleTop = droppedEle.offset().top;
      const droppedEleLeft = droppedEle.offset().left;

      const dropAreaTop = dropArea.offset().top;
      const dropAreaLeft = dropArea.offset().left;

      const setDropTop = droppedEleTop - dropAreaTop + "px";
      const setDropLeft = droppedEleLeft - dropAreaLeft + "px";

      dropArea.append(droppedEle);
      droppedEle.css({ top: setDropTop, left: setDropLeft });

      const containerLayerId = dropArea.closest(".cs-layer").attr("id");
      droppedEle.attr("data-container-layer", containerLayerId);
      callback();
      setTimeout(() => {
        $(".ui-droppable-hover").removeClass("ui-droppable-hover");
      }, 100);
    },
  });
};
// const convertPixelToPer = (csLayer) => {
//   const dropzone = $("#dropzone");
//   const dropzoneHeight = dropzone.height();
//   const dropzoneWidth = dropzone.width();
//   const dropzoneTop = dropzone.offset().top;
//   const dropzoneLeft = dropzone.offset().left;

//   const csLayerTop = csLayer.offset().top;
//   const csLayerLeft = csLayer.offset().left;

//   const finalLeft = csLayerLeft - dropzoneLeft;
//   const finalTop = csLayerTop - dropzoneTop;

//   let leftPer = (finalLeft / dropzoneWidth) * 100;
//   let topPer = (finalTop / dropzoneHeight) * 100;

//   leftPer = leftPer.toFixed() + "%";
//   topPer = topPer.toFixed() + "%";

//   csLayer.css({ top: topPer, left: leftPer });
// };
//layer draggable and resizeable
export const csDragResizeable = (
  el,
  zoom,
  updateHistory = () => {},
  onDropCallback = () => {},
  activeElement
) => {
  const element = $(el);
  makeElementDropable($(".cs-slide"), () => {
    onDropCallback();
  });
  $(".cs-slide").droppable("disable");
  var pointerX;
  var pointerY;
  var zoom = $("#dropzone").css("zoom");
  let initialStyleAttr = "";
  let startingparent = null;
  let startingDetails = null;

  element.draggable({
    containment: "parent",
    cancel: ".no-drag",
    start: function (evt, ui) {
      isDragging = true;

      var zoom = $("#dropzone").css("zoom");
      var canvasTop = element.parent().offset().top;
      var canvasLeft = element.parent().offset().left;

      var elementStyleTop = parseInt(element.css("top"));
      var elementStyleLeft = parseInt(element.css("left"));
      initialStyleAttr = element.attr("style");
      startingparent = element.get(0).parentElement;
      startingDetails = getElementData({
        id: element.attr("id"),
        styles: initialStyleAttr,
      });

      pointerY = (evt.pageY - canvasTop) / zoom - elementStyleTop;
      pointerX = (evt.pageX - canvasLeft) / zoom - elementStyleLeft;
    },
    drag: function (evt, ui) {
      var zoom = $("#dropzone").css("zoom");
      const parentElement = element.parent();
      // console.log(element, parentElement);
      //check if ctrl button press then this element can be drop in container component
      //if (parentElement.hasClass("cs-slide")) {
      //keeep element inside of parent
      var canvasTop = element.parent().offset().top;
      var canvasLeft = element.parent().offset().left;
      // Fix for zoom
      ui.position.top = Math.round((evt.pageY - canvasTop) / zoom - pointerY);
      ui.position.left = Math.round((evt.pageX - canvasLeft) / zoom - pointerX);

      ui.position.top= ui.position.top < 0? 0 : ui.position.top 
      ui.position.left = ui.position.left < 0? 0 : ui.position.left 

      if (ctrlKeyPress) {
        if (!element.hasClass("canDrop")) {
          element.addClass("canDrop");
          activeElement(element[0]);
        }
      }

      // Finally, make sure offset aligns with position
      ui.offset.top = Math.round(ui.position.top + canvasTop);
      ui.offset.left = Math.round(ui.position.left + canvasLeft);

      //if dragging inside container
      if (parentElement.hasClass("container-component")) {
        parentElement.droppable("disable");
        $(".cs-slide").droppable("enable");
        // console.log("dragging inside container");
      }
      //if dragging inside container
      if (parentElement.hasClass("cs-tabContent")) {
        parentElement.droppable("disable");
        $(".cs-slide").droppable("enable");
        // console.log("dragging inside tabGroup");
      }

      //if dragging inside popup
      if (parentElement.hasClass("cs-popup")) {
        parentElement.droppable("disable");
        $(".cs-slide").droppable("enable");
        // console.log("dragging inside popup");
      }

      if (parentElement.hasClass("cs-grid-item")) {
        // parentElement.droppable("disable");
        $(".cs-slide").droppable("enable");
        // console.log("dragging inside grid", evt.pageX, evt.pageY);
      }

      //if dragging inside cs-slide
      if (parentElement.hasClass("cs-slide")) {
        //if slide has ui-droppable then disable droppable slide ,because its currently dragging area
        if (parentElement.hasClass("ui-droppable")) {
          parentElement.droppable("disable");
        }

        if (parentElement.find(".container-component").length > 0) {
          $(".container-component").droppable("enable");
        }

        if (parentElement.find(".tabGroup-component").length > 0) {
          $(".cs-tabContent").droppable("enable");
        }

        if (parentElement.find(".popup-component").length > 0) {
          $(".popup-component .cs-popup").droppable("enable");
        }

        // if (parentElement.find(".grid-component").length > 0) {
        //   $(".grid-component .cs-grid-item").droppable("enable");
        // }

        //console.log("dragging inside slide");
        let detectTolerance = 0;
        let elementCssTop = element.offset().top;
        let elementCssBottom = Number(elementCssTop) + element.height();

        let parentElementTop = parentElement.offset().top + detectTolerance;
        let parentElementBottom =
          parentElement.offset().top + parentElement.height() - detectTolerance;

        //check if dragg element is nearest on top of slide
        if (elementCssTop < parentElementTop) {
          element.addClass("nearTop");
        } else {
          element.removeClass("nearTop");
        }
        //check if dragg element is nearest on bottom of slide
        if (elementCssBottom > parentElementBottom) {
          element.addClass("nearBottom");
        } else {
          element.removeClass("nearBottom");
        }
        //let result = `element top, ${elementCssTop}, element bottom, ${elementCssBottom}, parent botton ${parentElementBottom}`;
        //element.find(".container").html(result);
      }
      //}
    },
    stop: function (evt, ui) {
      isDragging = false;
      const csLayer = $(evt.target);
      if (csLayer.attr("data-state") !== "updated") {
        csLayer.attr("data-state", "updated");
      }

      //convertPixelToPer(csLayer)
      //console.log("dragba", convertPixelToPer(csLayer.css("top")));
      //create history
      const endedParent = csLayer.get(0).parentElement;
      const isContainerChange = startingparent !== endedParent;
      const actionType = isContainerChange
        ? HISTORY_ACTIONS.containerChanged
        : HISTORY_ACTIONS.STYLED_ACTIONS.dragged;

      const styledDetails = {
        initialStyleAttr,
        updatedStyleAttr: csLayer.attr("style"),
        itemId: csLayer.attr("id"),
      };

      if (isContainerChange) {
        startingDetails.action = HISTORY_ACTIONS.containerChanged;
      }

      const containerDetails = {
        startingparent,
        startingDetails,
        endedParent,
        initialStyleAttr,
        updatedStyleAttr: csLayer.attr("style"),
        itemId: csLayer.attr("id"),
      };

      const extraInfo = isContainerChange ? containerDetails : styledDetails;

      updateHistory(csLayer.attr("id"), actionType, extraInfo);
    },
  });

  element.resizable({
    containment: "parent",
    autoHide: true,
    handles:
      element.hasClass("container-component") ||
      element.hasClass("popup-component") ||
      element.hasClass("carousel-component") ||
      element.hasClass("tabGroup-component")
        ? "e,s"
        : "e",
    aspectRatio:
      /*both comments beacuse aspectRatio is not working on resize element
       if element attached to canvas left or right*/

      // element.hasClass("image-component") ||
      // element.hasClass("card-component") ||
      element.hasClass("carousel-component") ||
      element.hasClass("flipCard-component") ||
      element.hasClass("audio-component") ||
      element.hasClass("graph-component") 
        ? true
        : false,
    minWidth: 100,
    start: function () {
      initialStyleAttr = element.attr("style");
    },
    resize: function (evt, ui) {
      const csLayer = $(evt.target);
      zoom = $("#dropzone").css("zoom");

      var canvasTop = element.parent().offset().top;
      var canvasLeft = element.parent().offset().left;

      element[0].setAttribute("data-state", "updated");
      if (true) {
        //Fix resizeable zoom
        var changeWidth = ui.size.width - ui.originalSize.width; // find change in width
        var newWidth = ui.originalSize.width + changeWidth / zoom; // adjust new width by our zoomScale
        var changeHeight = ui.size.height - ui.originalSize.height; // find change in width
        var newHeight = ui.originalSize.height + changeHeight / zoom; // adjust new width by our zoomScale

        // console.log({
        //   realWidth: ui.size.width,
        //   changeWidth,
        //   newWidth,
        //   zoom,
        // });
        //ui.size.width = newWidth;
        ui.originalElement.width(newWidth);
        //set height on resize element
        if (!element.hasClass("cs-button")) {
          ui.originalElement.height(newHeight);
        }
      }

      useSetLayerHeight(csLayer);
    },

    stop: function (evt, ui) {
      console.log("resize stopped");
      const csLayer = $(evt.target);
      const isCoverflowOrCarousel =
        csLayer.hasClass("coverflow-component") ||
        csLayer.hasClass("carousel-component");

      if (isCoverflowOrCarousel) {
        csRenderFunctional(csLayer.find(".editable"));
      }
      //create history
      const historyAction = isCoverflowOrCarousel
        ? HISTORY_ACTIONS.updated
        : HISTORY_ACTIONS.STYLED_ACTIONS.resized; // coverflow and carousel always rerender

      updateHistory(csLayer.attr("id"), historyAction, {
        initialStyleAttr,
        updatedStyleAttr: csLayer.attr("style"),
        itemId: csLayer.attr("id"),
      });
    },
  });

  const elementStyle = element.attr("style");
  rotatable(element, {
    start: function (csLayer) {
      initialStyleAttr = element.attr("style");
      $(csLayer).draggable("disable");
    },
    rotate: function (csLayer) {},
    stop: function (csLayer) {
      $(csLayer).draggable("enable");
      updateHistory(
        csLayer.attr("id"),
        HISTORY_ACTIONS.STYLED_ACTIONS.rotated,
        {
          initialStyleAttr,
          updatedStyleAttr: csLayer.attr("style"),
          itemId: csLayer.attr("id"),
        }
      );
    },
  });

  element.attr("style", elementStyle);
  if (element.hasClass("container-component")) {
    //const container = element.find(".container");
    makeElementDropable(element, () => {
      onDropCallback();
    });
  }
  if (element.hasClass("tabGroup-component")) {
    const tabContents = element.find(".cs-tabContent");
    makeElementDropable(tabContents, () => {
      onDropCallback();
    });
  }
  if (element.hasClass("popup-component")) {
    const popup = element.find(".cs-popup");
    makeElementDropable(popup, () => {
      onDropCallback();
    });
  }
  if (element.hasClass("grid-component")) {
    const gridItem = element.find(".cs-grid-item");
    makeElementDropable(gridItem, () => {
      onDropCallback();
    });
  }
};

export const findComponentType = (classs) => {
  return classs?.substring(classs.indexOf(" ") + 1, classs.indexOf("-com"));
};

const searchEditableEle = (element) => {
  if (element.hasClass("cs-layer")) {
    element.addClass("padding");
  }

  $(element)
    .children()
    .each(function () {
      const editable = $(this);
      if (editable.hasClass("editable")) {
        if (editable.find(".editable").length > 0) {
          editable.addClass("padding");
        }
      }
      searchEditableEle($(this));
    });
};

// export const usePaddingLayer = (target) => {
//   return;
//   if (!target.hasClass("container-component")) {
//     //now apply padding class on active element
//     searchEditableEle(target.closest(".cs-layer"));
//     setTimeout(() => {
//       useSetLayerHeight(target.closest(".cs-layer"));
//     }, 300);
//   }
// };

// grid action helper
const getGridItemsStyles = (gridItem) => {
  const items = [...gridItem.querySelectorAll(".cs-grid-item")];
  const itemsStyles = items.map((el) => ({
    id: el.id,
    eleStyles: el.getAttribute("style"),
  }));

  console.log(itemsStyles);
  return itemsStyles;
};

export const gridActions = {
  resizeable: function (elment, historyCallback) {
    if (historyCallback) {
      this.historyCallback = historyCallback;
    }
    // Main Resizer Function
    const applyResizersFunction = (resizer) => {
      // Query the element
      const root = document.documentElement;
      const gridItem = resizer.parentNode;
      const container = gridItem.closest(".cs-grid");
      let initialHTML = "";
      // The current position of mouse
      let x = 0;
      let y = 0;
      let minWidth = 20;
      // other Details
      let itemWidth = 0;
      let zoom = 1;

      let startingStyles;
      let startingDetails;

      // Handle the mousedown event
      // that's triggered when user drags the resizer
      const mouseDownHandler = function (e) {
        e.stopPropagation();
        // Get the current mouse position
        x = e.clientX;
        y = e.clientY;
        itemWidth = gridItem.getBoundingClientRect().width;
        zoom = 1 || document.getElementById("dropzone").style.zoom;
        const layer = gridItem.closest(".cs-layer");
        initialHTML = layer.outerHTML;

        startingStyles = getGridItemsStyles(container);
        startingDetails = getElementData({
          id: gridItem.id,
          type: HISTORY_ACTIONS.gridResized,
        });

        document.addEventListener("mousemove", mouseMoveHandler);
        document.addEventListener("mouseup", mouseUpHandler);
      };

      const mouseMoveHandler = (e) => {
        // How far the mouse has been moved
        const dx = e.clientX - x;
        const nextSibling = gridItem.nextElementSibling;

        const newWidth =
          ((itemWidth + dx / zoom) * 100) /
          container.getBoundingClientRect().width;

        const widthToShare = 100 - newWidth;

        if (newWidth > minWidth && widthToShare > minWidth) {
          gridItem.style.width = newWidth + "%";

          nextSibling.style.width = widthToShare + "%";

          // to avoid the selection of other elements
          resizer.style.cursor = "col-resize";
          root.style.setProperty("--user-selection", "none");
          document.body.style.cursor = "col-resize";
        }
      };

      const mouseUpHandler = (e) => {
        e.preventDefault();
        resizer.style.cursor = "";
        root.style.setProperty("--user-selection", "auto");
        document.body.style.cursor = "";
        // Remove the handlers of `mousemove` and `mouseup`
        document.removeEventListener("mousemove", mouseMoveHandler);
        document.removeEventListener("mouseup", mouseUpHandler);

        const endingStyles = getGridItemsStyles(container);
        const historyPayload = {
          startingDetails,
          startingStyles,
          endingStyles,
        };

        this.historyCallback(
          container.id,
          HISTORY_ACTIONS.gridResized,
          historyPayload
        );
      };

      // Attach the handler
      resizer.addEventListener("pointerdown", mouseDownHandler);
    };

    // grid Items
    const items = elment.querySelectorAll(".cs-grid-item");
    // ALL Grid Items are calling resize funtion except last one
    items.forEach((item, index) => {
      const isLastItem = index == items.length - 1;
      // deleting old Resizers divs
      const oldResizer = item.querySelector(".grid-resizer");
      if (oldResizer) {
        oldResizer.remove();
      }
      if (!isLastItem) {
        const handle = document.createElement("div");
        handle.classList.add("grid-resizer");
        item.append(handle);
        applyResizersFunction(handle);
      }
    });
  },
  callback: () => {},
};

export const onLayerActiveHandler = (e) => {
  const target = e.target;

  if (!element.hasClass("cs-slide")) {
    element.on("click", function (e) {
      const target = e.target;
      // not click if element has not class
      if (
        target.hasAttribute("class") &&
        target.getAttribute("class").includes("cs-")
      ) {
        //not click on rotate handle
        if (!e.target.getAttribute("class").includes("ui-rotatable")) {
          activeElement($(target), () => {
            activeElementCallback();
          });
          //update state for active element
        } else {
          //if user click on rotate handle icon then
          activeElement($(target).closest(".cs-layer"), () => {
            activeElementCallback();
          });
        }
      } else {
        //if user click on (cs-text) of children then triggle cs-text
        $(target).parent().trigger("click");
      }
    });
  }
};

export const csRenderEditable = (
  id,
  updateHistory,
  activeElementCallback,
  zoom,
  actionState,
  callback = () => {},
  editorType,
  deviceDimensions
) => {
  // Adding listeners..
  const element = $(`#${id}`);
  if (!element.find(".status").length) {
    element.append("<div class='status'><span></span></div>");
  }

  const a = element[0].tagName === "A" ? element : element.find("a");
  if (a.length) {
    a.on("click", (e) => {
      e.preventDefault();
    });
  }

  if (!element.hasClass("cs-slide")) {
    element.on("click", function (e) {
      const target = e.target;
      // not click if element has not class
      if (
        target.hasAttribute("class") &&
        target.getAttribute("class").includes("cs-")
      ) {
        //not click on rotate handle
        if (!e.target.getAttribute("class").includes("ui-rotatable")) {
          activeElement($(target), () => {
            activeElementCallback();
          });
          //update state for active element
        } else {
          //if user click on rotate handle icon then
          activeElement($(target).closest(".cs-layer"), () => {
            activeElementCallback();
          });
        }
      } else {
        //if user click on (cs-text) of children then triggle cs-text
        $(target).parent().trigger("click");
      }
    });
  }
  if (element.hasClass("text-component")) {
    element.on("keypress", function (e) {
      const getLayer = $(this).parent().parent();
      useSetLayerHeight(getLayer);
    });
  }

  //editable status code start
  $("#dropzone .editable").on("mouseout", function (e) {
    $(".editable").removeClass("hover");
    const status = $(this).find(".status");
    if (status.length > 0) {
      status.stop().fadeOut("fast");
    }
  });

  $("#dropzone .editable").on("mouseover", function (e) {
    e.stopImmediatePropagation();
    let csElement = e.target;
    let classAttr = csElement.getAttribute("class");
    let type = "";
    if (
      typeof classAttr !== "undefined" &&
      classAttr !== null &&
      classAttr.includes("cs-")
    ) {
      type = findElementType(classAttr);
      csElement = $(csElement);
    } else {
      csElement = $(csElement).closest('[class*="cs-"]');
      type = findElementType(csElement.attr("class"));
    }
    $(csElement).addClass("hover");
    //finally we received actual hover element and its type
    //skip slide
    if (type != "cs-slide") {
      const csLayer = $(csElement).closest(".cs-layer");
      const status = csLayer.find(">.status");

      status.find("span").html(type);
      status.stop().fadeIn("fast");
    }
  });

  //make slide active
  $("#dropzone.cs-slide").on("dblclick", function (e) {
    e.stopImmediatePropagation();
    activeElement($(this), activeElementCallback);
  });
  //editable status code end

  const historyCallback = (id, action = "updated", extraInfo) => {
    // waiting for JQuery to remove dragging classes
    setTimeout(() => {
      updateHistory({
        action,
        actionState,
        id: typeof id === "string" ? id : "",
        extraInfo,
      });
    }, 30);
  };
  // making resizeable , draggable , including padding
  if (element.hasClass("grid-component")) {
    // makeElementDropable(element.find(".cs-grid-item"), () => {
    //   historyCallback();
    // });
    gridActions.resizeable(element.get(0), historyCallback);
  } else {
    if (editorType === "presentation") {
      csDragResizeable(
        element,
        zoom,
        historyCallback,
        () => {
          if (typeof callback === "function") {
            callback();
          }
        },
        activeElementCallback
      );
    }
  }

  // if component have slider JS functionality.
  const csComponent = element.find(".editable");

  if (csComponent.length) {
    csRenderFunctional(csComponent);

    // if not on Editor
    if (editorType === "email") {
      if (!csComponent.hasClass("cs-slide")) {
        sortable({
          item: csComponent.closest(".cs-layer").get(0),
          appendable: document.getElementById("dropzone"),
          onsort: (itemDetails) => {
            historyCallback(null, HISTORY_ACTIONS.sorted, itemDetails);
          },
          appendableGridClass: "cs-grid-item",
        });
      }
    } else if (editorType === "microsite") {
      if (!csComponent.hasClass("cs-slide")) {
        sortable({
          item: csComponent.closest(".cs-layer").get(0),
          appendable: document.getElementById("dropzone"),
          onsort: (itemDetails) => {
            historyCallback(null, HISTORY_ACTIONS.sorted, itemDetails);
          },
          appendableGridClass: "cs-col",
        });
      }
    }
  }
  if (element.attr("class").includes("flipCard-component")) {
    setLayerElementIds(element);
  }
  if (element.attr("class").includes("tabGroup-component")) {
    const tabLinks = element[0].querySelectorAll(".tabLinks");
    const tabContents = element[0].querySelectorAll(".cs-tabContent");
    setTimeout(() => {
      tabContents.forEach(
        (tab, index) => (tabLinks[index].dataset.target = tab.id)
      );
    }, 100);
  }
};

// Hierarchy starts here
export const removeNoClassTags = (id) => {
  let duplicateLayer = $(id).clone().wrap("div").parent();
  duplicateLayer.find(".status").remove();
  duplicateLayer.find(".ui-resizable-handle").remove();
  duplicateLayer.find(".ui-rotatable").remove();

  let selector = ":not([class*=cs-])";

  const selectorLen = $(duplicateLayer).find(selector).length;
  for (var a = 0; a < selectorLen; a++) {
    duplicateLayer.find(selector).each(function () {
      $(this).replaceWith($(this).html());
    });
  }

  const element = duplicateLayer.html();

  return $(element);
};

// Getting all cs-layers

const getCsLayers = (parent) => {
  const children = [];
  $(parent)
    .children()
    .each(function (index, item) {
      children.push(getCsLayers(item));
    });
  return {
    parent: $(parent).attr("class") || "",
    parentId: $(parent).attr("id") || "",
    type: getCsClass(
      $(parent).attr("class"),
      $(parent)
        .attr("id")
        ?.slice($(parent).attr("id").lastIndexOf("-") + 1)
    ),
    children: children,
  };
};

export const showLayers = (id) => {
  const parent = removeNoClassTags(id);

  return getCsLayers(parent);
};

// export const useImageToDataURL = (url, callback) => {
//   var xhr = new XMLHttpRequest();
//   xhr.onload = function () {
//     var reader = new FileReader();
//     reader.onloadend = function () {
//       if (url.includes(".jpg")) {
//         callback(
//           reader.result.replace(
//             "data:application/octet-stream",
//             "data:image/jpg"
//           )
//         );
//       } else {
//         callback(
//           reader.result.replace(
//             "data:application/octet-stream",
//             "data:image/png"
//           )
//         );
//       }
//     };
//     reader.readAsDataURL(xhr.response);
//   };

//   xhr.open("GET", url);
//   xhr.responseType = "blob";
//   xhr.send();
// };

// export const useToDataURL = (url, callback) => {
//   var xhr = new XMLHttpRequest();
//   xhr.onload = function () {
//     var reader = new FileReader();
//     reader.onloadend = function () {
//       callback(reader.result);
//     };
//     reader.readAsDataURL(xhr.response);
//   };

//   xhr.open("GET", url);
//   xhr.responseType = "blob";
//   xhr.send();
// };

export const setBodyinHTML = (html, link, script, email = false, microsite) => {
  return `
  <!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    ${link ? link : ""}
    <link href='http://fonts.googleapis.com/css?family=Lato:400,700' rel='stylesheet' type='text/css'>

    ${
      email
        ? `<style>
    *{
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: 'lato' , sans-serif;
    }

    #dropzone{
      overflow: hidden !important;
      margin: auto !important;
      background: white;
    }

    .cs-image{
      vertical-align: middle;
    }

    .cs-button {
      padding: 10px;
      border-radius: 6px;
      outline: none;
      border: none;
      background-color: rgb(0, 183, 255);
      color: white;
      border-radius: inherit;
    }

    .cs-button * {
    margin: 0px;
    }

  .cs-button p {
    word-break: break-all;
  }

    @media only screen and (max-width:768px)  {
      .desktop-view{display: none !important;}
      input{
        display:block  !important;
        width:100% !important;
      }
    }

   @media only screen and (min-width:769px) {
      .mobile-view{display: none !important;}
    }
  </style>`
        : ""
    }
  </head>
  <body ${email ? "style='padding: 50px 0px;'" : ""}>

  ${
    microsite
      ? `<div id="landing-page" data-content="landingpage">${microsite}</div>`
      : html
      ? html
      : '<div class="item-loading">No Data Available</div>'
  }
  ${script ? script : ""}
  </body>
</html>
  `;
};

export const getHTMLFromBody = (html, eleTopAppend) => {
  const doc = new DOMParser().parseFromString(html, "text/html");
  const dropzone = doc.getElementById("dropzone");
  if (
    dropzone &&
    (dropzone.innerHTML ||
      dropzone.getAttribute("style").includes("background"))
  ) {
    const div = document.createElement("div");
    div.append(dropzone);
    if (eleTopAppend) {
      div.innerHTML += eleTopAppend;
    }
    return div.innerHTML;
  }
};

export const createLink = (file) => {
  const link = document.createElement("link");
  link.rel = "stylesheet";
  link.href = `/css/${file}`;
  return link;
};
export const createScript = (file, src) => {
  const script = document.createElement("script");
  if (src) {
    script.src = src;
  } else {
    script.src = `/js/${file}`;
  }
  return script;
};

export const emailValidator = (email) => {
  if (
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  ) {
    return true;
  } else {
    return false;
  }
};

export const URLValidator = (URL) => {
  if (
    URL.toLowerCase().match(
      "^(?:(?:http(?:s)?|ftp)://)(?:\\S+(?::(?:\\S)*)?@)?(?:(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)(?:\\.(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)*(?:\\.(?:[a-z0-9\u00a1-\uffff]){2,})(?::(?:\\d){2,5})?(?:/(?:\\S)*)?$"
    )
  ) {
    return true;
  } else {
    return false;
  }
};

export const cleanDropzone = (dropzone, isEmailOrMicrosite) => {
  let newDropzone = $(dropzone.outerHTML);
  //remove tags
  newDropzone.find(".status").remove();
  newDropzone.find(".ui-rotatable").remove();
  newDropzone.find(".ui-resizable-handle").remove();
  newDropzone.find(".grid-resizer").remove();
  newDropzone.find(".animate__animated").removeClass("animate__animated");
  newDropzone.removeClass("active");
  //remove ui-clases
  newDropzone.find(".editable").each(function () {
    const ele = $(this);
    ele.removeClass(function (i, c) {
      //remove all classes contained ui-
      var matches = c.match(/\S*ui-\S*/g);
      return matches ? matches.join(" ") : false;
    });
    ele.removeClass(function (i, c) {
      // Check if the element has the class 'tab-content'
      if ($(this).hasClass('cs-tabContent')) {
        // If 'cs-tabContent' class is present, do not remove 'activeTab' class
        return false;
      }

      //remove all classes active
      var matches = c.match(/\S*active\S*/g);
      return matches ? matches.join(" ") : false;
    });
    ele.removeClass("editable");
    ele.removeClass("active");
    ele.removeClass("sortable-item");
  });

  if (isEmailOrMicrosite) {
    newDropzone.attr("data-height", newDropzone.css("height"));

    newDropzone.css({
      overflow: "hidden",
      height: "auto",
    });
  }

  newDropzone.css({ zoom: "", padding: "" });
  return newDropzone.get(0);
};

export const insertHtmlToDropzone = (dropzone, html = "") => {
  if (html) {
    const div = document.createElement("div");
    div.innerHTML = html;
    const fetchedDropzone = div.querySelector("#dropzone");
    // inserting attributes from fetch dropzone to real dropzone

    if (fetchedDropzone) {
      Array.prototype.forEach.call(
        fetchedDropzone.attributes,
        ({ nodeName, value }) => {
          if (nodeName === "class") {
            const oldClass = dropzone.getAttribute("class");
            if (oldClass) {
              // merging old and fetched class attribute
              dropzone.setAttribute("class", `${oldClass} ${value}`);
            } else dropzone.setAttribute(nodeName, value);
          } else dropzone.setAttribute(nodeName, value);
        }
      );
      dropzone.innerHTML = fetchedDropzone.innerHTML;
    }
    return true;
  } else {
    dropzone.innerHTML = "";
    return false;
  }
};

export const getFirstCapitalized = (string) => {
  const capital = string
    .split("-")
    .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
    .join("-");
  return capital;
};

export const getCsClass = (classList, index, withCs = true) => {
  const comp = findComponentType(classList)?.trim();
  const capitalized = getFirstCapitalized(
    comp?.includes("cs") ? comp : "layer-" + comp
  );
  if (capitalized.includes("Layer")) {
    const capLayer = capitalized.split("-");
    capLayer.splice(1, 0, index);
    return capLayer.join("-");
  } else if (capitalized.includes("Slide")) {
    return capitalized;
  } else {
    if (withCs) {
      return capitalized + `-${index}`;
    } else {
      return capitalized.split("-")[1] + `-${index}`;
    }
  }
};

export const getEditableElements = () => {
  const dropzoneWrapper = document.getElementById("mainWrapper");
  const active = dropzoneWrapper.querySelector(".active");
  let children = [active];
  if (active && active.classList.contains("cs-layer")) {
    children = [...children, ...active.querySelectorAll(".editable")];
  } else {
    const layer = active.closest(".cs-layer");
    if (layer) {
      children = [layer, ...layer.querySelectorAll(".editable")];
    } else {
      children = [active, ...active.querySelectorAll(".cs-layer")];
    }
  }

  const data = [];

  children.forEach((item) =>
    data.push({
      name: getCsClass(
        item.classList.value,
        item.id.slice(item.id.lastIndexOf("-") + 1),
        false
      ),
      id: item.id,
    })
  );

  return { options: data, activeId: active.id };
};

export const editableActions = {
  clickHandler: ({ element, activeElement }) => {
    activeElement(element);
  },
  mouseOverHandler: ({ e, element }) => {
    e.stopImmediatePropagation();
    element.classList.add("hover");
    let csElement = e.target;
    let classAttr = csElement.getAttribute("class");
    let type = "";
    if (
      typeof classAttr !== "undefined" &&
      classAttr !== null &&
      classAttr.includes("cs-")
    ) {
      type = findElementType(classAttr);
      csElement = $(csElement);
    } else {
      csElement = $(csElement).closest('[class*="cs-"]');
      type = findElementType(csElement.attr("class"));
    }
    $(csElement).addClass("hover");
    //finally we received actual hover element and its type
    //skip slide
    if (type != "cs-slide") {
      const csLayer = $(csElement).closest(".cs-layer");
      const status = csLayer.find(">.status");
      status.find("span").html(type);
      status.stop().fadeIn("fast");
    }
  },
  mouseOutHandler: ({ element }) => {
    $(".editable").removeClass("hover");
    const status = $(element).find(".status");
    if (status.length > 0) {
      status.stop().fadeOut("fast");
    }
  },
  draggableAndResizeable({
    element,
    updateHistory,
    zoom = 1,
    onDragDropCallback = () => {},
    activeElement,
    actionState,
  } = {}) {
    const historyCallback = (id, action = "updated", extraInfo) => {
      // waiting for JQuery to remove dragging classes
      setTimeout(() => {
        updateHistory({
          action,
          actionState,
          id: typeof id === "string" ? id : "",
          extraInfo,
        });
      }, 30);
    };

    csDragResizeable(
      element,
      zoom,
      historyCallback,
      onDragDropCallback,
      activeElement
    );
  },
  renderSliderFunctional() {},
};

export const getNewLayerZIndex = (dropzone) => {
  return dropzone.querySelectorAll(".cs-layer").length + 1;
};

export const takeScreenShot = async (elementToCapture) => {
  const captureElement = (dropzone) => {
    const image = toJpeg(dropzone, {
      quality: 0.5,
    });
    const thumbnail = toJpeg(dropzone, {
      quality: 0.2,
    });
    return Promise.all([image, thumbnail]);
  };

  // Converting images to base64,to take screenshot properly
  const backgroundAndImages = elementToCapture.parentElement.querySelectorAll(
    "img[src] , [style*=background-image] , video[poster]"
  );
  const promises = [];
  backgroundAndImages.forEach((ele) => {
    const bgImage = ele.style.backgroundImage;
    if (bgImage) {
      ele.dataset.realUrl = bgImage;
      const base64Promise = toDataURL({
        src: bgImage.replace('url("', "").replace('")', "").trim(),
      }).then((base64) => {
        ele.style.backgroundImage = `url("${base64}")`;
      });
      promises.push(base64Promise);
    } else if (ele.poster) {
      ele.dataset.realUrl = ele.poster;
      const base64Promise = toDataURL({ src: ele.poster }).then((base64) => {
        ele.poster = base64;
      });
      promises.push(base64Promise);
    } else {
      ele.dataset.realUrl = ele.src;
      const base64Promise = toDataURL({ src: ele.src }).then((base64) => {
        ele.src = base64;
      });
      promises.push(base64Promise);
    }
  });

  await Promise.all(promises);

  // after all images and backgrounds are converted to base64 Take it's Capture
  return captureElement(elementToCapture).then((captureRes) => {
    // AND THEN AFTER SCREENSHOT PUTING ORIGNAL SRC'S TO IMAGES
    backgroundAndImages.forEach((ele) => {
      const bgImage = ele.style.backgroundImage;
      if (bgImage) {
        ele.style.backgroundImage = ele.dataset.realUrl;
      } else if (ele.poster) {
        ele.poster = ele.dataset.realUrl;
      } else {
        ele.src = ele.dataset.realUrl;
      }
      delete ele.dataset.realUrl;
    });
    return captureRes;
  });
};

export const deActiveElementOnOtherSideClick = (deActiveElement) => {
  const validClasses = [
    "editorCard",
    "zoom-selectbox",
    "MuiDialog-container",
    "jodit-popup",
    "MuiButtonBase-root",
    "MuiSelect-select",
  ];

  const validTags = ["body"];

  const clickListener = (e) => {
    const target = e.target;
    const targetClasses = [...target.classList];
    let shouldDeActive = true;

    const activeElement = document.activeElement;
    const isValidActiveElement =
      activeElement.nodeName === "INPUT" ||
      activeElement.contentEditable === "true";

    if (isValidActiveElement) {
      shouldDeActive = false;
    } else {
      for (let index = 0; index < validClasses.length; index++) {
        const currentClass = validClasses[index];
        if (
          targetClasses.includes(currentClass) ||
          target.closest("." + currentClass) ||
          validTags.includes(target.tagName?.toLowerCase())
        ) {
          shouldDeActive = false;
          break;
        }
      }
    }

    if (
      // shouldDeActive ||
      (targetClasses.includes("editorCard") &&
        targetClasses.includes("main")) ||
      targetClasses.includes("mainWrapper")
    ) {
      deActiveElement();
    }
  };

  document.addEventListener("click", clickListener);
  return () => {
    document.removeEventListener("click", clickListener);
  };
};

export const getElementIndex = (element) => {
  const layerParent = element.parentElement;
  return Array.from(layerParent.children).indexOf(element);
};

export const areElementsHaveSameIndex = (element1, element2) => {
  return getElementIndex(element1) === getElementIndex(element2);
};

export const generateUniqueLetterId = () => {
  const randomString = Math.random().toString(36).substring(2, 8);
  const timestamp = Date.now();
  const uniqueId = randomString + timestamp.toString(36);
  return uniqueId;
};

export const isActiveComponent = (component)=>{
  return document.querySelector(".cs-layer.active")?.classList?.contains(component);
}

export const getNewAssetsAndHTML = (saveSlideDiv, moveToUrl, baseUrl) => {
  const assets = [];
  const defaultAssets = [];
  const uploadedAssets = [];
  
  const saveDivNodeInstance = $(saveSlideDiv);
  //select all elements that media is changed and need to upload on s3 , by the help of data-src
  //data-src contained the path of asset that should move to target slide, microsite, email folder
  saveDivNodeInstance.find('[data-src], [data-poster], div[style*="/assets/images/"]').each(function () {
    let element  = $(this)
    let elementId = element.attr("id");
    let elementType = "";
    let videoPoster = null;
    let elementSrc = element.attr("data-src");
    let elementPoster = element.attr("data-poster");
    
   
    const bgImg = element.css("background-image");
    if (bgImg && bgImg.includes("url")) {
      elementType = "background";
      elementSrc = bgImg.replace(/url\(['"](.+)['"]\)/, '$1');
    } else if (elementSrc && elementSrc.includes("pdf")) {
      elementType = "pdf";
      const fileName = elementSrc.split("/").pop();
      element.attr("data-src", moveToUrl(elementType, fileName));
    } else {
      if (element.is("audio")) {
        elementType = "audio";
      } else if (element.is("video")) {
        elementType = "video";
        videoPoster = element.attr("poster");
      } else {
        elementType = "images";
      }
    }
    elementType = $.trim(elementType);

    // Pushing founded assets into default or normal array
    const assetDetails = {
      elementId: elementId,
      elementSrc: elementSrc,
      elementType: elementType,
    };
    //if video poster found, then add poster into video object
    if (videoPoster) {
      assetDetails.videoPoster = videoPoster;
    }

    if (elementSrc && elementSrc.includes("createmart-seed-folder")) {
      defaultAssets.push(assetDetails);
    } else {
      assets.push(assetDetails);
    }

    // --Replacing URLs with moveToUrl inside html before save
    // For files Src img,audio,video,
    if (elementSrc && elementSrc.includes("http") && elementType != "background") {
      // if src is not relative then make it relative !
      const fileName = elementSrc.split("/").pop();
      if (element.is("video")) {
        const videoPosterFileName = videoPoster.split("/").pop();
        //only will work if video has data-src
        element.attr("src", moveToUrl(elementType, fileName));
        element.attr("poster", moveToUrl(elementType, videoPosterFileName));
        //collect assets for real dom change
        uploadedAssets.push({elementId, src: baseUrl+moveToUrl(elementType, fileName), poster: baseUrl+moveToUrl(elementType, videoPosterFileName)})
      }else{
        element.attr("src", moveToUrl(elementType, fileName));
        //collect new uploaded path of asset
        uploadedAssets.push({elementId, src: baseUrl+moveToUrl(elementType, fileName)})
      }
    }

    //if video poster found like poster or data-poster
    //check video src is not changed, and poster is changed then update poster url
    if (!elementSrc && elementPoster) {
      const videoPosterFileName = videoPoster.split("/").pop();
      element.attr("poster", moveToUrl(elementType, videoPosterFileName));
      uploadedAssets.push({elementId, poster: baseUrl+moveToUrl(elementType, videoPosterFileName)})
    }

    // FOR BACKGROUND IMAGES
    const bgImage = $(this).css("background-image");
    if (bgImage && bgImage.includes("http") && bgImage.includes("url")) {
      // if src is not relative then make it relative !
      let fileName = bgImage.split("/").pop();
      // removing ")" from file name because it was in b-g url("") format
      fileName = fileName.substring(0, fileName.length - 2);
      $(this).css("background-image", `url("${moveToUrl(elementType, fileName)}")`);
      uploadedAssets.push({elementId, bg: baseUrl+moveToUrl(elementType, fileName)})
    }
  });

  // As data-src only for new added asset, so once we finaizlisze assets and html,
  // then remove data-src from dropzone ele
  $("#dropzone [data-src], #dropzone [data-poster]").each(function () {
    $(this).removeAttr('data-src');
    $(this).removeAttr('data-poster');
  })
  
  
  //updated html with new paths
  //replace url only for presentation, prsentation html build require relative url, while microsite and email require absolute url 
  const replacedUrlHtml = baseUrl.includes('presentation') ? saveDivNodeInstance.html().replaceAll(baseUrl, '') : saveDivNodeInstance.html()
  return { assets, defaultAssets, uploadedAssets, HTML: replacedUrlHtml };
};
export const updateDomAssetsPath = (s3Assets)=>{
  s3Assets.forEach((asset)=>{
    const element = document.getElementById(asset?.elementId);
    if(asset.src){
      element.setAttribute('src', asset.src)
    }
    if(asset.poster){
      element.setAttribute('poster', asset.poster)
    }
    if(asset.bg){
      element.style.backgroundImage = `url(${asset.bg})`
    }
  })
}
export const removeFontSizeFromStr = (htmlString, tags = 'h1, h2, h3, h4, h5, h6')=>{
  // Create a DOMParser
  var parser = new DOMParser();

  // Parse the HTML string
  var doc = parser.parseFromString(htmlString, 'text/html');

  // Get all heading elements within the parsed document
  var headings = doc.querySelectorAll(tags);

  // Remove the font-size property from each heading
  headings.forEach(function(heading) {
    heading.style.fontSize = null;
  });

  return doc.body.innerHTML
}