import React from "react";
import Control from "./Control";
import UnitBoxes from "./unitBoxes/UnitBoxes";
import "src/styles/properties.scss";
import { HISTORY_ACTIONS } from "src/data/contants";

const Padding = ({
  elementId,
  updateHistory: propsUpdateHistory,
  undoRedoChange,
}) => {
  const updateHistory = () => {
    propsUpdateHistory({ action: HISTORY_ACTIONS.STYLED_ACTIONS.common });
  };
  
  return (
    <Control
      className="paddingProperties"
      heading="Padding"
      isCollapse={true}
      divider={false}
      expanded={false}
    >
      <UnitBoxes
        selector={`#${elementId}`}
        property="padding"
        updateHistory={updateHistory}
        undoRedoChange={undoRedoChange}
        elementId={elementId}
      />
    </Control>
  );
};

export default Padding;
