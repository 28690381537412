import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { getFirstSlideId } from "src/services/utils";

const SidebarItems = (props) => {
  const active = "active";
  let isOnEditor = false;
  let isOnPreview = false;
  const { slideId } = useParams();
  const navigate = useNavigate();

  const { presentation, presentationSlides } = useSelector((state) => state.presentations);
  const { landingPage } = useSelector((state) => state.microsites);
  const { email } = useSelector((state) => state.emails);
  const { isChangesNotSaved } = useSelector((state) => state.editor);

  const getURL = (url) => {
    let id = "";
    let type = "";

    //To Check Module
    if (location.pathname.includes("presentation")) {
      id = presentation._id;
      type = "presentation";
    } else if (location.pathname.includes("email")) {
      id = email._id;
      type = "email";
    } else if (location.pathname.includes("microsite")) {
      id = landingPage._id;
      type = "landingpage";
    }

    let replacedUrl = url.replace(":id", id);
    if (
      (type === "presentation" && replacedUrl.includes("/edit")) ||
      (type === "presentation" && replacedUrl.includes("/view"))
    ) {
      const firstSlideId = getFirstSlideId(presentation?.chapters);
      if (firstSlideId) {
        replacedUrl = replacedUrl.replace(":slideId", slideId || firstSlideId);
      } else {
        return "";
      }
    }

    return replacedUrl;
  };

  const navigationHandler = (e, url, whereTonavigate) => {
    const presentationEditUrl = "/presentation/:id/edit/:slideId";
    const navigationURLs = [presentationEditUrl];
    if (navigationURLs.includes(url)) {
      e.preventDefault();
      if (url === presentationEditUrl) {
        const location = window.location;
        // Editor Will handle from Here
        if (location.pathname.includes("view")) {
          const currentSlideViewed = location.pathname.split("/").pop();
          const navigateTo = presentationEditUrl.replace(
            ":slideId",
            currentSlideViewed
          );
          navigate(getURL(navigateTo));
        } else {
          console.log("else");
          navigate(whereTonavigate);
        }
      }
    }
  };

  const activeCLassHandler = (navData, navItem) => {
    let { isActive } = navData;
    const isEditActive = navItem.label == "Edit" && isActive;
    const isPreviewActive = navItem.label == "View" && isActive;
    const location = window.location.pathname;

    if (isEditActive) {
      isOnEditor = true;
    } else if (isPreviewActive) {
      isOnPreview = true;
    }

    if (navItem.label === "Campaigns") {
      const shouldCampaignMainActive =
        location === "/campaign/list" ||
        location === "/campaign/create" ||
        location.includes("/campaign/update") ||
        location.includes("/campaign/analytics");

      if (!shouldCampaignMainActive) {
        isActive = false;
      }
    }

    let isDisabled = false;
    if (
      (location.includes("/presentation") && navItem.label === "View") ||
      (location.includes("/presentation") && navItem.label === "Edit")
    ) {
      if (presentationSlides && !presentationSlides.length) {
        isDisabled = true;
      }
    }

    return `${isActive ? active : ""} ${
      navItem.label == "View" && isChangesNotSaved ? "disabled" : ""
    } ${navItem.disabled || isDisabled ? "disabled" : ""}`;
  };

  return props.items.map((navItem, index) => {
    const url = getURL(navItem.url);
    return (
      <div key={index}>
        {presentation && (
          <li>
            <NavLink
              onClick={(e) => {
                navigationHandler(e, navItem.url, url);
              }}
              className={(navData) => activeCLassHandler(navData, navItem)}
              to={url}
            >
              <Typography variant="span" component="span" className="navIcon">
                {navItem.icon}
              </Typography>
              <Typography variant="body2" component="span" className="navText">
                {navItem.label}
              </Typography>
            </NavLink>
          </li>
        )}
      </div>
    );
  });
};
export default SidebarItems;
