import React, { useState, useEffect } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import {
  MdAlignVerticalTop,
  MdAlignVerticalCenter,
  MdAlignVerticalBottom,
  MdOutlineAlignHorizontalLeft,
  MdOutlineAlignHorizontalCenter,
  MdOutlineAlignHorizontalRight,
} from "react-icons/md";
import Control from "./Control";
import "src/styles/properties.scss";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import { HISTORY_ACTIONS } from "src/data/contants";
const Alignment = (props) => {
  const dispatch = useDispatch();

  const poistionHandler = (position) => {
    const elementId = props.elementId;
    const layer = $("#" + elementId);
    const layerHeight = layer.outerHeight();
    const layerWidth = layer.outerWidth();

    const dropzone = layer.parent();
    const dropzoneHeight = dropzone.outerHeight();
    const dropzoneWidth = dropzone.outerWidth();

    if (position == "top") {
      layer.css({ top: "0px", bottom: "" });
    } else if (position == "bottom") {
      layer.css({ top: "", bottom: "0px" });
    } else if (position == "left") {
      layer.css({ left: "0px", right: "" });
    } else if (position == "right") {
      layer.css({ left: "", right: "0px" });
    } else if (position == "vc") {
      const top = dropzoneHeight / 2 - layerHeight / 2;
      layer.css({ top: top, bottom: "" });
    } else if (position == "hc") {
      const left = dropzoneWidth / 2 - layerWidth / 2;
      layer.css({ left: left, right: "" });
    }

    props.updateHistory({ action: HISTORY_ACTIONS.STYLED_ACTIONS.common });
  };
  return (
    <Control
      heading="Alignment"
      headingVariant="h2"
      className="alignment"
      divider={false}
      isCollapse={true}
      expanded={true}
    >
      <Box className="iconBtns">
        <Tooltip title="Top" arrow>
          <IconButton
            className="icon"
            onClick={() => {
              poistionHandler("top");
            }}
          >
            <MdAlignVerticalTop />
          </IconButton>
        </Tooltip>

        <Tooltip title="Vertical Center" arrow>
          <IconButton
            className="icon"
            onClick={() => {
              poistionHandler("vc");
            }}
          >
            <MdAlignVerticalCenter />
          </IconButton>
        </Tooltip>

        <Tooltip title="Bottom" arrow>
          <IconButton
            className="icon"
            onClick={() => {
              poistionHandler("bottom");
            }}
          >
            <MdAlignVerticalBottom />
          </IconButton>
        </Tooltip>

        <Tooltip title="Left" arrow>
          <IconButton
            className="icon"
            onClick={() => {
              poistionHandler("left");
            }}
          >
            <MdOutlineAlignHorizontalLeft />
          </IconButton>
        </Tooltip>

        <Tooltip title="Horizontal Center" arrow>
          <IconButton
            className="icon"
            onClick={() => {
              poistionHandler("hc");
            }}
          >
            <MdOutlineAlignHorizontalCenter />
          </IconButton>
        </Tooltip>

        <Tooltip title="Right" arrow>
          <IconButton
            className="icon"
            onClick={() => {
              poistionHandler("right");
            }}
          >
            <MdOutlineAlignHorizontalRight />
          </IconButton>
        </Tooltip>
      </Box>
    </Control>
  );
};

export default Alignment;
