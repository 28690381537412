const adminAllowedUrls = [
  "presentations",
  "presentation",
  "microsites",
  "microsite",
  "emails",
  "email",
  "integration",
  "profile",
  "campaign",
  "assets",
  "reviews",
  "settings",
];

const developerAllowedUrls = [
  "presentations",
  "presentation",
  "microsites",
  "microsite",
  "emails",
  "email",
  "integration",
  "profile",
];

const reviewerAllowedUrls = ["reviews", "profile"];

export const roles = {
  admin: {
    entry: "presentations",
    allowedUrls: adminAllowedUrls,
    role: "admin",
  },
  developer: {
    entry: "presentations",
    allowedUrls: developerAllowedUrls,
    role: "developer",
  },
  reviewer: {
    entry: "reviews",
    allowedUrls: reviewerAllowedUrls,
    role: "reviewer",
  },
};
