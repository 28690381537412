import React from "react";
import { Box, Drawer } from "@mui/material";
const SideDrawer = (props) => {
  return (
    <Drawer
      className={props.className}
      anchor={"right"}
      open={props.open || false}
      classes={{ paper: props.paperClass }}
      onClose={props.onClose}
      container={document.getElementById("drawer")}
    >
      <Box p={"23px"} className={props.boxClassName} height="100%">
        {props.children}
      </Box>
    </Drawer>
  );
};
export default React.memo(SideDrawer);
