import React, { useEffect, useState } from "react";
//Stlyes
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchEmailById, fetchEmailHtml } from "src/store/actions/emails";
import {
  Typography,
  Grid,
  Button,
  Box,
  IconButton,
  Tooltip,
  CircularProgress
} from "@mui/material";
import { BsTablet, BsTabletLandscape } from "react-icons/bs";
import {
  AiOutlineClose,
  AiOutlineMobile,
  AiOutlineTablet,
  AiOutlineDesktop,
} from "react-icons/ai";
import { BiCommentDetail, BiZoomIn, BiZoomOut } from "react-icons/bi";
import Comments from "../../components/dialogs/Comments";
import "../../styles/EmailPreview.scss";
import { MdFitScreen, MdOutlineFitScreen } from "react-icons/md";
import {
  getHTMLFromBody,
  setBodyinHTML,
  createLink,
} from "src/services/editor.service";
import {
  clearEmailData,
  setPreviewEmail,
} from "src/store/reducers/emails-slice";
import PermissionComp from "src/components/Permission";
import { hasPermission } from "src/utils/Utils";

const EmailPreview = (props) => {
  const dispatch = useDispatch();
  const type = "email";
  const { isLoadingApprove, isLoadingDeclined } = props;
  const [isOpenComments, setIsOpenComments] = useState(false);
  const { isReviewed } = useSelector((state) => state.emails.preview);
  const [ShouldDevicesShow, setShouldDevicesShow] = useState(false);
  const [device, setDevice] = useState("desktop");
  const [scaledToFit, setScaledToFit] = useState(false);
  //zoom code
  const [zoom, setZoom] = useState("1");
  const { list: versions } = useSelector((state) => state.versions);
  const { email, emailData } = useSelector((state) => state.emails);
  const handleCloseComments = () => {
    setIsOpenComments(false);
  };

  const handleOpenComments = () => {
    setIsOpenComments(true);
  };
  const { id } = useParams();
  const emailId = id || props.emailId;
  const versionId = props.versionId;
  const isOnDialog = id == undefined;
  const [loading, setLoading] = useState(false);

  // API call to get the presentation
  useEffect(() => {
    dispatch(fetchEmailById(emailId));

    const filter = { emailId, versionId }
    if (versions?.length > 0) {
      let dataList = [...versions]
      const latest = dataList.sort((a, b) => Number(b.version) - Number(a.version))[0];
      if (latest._id == versionId) {
        delete filter.versionId
      }
    }
    dispatch(fetchEmailHtml(filter));
  }, [emailId, versionId]);

  useEffect(() => {
    return () => {
      dispatch(setPreviewEmail({ isOpen: false }));
    };
  }, []);

  useEffect(() => {
    if (emailData) {
      setLoading(false);
      setShouldDevicesShow(true);
    } else {
      setLoading(true);
    }
  }, [emailData]);

  const handleZoomChange = (value) => {
    const preview = document.querySelector("#preview");

    if (value == "scaleToFit") {
      handleScaleToFit();
    } else {
      const minZoom = 1;
      const maxZoom = 250;
      if (value < minZoom) {
        value = minZoom;
      } else if (value > maxZoom) {
        value = maxZoom;
      }
      const zoom = value / 100;
      scaleDropzone(
        preview.querySelector(".email"),
        preview.querySelector(".cs-slide"),
        zoom
      );
      setScaledToFit(false);
      setZoom(value);
    }
  };
  // To clear editor's data
  const clearData = () => {
    dispatch(clearEmailData());
  };

  useEffect(() => {
    return () => {
      clearData();
    };
  }, []);

  const handleScaleToFit = () => {
    const activeSlide = document.querySelector(".slide.selected");
    scaleDropzone(
      activeSlide,
      activeSlide.querySelector(".cs-slide"),
      "scaleToFit",
      (scale) => {
        let zoom = Math.round(scale * 100);
        setZoom(zoom);
      }
    );
    setScaledToFit(true);
  };

  const iframeHandler = (e) => {
    const css = `
    @import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
    *{
      margin:0;
      padding:0;
      box-sizing:border-box;

    }
    html{
      height: 100%;
    }
    body{
          display: flex;
         justify-content: center;
         width:100%;
         height: 100%;
    }
    #dropzone{
      width: 100% !important;
      height: auto;
    }
    .item-loading{
      align-self: center;
      font-size: 20px;
      font-weight: 600;
      font-family: lato;
    }

    ::-webkit-scrollbar {
    width: 0.4em;
    height: 0.4em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: #2cc1d6;
}`;
    const link1 = createLink("components.css");
    e.target.contentWindow.document.head.append(link1);
    e.target.contentWindow.document.head.querySelector("style").prepend(css);
    e.target.contentWindow.document.body.style.background = "";
    e.target.contentWindow.document.body.style.padding = "";
  };

  return (
    <>
      {isOpenComments && (
        <Comments
          isOpen={isOpenComments}
          handleClose={handleCloseComments}
          id={emailId}
          type={type}
        />
      )}
      <div className="container-preview">
        <div className="preview-head flex-between">
          <div className="flex-item-sm paddingX">
            <Typography className="w-100" variant="h2">
              {email.name ? email.name : "Please Wait..."}
            </Typography>
          </div>
          <div className="flex-item-fit">
            {props.viewType === "presentation" && (
              <>
                <Typography variant="body1" component="span" className="text">
                  Target Device :
                  <Typography
                    variant="body1"
                    component="span"
                    ml={1}
                    sx={{ verticalAlign: "middle" }}
                  >
                    <BsTablet />;
                  </Typography>
                </Typography>
              </>
            )}

            {ShouldDevicesShow && (
              <Box container className="zoom-selectbox">
                <Tooltip title="Mobile" arrow>
                  <IconButton
                    className={device == "mobile-sm" ? "activeDevice" : ""}
                    onClick={() => setDevice("mobile-sm")}
                  >
                    <AiOutlineMobile />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Desktop" arrow>
                  <IconButton
                    className={device == "desktop" ? "activeDevice" : ""}
                    onClick={() => setDevice("desktop")}
                  >
                    <AiOutlineDesktop />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </div>
          <div className="flex-item-sm paddingX">
            <div className="w-100 text-end  ">
              <Button
                variant="text"
                color="primary"
                sx={{ marginRight: 3 }}
                onClick={handleOpenComments}
                startIcon={<BiCommentDetail color={"#2E3A94"} size={17} />}
              >
                Comments
              </Button>
              {/* It means the component is dependent on dialog then show close button */}
              {isOnDialog && (
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => props.handleClose()}
                  startIcon={<AiOutlineClose color="#2E3A94" size={17} />}
                >
                  Close
                </Button>
              )}
            </div>
          </div>
        </div>
        {!isReviewed && hasPermission('approvalProcess') && (
          <Grid container>
            <Grid item xs={12} md={12} className="preview-head justify">
              {isLoadingApprove ? (
                <CircularProgress size={20} sx={{ marginLeft: 1 }} />
              ) : (
                <Button
                  variant="text"
                  color="success"
                  size="large"
                  onClick={() => props.reviewEmail(true)}
                  loading={true}
                >
                  Approve
                </Button>
              )}

              {isLoadingDeclined ? (
                <CircularProgress size={20} sx={{ marginLeft: 1 }} />
              ) : (
                <Button
                  variant="text"
                  color="error"
                  size="large"
                  onClick={() => props.reviewEmail(false)}
                >
                  Declined
                </Button>
              )}

            </Grid>
          </Grid>
        )}

        <div className="preview-wrapper">
          <div id="preview" className="preview-email">
            {emailData ? (
              <iframe
                onLoad={iframeHandler}
                loading="lazy"
                className={"data " + device}
                srcDoc={setBodyinHTML(
                  getHTMLFromBody(emailData),
                  false,
                  false,
                  true,
                  false
                )}
              ></iframe>
            ) : (
              <div className="item-loading">loading .....</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailPreview;
