export const assetTypes = [
  { label: "All", value: "all" },
  { label: "Image", value: "image" },
  { label: "Audio", value: "audio" },
  { label: "Video", value: "video" },
  { label: "Document", value: "document" },
];

export const assetsFormats = {
  all: {
    value: "All",
    format: [
      { label: "All", value: "all" },
      { label: "JPEG", value: "jpeg" },
      { label: "JPG", value: "jpg" },
      { label: "PNG", value: "png" },
      { label: "jfif", value: "jfif" },
      { label: "MP3", value: "mp3" },
      { label: "OGG", value: "ogg" },
      { label: "WAV", value: "wav" },
      { label: "MP4", value: "mp4" },
      { label: "PDF", value: "pdf" },
    ],
  },
  image: {
    value: "Image",
    format: [
      { label: "All", value: "all" },
      { label: "JPEG", value: "jpeg" },
      { label: "JPG", value: "jpg" },
      { label: "PNG", value: "png" },
      { label: "jfif", value: "jfif" },
    ],
  },
  audio: {
    value: "Audio",
    format: [
      { label: "All", value: "all" },
      { label: "MP3", value: "mp3" },
      { label: "OGG", value: "ogg" },
      { label: "WAV", value: "wav" },
    ],
  },
  video: {
    value: "Video",
    format: [
      { label: "All", value: "all" },
      { label: "MP4", value: "mp4" },
    ],
  },
  document: {
    value: "Document",
    format: [
      { label: "All", value: "all" },
      { label: "PDF", value: "pdf" },
    ],
  },
};
