export const languages = [
  {
    label: "Albanian (SQI)",
    value: "SQI",
  },
  {
    label: "Arabic (ARA)",
    value: "ARA",
  },
  {
    label: "Armenian (HYE)",
    value: "HYE",
  },
  {
    label: "Azerbaijani (AZE)",
    value: "AZE",
  },
  {
    label: "Bengali (BEN)",
    value: "BEN",
  },
  {
    label: "Bosnian (BOS)",
    value: "BOS",
  },
  {
    label: "Bulgarian (BUL)",
    value: "BUL",
  },
  {
    label: "Chinese (ZHO)",
    value: "ZHO",
  },
  {
    label: "Croatian (HRV)",
    value: "HRV",
  },
  {
    label: "Czech (CES)",
    value: "CES",
  },
  {
    label: "Danish (DAN)",
    value: "DAN",
  },
  {
    label: "Dutch (NLD)",
    value: "NLD",
  },
  {
    label: "English (ENG)",
    value: "ENG",
  },
  {
    label: "Finnish (FIN)",
    value: "FIN",
  },
  {
    label: "French (FRA)",
    value: "FRA",
  },
  {
    label: "Georgian (KAT)",
    value: "KAT",
  },
  {
    label: "German (DEU)",
    value: "DEU",
  },
  {
    label: "Greek (ELL)",
    value: "ELL",
  },
  {
    label: "Hindi (HIN)",
    value: "HIN",
  },
  {
    label: "Hungarian (HUN)",
    value: "HUN",
  },
  {
    label: "Indonesian (IDN)",
    value: "IDN",
  },
  {
    label: "Italian (ITA)",
    value: "ITA",
  },
  {
    label: "Japanese (JPN)",
    value: "JPN",
  },
  {
    label: "Korean (KOR)",
    value: "KOR",
  },
  {
    label: "Latvian (LAV)",
    value: "LAV",
  },
  {
    label: "Macedonian (MKD)",
    value: "MKD",
  },
  {
    label: "Montenegrin (SRP)",
    value: "SRP",
  },
  {
    label: "Norwegian (NOR)",
    value: "NOR",
  },
  {
    label: "Polish (POL)",
    value: "POL",
  },
  {
    label: "Portuguese (POR)",
    value: "POR",
  },
  {
    label: "Russian (RUS)",
    value: "RUS",
  },
  {
    label: "Serbian (SRP)",
    value: "SRP",
  },
  {
    label: "Slovak (SLK)",
    value: "SLK",
  },
  {
    label: "Slovenian (SLV)",
    value: "SLV",
  },
  {
    label: "Spanish (SPA)",
    value: "SPA",
  },
  {
    label: "Swedish (SWE)",
    value: "SWE",
  },
  {
    label: "Turkish (TUR)",
    value: "TUR",
  },
  {
    label: "Ukrainian (UKR)",
    value: "UKR",
  },
  {
    label: "Uzbek (UZB)",
    value: "UZB",
  },
  {
    label: "Vietnamese (VIE)",
    value: "VIE",
  },
];

export const countries = [
  {
    label: "Algeria (DZA)",
    value: "DZA",
  },
  {
    label: "APAC (APAC)",
    value: "APAC",
  },
  {
    label: "Argentina (ARG)",
    value: "ARG",
  },
  {
    label: "Austria (AUT)",
    value: "AUT",
  },
  {
    label: "Belarus (BLR)",
    value: "BLR",
  },
  {
    label: "Belgium (BEL)",
    value: "BEL",
  },
  {
    label: "Bolivia (BOL)",
    value: "BOL",
  },
  {
    label: "Brazil (BRA)",
    value: "BRA",
  },
  {
    label: "Canada (CAN)",
    value: "CAN",
  },
  {
    label: "CENAM (CENAM)",
    value: "CENAM",
  },
  {
    label: "Chile (CHL)",
    value: "CHL",
  },
  {
    label: "Colombia (COL)",
    value: "COL",
  },
  {
    label: "Costa Rica (CRI)",
    value: "CRI",
  },
  {
    label: "Cuba (CUB)",
    value: "CUB",
  },
  {
    label: "Czech Republic (CZE)",
    value: "CZE",
  },
  {
    label: "Denmark (DNK)",
    value: "DNK",
  },
  {
    label: "Dominican Republic (DOM)",
    value: "DOM",
  },
  {
    label: "Ecuador (ECU)",
    value: "ECU",
  },
  {
    label: "Egypt (EGY)",
    value: "EGY",
  },
  {
    label: "El Salvador (SLV)",
    value: "SLV",
  },
  {
    label: "Finland (FIN)",
    value: "FIN",
  },
  {
    label: "France (FRA)",
    value: "FRA",
  },
  {
    label: "French Guiana (GUF)",
    value: "GUF",
  },
  {
    label: "Georgia (GEO)",
    value: "GEO",
  },
  {
    label: "Germany (DEU)",
    value: "DEU",
  },
  {
    label: "Global (GBL)",
    value: "GBL",
  },
  {
    label: "Greece (GRC)",
    value: "GRC",
  },
  {
    label: "Guadeloupe (GLP)",
    value: "GLP",
  },
  {
    label: "Guatemala (GTM)",
    value: "GTM",
  },
  {
    label: "Gulf (GLF)",
    value: "GLF",
  },
  {
    label: "Haiti (HTI)",
    value: "HTI",
  },
  {
    label: "Honduras (HND)",
    value: "HND",
  },
  {
    label: "Hong Kong (HKG)",
    value: "HKG",
  },
  {
    label: "Hungary (HUN)",
    value: "HUN",
  },
  {
    label: "India (IND)",
    value: "IND",
  },
  {
    label: "Indonesia (IDN)",
    value: "IDN",
  },
  {
    label: "Ireland (IRL)",
    value: "IRL",
  },
  {
    label: "Israel (ISR)",
    value: "ISR",
  },
  {
    label: "Italy (ITA)",
    value: "ITA",
  },
  {
    label: "Kazakhstan (KAZ)",
    value: "KAZ",
  },
  {
    label: "Korea (KOR)",
    value: "KOR",
  },
  {
    label: "Latvia (LVA)",
    value: "LVA",
  },
  {
    label: "Malaysia (MYS)",
    value: "MYS",
  },
  {
    label: "Martinique (MTQ)",
    value: "MTQ",
  },
  {
    label: "Mexico (MEX)",
    value: "MEX",
  },
  {
    label: "Montenegro (MNE)",
    value: "MNE",
  },
  {
    label: "Morocco (MAR)",
    value: "MAR",
  },
  {
    label: "Netherlands (NLD)",
    value: "NLD",
  },
  {
    label: "Nicaragua (NIC)",
    value: "NIC",
  },
  {
    label: "Norway (NOR)",
    value: "NOR",
  },
  {
    label: "Pakistan (PAK)",
    value: "PAK",
  },
  {
    label: "Panama (PAN)",
    value: "PAN",
  },
  {
    label: "Paraguay (PRY)",
    value: "PRY",
  },
  {
    label: "Peru (PER)",
    value: "PER",
  },
  {
    label: "Philippines (PHL)",
    value: "PHL",
  },
  {
    label: "Poland (POL)",
    value: "POL",
  },
  {
    label: "Portugal (PRT)",
    value: "PRT",
  },
  {
    label: "Puerto Rico (PRI)",
    value: "PRI",
  },
  {
    label: "Romania (ROU)",
    value: "ROU",
  },
  {
    label: "Russian Federation (RUS)",
    value: "RUS",
  },
  {
    label: "Saint Barthélemy (BLM)",
    value: "BLM",
  },
  {
    label: "Saint Martin (MAF)",
    value: "MAF",
  },
  {
    label: "Saudi Arabia (SAU)",
    value: "SAU",
  },
  {
    label: "Singapore (SGP)",
    value: "SGP",
  },
  {
    label: "Slovakia (SVK)",
    value: "SVK",
  },
  {
    label: "South Africa (ZAF)",
    value: "ZAF",
  },
  {
    label: "Spain (ESP)",
    value: "ESP",
  },
  {
    label: "Sweden (SWE)",
    value: "SWE",
  },
  {
    label: "Switzerland (CHE)",
    value: "CHE",
  },
  {
    label: "Taiwan (TWN)",
    value: "TWN",
  },
  {
    label: "Thailand (THA)",
    value: "THA",
  },
  {
    label: "Tunisia (TUN)",
    value: "TUN",
  },
  {
    label: "Turkey (TUR)",
    value: "TUR",
  },
  {
    label: "UAE (UAE)",
    value: "UAE",
  },
  {
    label: "Ukraine (UKR)",
    value: "UKR",
  },
  {
    label: "United Kindom (GBR)",
    value: "GBR",
  },
  {
    label: "Uruguay (URY)",
    value: "URY",
  },
  {
    label: "Uzbekistan (UZB)",
    value: "UZB",
  },
  {
    label: "Venezuela (VEN)",
    value: "VEN",
  },
  {
    label: "Vietnam (VNM)",
    value: "VNM",
  },
];

export const timezones = [
  {
    value: -12,
    label: "(GMT -12:00) Eniwetok, Kwajalein",
  },
  {
    value: -11,
    label: "(GMT -11:00) Midway Island, Samoa",
  },
  {
    value: -10,
    label: "(GMT -10:00) Hawaii",
  },
  {
    value: -9,
    label: "(GMT -9:00) Alaska",
  },
  {
    value: -8,
    label: "(GMT -8:00) Pacific Time (US & Canada)",
  },
  {
    value: -7,
    label: "(GMT -7:00) Mountain Time (US & Canada)",
  },
  {
    value: -6,
    label: "(GMT -6:00) Central Time (US & Canada), Mexico City",
  },
  {
    value: -5,
    label: "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima",
  },
  {
    value: -4,
    label: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz",
  },
  {
    value: -3.5,
    label: "(GMT -3:30) Newfoundland",
  },
  {
    value: -3,
    label: "(GMT -3:00) Brazil, Buenos Aires, Georgetown",
  },
  {
    value: -2,
    label: "(GMT -2:00) Mid-Atlantic",
  },
  {
    value: -1,
    label: "(GMT -1:00) Azores, Cape Verde Islands",
  },
  {
    value: 0,
    label: "(GMT) Western Europe Time, London, Lisbon, Casablanca",
  },
  {
    value: "+1",
    label: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris",
  },
  {
    value: "+2",
    label: "(GMT +2:00) Kaliningrad, South Africa",
  },
  {
    value: "+3",
    label: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg",
  },
  {
    value: "+3.5",
    label: "(GMT +3:30) Tehran",
  },
  {
    value: "+4",
    label: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi",
  },
  {
    value: "+4.5",
    label: "(GMT +4:30) Kabul",
  },
  {
    value: "+5",
    label: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent",
  },
  {
    value: "+5.5",
    label: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi",
  },
  {
    value: "+5.75",
    label: "(GMT +5:45) Kathmandu",
  },
  {
    value: "+6",
    label: "(GMT +6:00) Almaty, Dhaka, Colombo",
  },
  {
    value: "+7",
    label: "(GMT +7:00) Bangkok, Hanoi, Jakarta",
  },
  {
    value: "+8",
    label: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong",
  },
  {
    value: "+9",
    label: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk",
  },
  {
    value: "+9.5",
    label: "(GMT +9:30) Adelaide, Darwin",
  },
  {
    value: "+10",
    label: "(GMT +10:00) Eastern Australia, Guam, Vladivostok",
  },
  {
    value: "+11",
    label: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia",
  },
  {
    value: "+12",
    label: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka",
  },
];

export const weekDays = [
  { title: "Monday", value: "monday" },
  { title: "Tuesday", value: "tuesday" },
  { title: "Wednesday", value: "wednesday" },
  { title: "Thursday", value: "thursday" },
  { title: "Friday", value: "friday" },
  { title: "Saturday", value: "saturday" },
  { title: "Sunday", value: "sunday" },
];

export const monthDays = [
  {
    label: 1,
    value: "1",
  },
  {
    label: 2,
    value: "2",
  },
  {
    label: 3,
    value: "3",
  },
  {
    label: 4,
    value: "4",
  },
  {
    label: 5,
    value: "5",
  },
  {
    label: 6,
    value: "6",
  },
  {
    label: 7,
    value: "7",
  },
  {
    label: 8,
    value: "8",
  },
  {
    label: 9,
    value: "9",
  },
  {
    label: 10,
    value: "10",
  },
  {
    label: 11,
    value: "11",
  },
  {
    label: 12,
    value: "12",
  },
  {
    label: 13,
    value: "13",
  },
  {
    label: 14,
    value: "14",
  },
  {
    label: 15,
    value: "15",
  },
  {
    label: 16,
    value: "16",
  },
  {
    label: 17,
    value: "17",
  },
  {
    label: 18,
    value: "18",
  },
  {
    label: 19,
    value: "19",
  },
  {
    label: 20,
    value: "20",
  },
  {
    label: 21,
    value: "21",
  },
  {
    label: 22,
    value: "22",
  },
  {
    label: 23,
    value: "23",
  },
  {
    label: 24,
    value: "24",
  },
  {
    label: 25,
    value: "25",
  },
  {
    label: 26,
    value: "26",
  },
  {
    label: 27,
    value: "27",
  },
  {
    label: 28,
    value: "28",
  },
  {
    label: 29,
    value: "29",
  },
  {
    label: 30,
    value: "30",
  },
  {
    label: 31,
    value: "31",
  },
];

export const targetSystem = [
  { value: "Salesforce", label: "Salesforce" },
  { value: "Salesforce Marketing Cloud", label: "Salesforce Marketing Cloud" },
];