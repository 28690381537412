import React from "react";
import "src/styles/unitboxes/unitchecbox.scss";

const UnitChecbox = (props) => {
  const { onChange, items = [], getActive = () => {} } = props;

  return (
    <div className="unit-checbox">
      {items.map((str ,i) => {
        const active = getActive(str);
        return (
          <div
            key={i}
            onClick={(e) => {
              onChange && onChange(e, str);
            }}
            className={`unit-checbox-item ${active ? "active" : ""}`}
          >
            {str}
          </div>
        );
      })}
    </div>
  );
};

export default UnitChecbox;
