import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  Grid,
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import SelectBox from "src/components/ui/formElements/SelectBox";
import Input from "src/components/ui/formElements/Input";
import Content from "src/layouts/Content";
import { fetchReviews, filterReview } from "src/store/actions/reviews";
import Pagination from "../../components/ui/Pagination";

const statusOptions = [
  { label: "All", value: 'all' },
  { label: "In-Review", value: "inReview" },
  { label: "Reviewed", value: "reviewed" },
  { label: "Declined", value: "declined" },
  { label: "Canceled", value: "canceled" },
  { label: "Expired", value: "expired" },

];

const Reviews = ({ children, type, subtitle, count, loadingMsg }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [status, setStatus] = useState('inReview');
  const [searchParams, setSearchParams] = useSearchParams();

  const cancel = () => {
    setName("");
    setStatus('inReview');
    dispatch(fetchReviews({ limit: 10, skip: 0, type, status: 'inReview' }));
  };

  useEffect(() => {
    const limit = 10;
    const pageNo = Math.round(Number(searchParams.get("page"))) || 1;
    const skip = (pageNo - 1) * limit;
    applyFilter({ limit, skip })
  }, [type]);

  const applyFilter = ({ limit, skip }) => {
    const filters = { limit, skip, type, name, status }
    if (!limit) {
      filters.limit = 10
      filters.skip = 0
    }
    if (filters.status == 'all') {
      delete filters.status
    }
    dispatch(fetchReviews(filters));
  };

  return (
    <Content title="Reviews" subtitle={subtitle}>
      <Box className="inlineForm">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={6} md={4}>
            <Box className="ControlWrap">
              <Input
                type="text"
                placeholder={`Search ${type}`}
                labelTag="Keyword"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={4}>
            <Box className="ControlWrap">
              <SelectBox
                options={statusOptions}
                labelTag="Status"
                defaultValue="inReview"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              />
            </Box>
          </Grid>
          <Grid item xs={4} textAlign="end">
            <Button
              className="cancelBtn"
              variant="text"
              onClick={cancel}
              // disabled={!name && status === "inReview"}
            >
              Cancel
            </Button>
            <Button
              color="secondary"
              variant="contained"
              // disabled={!name && status === "inReview"}
              onClick={applyFilter}
            >
              Apply Filter
            </Button>
          </Grid>
        </Grid>
      </Box>
      <br/>
      <Grid style={{ mt: 2 }} className="assetReviewTable">
        <Grid item xs={12}>
          <TableContainer component={Paper} className="assetReviewContainer" sx={{ maxHeight: "47vh" }}>
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className="tableHead">PREVIEW</TableCell>
                  <TableCell className="tableHead columnSize">NAME</TableCell>
                  <TableCell className="tableHead columnSize">OWNER</TableCell>
                  <TableCell className="tableHead columnSize">
                    STATUS CHANGED ON
                  </TableCell>
                  <TableCell className="tableHead columnSize">STATUS</TableCell>
                  <TableCell className="tableHead" sx={{ width: "10%" }}>
                    ACTIONS
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loadingMsg ? (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      sx={{
                        textAlign: "center",
                        fontSize: "24px",
                        marginX: "20px",
                      }}
                    >
                      {loadingMsg}
                    </TableCell>
                  </TableRow>
                ) : (
                  children
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Pagination
        count={count}
        onChange={(value) => {
          applyFilter({ ...value });
        }}
      />
    </Content>
  );
};

export default React.memo(Reviews);
