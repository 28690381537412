import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, Grid, Portal, Typography } from "@mui/material";
import upload from "../../assets/images/upload.png";
import Main from "./Main";
import SelectBox from "../ui/formElements/SelectBox";
import Input from "../ui/formElements/Input";
import { uiActions } from "src/store/reducers/ui-slice";
import "../../styles/Upload.scss";

const UploadNew = (props) => {
  const { show, title, onUpload, type, fileType, fieldsWidth } = props;
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("fill file from your computer here");
  const [data, setData] = useState({
    country: "",
    lang: "",
    targetSystem: "",
    targetDevice: "",
  });

  const dispatch = useDispatch();
  const inputFile = useRef();

  const closeDialog = () => {
    setFile("");
    setFileName("fill file from your computer here");
    props.close();
  };

  const browseFileHandler = () => {
    inputFile.current.click();
  };

  const DragOverHandler = (e) => {
    e.preventDefault();
    e.currentTarget.classList.add("dragOver");
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    e.currentTarget.classList.remove("dragOver");
  };

  const submitHandler = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    data.set("file", file);
    const fileData = data.get("file");
    if (
      fileData &&
      (fileData.type == "application/x-zip-compressed" ||
        fileData.type == "application/zip")
    ) {
      if (onUpload) {
        onUpload(data);
        setFile("");
        setFileName("fill file from your computer here");
        setData({
          country: "",
          lang: "",
          targetSystem: "",
          targetDevice: "",
        });
      }
      props.close();
    } else {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: `Select ${type}`,
          message: "Please Select a Zip File",
        })
      );
    }
  };

  const handleFileChange = (e) => {
    if (e.target?.files?.[0]) {
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    }
  };

  // Drag & Drop Functionality
  const uploadOnDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer?.files?.[0]) {
      setFile(e.dataTransfer.files[0]);
      setFileName(e.dataTransfer.files[0].name);
    }
  };

  const handleChange = (e) => {
    setData((pre) => ({ ...pre, [e.target.name]: [e.target.value] }));
  };

  return (
    <Main isOpen={show} handleClose={closeDialog} modalWidth="md">
      <Box className="dialogBody">
        <Typography variant="h3" className="uploadHead">
          {title}
        </Typography>
        <div className="divider"></div>
        <form onSubmit={submitHandler}>
          <Grid container my={3} justifyContent="center">
            <Grid item xs={12} md={8}>
              <div
                className={`${file ? "dragOver" : ""} uploadArea`}
                onClick={browseFileHandler}
                onDragOver={DragOverHandler}
                onDrop={uploadOnDrop}
                onDragLeave={onDragLeave}
              >
                <input
                  type="file"
                  name="file"
                  id="uploadAsset"
                  onChange={handleFileChange}
                  hidden
                  accept={fileType}
                  ref={inputFile}
                />

                <img src={upload} className="uploadImage" alt="upload" />
                <Typography textAlign="center" className="heading">
                  drag &amp; drop
                </Typography>
                <Typography
                  textAlign="center"
                  className={`subHeading ${file ? "fileName" : ""}`}
                >
                  {fileName}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end">
            <Button type="submit" variant="contained">
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Main>
  );
};

export default React.memo(UploadNew);

UploadNew.defaultProps = {
  fieldsWidth: 3,
  fileType: ".zip",
};
