import React, { useEffect, useState } from "react";
import Content from "src/layouts/Content";
import Card from "src/components/ui/Card";
import Input from "src/components/ui/formElements/Input";
import {
  MenuItem,
  FormControl,
  Select,
  Grid,
  Button,
  Box,
  Typography,
} from "@mui/material";
import ContentAction from "src/components/ui/formElements/ContentAction";
import { IoCloudUploadOutline } from "react-icons/io5";
import UploadContact from "./UploadContact";
import { createContact, fetchAccounts } from "src/store/actions/campaign";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { languages, countries } from "../../../data/data";
import { uiActions } from "src/store/reducers/ui-slice";

const CreateContact = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [accounts, setAccounts] = useState([]);
  const [selectAccount, setSelectAccount] = useState(
    location.state ? location.state.selectedAccount : ""
  );

  useEffect(() => {
    dispatch(fetchAccounts()).then((res) => {
      setAccounts(res.payload);
    });
  }, [dispatch]);

  const submitCreateContact = async (e) => {
    e.preventDefault();
    const data = {
      firstName: e.target.firstName.value,
      middleName: e.target.middleName.value,
      lastName: e.target.lastName.value,
      email: e.target.email.value,
      mobile: e.target.mobile.value,
      phone: e.target.phone.value,
      fax: e.target.fax.value,
      lang: e.target.lang.value,
      account: e.target.account.value,
      address: {
        street: e.target.street.value,
        country: e.target.country.value,
        state: e.target.state.value,
        city: e.target.city.value,
        postalCode: e.target.postalCode.value,
      },
    };
    await dispatch(createContact(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        navigate("/campaign/contacts");
      } else {
        dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Contact Error!",
            message: `Email Already Exists`,
          })
        );
      }
    });
  };

  return (
    <>
      <Grid container gap={4}>
        <Grid item xs={12}>
          <Content title="Create Contact">
            <Card classes="campaign">
              <form onSubmit={submitCreateContact}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Input
                      name="firstName"
                      className="input_box"
                      labelTag="First Name *"
                      required={true}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Input
                      name="middleName"
                      className="input_box"
                      labelTag="Middle Name"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      name="lastName"
                      className="input_box"
                      labelTag="Last Name *"
                      required={true}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Input
                      name="email"
                      className="input_box"
                      labelTag="Email *"
                      type="email"
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      name="mobile"
                      type="number"
                      className="input_box"
                      labelTag="Mobile"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      name="phone"
                      type="number"
                      className="input_box"
                      labelTag="Phone"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input name="fax" className="input_box" labelTag="Fax" />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Language</Typography>
                    <FormControl className="input_box" fullWidth>
                      <Select
                        name="lang"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        defaultValue=""
                      >
                        {languages &&
                          languages.map((data, index) => {
                            return (
                              <MenuItem
                                value={`${data.name}`}
                                key={`${index}`}
                              >{`${data.name}`}</MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      name="street"
                      className="input_box"
                      labelTag="Street "
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Country </Typography>
                    <FormControl className="input_box" fullWidth>
                      <Select
                        name="country"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        defaultValue=""
                      >
                        {countries &&
                          countries.map((data, index) => {
                            return (
                              <MenuItem
                                value={`${data.name}`}
                                key={`${index}`}
                              >{`${data.name}`}</MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      name="state"
                      className="input_box"
                      labelTag="State "
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input name="city" className="input_box" labelTag="City " />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      name="postalCode"
                      className="input_box"
                      labelTag="Postal Code "
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Typography>Select Account *</Typography>
                    <FormControl required className="input_box" fullWidth>
                      <Select
                        name="account"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={accounts.length >= 1 ? selectAccount : ""}
                        onChange={(e) => setSelectAccount(e.target.value)}
                      >
                        {accounts &&
                          accounts.map((data) => {
                            return (
                              <MenuItem
                                value={`${data._id}`}
                                key={`${data._id}`}
                              >{`${data.name}`}</MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    md={12}
                    display="flex"
                    className="d-flex btn-align"
                  >
                    <Box>
                      <Button
                        variant="contained"
                        type="submit"
                        className="campaignBtn"
                      >
                        Create Contact
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Card>
          </Content>
        </Grid>
      </Grid>
    </>
  );
};

export default CreateContact;
