import React from "react";
import { Grid, Box, Typography } from "@mui/material";

import { dateFormat } from "src/services/utils";
import { getThumbnailByFormat } from "src/services/utils";

import Status from "./Status";

import "src/styles/presentations/details.scss";

const Detail = (props) => {
  const { detail, Action, showStatus } = props;
  let thumbnail = detail?.thumbnail;

  if (detail?.bucketURL) {
    thumbnail = detail.bucketURL + "/" + thumbnail;
  }

  if (!thumbnail && detail.URL) {
    let format = detail.URL.split(".").at(-1);
    thumbnail = getThumbnailByFormat(format, thumbnail);
  }
  return (
    <Box className="sub-details">
      <Grid container>
        <Grid item xs={4} sx={{ display: "flex" }}>
          <Box className="image-wrap-micro">
            <img src={thumbnail}/>
          </Box>
          <Box className="imageText">
            <Typography variant="h2" className="details-heading">
              {detail.name}
            </Typography>

            {showStatus && <Status status={detail.status} />}
            <Typography
              component="div"
              className="createdby"
              marginTop={showStatus ? "0px" : "20px"}
            >
              Created By
            </Typography>
            <Typography className="creator" component="div">
              {`${detail.createdBy?.firstName} ${detail.createdBy?.lastName}`}
            </Typography>
          </Box>
          <Box className="vertical-line"></Box>
        </Grid>
        <Grid item xs={2} className="gridItem">
          <Box>
            <Typography className="subheading">Created On</Typography>
            <Typography component="div" className="subheadingtext">
              {dateFormat(detail.createdAt)}
            </Typography>
            <Typography className="subheading">Last Updated</Typography>
            <Typography component="div" className="subheadingtext">
              {dateFormat(detail.updatedAt)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2} className="gridItem">
          {detail.lang && (
            <>
              <Typography className="subheading">Language</Typography>
              <Typography component="div" className="subheadingtext">
                {detail.lang}
              </Typography>
            </>
          )}
          {detail.country && (
            <>
              <Typography className="subheading">Country</Typography>
              <Typography component="div" className="subheadingtext">
                {detail.country}
              </Typography>
            </>
          )}
        </Grid>
        {Action && (
          <Grid item xs={2} className="clonegrid">
            {Action}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Detail;

Detail.defaultProps = {
  showStatus: true,
};
