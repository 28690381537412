import React from "react";
import Content from "src/layouts/Content";
import Card from "src/components/ui/Card";
import Input from "src/components/ui/formElements/Input";
import { Grid, Button, Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { createAccount } from "src/store/actions/campaign";
import { useNavigate } from "react-router-dom";
import { uiActions } from "src/store/reducers/ui-slice";

const CreateAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const submitCreateAccount = async (e) => {
    e.preventDefault();
    const data = {
      name: e.target.name.value,
    };
    await dispatch(createAccount(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        navigate("/campaign/accounts");
      } else {
        dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Account Error!",
            message: `Account Already Exists`,
          })
        );
      }
    });
  };

  return (
    <Grid container gap={4}>
      <Grid item xs={12}>
        <Content title="Create Account">
          <Card classes="campaign">
            <form onSubmit={submitCreateAccount}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Input
                    name="name"
                    className="input_box"
                    labelTag="Name *"
                    required={true}
                  />
                </Grid>

                <Grid item md={12} display="flex" className="d-flex btn-align">
                  <Box>
                    <Button
                      variant="contained"
                      type="submit"
                      className="campaignBtn"
                    >
                      Create Account
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Content>
      </Grid>
    </Grid>
  );
};

export default CreateAccount;
