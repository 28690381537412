import React, { useEffect, useState } from "react";
import { FormControl, MenuItem, Select, Slider } from "@mui/material";
import { convertRgbAToHexAplha } from "src/services/editor.service";
import Control from "./Control";
import Row from "src/components/properties/Row";
import "src/styles/properties.scss";
import Select_Box from "src/components/common/Select_Box";

const DividerBorder = ({ elementId, updateHistory, undoRedoChange }) => {
  const element = document.getElementById(elementId);

  const [dividerProperties, setDividerProperties] = useState({
    color: "gray",
    height: 1,
    style: "solid",
  });

  useEffect(() => {
    const elementStyles = getComputedStyle(element);
    const dividerHeight = Math.round(
      getComputedStyle(element).borderTopWidth.split("px")[0]
    );
    const dividerColor = convertRgbAToHexAplha(elementStyles.borderTopColor).hex;
    const dividerStyle = elementStyles.borderTopStyle;

    setDividerProperties((prev) => ({
      ...prev,
      color: dividerColor,
      height: dividerHeight,
      style: dividerStyle,
    }));
  }, [elementId, undoRedoChange]);

  let timeOut;
  const colorHandler = (event) => {
    clearTimeout(timeOut);
    const { value } = event.target;
    element.style.borderTopColor = value;
    timeOut = setTimeout(() => {
      setDividerProperties((prev) => ({ ...prev, color: value }));
    }, 200);
    updateHistory();
  };

  const heightHandler = (event) => {
    const { value } = event.target;
    if (value && value > 0) {
      element.style.borderTopWidth = value + "px";
      setDividerProperties((prev) => ({ ...prev, height: value }));
    } else {
      element.style.borderTopWidth = 1 + "px";
      setDividerProperties((prev) => ({ ...prev, height: 1 }));
    }
    updateHistory();
  };

  const styleHandler = (event) => {
    const { value } = event.target;
    element.style.borderTopStyle = value;
    setDividerProperties((prev) => ({ ...prev, style: value }));
    updateHistory();
  };

  const dividerStyles = [
    { label: "Solid", value: "solid" },
    { label: "Dotted", value: "dotted" },
    { label: "Dashed", value: "dashed" },
  ];

  return (
    <>
      <Control
        heading="Divider"
        isCollapse={true}
        divider={false}
        expanded={false}
      >
        <Row
          label="Divider Style"
          element={
            <Select_Box
              value={dividerProperties.style}
              onChange={styleHandler}
              options={dividerStyles}
            />
          }
        />
        <Row
          label="Divider Height"
          element={
            <input
              type="number"
              className="input"
              value={dividerProperties.height}
              onChange={heightHandler}
            />
          }
        />
        <Row
          label="Divider Color"
          element={
            <input
              type="color"
              className="color"
              value={dividerProperties.color}
              onChange={colorHandler}
            />
          }
        />
      </Control>
    </>
  );
};

export default DividerBorder;
