import { useDispatch, useSelector } from "react-redux";
import { createEntry } from "./actionsEntryCreator";
import { editorActions } from "src/store/reducers/editor-slice";
import { getElementBackUpDetails } from "../shared-logic";

export const useEditorHistoryUpdater = () => {
  const dispatch = useDispatch();
  const { activeEditable } = useSelector((state) => state.editor);

  // To Update History
  const updateHistory = (data = {}) => {
    const updatedActiveEditable = data.activeEditable || activeEditable;

    const actionEntry = createEntry({
      data,
      activeEditable: updatedActiveEditable,
    });

    const backupDetails = getElementBackUpDetails(actionEntry);

    dispatch(editorActions.updateHistory({ actionEntry, backupDetails }));
  };

  return { updateHistory };
};
