import classes from "./SwitchButton.module.css";
import { FormGroup, FormControlLabel, Switch } from "@mui/material";

const SwitchButton = (props) => {
  const { inputName, labelText, switchHandler, checked } = props;
  return (
    <FormGroup>
      <FormControlLabel
        control={<Switch onChange={switchHandler} checked={checked}/>}
        label={props.label}
      />
    </FormGroup>
  );
};

export default SwitchButton;
