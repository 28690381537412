import React, { useRef } from "react";
import { Box, Typography } from "@mui/material";
import upload from "../../assets/images/upload.png";
import "../../styles/Upload.scss";

import Main from "./Main";

const UploadNewVersion = (props) => {
  const { show, title, id , onUpload} = props;
  const uploadVersion = useRef();

  const closeDialog = () => {
    props.close();
  };

  const sendFormData = (file) => {
    const data = new FormData();
    data.append("file", file);
    const obj = {id , data}
    if(onUpload){
      onUpload(obj)
    }
    props.close();
  };

  const browseFileHandler = () => {
    uploadVersion.current.click();
  };

  const uploadImage = (e) => {
    const file = e.target.files[0];
    sendFormData(file);
  };

  // Drag & Drop Functionality
  const uploadOnDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    sendFormData(file);
  };

  const DragOverHandler = (e) => {
    e.preventDefault();
    e.currentTarget.classList.add("dragOver");
  };
  const DragLeaveHandler = (e) => {
    e.currentTarget.classList.remove("dragOver");
  };

  return (
    <Main className="uploadPresentaion" isOpen={show} handleClose={closeDialog}>
      <Box className="dialogBody">
        <Typography variant="h3" className="uploadHead">
          {title}
        </Typography>
        <div className="divider"></div>
        <div
          className="uploadArea"
          onClick={browseFileHandler}
          onDragOver={DragOverHandler}
          onDragLeave={DragLeaveHandler}
          onDrop={uploadOnDrop}
        >
          <input
            type="file"
            name="uploadAsset"
            id="uploadAsset"
            hidden
            accept=".zip,.rar,.7zip"
            onChange={uploadImage}
            ref={uploadVersion}
          />

          <img src={upload} className="uploadImage" alt="upload" />
          <Typography textAlign="center" className="heading">
            drag &amp; drop
          </Typography>
          <Typography textAlign="center" className="subHeading">
            fill file from your computer here
          </Typography>
        </div>
      </Box>
    </Main>
  );
};

export default React.memo(UploadNewVersion);
