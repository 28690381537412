import React from "react";
import "src/styles/unitboxes/autocheckbox.scss";

const AutoCheckBox = (props) => {
  const { checked, onChange = null } = props;
  return (
    <input
      className="auto-checkbox"
      type="checkbox"
      checked={checked}
      onChange={onChange}
    />
  );
};

export default AutoCheckBox;
