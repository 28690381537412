import { languages } from "src/data/data";
import { objToParams } from "src/services/utils";
import { reArangeFilters } from "src/utils/Utils";
// export const apiURL = "https://api.createsmart.io/api/v1.0";
export const apiURL = process.env.REACT_APP_API_BASE_URL // "http://localhost:4000/api/v1.0";
// export const apiURL = "http://192.168.50.101:3002/api/v1.0";
console.log('apiURL =>', apiURL);
export const api = {
  login: () => {
    return {
      url: `${apiURL}/users/login`,
      method: "post",
    };
  },
  logout: () => {
    return {
      url: `${apiURL}/users/logout`,
      method: "post",
    };
  },
  forgotPassword: () => {
    return {
      url: `${apiURL}/users/forgot`,
      method: "post",
    };
  },
  activateAccount: () => {
    return {
      url: `${apiURL}/users/set-password`,
      method: "post",
    };
  },
  changePassword: () => {
    return {
      url: `${apiURL}/users/change-password/`,
      method: "put",
    };
  },
  newActivationToken: () => {
    return {
      url: `${apiURL}/users/new-activation-token`,
      method: "post",
    };
  },
  getChaptersSettings: (id) => {
    return {
      url: `${apiURL}/chapters/${id}/settings`,
      method: "get",
    };
  },
  getSlidesSettings: (id) => {
    return {
      url: `${apiURL}/slides/${id}`,
      method: "get",
    };
  },
  updatePresentationSetting: (id) => {
    return {
      url: `${apiURL}/presentations/${id}`,
      method: "put",
    };
  },

  fetchTemplates: (targetDevice, type) => {
    return {
      url: `${apiURL}/templates?targetDevice=${targetDevice}&type=${type}`,
      method: "get",
    };
  },

  fetchSlideTemplates: (targetDevice) => {
    return {
      url: `${apiURL}/templates/slides?targetDevice=${targetDevice}`,
      method: "get",
    };
  },

  fetchTemplateCategory: (type) => {
    return {
      url: `${apiURL}/template-category?type=${type}`,
      method: "get",
    };
  },
  fetchTemplatesByCategory: (categoryId, type) => {
    return {
      url: `${apiURL}/templates/${categoryId}?type=${type}`,
      method: "get",
    };
  },
  fetchVersions: (id, type) => {
    return {
      url: `${apiURL}/${type}s/${id}/versions?type=${type}`,
      method: "get",
    };
  },
  sharing: {
    fetch: (params) => {
      return {
        url: `${apiURL}/sharings/withuser${objToParams(params)}`,
        method: "get",
      };
    },
    getSettings: (id, type) => {
      return {
        url: `${apiURL}/sharings/${id}?type=${type}`,
        method: "get",
      };
    },
    share: (id, type) => {
      return {
        url: `${apiURL}/sharings/${id}?type=${type}`,
        method: "post",
      };
    },
    removeSharingUser: (id, userId, type) => {
      return {
        url: `${apiURL}/sharings/${id}/removeuser/${userId}?type=${type}`,
        method: "put",
      };
    },
  },
  presentations: {
    fetch: (params) => {
      return {
        url: `${apiURL}/presentations${objToParams(params)}`,
        method: "get",
      };
    },
    create: () => {
      return {
        url: `${apiURL}/presentations`,
        method: "post",
      };
    },
    clone: (id) => {
      return {
        url: `${apiURL}/presentations/${id}/clone`,
        method: "post",
      };
    },

    getPresentaionById: (id) => {
      return {
        url: `${apiURL}/presentations/${id}`,
        method: "get",
      };
    },
    preview: () => {
      return {
        url: `${apiURL}/preview/presentations/`,
        method: "get",
      };
    },
    previewHTML: (slideId) => {
      return {
        url: `${apiURL}/preview/presentations/${slideId}`,
        method: "get",
      };
    },
    getStructure: (id) => {
      return {
        url: `${apiURL}/presentations/${id}/structure`,
        method: "get",
      };
    },
    globalSearch: ({ limit, skip }) => {
      return {
        url: `${apiURL}/global/search?limit=${limit}&skip=${skip}`,
        method: "put",
      };
    },
    createSlide: (presentationId) => {
      return {
        url: `${apiURL}/presentations/${presentationId}/slide`,
        method: "post",
      };
    },
    saveSlide: (presentationId, slideId) => {
      return {
        url: `${apiURL}/presentations/${presentationId}/${slideId}`,
        method: "put",
      };
    },
    getSlide: (presentationId, slideId) => {
      return {
        url: `${apiURL}/presentations/${presentationId}/${slideId}`,
        method: "get",
      };
    },
    cloneSlide: (presentationId, slideId) => {
      return {
        url: `${apiURL}/presentations/${presentationId}/slide/${slideId}/clone`,
        method: "post",
      };
    },
    createChapter: (presentationId) => {
      return {
        url: `${apiURL}/presentations/${presentationId}/chapter`,
        method: "post",
      };
    },
    updateStructure: (presentationId) => {
      return {
        url: `${apiURL}/presentation/${presentationId}/structure`,
        method: "put",
      };
    },
    updateChapterSetting: (presentationId, chapterIndex) => {
      return {
        url: `${apiURL}/presentation/${presentationId}/chapter/${chapterIndex}`,
        method: "put",
      };
    },
    updateSlideSetting: (id) => {
      return {
        url: `${apiURL}/presentation/${id.presentationId}/chapter/${id.chapterIndex}/${id.slideIndex}`,
        method: "put",
      };
    },
    deleteChapter: (presentationId, chapterIndex) => {
      return {
        url: `${apiURL}/presentation/${presentationId}/chapter/${chapterIndex}`,
        method: "delete",
      };
    },
    deleteSlide: (presentationId, slideId, chapterId) => {
      return {
        url: `${apiURL}/presentation/${presentationId}/chapter/${chapterId}/slide/${slideId}`,
        method: "delete",
      };
    },
    downloadSource: (id, type) => {
      return {
        url: `${apiURL}/presentations/${id}/download/${type ? type : "sourcecode"
          }`,
        method: "get",
      };
    },
    downloadVersionSource: (presentationId, versionId) => {
      return {
        url: `${apiURL}/presentations/${presentationId}/download/${versionId}`,
        method: "get",
      };
    },
    uploadNewVersion: (presentationId) => {
      return {
        url: `${apiURL}/presentations/${presentationId}/import`,
        method: "post",
      };
    },
    exportPresentation: ({ id, exportAs, resolution }) => {
      return {
        url: `${apiURL}/presentations/${id}/download-${exportAs}${objToParams(resolution)}`,
        method: "get",
      };
    },
    importPresentation: () => {
      return {
        url: `${apiURL}/presentations/import/new`,
        method: "post",
      };
    },
    deletePresentation: (id) => {
      return {
        url: `${apiURL}/presentation/${id}`,
        method: "delete",
      };
    },
    getReviewed: () => {
      return {
        url: `${apiURL}/presentations?status=reviewed`,
        method: "get",
      };
    },
    sendAnalytics: () => {
      return {
        url: `${apiURL}/send-analytics`,
        method: "put",
      };
    },
  },

  user: {
    getAllCompanyUsers: (companyId) => {
      return {
        url: `${apiURL}/users/${companyId}`,
        method: "get",
      };
    },
    updateUser: (id) => {
      return {
        url: `${apiURL}/users/${id}`,
        method: "put",
      };
    },

    createUser: () => {
      return {
        url: `${apiURL}/users`,
        method: "post",
      };
    },

    getUser: (companyId, id) => {
      return {
        url: `${apiURL}/users/${companyId}/${id}`,
        method: "get",
      };
    },

    userVerify: () => {
      return {
        url: `${apiURL}/userVerify`,
        method: "get",
      };
    },

    deleteUser: (id) => {
      return {
        url: `${apiURL}/users/${id}`,
        method: "delete",
      };
    },
    // Address===
    getCountries: () => {
      return {
        url: `https://api.countrystatecity.in/v1/countries`,
        method: "get",
      };
    },
    getStates: (data) => {
      return {
        url: `https://api.countrystatecity.in/v1/countries/${data.country_code}/states`,
        method: "get",
      };
    },
    getCities: (data) => {
      return {
        url: `https://api.countrystatecity.in/v1/countries/${data.country_code}/states/${data.state_code}/cities`,
        method: "get",
      };
    },
  },

  Assets: {
    fetchAssets: ({ limit, skip, type, format, keyword, status, isFavourite }) => {
      let url = `${apiURL}/assets?limit=${limit}&skip=${skip}&type=${type}&format=${format}&status=${status}`;

      if (isFavourite) {
        url = url + `&isFavourite=${isFavourite}`
      }

      if (keyword) {
        url = url + `&keyword=${keyword}`;
      }
      return {
        url,
        method: "get",
      };
    },
    fetchAssetsById: (id) => {
      return {
        url: `${apiURL}/assets/${id}`,
        method: "get",
      };
    },
    uploadAssets: () => {
      return {
        url: `${apiURL}/assets`,
        method: "post",
      };
    },
    addToFavourite: (id) => {
      return {
        url: `${apiURL}/assets/${id}`,
        method: "put",
      };
    },
    deleleAsset: (id) => {
      return {
        url: `${apiURL}/assets/${id}`,
        method: "delete",
      };
    },
    getAsset: (id) => {
      return {
        url: `${apiURL}/assets/${id}/base64`,
        method: "get",
      };
    },
  },
  comments: {
    fetchComments: (id, type) => {
      return {
        url: `${apiURL}/comments/${id}?type=${type}`,
        method: "get",
      };
    },
    addComment: (id, type) => {
      return {
        url: `${apiURL}/comments/${id}?type=${type}`,
        method: "post",
      };
    },
    editComment: (id, type) => {
      return {
        url: `${apiURL}/comments/${id}/edit?type=${type}`,
        method: "put",
      };
    },
    deleteComment: (id, type) => {
      return {
        url: `${apiURL}/comments/${id}?type=${type}`,
        method: "delete",
      };
    },
    changeStatus: (id, type) => {
      return {
        url: `${apiURL}/comments/${id}/status?type=${type}`,
        method: "put",
      };
    },
    addReply: (id, type) => {
      return {
        url: `${apiURL}/comments/${id}/reply?type=${type}`,
        method: "post",
      };
    },
    editReply: (id, replyId, type) => {
      return {
        url: `${apiURL}/comments/${id}/${replyId}?type=${type}`,
        method: "put",
      };
    },
    deleteReply: (id, replyId, type) => {
      return {
        url: `${apiURL}/comments/${id}/${replyId}?type=${type}`,
        method: "delete",
      };
    },
  },
  reviews: {
    sendForReview: (id) => {
      return {
        url: `${apiURL}/reviews/send/${id}`,
        method: "post",
      };
    },
    fetchReviews: (filters) => {
      const filterString = reArangeFilters(filters)
      return {
        url: `${apiURL}/reviews${filterString}`,
        method: "get",
      };
    },
    fetchReviewDetails: (id, type) => {
      return {
        url: `${apiURL}/reviews/${id}?type=${type}`,
        method: "get",
      };
    },
    applyFilter: () => {
      return {
        url: `${apiURL}/reviews/filter`,
        method: "post",
      };
    },
    checkIfReviewed: (id, type) => {
      //Check that user had reviewed the presentation or not
      return {
        url: `${apiURL}/reviews/checkReview/${id}?type=${type}`,
        method: "get",
      };
    },
    review: (id, type) => {
      return {
        url: `${apiURL}/reviews/${id}?type=${type}`,
        method: "post",
      };
    },
    cancelReview: (id, type) => {
      return {
        url: `${apiURL}/reviews/cencel/${id}?type=${type}`,
        method: "post",
      };
    },
  },
  salesForce: {
    login: () => {
      return {
        url: `${apiURL}/salesforce/authenticate`,
        method: "post",
      };
    },
    sharePresentation: (id) => {
      return {
        url: `${apiURL}/salesforce/share-presentation/${id}`,
        method: "post",
      };
    },
    getReviewed: () => {
      return {
        url: `${apiURL}/presentations?status=reviewed&notConnected=true`,
        method: "get",
      };
    },
    getSFPresentations: () => {
      return {
        url: `${apiURL}/salesforce/presentations`,
        method: "post",
      };
    },
  },
  emails: {
    fetch: (params) => {
      return {
        url: `${apiURL}/emails${objToParams(params)}`,
        method: "get",
      };
    },
    create: () => {
      return {
        url: `${apiURL}/email`,
        method: "post",
      };
    },
    clone: (id) => {
      return {
        url: `${apiURL}/emails/${id}/clone`,
        method: "post",
      };
    },
    exportEmail: ({ id, exportAs }) => {
      return {
        url: `${apiURL}/emails/${id}/download-${exportAs}`,
        method: "get",
      };
    },
    importEmail: () => {
      return {
        url: `${apiURL}/emails/import/new`,
        method: "post",
      };
    },
    uploadNewVersion: (id) => {
      return {
        url: `${apiURL}/emails/${id}/import`,
        method: "post",
      };
    },
    deleteEmail: (id) => {
      return {
        url: `${apiURL}/emails/${id}`,
        method: "delete",
      };
    },
    downloadSource: (id) => {
      return {
        url: `${apiURL}/emails/${id}/download`,
        method: "get",
      };
    },
    downloadVersionSource: (presentationId, versionId) => {
      return {
        url: `${apiURL}/emails/${presentationId}/download/${versionId}`,
        method: "get",
      };
    },
    fetchById: (id) => {
      return {
        url: `${apiURL}/emails/${id}`,
        method: "get",
      };
    },
    fetchEmailHtml: (id, versionId) => {
      let url = `${apiURL}/emails/html/${id}/`;
      if (versionId) {
        url = url + versionId;
      }
      return {
        url,
        method: "get",
      };
    },
    fetchEmailVersionHTML: (id, versionId) => {
      return {
        url: `${apiURL}/emails/html/${id}/${versionId}`,
        method: "get",
      };
    },
    saveEmail: (emailId) => {
      return {
        url: `${apiURL}/emails/save-html/${emailId}`,
        method: "put",
      };
    },
    update: (id) => {
      return {
        url: `${apiURL}/emails/${id}`,
        method: "put",
      };
    },
    sendTestEmail: () => {
      return {
        url: `${apiURL}/emails/preview`,
        method: "post",
      };
    },
  },
  microsites: {
    fetchTemplates: (type) => {
      return {
        url: `${apiURL}/templates?type=${type}`,
        method: "get",
      };
    },
    create: () => {
      return {
        url: `${apiURL}/landingpage`,
        method: "post",
      };
    },
    fetch: (params) => {
      return {
        url: `${apiURL}/landingpages${objToParams(params)}`,
        method: "get",
      };
    },
    fetchById: (id) => {
      return {
        url: `${apiURL}/landingpages/${id}`,
        method: "get",
      };
    },
    updateById: (id) => {
      return {
        url: `${apiURL}/landingpages/${id}`,
        method: "put",
      };
    },
    clone: (id) => {
      return {
        url: `${apiURL}/landingpages/${id}/clone`,
        method: "post",
      };
    },
    downloadSource: (id) => {
      return {
        url: `${apiURL}/landingpages/${id}/download`,
        method: "get",
      };
    },
    export: (id, exportAs) => {
      return {
        url: `${apiURL}/landingpages/${id}/download-${exportAs}`,
        method: "get",
      };
    },
    getHtmlById: (data) => {
      return {
        url: data.versionId ? `${apiURL}/landingpages/html/${data.micrositeId}/${data.versionId}` : `${apiURL}/landingpages/html/${data.micrositeId}`,
        method: "get",
      };
    },
    saveHtml: (id) => {
      return {
        url: `${apiURL}/landingpages/save-html/${id}`,
        method: "put",
      };
    },
    downloadVersionSource: (landingPageId, versionId) => {
      return {
        url: `${apiURL}/landingpages/${landingPageId}/download/${versionId}`,
        method: "get",
      };
    },
    importNew: () => {
      return {
        url: `${apiURL}/landingpages/import/new`,
        method: "post",
      };
    },
    uploadNewVersion: (id) => {
      return {
        url: `${apiURL}/landingpages/${id}/import`,
        method: "post",
      };
    },
    delete: (id) => {
      return {
        url: `${apiURL}/landingpages/${id}`,
        method: "delete",
      };
    },
  },
  campaign: {
    //Account
    createAccount: () => {
      return {
        url: `${apiURL}/campaigns/accounts`,
        method: "post",
      };
    },
    fetchAccounts: () => {
      return {
        url: `${apiURL}/campaigns/accounts`,
        method: "get",
      };
    },
    fetchAccountById: (id) => {
      return {
        url: `${apiURL}/campaigns/accounts/${id}`,
        method: "get",
      };
    },
    updateAccount: (id) => {
      return {
        url: `${apiURL}/campaigns/accounts/${id}`,
        method: "put",
      };
    },
    deleteAccount: (id) => {
      return {
        url: `${apiURL}/campaigns/accounts/${id}`,
        method: "delete",
      };
    },
    //Contact
    createContact: () => {
      return {
        url: `${apiURL}/campaigns/contacts`,
        method: "post",
      };
    },
    fetchContacts: () => {
      return {
        url: `${apiURL}/campaigns/contacts`,
        method: "get",
      };
    },
    fetchContactById: (id) => {
      return {
        url: `${apiURL}/campaigns/contacts/${id}`,
        method: "get",
      };
    },
    updateContact: (id) => {
      return {
        url: `${apiURL}/campaigns/contacts/${id}`,
        method: "put",
      };
    },
    deleteContact: (id) => {
      return {
        url: `${apiURL}/campaigns/contacts/${id}`,
        method: "delete",
      };
    },

    //filter Contact
    filterContact: (id) => {
      return {
        url: `${apiURL}/campaigns/contacts/accounts/${id}`,
        method: "get",
      };
    },

    //uploadContacts
    uploadContacts: () => {
      return {
        url: `${apiURL}/campaigns/contacts/multiples`,
        method: "post",
      };
    },

    //fetch contacts By accountId
    fetchContactsByAccount: (id) => {
      return {
        url: `${apiURL}/campaigns/contacts/accounts/${id}`,
        method: "get",
      };
    },

    //Groups
    createGroup: () => {
      return {
        url: `${apiURL}/campaigns/groups`,
        method: "post",
      };
    },
    fetchGroups: () => {
      return {
        url: `${apiURL}/campaigns/groups`,
        method: "get",
      };
    },
    fetchGroupById: (id) => {
      return {
        url: `${apiURL}/campaigns/groups/${id}`,
        method: "get",
      };
    },
    updateGroup: (id) => {
      return {
        url: `${apiURL}/campaigns/groups/${id}`,
        method: "put",
      };
    },
    deleteGroup: (id) => {
      return {
        url: `${apiURL}/campaigns/groups/${id}`,
        method: "delete",
      };
    },

    //Campaign
    createCampaign: () => {
      return {
        url: `${apiURL}/campaigns`,
        method: "post",
      };
    },
    fetchCampaigns: () => {
      return {
        url: `${apiURL}/campaigns`,
        method: "get",
      };
    },
    fetchCampaignById: (id) => {
      return {
        url: `${apiURL}/campaigns/${id}`,
        method: "get",
      };
    },
    updateCampaign: (id) => {
      return {
        url: `${apiURL}/campaigns/${id}`,
        method: "put",
      };
    },
    deleteCampaign: (id) => {
      return {
        url: `${apiURL}/campaigns/${id}`,
        method: "delete",
      };
    },
    getUsersAnalytics: (campaignId, userId) => {
      return {
        url: `${apiURL}/get-analytics/${campaignId}/user/${userId}`,
        method: "get",
      };
    },
    getAnalyticsReports: (campaignId) => {
      return {
        url: `${apiURL}/campaigns/reports/${campaignId}`,
        method: "get",
      };
    },
  },
};
