import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";
import { Grid, Typography, IconButton, Button, Checkbox } from "@mui/material";
import SelectBox from "src/components/ui/formElements/SelectBox";
import Typo from "./Typography";
import {
  IoDuplicateOutline,
  IoTrashOutline,
  IoChevronDown,
} from "react-icons/io5";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import Control from "./Control";
import Main from "src/components/dialogs/Main";
import { setLayerElementIds } from "src/services/editor.service";
import Row from "src/components/properties/Row";
import "src/styles/properties.scss";
import { Box } from "@mui/material";
import { csRenderPresentationEditable } from "../presentation/renderPresentation";
import { useElementStatus } from "src/hooks/editor/element.controller";
import { useEditorHeirarchy } from "src/hooks/editor/heirarchy.controller";

const readTableData = (tableId) => {
  const table = $("#" + tableId);
  var convertedIntoArray = [];
  table.find("tr").each(function () {
    var rowDataArray = [];
    var actualData = $(this).find("td");
    $(this).find("input").css("fontSize", "");
    if (actualData.length > 0) {
      actualData.each(function () {
        rowDataArray.push($(this).html());
      });
      convertedIntoArray.push(rowDataArray);
    }
  });
  return convertedIntoArray;
};

const AddRow = (props) => {
  const BootstrapButton = styled(IconButton)({
    position: "absolute",
    left: "-30px",
    top: "7px",
  });

  const duplicateRow = (e) => {
    const trIndex = $(e.target).closest("tr").index();
    let copyRow = props.tblData[trIndex];

    props.tblData.splice(trIndex + 1, 0, copyRow);

    props.updateTableHandler([...props.tblData]);
  };
  return (
    <BootstrapButton
      color="primary"
      aria-label="add to shopping cart"
      onClick={duplicateRow}
      size="small"
    >
      <IoDuplicateOutline />
    </BootstrapButton>
  );
};

const DeleteRow = (props) => {
  const BootstrapButton = styled(IconButton)({
    position: "absolute",
    right: "-30px",
    top: "7px",
  });

  const deleteRow = (e) => {
    const trIndex = $(e.target).closest("tr").index();
    let copyRow = props.tblData[trIndex];

    props.tblData.splice(trIndex, 1);
    props.updateTableHandler([...props.tblData]);
  };
  return (
    <BootstrapButton
      color="warning"
      aria-label="add to shopping cart"
      onClick={deleteRow}
      size="small"
      disabled={props.disabled}
    >
      <IoTrashOutline />
    </BootstrapButton>
  );
};
const ColumnDropDown = (props) => {
  //duplicate col
  const duplicateColHandler = (e) => {
    const tdIndex = $(e.target).closest("td").index();
    const readData = readTableData(props.elementId);
    readData.map((row) => {
      //add new column in array
      let copyTd = row[tdIndex].trim();

      const nextIndex = tdIndex + 1;
      row.splice(nextIndex, 0, copyTd);
    });
    props.updateTableHandler([...readData]);

    const layer = document.getElementById(props.elementId).closest(".cs-layer");
    setTimeout(() => {
      setLayerElementIds(layer);
    }, 100);
  };

  //delete col
  const deleteColHandler = (e) => {
    const tdIndex = $(e.target).closest("td").index();
    props.tblData.map((cols) => {
      //dont remove last column
      if (cols.length > 1) {
        //delete all vertical columns form array
        cols.splice(tdIndex, 1);
      }
    });
    props.updateTableHandler([...props.tblData]);
  };

  return (
    <ul className="tabledropdown">
      <li>
        <span className="toggle">
          <IoChevronDown />
        </span>
        <ul className="items">
          <li onClick={duplicateColHandler}>
            <IoDuplicateOutline color="primary" /> Duplicate
          </li>
          <li onClick={deleteColHandler}>
            <IoTrashOutline color="warning" /> Delete
          </li>
        </ul>
      </li>
    </ul>
  );
};
const Table = (props) => {
  const { elementId, updateHistory, undoRedoChange } = props;

  const { activeElement } = useElementStatus();
  const { requestHierarchyUpdate } = useEditorHeirarchy();

  const [tblData, setTblData] = useState([]);
  const [tblStyle, setTblStyle] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [cellPadding, setCellPadding] = useState(0);
  const [cellSpacing, setCellSpacing] = useState(0);
  const [cellBorder, setCellBorder] = useState(false);

  const element = document.getElementById(elementId);

  console.log(tblData);

  let timeout;

  const cellPaddingHandler = (e) => {
    setCellPadding(e.target.value);
    element.cellPadding = e.target.value;
    e.target.onblur = () =>
      updateHistory({
        id: elementId,
      });
  };

  const cellSpacingHandler = (e) => {
    setCellSpacing(e.target.value);
    element.cellSpacing = e.target.value;
    e.target.onblur = () =>
      updateHistory({
        id: elementId,
      });
  };

  useEffect(() => {
    setCellPadding(+element.cellPadding);
    setCellSpacing(+element.cellSpacing);

    if (element.querySelector("thead").style.border == "inherit") {
      setCellBorder(true);
    } else {
      setCellBorder(false);
    }
  }, [elementId, undoRedoChange]);

  const toggleDialog = () => {
    if (isOpen) {
      setIsOpen(!isOpen);
      updateHistory();
    } else {
      setIsOpen(!isOpen);
    }

    const readData = readTableData(elementId);
    updateTableHandler(readData);
    setTblStyle(document.getElementById(elementId).getAttribute("style"));
    //then update to state
    setTblData(readData);
  };
  const updateTableHandler = (tblData) => {
    setTblData(tblData);
  };

  useEffect(() => {
    if (tblData.length > 0) {
      let geneTable = "";
      geneTable += "<thead><tr>";
      tblData.map((rows, index) => {
        if (index == 0) {
          rows.map((td) => {
            geneTable += "<td>" + td + "</td>";
          });
        }
      });
      geneTable += "</tr></thead>";

      geneTable += "<tbody>";
      tblData.map((rows, index) => {
        if (index >= 1) {
          geneTable += "<tr>";
          //$(".cs-table.active").append("<tr><td>1</td></tr>");
          rows.map((td) => {
            geneTable += "<td>" + td + "</td>";
          });
          geneTable += "</tr>";
        }
      });
      geneTable += "</tbody>";
      $(".cs-table.active").html(geneTable);

      setTimeout(() => {
        const layer = $("#" + elementId).closest(".cs-layer");
        const layerId = layer.attr("id");

        csRenderPresentationEditable({
          element: layer[0],
          activeElement,
          requestHierarchyUpdate,
          updateHistory,
        });
        useSetLayerHeight($("#" + layerId));
      }, 1000);
    }
  }, [tblData]);

  return (
    <>
      <Control
        heading="Create Table"
        className="styles"
        isCollapse={true}
        expanded={true}
        divider={false}
      >
        <Box className="global-btn">
          <Button variant="contained" onClick={toggleDialog}>
            Update Table Content
          </Button>
        </Box>
        <Main isOpen={isOpen} handleClose={toggleDialog} background="#fff">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item md={11}>
              <table
                id="showTable"
                border="1"
                // style={covertStyleStrToObject(tblStyle)}
                cellSpacing="0"
                cellPadding="0"
              >
                <tbody>
                  {tblData.map((rows, rowIndex) => {
                    const tdLen = rows.length;
                    return (
                      <tr key={rowIndex}>
                        {rows.map((td, tdIndex) => {
                          return (
                            <td key={tdIndex}>
                              {rowIndex != 0 && tdIndex == 0 && (
                                <AddRow
                                  tblData={tblData}
                                  updateTableHandler={updateTableHandler}
                                  elementId={elementId}
                                />
                              )}
                              {rowIndex > 0 && tdIndex == tdLen - 1 && (
                                <DeleteRow
                                  tblData={tblData}
                                  updateTableHandler={updateTableHandler}
                                  elementId={elementId}
                                  disabled={tblData.length > 2 ? false : true}
                                />
                              )}

                              <div
                                className="parseHTML"
                                dangerouslySetInnerHTML={{
                                  __html: td,
                                }}
                              />
                              {rowIndex == 0 && (
                                <ColumnDropDown
                                  updateTableHandler={updateTableHandler}
                                  tblData={tblData}
                                  elementId={elementId}
                                />
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <br />
            </Grid>
          </Grid>
        </Main>

        {/* Table Properties  */}
        <Row
          label="Cell Padding"
          element={
            <input
              type="number"
              className="input"
              name="cellPadding"
              value={cellPadding}
              onChange={cellPaddingHandler}
            />
          }
        />

        <Row
          label="Cell Spacing"
          element={
            <input
              type="number"
              className="input"
              name="cellSpacing"
              value={cellSpacing}
              onChange={cellSpacingHandler}
            />
          }
        />

        {/* Cell Borders */}
        {/* <Grid container alignItems="center" className="border-row">
          <Grid xs={6} item>
            <Typography component="span">Table Cell Border</Typography>
          </Grid>
          <Grid xs={6} className="background text-right" item>
            <Checkbox
              checked={cellBorder}
              name="borderShown"
              sx={{ p: 0 }}
              onChange={cellBorderHandler}
            />
          </Grid>
        </Grid> */}

        {/* Head or Body */}
        {/* <div className="event selectBox">
        <span>Style</span>
        <SelectBox
          classes="fontFamily"
          options={options}
          value={option}
          onChange={(e) => setOption(e.target.value)}
        />
      </div> */}
      </Control>
      <Typo
        heading="Text Styles"
        table={true}
        elementId={elementId}
        updateHistory={updateHistory}
      />
    </>
  );
};

export default Table;
