import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";

//Icons
import { IoAdd, IoCreate, IoCloudUploadOutline } from "react-icons/io5";

//APIs
import { fetchPresentations } from "src/store/actions/presentations";

//MUI Componenets
import { Grid, Typography } from "@mui/material";

import Content from "src/layouts/Content";
import DropDown from "src/components/ui/DropDown";
import Pagination from "src/components/ui/Pagination";
import PresentationCard from "src/components/presentations/PresentationCard";

//Dialogs
import MyLibraryDialogs from "src/components/dialogs/presentations/MyLibrary-Dialogs";
import Permission from "src/components/Permission"

const MyLibrary = () => {
  const limit = 10;
  const dispatch = useDispatch();
  const {
    list: presentations,
    count,
    loadingMsg,
  } = useSelector((state) => state.presentations);

  const [searchParams] = useSearchParams();
  const [details, setDetails] = useState({});
  const [presentationId, setPresentationId] = useState("");

  //Dialogs State
  const [dialog, setDialog] = useState({});

  useEffect(() => {

    const pageNo = Math.round(Number(searchParams.get("page"))) || 1;
    const skip = (pageNo - 1) * limit;

    const params = {};
    searchParams.forEach((value, key) => {
      if (key !== "page") params[key] = value;
    });

    params.skip = skip;
    params.limit = limit;

    dispatch(fetchPresentations(params));
  }, [searchParams]);

  const dropDownData = useMemo(() => {
    return [
      {
        label: "Create New",
        icon: <IoCreate />,
        permissionPath: "presentation.create.createViaTemplate",
        onClick: () => {
          openDialog("", "createNew");
        },
      },
      {
        label: "Upload From",
        icon: <IoCloudUploadOutline />,
        permissionPath: "presentation.create.uploadZipFile",
        onClick: () => {
          openDialog("", "uploadNew");
        },
      },
    ];
  }, []);

  //presentation info
  const showDetails = useCallback((presenetation) => {
    setDialog({ info: true });
    setDetails(presenetation);
  }, []);

  //open dialogs function
  const openDialog = useCallback((id, name, device) => {
    setPresentationId(id);
    setDialog({ [name]: true, device: device });
  }, []);

  return (
    <>
      {/* Dialogs */}
      <MyLibraryDialogs
        dialog={dialog}
        presentation={details}
        presentationId={presentationId}
        onClose={() => setDialog({})}
      />
      {/* Dialogs */}

      <Content
        title="Presentations"
        // subtitle={!loadingMsg && "New Presentations"}
        headerAction={
          <Permission permissionPath="presentation.create">
            <DropDown
              dropText="Add New"
              tooltip="options"
              startIcon={<IoAdd />}
              items={dropDownData}
            />
          </Permission>
        }
      >
        <Grid container spacing={2} display="flex" mb={12}>
          {presentations && !loadingMsg ? (
            presentations.map((presentation) => {
              return (
                <PresentationCard
                  key={presentation._id}
                  presentation={presentation}
                  showDetails={showDetails}
                  openDialog={openDialog}
                  role="owner"
                />
              );
            })
          ) : (
            <Typography variant="h1" pl={3} pt={2}>
              {loadingMsg}
            </Typography>
          )}
        </Grid>
        <Pagination
          count={count}
          itemsPerPage={limit}
          enableParams
          onChange={(value) => {
            if (
              !searchParams.get("search") &&
              !searchParams.get("language") &&
              !searchParams.get("country")
            ) {
              dispatch(fetchPresentations(value));
            }
            console.log(value)
          }}
        />
      </Content>
    </>
  );
};
export default React.memo(MyLibrary);
