import React, { useRef, useState, useEffect } from "react";
import * as XLSX from "xlsx";
import upload from "../../../assets/images/upload.png";
import {
  Alert,
  Box,
  Button,
  Grid,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { uiActions } from "src/store/reducers/ui-slice";
import Main from "src/components/dialogs/Main";
import { fetchAccounts, uploadContacts } from "src/store/actions/campaign";
import { useDispatch } from "react-redux";
import "../../../styles/Upload.scss";
import { countries, languages } from "../../../data/data";

const UploadContact = (props) => {
  const { show, selectAccount } = props;
  const dispatch = useDispatch();
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("fill file from your computer here");
  const [contactJSON, setcontactJSON] = useState([]);
  const [contactError, setContactsError] = useState([]);
  const [accounts, setAccounts] = useState([]);

  const inputFile = useRef();

  useEffect(() => {
    dispatch(fetchAccounts()).then((res) => {
      setAccounts(res.payload);
    });
  }, [dispatch]);

  const closeDialog = () => {
    setFile("");
    setFileName("fill file from your computer here");
    props.close();
  };

  const browseFileHandler = () => {
    inputFile.current.click();
  };

  const DragOverHandler = (e) => {
    e.preventDefault();
    e.currentTarget.classList.add("dragOver");
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    e.currentTarget.classList.remove("dragOver");
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (
      file &&
      (file.type ==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type == "text/csv")
    ) {
      const contactarr = [];
      const invalidarr = [];
      const accountId = e.target.accounts.value;
      const arrValue = [
        "firstName",
        "lastName",
        "email",
        // "mobile",
        // "language",
        // "street",
        // "country",
        // "state",
        // "city",
        // "postalCode",
      ];
      const capitalFirstLetter = (text) => {
        return text
          .split(" ")
          .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )
          .join(" ");
      };
      const validation = {
        firstName: () => {
          return true;
        },
        lastName: () => {
          return true;
        },
        email: (val) => {
          const emailRegix =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
          if (emailRegix.test(val)) {
            return true;
          }
          return false;
        },
        // mobile: () => {
        //   return true;
        // },
        // language: (val) => {
        //   return true;
        //   // const isExists = languages.find(
        //   //   (lang) => lang.name.toLowerCase() === val.toLowerCase()
        //   // );
        //   // if (isExists) {
        //   //   return true;
        //   // }
        //   // return false;
        // },
        // street: () => {
        //   return true;
        // },
        // country: (val) => {
        //   return true;
        //   // const isExists = countries.find(
        //   //   (country) => country.name.toLowerCase() === val.toLowerCase()
        //   // );
        //   // if (isExists) {
        //   //   return true;
        //   // }
        //   // return false;
        // },
        // state: () => {
        //   return true;
        // },
        // city: () => {
        //   return true;
        // },
        // postalCode: () => {
        //   return true;
        // },
      };
      contactJSON.forEach((data, index) => {
        console.log(data);
        const entryNo = index + 1;
        let validData = true;
        arrValue.forEach((val) => {
          if (data[val]) {
            let isValid = validation[val](data[val]);
            if (!isValid) {
              validData = false;
              invalidarr.push({
                error: `Error in Contact No.${entryNo} and ${val} is not valid`,
              });
            }
          } else {
            validData = false;
            invalidarr.push({
              error: `Error in Contact No.${entryNo} and Missing ${val} field`,
            });
          }
        });
        if (data.country) {
          const isExists = countries.find(
            (country) =>
              country.name.toLowerCase() === data.country.toLowerCase()
          );
          if (!isExists) {
            invalidarr.push({
              error: `Error in Contact No.${entryNo} and country is not valid`,
            });
          }
        }
        if (data.language) {
          console.log("land");
          const isExists = languages.find(
            (lang) => lang.name.toLowerCase() === data.language.toLowerCase()
          );
          if (!isExists) {
            invalidarr.push({
              error: `Error in Contact No.${entryNo} and language is not valid`,
            });
          }
        }
        if (validData) {
          contactarr.push({
            firstName: data.firstName,
            middleName: data.middleName ? data.middleName : "",
            lastName: data.lastName,
            email: data.email,
            mobile: data.mobile ? data.mobile : "",
            phone: data.phone ? data.phone : "",
            fax: data.fax ? data.fax : "",
            lang: data.language ? capitalFirstLetter(data.language) : "",
            address: {
              street: data.street ? data.street : "",
              country: data.country ? capitalFirstLetter(data.country) : "",
              state: data.state ? data.state : "",
              city: data.city ? data.city : "",
              postalCode: data.postalCode ? data.postalCode : "",
            },
            account: accountId,
          });
        }
      });
      setContactsError(invalidarr);

      if (invalidarr.length === 0) {
        dispatch(uploadContacts(contactarr));
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Contact Uploaded!",
            message: `contacts uploaded succesfully`,
          })
        );
        props.close();
      } else {
        dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Contact Error!",
            message: `Error in  ${invalidarr.length} Contacts`,
          })
        );
      }
    } else {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: `Select file`,
          message: "Please Select a csv or xlsx File",
        })
      );
    }
  };

  const handleFileChange = (e) => {
    if (e.target?.files?.[0]) {
      e.preventDefault();
      if (e.target.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = XLSX.utils.sheet_to_json(worksheet);
          setcontactJSON(json);
        };
        reader.readAsArrayBuffer(e.target.files[0]);
      }
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    }
  };

  // Drag & Drop Functionality
  const uploadOnDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer?.files?.[0]) {
      setFile(e.dataTransfer.files[0]);
      setFileName(e.dataTransfer.files[0].name);
    }
  };
  return (
    <Main
      isOpen={show}
      handleClose={closeDialog}
      overflowY="scroll"
      className="campaignUpload"
    >
      <Box className="dialogBody">
        <form onSubmit={submitHandler}>
          <Grid container my={5} justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FormControl
                  required
                  className="filterContact"
                  sx={{ minWidth: 280 }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Select Account
                  </InputLabel>
                  <Select name="accounts" defaultValue={selectAccount}>
                    {accounts &&
                      accounts.map((data) => {
                        return (
                          <MenuItem
                            value={`${data._id}`}
                            key={`${data._id}`}
                          >{`${data.name}`}</MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <div
                className={`${file ? "dragOver" : ""} uploadArea`}
                onClick={browseFileHandler}
                onDragOver={DragOverHandler}
                onDrop={uploadOnDrop}
                onDragLeave={onDragLeave}
              >
                <input
                  type="file"
                  name="file"
                  id="uploadAsset"
                  onChange={handleFileChange}
                  hidden
                  accept=".csv,.xlsx"
                  ref={inputFile}
                />

                <img src={upload} className="uploadImage" alt="upload" />
                <Typography textAlign="center" className="heading">
                  drag &amp; drop
                </Typography>
                <Typography
                  textAlign="center"
                  className={`subHeading ${file ? "fileName" : ""}`}
                >
                  {fileName}
                </Typography>
              </div>
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="flex-end">
            <Button type="submit" variant="contained">
              Submit
            </Button>
          </Box>
        </form>
        <Box>
          {contactError &&
            contactError.map((value, index) => {
              return (
                <Alert
                  variant="filled"
                  severity="error"
                  className="contactError"
                  key={index}
                >
                  {value.error}
                </Alert>
              );
            })}
        </Box>
      </Box>
    </Main>
  );
};

export default UploadContact;
