import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, Button, Box } from "@mui/material";

import { BiCommentDetail } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import { addComment } from "src/store/actions/comments";

import { uiActions } from "src/store/reducers/ui-slice";

import Card from "../ui/Card";
import CommnetsCard from "./CommnetsCard";

import "../../styles/review_comment.scss";

const Comments = (props) => {
  const dispatch = useDispatch();
  const { comments } = useSelector((state) => state.comments);
  const { id, slideId, slideComments, type } = props;
  const [newComment, setNewComment] = useState(false);
  const [comment, setComment] = useState("");

  const sendComment = () => {
    if (!comment.trim().length) {
      return dispatch(
        uiActions.showNotification({
          message: "Please enter comment",
          title: "Empty Comment",
          status: "error",
        })
      );
    }
    dispatch(addComment({ id, data: { comment, slideId }, type }));
    cancelComment();
  };

  const cancelComment = () => {
    setNewComment(false);
    setComment("");
  };

  return (
    <>
      {/* {New Comment Component Starts Here} */}
      <Box>
        <Button
          variant="contained"
          size="small"
          onClick={() => setNewComment(true)}
          className="addNewBtn"
        >
          Add New
        </Button>
        <Collapse in={newComment} timeout="auto" unmountOnExit>
          <Card classes="addNew">
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="textBox"
            ></textarea>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="text"
                startIcon={<BiCommentDetail size={18} className="btnTab"/>}
                onClick={sendComment}
              >
                Send
              </Button>
              <Button
                variant="text"
                onClick={cancelComment}
                startIcon={<AiOutlineClose size={18} className="btnTab" />}
              >
                Cancel
              </Button>
            </Box>
          </Card>
        </Collapse>
      </Box>
      {/* {New Comment Component Ends Here} */}

      <Box display="flex" flexDirection="column">
        {/* {All Comments map} */}
        {comments.map((comment) => {
          if (slideComments) {
            if (comment.slideId === slideId) {
              return (
                <CommnetsCard
                  type={type}
                  key={comment._id}
                  id={id}
                  comment={comment}
                />
              );
            }
          } else {
            return (
              <CommnetsCard
                type={type}
                key={comment._id}
                id={id}
                comment={comment}
              />
            );
          }
        })}
      </Box>
    </>
  );
};

export default React.memo(Comments);
