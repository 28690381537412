import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Button, Box } from "@mui/material";
import { useParams } from "react-router-dom";

import { fetchEmailById, updateEmail } from "src/store/actions/emails";

import Content from "src/layouts/Content";
import Card from "src/components/ui/Card";
import Input from "src/components/ui/formElements/Input";
import SelectBox from "src/components/ui/formElements/SelectBox";

import "../../styles/presentations/settings.scss";
import { canSendRequest } from "src/services/utils";
import { uiActions } from "src/store/reducers/ui-slice";
import { countries, languages, targetSystem } from "src/data/data";
const Details = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { email, config } = useSelector((state) => state.emails);
  const [settings, setSettings] = useState(email);

  const handleChange = (e) => {
    const key = e.target.name;
    let val = e.target.value;
    setSettings((prev) => ({
      ...prev,
      [key]: val,
    }));
  };

  useEffect(() => {
    setSettings(email);
  }, [email]);
  
  useEffect(() => {
    if (canSendRequest(config, email._id)) {
      dispatch(fetchEmailById(id));
    }
  }, []);

  const updateSetting = () => {
    if (!settings?.name?.trim()?.length) {
      return dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Invalid Name",
          message: "Email name can't be empty",
        })
      );
    }
    dispatch(updateEmail({ id, data: settings }));
  };

  return (
    <Grid container gap={4}>
      <Grid item xs={12}>
        <Content title="General Settings">
          <Card classes="box-style">
            <Grid container spacing={3} className="margin-bottom">
              <Grid item xs={6} md={4}>
                <Input
                  onChange={handleChange}
                  value={settings.name}
                  name="name"
                  className="bordered"
                  labelTag="Name"
                  disabled={email.isLock}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <SelectBox
                  onChange={handleChange}
                  value={settings.country}
                  labelTag="Country"
                  name="country"
                  defaultValue="pakistan"
                  classes="bordered"
                  options={countries}
                  disabled={email.isLock}
                />
              </Grid>

              <Grid item xs={6} md={4}>
                <SelectBox
                  onChange={handleChange}
                  value={settings.lang}
                  labelTag="Language"
                  defaultValue="en"
                  classes="bordered"
                  name="lang"
                  options={languages}
                  disabled={email.isLock}
                />
              </Grid>

              <Grid item xs={6} md={4}>
                <SelectBox
                  onChange={handleChange}
                  value={settings?.targetSystem}
                  labelTag="Target System"
                  defaultValue="ipad"
                  name="targetSystem"
                  classes="bordered"
                  options={targetSystem}
                  disabled={email.isLock}
                />
              </Grid>

              {!email.isLock && (
                <Grid item md={8} display="flex" className="d-flex btn-align">
                  <Box>
                    <Button
                      variant="contained"
                      onClick={updateSetting}
                      sx={{ textTransform: "capitalize" }}
                      disabled={!settings?.name?.trim()?.length}
                    >
                        Update
                    </Button>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Card>
        </Content>
      </Grid>
    </Grid>
  );
};
export default Details;
