import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Tabs, Tab } from "@mui/material";
import { useParams } from "react-router-dom";

import {
  fetchLandingPageById,
  downloadSource,
  downloadVersionSource,
} from "src/store/actions/microsites";
import { setLandingPagePreview } from "src/store/reducers/microsite-slice";
import { canSendRequest } from "src/services/utils";

import Card from "src/components/ui/Card";
import AccessControl from "src/components/microsites/AccessControl";
import SharingDetails from "src/components/common/SharingDetails";
import VersionsDetail from "src/components/common/VersionsDetail";
import MicrositeDetail from "src/components/common/Detail";
import PermissionComp from "src/components/Permission";

const Action = ({ id }) => {
  const dispatch = useDispatch();

  const handleDownload = () => {
    dispatch(downloadSource(id));
  };

  return (
    <PermissionComp permissionPath="microsites.export.downloadSourceCode">
      <Button className="actionBtn" onClick={handleDownload}>
        Download Source
      </Button>
    </PermissionComp>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
};

const Details = () => {
  const type = "landingpage";
  const dispatch = useDispatch();
  const { id } = useParams();

  const { landingPage, config } = useSelector((state) => state.microsites);
  const [micrositeDetail, setMicrositeDetail] = useState({})
  useEffect(() => {
    if (canSendRequest(config, id)) {
      dispatch(fetchLandingPageById(id)).then(res => {
        setMicrositeDetail(res.payload)
      });
    }
  }, [dispatch]);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const previewVersion = (versionId) => {
    dispatch(
      setLandingPagePreview({ isOpen: true, micrositeId: id, versionId })
    );
  };

  const downloadVersion = (versionId) => {
    dispatch(downloadVersionSource({ id, versionId }));
  };

  return (
    <AccessControl component="details">
      <Card classes="screen">
        <Box className="details">
          <MicrositeDetail detail={landingPage} Action={<Action id={id} />} />
          <Box className="sub-details">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="wrapped label tabs example"
              visibleScrollbar={false}
              variant="scrollable"
              sx={{ marginBottom: "20px" }}
            >
              <Tab className="detailsTabs" value={0} label="Versions" />
              <Tab className="detailsTabs" value={1} label="Share Settings" />
              {/* <Tab className="detailsTabs" value={2} disabled label="Exports" />
              <Tab
                className="detailsTabs"
                value={3}
                disabled
                label="Applications"
              />
              <Tab
                className="detailsTabs"
                value={4}
                disabled
                label="Documents"
              />
              <Tab
                className="detailsTabs"
                value={5}
                disabled
                label="Share Link"
              />
              <Tab
                className="detailsTabs"
                value={6}
                disabled
                label="Publications"
              /> */}
            </Tabs>

            <TabPanel value={value} index={0}>
              <PermissionComp permissionPath="microsites.versions" alert>
                <VersionsDetail
                  id={id}
                  type={type}
                  downloadVersion={downloadVersion}
                  previewVersion={previewVersion}
                />
              </PermissionComp>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <PermissionComp permissionPath="share" alert>
                <SharingDetails id={id} type={type} Detail={micrositeDetail} />
              </PermissionComp>
            </TabPanel>

            <TabPanel value={value} index={2}></TabPanel>

            <TabPanel value={value} index={3}></TabPanel>

            <TabPanel value={value} index={4}></TabPanel>

            <TabPanel value={value} index={5}></TabPanel>

            <TabPanel value={value} index={6}></TabPanel>
          </Box>
        </Box>
      </Card>
    </AccessControl>
  );
};
export default Details;
