import React from "react";
import {
  Grid,
  Typography,
  Box,
  IconButton,
  Button,
  Tooltip,
} from "@mui/material";
import { useDispatch } from "react-redux";
import "jquery-ui-dist/jquery-ui";
import { IoCloudUploadOutline, IoCloudDoneOutline } from "react-icons/io5";
import { MdUndo, MdRedo } from "react-icons/md";
import { BsFillEyeFill } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { setLandingPagePreview } from "src/store/reducers/microsite-slice";
import { renderEditableElements } from "../renderMicrosite";
import { useEditorHistory } from "src/hooks/editor/history";

const TopBar = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const showPreviewDialog = () => {
    dispatch(
      setLandingPagePreview({
        micrositeId: id,
        isOpen: true,
      })
    );
  };

  const { undo, redo, disabledButtons } = useEditorHistory({
    csRenderEditable: renderEditableElements,
  });

  return (
    <Box className="editorHead">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid item md={2}>
          <Typography variant="h2" className="topbar_heading">
            {props.heading}
          </Typography>
        </Grid>
        <Grid item md="auto">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Tooltip title="Preview" placement="top" arrow>
              <IconButton
                color="primary"
                onClick={showPreviewDialog}
                disabled={props.isAnyChange}
              >
                <BsFillEyeFill />
              </IconButton>
            </Tooltip>
            <Grid></Grid>
          </Grid>
        </Grid>
        <Grid item md={2.5}>
          <Grid
            container
            alignItems="center"
            justifyContent="end"
            textAlign="end"
          >
            <Grid item>
              <Tooltip
                title={
                  disabledButtons.undo
                    ? "No Previous History"
                    : "Undo (ctrl + z)"
                } arrow
              >
                <span>
                  <IconButton
                    className="iconColor"
                    onClick={undo}
                    disabled={disabledButtons.undo}
                  >
                    <MdUndo />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip
                title={
                  disabledButtons.redo ? "No Next History" : "Redo (ctrl + y)"
                } arrow
              >
                <span>
                  <IconButton
                    className="iconColor"
                    onClick={redo}
                    disabled={disabledButtons.redo}
                  >
                    <MdRedo />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
            <Grid item>
              <Button
                sx={{ width: "160px" }}
                variant="text"
                onClick={props.handleSlideExport}
                startIcon={
                  props.isAnyChange ? (
                    <IoCloudUploadOutline />
                  ) : (
                    <IoCloudDoneOutline />
                  )
                }
                disabled={!props.isAnyChange}
              >
                {props.isAnyChange ? "Save All Changes" : "All Changes saved"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TopBar;
