import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { BiCommentDetail, BiCommentDots } from "react-icons/bi";
import { IoDocument, IoDocumentsSharp } from "react-icons/io5";
import { Tab, Tabs, Box } from "@mui/material";
import Comments from "../../common/Comments";
import { fetchComments } from "src/store/actions/comments";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
};

const TabsComments = (props) => {
  const { id, slideId,type } = props;
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (value === 0) {
      dispatch(fetchComments({ id, type }));
      console.log("comments");
    }
  }, [value]);

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="wrapped label tabs example"
        visibleScrollbar={false}
        variant="scrollable"
        sx={{ marginBottom: "20px" }}
      >
        <Tab
          className="detailsTabs "
          sx={{ flexGrow: 1 }}
          value={0}
          title="All Comments"
          label={<IoDocumentsSharp size={props.size} />}
        />

        {slideId && (
          <Tab
            className="detailsTabs"
            sx={{ flexGrow: 1 }}
            value={1}
            title="Slide Comments"
            label={<IoDocument size={props.size} />}
          />
        )}
      </Tabs>
      <TabPanel value={value} index={0} className="tabPanel">
        <Comments type={type} id={id} slideId={slideId} />
      </TabPanel>
      <TabPanel value={value} index={1} className="tabPanel">
        <Comments type={type} id={id} slideId={slideId} slideComments />
      </TabPanel>
    </>
  );
};

export default React.memo(TabsComments);

TabsComments.defaultProps = {
  size: 14,
};
