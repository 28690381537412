import { Button } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Main from "../Main";
import "../../../styles/SendTestEmail.scss";
import {
  emailValidator,
  getHTMLFromBody,
  setBodyinHTML,
} from "src/services/editor.service";
import { sendTestEmail } from "src/store/actions/emails";

const SendTestEmail = (props) => {
  const { isOpen, handleClose, close } = props;
  const [value, setValue] = useState("");
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();

  const html = useSelector((state) => state.emails.emailData);

  const submitHandler = (e) => {
    e.preventDefault();
    if (!disabled) {
      dispatch(
        sendTestEmail({
          to: value,
          html: setBodyinHTML(getHTMLFromBody(html), false, false, true, false),
        })
      );
      setValue("");
      setDisabled(true);
      close();
    }
  };

  const valueHandler = (e) => {
    const value = e.target.value;
    setValue(value);
    setDisabled(!emailValidator(value));
  };

  return (
    <Main
      background="white"
      modalWidth="sm"
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <form className="sendTestEmail" onSubmit={submitHandler}>
        <div className="formControl">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            className="sendEmail"
            value={value}
            onChange={valueHandler}
          />
        </div>

        <Button
          variant="contained"
          disabled={disabled}
          className="sendBtn"
          onClick={submitHandler}
        >
          Send Test Email
        </Button>
      </form>
    </Main>
  );
};
export default React.memo(SendTestEmail);
