import React, { useState, useEffect } from "react";
import $ from "jquery";
import { Button, Checkbox, Grid, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/material";
import { IoMdRemoveCircleOutline, IoMdAdd } from "react-icons/io";
import { convertRgbAToHexAplha } from "src/services/editor.service";
import Control from "./Control";
import "src/styles/properties.scss";
import Row from "src/components/properties/Row";
import Check_Box from "src/components/common/Check_Box";
import { useSetLayerHeight } from "../editorLogics/components";
const CheckBox = (props) => {
  // References
  const { updateHistory, elementId, undoRedoChange } = props;
  const checkBoxContainer = document.getElementById(elementId);
  const elementLayer = checkBoxContainer.closest(".cs-layer");
  let checkboxRows = checkBoxContainer.querySelectorAll(".checkbox-row");
  const checkTexts = checkBoxContainer.querySelectorAll(".label");
  const fontSize = checkBoxContainer.style.fontSize;

  const [text, setText] = useState("");
  const [textColor, setTextColor] = useState("#000000");
  const [checkboxes, setcheckboxes] = useState([]);
  const [properties, setProperties] = useState({
    outlined: false,
    color: "",
  });

  // < ----- GETTING INPUT VALUES ----- >
  useEffect(() => {
    // Clearing old checkboxes
    setcheckboxes([]);
    // getting innerhtml
    let svgColor;
    checkboxRows.forEach((row) => {
      const checkbox = row.querySelector(".checkbox");
      const checked = checkbox.classList.contains("checked");
      svgColor = getComputedStyle(checkbox.querySelector("svg")).fill;
      svgColor = convertRgbAToHexAplha(svgColor).hex;

      const text = row.querySelector(".label");

      setcheckboxes((prev) => [
        ...prev,
        {
          checked,
          text: text.innerHTML,
          id: Math.random(),
        },
      ]);
    });
    if (checkTexts[0].style.color != "") {
      setTextColor(convertRgbAToHexAplha(checkTexts[0].style.color).hex);
    }
    // getting checkbox style
    setProperties((prev) => ({ ...prev, outlined, color: svgColor }));
    const outlined = checkBoxContainer.classList.contains("outlined");
    if (outlined) {
      setProperties((prev) => ({ ...prev, outlined: true }));
    } else {
      setProperties((prev) => ({ ...prev, outlined: false }));
    }
  }, [elementId, undoRedoChange]);

  // < ----- EDITING IN HTML----- >
  // Adding checkbox row
  const addRow = () => {
    setcheckboxes((prev) => [
      ...prev,
      { checked: true, text, id: Math.random() },
    ]);
    setText("");
    updateHistory();
  };

  // removing checkbox row
  const removeRow = (id) => {
    const filtered = checkboxes.filter((obj) => obj.id !== id);
    setcheckboxes(filtered);
    updateHistory();
  };

  // changing color
  let timeout;
  const inputHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    clearTimeout(timeout);
    if (name === "color") {
      timeout = setTimeout(() => {
        setProperties((prev) => ({ ...prev, [name]: value }));
        updateHistory();
      }, 300);
      checkboxRows.forEach(
        (check) => (check.querySelector("svg").style.fill = value)
      );
    }
  };

  const outlineHandler = (e) => {
    const check = e.target.checked;
    setProperties((pre) => ({ ...pre, outlined: check }));
    checkBoxContainer.classList.toggle("outlined");
    updateHistory();
  };

  const textColorHandler = (e) => {
    const value = e.target.value;
    checkTexts.forEach((text) => (text.style.color = value));
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setTextColor(value);
      updateHistory();
    }, 300);
  };

  // check / uncheck
  const toggleCheck = (e, id) => {
    const checked = e.currentTarget.classList.contains("checked");
    const updatedCheck = checkboxes.map((check) => {
      return {
        ...check,
        checked: check.id === id ? !checked : check.checked,
      };
    });
    setcheckboxes(updatedCheck);
    updateHistory();
  };

  // updating text
  const updateText = (e, id) => {
    const text = e.target.value;
    const updatedText = checkboxes.map((check) => {
      return {
        ...check,
        text: check.id === id ? text : check.text,
      };
    });
    setcheckboxes(updatedText);
    e.target.onblur = () =>
      updateHistory({
        id: elementId,
      });
  };

  // < ----- THEN RENDERING OUTPUT----- >
  // rendering html
  useEffect(() => {
    if (checkboxes.length) {
      checkBoxContainer.innerHTML = "";
      checkboxes.forEach((obj) => {
        const row = `
      <div class="checkbox-row">
        <div class="checkbox ${obj.checked && "checked"}">
        <svg width="${fontSize}" fill="${
          properties.color
        }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"> <g> <path fill="none" d="M0 0h24v24H0z"/> <path fill="inherit" d="M4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1z"/> </g> </svg>
      </div>
        <span class="label" style="color:${textColor};">${obj.text}</span>`;
        checkBoxContainer.innerHTML += row;
      });
      checkboxRows = checkBoxContainer.querySelectorAll(".checkbox-row");
      useSetLayerHeight($(elementLayer));
    }
  }, [checkboxes]);

  // rendering styles

  return (
    <Box className={`checkbox-container`}>
      <Control
        heading="Checkbox List"
        className="checkboxProperty"
        divider={false}
        isCollapse={true}
        expanded={true}
      >
        {/* Preview for checkboxes */}
        {checkboxes.map((checkRow, i) => {
          return (
            <div className="checkbox-row" key={i}>
              <div
                onClick={(e) => toggleCheck(e, checkRow.id)}
                className={`checkbox ${checkRow.checked && "checked"}`}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <g>
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1z" />
                  </g>
                </svg>
              </div>
              <input
                onChange={(e) => updateText(e, checkRow.id)}
                className="label label-input-checkBox"
                value={checkRow.text}
              />
              <IconButton
                size="small"
                className="removeIcon"
                disabled={checkboxes.length === 1}
                onClick={() => removeRow(checkRow.id)}
              >
                <IoMdRemoveCircleOutline size={15} />
              </IconButton>
            </div>
          );
        })}

        {/* Adding new Field to checkbox */}
        <Grid container alignItems="center" className="checkbox-row addItem">
          <Grid item xs={9}>
            <input
              className="addItem-input"
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Add Item"
            />
          </Grid>
          <Grid item xs={3} className="text-right">
            <Button
              className="addNew-btn"
              size="small"
              variant="contained"
              disabled={!text.length}
              onClick={addRow}
            >
              <IoMdAdd size="20px" />
            </Button>
          </Grid>
        </Grid>
      </Control>

      {/* checkbox properties */}
      <Control
        heading="Styles"
        className="checkboxProperty"
        isCollapse={true}
        expanded={false}
        divider={false}
      >
        <Row
          label="Checkbox Color"
          element={
            <input
              type="color"
              className="color"
              name="color"
              value={properties.color}
              onInput={inputHandler}
            />
          }
        />
        <Row
          label="Text Color"
          element={
            <input
              type="color"
              className="color"
              name="color"
              value={textColor}
              onInput={textColorHandler}
            />
          }
        />
        <Row
          label="Outlined"
          element={
            <Check_Box
              color="secondary"
              size="small"
              checked={properties.outlined}
              onChange={outlineHandler}
            />
          }
        />
      </Control>
    </Box>
  );
};
export default CheckBox;
