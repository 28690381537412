import React, { useEffect, useState } from "react";
import Control from "./Control";
import "src/styles/properties.scss";

const Input = ({ elementId, updateHistory, children, undoRedoChange }) => {
  const [inputValue, setInputValue] = useState("");
  const element = document.getElementById(elementId);

  useEffect(() => {
    const input = document.getElementById(elementId);
    const { value } = input.dataset;

    if (value) {
      input.value = value;
      setInputValue(value);
    } else {
      setInputValue("");
    }
  }, [elementId, undoRedoChange]);

  const inputStyles = {
    padding: "8px",
    width: "100%",
    margin: 0,
    outline: "none",
    border:"1px solid #ddd"
  };

  const inputHandler = (e) => {
    const { value } = e.target;
    setInputValue(value);
    if (element.nodeName == "INPUT") {
      value ? (element.value = value) : (element.value = "Type here");
    } else {
      value ? (element.innerText = value) : (element.innerText = "Type here");
    }

    e.target.onblur = () => updateToHistory(value);
  };

  if (element.nodeName === "A") {
    element.addEventListener("click", (e) => e.preventDefault());
  }

  const updateToHistory = (value) => {
    element.setAttribute("data-value", value);
    updateHistory({
      id: elementId,
    });
  };

  return (
    <>
      <Control
        heading="Input Text"
        isCollapse={true}
        divider={false}
        expanded={true}
      >
        <input
          style={inputStyles}
          type="text"
          name="input"
          value={inputValue}
          onChange={inputHandler}
          placeholder="Type here"
          spellCheck={false}
        />
        {children}
      </Control>
    </>
  );
};

export default Input;
