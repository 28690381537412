import React, { useState, useEffect } from "react";
import Content from "src/layouts/Content";
import Card from "src/components/ui/Card";
import Input from "src/components/ui/formElements/Input";
import { Grid, Button, Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAccountById, updateAccount } from "src/store/actions/campaign";
import { uiActions } from "src/store/reducers/ui-slice";

const UpdateAccount = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //get account details
  const campaignAccountDetail = useSelector(
    (state) => state.campaign.accountDetail
  );
  const [accountDetails, setAccountDetails] = useState(campaignAccountDetail);

  useEffect(() => {
    dispatch(fetchAccountById(id));
  }, [dispatch]);

  useEffect(() => {
    setAccountDetails(campaignAccountDetail);
  }, [campaignAccountDetail]);

  const submitUpdateAccount = (e) => {
    e.preventDefault();
    const data = {
      name: e.target.name.value,
    };
    const id = accountDetails._id;
    dispatch(updateAccount({ id, data })).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        navigate("/campaign/accounts");
      } else {
        dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Account Error!",
            message: `Account Already Exists`,
          })
        );
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAccountDetails((pre) => ({ ...pre, [name]: value }));
  };

  return (
    <Grid container gap={4}>
      <Grid item xs={12}>
        <Content title="Update Account">
          <Card classes="campaign">
            <form onSubmit={submitUpdateAccount}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Input
                    name="name"
                    className="input_box"
                    labelTag="Name *"
                    value={accountDetails.name}
                    onChange={handleChange}
                    required={true}
                  />
                </Grid>

                <Grid item md={12} className="d-flex btn-align">
                  <Box>
                    <Button
                      variant="contained"
                      type="submit"
                      className="campaignBtn"
                    >
                      Update Account
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Content>
      </Grid>
    </Grid>
  );
};

export default UpdateAccount;
