import React from "react";
import { useSelector } from "react-redux";
import { hideOnStatus } from "src/services/utils";

const AccessController = (props) => {
  const { roles } = useSelector((state) => state.roles);
  const { role, children, component, status } = props;

  if (hideOnStatus?.[status]?.[component]) {
    return <></>;
  }

  return <>{roles[role][component] && children}</>;
};

export default React.memo(AccessController);
