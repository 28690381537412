import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Grid, Button, Checkbox, Typography, Box } from "@mui/material";
import { convertHexToRgba, convertRgbAToHexAplha } from "src/services/editor.service";

import ChooseAsset from "src/components/dialogs/ChooseAsset";
import Control from "./Control";
import "src/styles/properties.scss";
import { RiVideoUploadLine } from "react-icons/ri";
import { MdOutlineUpload } from "react-icons/md";
import Row from "src/components/properties/Row";
import AssetUpload from "./AssetUpload";
import Check_Box from "src/components/common/Check_Box";
import { csVideo } from "../editorLogics/components";


const Video = (props) => {
  const { elementId, updateHistory, undoRedoChange } = props;
  const element = document.getElementById(elementId);
  const video = element.querySelector("video");

  const location = useLocation();

  const videoControls = element.querySelector(".videoControls");
  const playBtn = element.querySelector(".play");
  const muteBtn = element.querySelector(".mute");
  const fullScreenBtn = element.querySelector(".fullScreen");
  const time = element.querySelectorAll(".time span");
  const timeline = element.querySelector(".timeline");
  const duration = element.querySelector(".duration");
  const currentTime = element.querySelector(".currentTime");

  const videoControl = csVideo(element);

  const minuteRef = useRef();
  const secondRef = useRef();
  let timeout;
  const [play, setPlay] = useState(false);
  const [loop, setLoop] = useState(false);
  const [playSlideEnter, setPlaySlideEnter] = useState(false);
  const [fullScreenOnPlay, setFullScreenOnPlay] = useState(false);
  const [mute, setMute] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [colorControl, setColorControl] = useState(`#000000`);
  const [controlBackground, setControlBackground] = useState(`#000000`);
  const [showControls, setShowControls] = useState(false);
  const [videoOpen, setVideoOpen] = useState(false);
  // const [posterOpen, setPosterOpen] = useState(false);

  // const toggleVideoUpload = () => {
  //   setVideoOpen(!videoOpen);
  // };

  // const togglePosterUpload = () => {
  //   setPosterOpen(!posterOpen);
  // };

  const loopHandler = (e) => {
    const loop = e.target.checked;
    setLoop(loop);
    if (loop) {
      video.loop = true;
      element.dataset.loop = true;
    } else {
      video.loop = false;
      element.dataset.loop = false;
    }

    updateHistory();
  };

  const showControlHandler = (e) => {
    setShowControls(e.target.checked);
    const showControls = e.target.checked;
    if (showControls) {
      element.dataset.controls = true;
      videoControls.style.display = "flex";
    } else {
      element.dataset.controls = false;
      videoControls.style.display = "none";
    }

    updateHistory();
  };

  const playHandler = (e) => {
    const play = e.target.checked;
    setPlay(play);
    if (play) {
      video.play();
      element.dataset.play = true;
      playBtn.innerHTML = `<svg stroke="currentColor" fill="currentColor"
      stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
      <path d="M224 432h-80V80h80zm144 0h-80V80h80z"></path></svg>`;
    } else {
      video.pause();
      element.dataset.play = false;
      playBtn.innerHTML = `<svg stroke="currentColor"
            fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M96 448l320-192L96 64v384z"></path></svg>`;
    }
    videoControl.toggleScreen();

    updateHistory();
  };

  const muteHandler = (e) => {
    const check = e.target.checked;
    setMute(check);
    if (check) {
      video.muted = true;
      element.dataset.mute = true;
      muteBtn.innerHTML = `
      <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512"
      height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-linecap="square"
      stroke-miterlimit="10" stroke-width="32" d="M416 432L64 80"></path>
      <path d="M352 256c0-24.56-5.81-47.88-17.75-71.27L327 170.47 298.48 185l7.27 14.25C315.34 218.06 320 236.62 320 256a112.91 112.91 0 01-.63 11.74l27.32 27.32A148.8 148.8 0 00352 256zm64 0c0-51.19-13.08-83.89-34.18-120.06l-8.06-13.82-27.64 16.12 8.06 13.82C373.07 184.44 384 211.83 384 256c0 25.93-3.89 46.21-11 65.33l24.5 24.51C409.19 319.68 416 292.42 416 256z"></path>
      <path d="M480 256c0-74.26-20.19-121.11-50.51-168.61l-8.61-13.49-27 17.22 8.61 13.49C429.82 147.38 448 189.5 448 256c0 48.76-9.4 84-24.82 115.55l23.7 23.7C470.16 351.39 480 309 480 256zM256 72l-73.6 58.78 73.6 73.59V72zM32 176.1v159.8h93.65L256 440V339.63L92.47 176.1H32z"></path>
      </svg>
      `;
    } else {
      video.muted = false;
      element.dataset.mute = false;
      muteBtn.innerHTML = `
      <svg stroke="currentColor" fill="currentColor" stroke-width="0"
      viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
      <path fill="none" stroke-linecap="square" stroke-miterlimit="10" stroke-width="32"
      d="M320 320c9.74-19.38 16-40.84 16-64 0-23.48-6-44.42-16-64m48 176c19.48-33.92 32-64.06 32-112s-12-77.74-32-112m48 272c30-46 48-91.43 48-160s-18-113-48-160"></path>
      <path d="M125.65 176.1H32v159.8h93.65L256 440V72L125.65 176.1z"></path></svg>
      `;
    }

    updateHistory();
  };

  const getDuration = () => {
    video.currentTime = element.dataset.time;
    video.removeEventListener("timeupdate", getDuration);
    duration.textContent = new Date(video.duration * 1000)
      .toISOString()
      .slice(14, 19);
  };

  const colorControlHandler = (e) => {
    let val = e.target.value;
    const color = convertHexToRgba(e.target.value).slice(5, -1).split(",");
    time.forEach((span) => (span.style.color = e.target.value));
    timeline.style.background = `rgba(${color[0]} , ${color[1]} , ${color[2]} , 0.6)`;
    timeline.style.setProperty("--sliderColor", e.target.value);
    playBtn.style.color = e.target.value;
    muteBtn.style.color = e.target.value;
    fullScreenBtn.style.color = e.target.value;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setColorControl(val);
      updateHistory();
    }, 100);
  };

  const controlBackgroundHandler = (e) => {
    let val = e.target.value;
    videoControls.style.background = e.target.value;

    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setControlBackground(val);
      updateHistory();
    }, 200);
  };

  const minuteHandler = (e) => {
    let val = e.target.value;

    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setMinutes(val);
      updateHistory();
    }, 200);
  };

  const secondHandler = (e) => {
    let val = e.target.value;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setSeconds(val);
      updateHistory();
    }, 200);
  };

  useEffect(() => {
    if (location.pathname.includes("presentation")) {
      const timeInSeconds = Number(minutes) * 60 + Number(seconds);
      video.currentTime = timeInSeconds;
      element.dataset.time = timeInSeconds;
      timeline.value = timeInSeconds;
    }
  }, [minutes, seconds]);
  
  useEffect(() => {
    if (location.pathname.includes("presentation")) {
      if (videoOpen) {
        setPlay(false);
        playBtn.innerHTML = `<svg stroke="currentColor"
            fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M96 448l320-192L96 64v384z"></path></svg>`;
      } else {
        setTimeout(() => {
          // element.dataset.src = video.src;
          timeline.max = video.duration;
          currentTime.textContent = new Date(video.currentTime * 1000)
            .toISOString()
            .slice(14, 19);
          timeline.value = Math.trunc(video.currentTime);
          video.addEventListener("loadedmetadata", function () {
            if (video.duration === Infinity) {
              video.currentTime = 24 * 60 * 60;
              video.addEventListener("timeupdate", getDuration);
            } else {
              duration.textContent = new Date(video.duration * 1000)
                .toISOString()
                .slice(14, 19);
            }
          });
          minuteRef.current.max = Math.trunc(video.duration / 60);
          secondRef.current.max = Math.trunc(video.duration % 60);
        }, 400);
      }
    }
  }, [videoOpen]);

  useEffect(() => {
    if (fullScreenOnPlay) {
      element.setAttribute("data-full-screen-play", "true");
    } else {
      element.setAttribute("data-full-screen-play", "false");
    }
  }, [fullScreenOnPlay]);

  useEffect(() => {
    if (playSlideEnter) {
      element.setAttribute("data-play-slide-enter", "true");
    } else {
      element.setAttribute("data-play-slide-enter", "false");
    }
  }, [playSlideEnter]);
  
  // useEffect(() => {
  //   if (!posterOpen) {
  //     if (video.poster) {
  //       element.dataset.poster = video.poster;
  //     }
  //   }
  // }, [posterOpen]);
  
  useEffect(() => {
    if (location.pathname.includes("presentation")) {
      const [mins, secs] = new Date(video.currentTime * 1000)
        .toISOString()
        .slice(14, 19)
        .split(":");

      // Pause
      if (!video.paused) {
        setPlay(true);
      } else {
        setPlay(false);
      }

      // Mute
      if (video.muted) {
        setMute(true);
      } else {
        setMute(false);
      }

      if (element.dataset.controls == "true") {
        setShowControls(true);
      } else {
        setShowControls(false);
      }

      if (element.dataset.loop == "true") {
        setLoop(true);
      } else {
        setLoop(false);
      }

      if (element.getAttribute("data-full-screen-play") == "true") {
        setFullScreenOnPlay(true);
      } else {
        setFullScreenOnPlay(false);
      }

      if (element.getAttribute("data-play-slide-enter") == "true") {
        setPlaySlideEnter(true);
      } else {
        setPlaySlideEnter(false);
      }

      setColorControl(convertRgbAToHexAplha(playBtn.style.color).hex);

      video.addEventListener("loadedmetadata", function () {
        if (video.duration === Infinity) {
          video.currentTime = 24 * 60 * 60;
          video.addEventListener("timeupdate", getDuration);
        } else {
          duration.textContent = new Date(video.duration * 1000)
            .toISOString()
            .slice(14, 19);
        }
      });

      currentTime.textContent = new Date(video.currentTime * 1000)
        .toISOString()
        .slice(14, 19);
      timeline.value = Math.trunc(video.currentTime);

      setControlBackground(
        convertRgbAToHexAplha(videoControls.style.background).hex
      );

      setMinutes(Number(mins));
      setSeconds(Number(secs));
      timeline.max = Math.trunc(video.duration);
      minuteRef.current.max = Math.trunc(video.duration / 60);
      secondRef.current.max = Math.trunc(video.duration % 60);
    }
  }, [elementId, undoRedoChange]);
  
  return (
    <Control
      heading="Video"
      className="audio video"
      isCollapse={true}
      divider={false}
      expanded={true}
    >
      <AssetUpload
        elementId={elementId}
        isCollapse={false}
        assetFormat="video"
        updateType="video"
        updateHistory={updateHistory}
      />
      {location.pathname.includes("presentation") && (
        <>
          <Typography>Upload Poster</Typography>
          <AssetUpload
            elementId={elementId}
            isCollapse={false}
            assetFormat="image"
            updateType="poster"
            updateHistory={updateHistory}
            undoRedoChange={undoRedoChange}
          />
          <Row
            label="Current Time"
            element={
              <>
                <input
                  type="number"
                  className="input timer-input"
                  value={minutes}
                  ref={minuteRef}
                  min={0}
                  max={59}
                  onChange={minuteHandler}
                />
                <span> : </span>
                <input
                  type="number"
                  className="input timer-input"
                  value={seconds}
                  ref={secondRef}
                  min={0}
                  max={59}
                  onChange={secondHandler}
                />
              </>
            }
          />
          <Row
            label="Mute"
            element={
              <Check_Box
                name="mute"
                color="secondary"
                size="small"
                checked={mute}
                onChange={muteHandler}
              />
            }
          />
          <Row
            label="Play"
            element={
              <Check_Box
                name="play"
                color="secondary"
                size="small"
                checked={play}
                onChange={playHandler}
              />
            }
          />
          <Row
            label="Loop"
            element={
              <Check_Box
                name="loop"
                color="secondary"
                size="small"
                checked={loop}
                onChange={loopHandler}
              />
            }
          />
          <Row
            label="play On Slide Enter"
            element={
              <Check_Box
                name="playSlideEnter"
                color="secondary"
                size="small"
                checked={playSlideEnter}
                onChange={(e) => setPlaySlideEnter(e.target.checked)}
              />
            }
          />
          <Row
            label="FullScreen On Play"
            element={
              <Check_Box
                name="fullScreenOnPlay"
                color="secondary"
                size="small"
                checked={fullScreenOnPlay}
                onChange={(e) => setFullScreenOnPlay(e.target.checked)}
              />
            }
          />
          <Row
            label="Show Controls"
            element={
              <Check_Box
                name="controls"
                color="secondary"
                size="small"
                checked={showControls}
                onChange={showControlHandler}
              />
            }
          />
          <Row
            label="Control Color"
            element={
              <input
                type="color"
                name="controlColor"
                className="color"
                value={colorControl}
                onChange={colorControlHandler}
              />
            }
          />
          <Row
            label="Control Background"
            element={
              <input
                type="color"
                name="controlBackground"
                className="color"
                value={controlBackground}
                onChange={controlBackgroundHandler}
              />
            }
          />
        </>
      )}
    </Control>
  );
};

export default Video;
