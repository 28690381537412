import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../components/ui/Logo";
import LogoLarge from "../assets/images/logo.png";
import Copyright from "../components/ui/Copyright";
import EmailForm from "src/components/login/EmailForm";
import { Grid, Box, Typography } from "@mui/material";
import { newActivationToken } from "src/store/actions/auth";
import { uiActions } from "src/store/reducers/ui-slice";

const GenerateToken = () => {
  const dispatch = useDispatch();
  const { isUser } = useSelector((state) => state.auth);

  const generateTokenHandler = (e) => {
    e.preventDefault();
    const [[_, email]] = new FormData(e.target);
    dispatch(newActivationToken(email));
  };
  useEffect(() => {
    if (isUser) {
      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Token Generated",
          message: "An Email has been sent to you with new token",
        })
      );
    }
  }, [isUser]);
  return (
    <Grid className="login-page" spacing={2} container>
      <Grid item md={7}>
        <Box id="login-background"></Box>
      </Grid>
      <Grid
        item
        md={5}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Box className="login-form-container">
          <Logo logoSrc={LogoLarge} />
          <Typography
            component="h1"
            variant="h1"
            className="login-heading"
            sx={{
              marginBottom: "30px !important",
            }}
          >
            Generate Token
          </Typography>
          <EmailForm submitHandler={generateTokenHandler} btnText="generate" />
          <Copyright />
        </Box>
      </Grid>
    </Grid>
  );
};
export default GenerateToken;
