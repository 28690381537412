import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import PresentationStatus from "./Status";
import { makeStyles } from "@mui/styles";
import { FaUsers } from "react-icons/fa";
const useStyles = makeStyles((theme) => ({
  shapedPaper: {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1), 
  },
}));

const CardItem = (props) => {
  const classes = useStyles();
  return (
    <Box
      id={props.id}
      className={`cardItem ${props.classes} ${classes.shapedPaper}`}
      onClick={props.onClick}
      onDoubleClick={props.onDoubleClick}
    >
      <Box className="cardItemHeader">
        <Box className="image-wrap">
          {props.isShared && <span className="sharedIcon"><FaUsers/></span>}
          <img src={props.image + `?time=${Date.now()}`} draggable={false} />
        </Box>
      </Box>
      <Box className="cardItemBody">
        <Grid container>
          <Grid item md={12}>
            <Typography component="h2" className="title">
              {props?.title || 'No Name'}
            </Typography>
          </Grid>
          <Grid item md={12} className="cardItemSubContainer">
            <Typography component="p" className="subtitle">
              {props.subtitle}
            </Typography>
            <PresentationStatus status={props.status} onCard />
          </Grid>
        </Grid>
      </Box>
      {props.controls && (
        <Box>
          <Box className="controls">
            {props.controls}
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default React.memo(CardItem);
