import React from 'react';
import './Card.scss';
const Card = (props) => {
 const {heading, body, className,children} = props
  return (
    <div className={`summaryCardItem ${className? className:''}`}>
        <div className='header'>
            <h3> {heading}</h3>
        </div>
        <div className='body'>
            {children}
        </div>
    </div>
  )
}

export default Card