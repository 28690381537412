import React from "react";
import { MdExpandMore } from "react-icons/md";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { getFormattedTime, getSpendedTime } from "src/services/utils";

const Timeline = (props) => {
  const { data: analyticsData, viewType = "summary" } = props;

  const getHigh = (analytics) => {
    const data = analytics[viewType];

    let highNum = 0;
    let highName = "";
    let chapter = "";
    let highIndex = 0;

    data.forEach((elData, i) => {
      if (highNum < elData.time) {
        highIndex = i;
        highNum = elData.time;
        highName = elData.slide;
        chapter = elData.chapter;
      }
    });
    return {
      slide: highName,
      chapter: chapter,
      stayed: highNum,
      i: highIndex,
    };
  };

  return (
    <>
      {analyticsData.length ? (
        <div className="timeline-container">
          {analyticsData.map((analytics, i) => {
            const highranked = getHigh(analytics);

            return (
              <div className="timeline-wrapper" key={i}>
                <div className={`timeline-heading`}>
                  <span className="time">
                    {getFormattedTime(analytics[viewType][0]?.loadTime) +
                      "  - " +
                      getFormattedTime(
                        analytics[viewType][0]?.loadTime,
                        "toLocaleTimeString"
                      )}
                  </span>
                </div>
                <div className={`timeline-body`}>
                  {analytics[viewType].map((data, i) => {
                    return (
                      <div
                        className={`timeline ${
                          highranked.i == i ? "highranked" : ""
                        }`}
                        key={i}
                      >
                        <div className="wrap">
                          <div className="title">
                            <h3>{data.slide}</h3>
                            <p>
                              {getFormattedTime(
                                data.loadTime,
                                "toLocaleTimeString"
                              )}
                            </p>
                          </div>
                          <div className="desc">
                            <p>Chapter - {data.chapter}</p>
                            <p>Stayed {getSpendedTime(data.time)}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="no-data">
          <p>No Activity was Found {props.name && `for ${props.name}`}</p>
        </div>
      )}
    </>
  );
};

export default Timeline;

Timeline.defaultProps = {
  data: [],
  name: "",
};
