import { Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  chip:{
    borderRadius:'2px',
    fontSize:'10px',
    textTransform:'capitalize'
  },
  pending: {
    backgroundColor: "#9E9E9E", // Pending color
    color: "#FFFFFF",
  },
  active: {
    backgroundColor: "#b8e3ba", // Active color
    color: "#226e25",
  },
  inactive: {
    backgroundColor: "#e2a197", // Inactive color
    color: "#894035",
  },
}));

const StatusChip = ({ label, status }) => {
  const classes = useStyles();
  let chipStyle;
  switch (status) {
   case 'pending':
     chipStyle = `${classes.chip} ${classes.pending}`;
     break;
   case 'active':
     chipStyle = `${classes.chip} ${classes.active}`;
     break;
   case 'inactive':
     chipStyle = `${classes.chip} ${classes.inactive}`;
     break;
   default:
     chipStyle = `${classes.chip} ${classes.pending}`;
  }

  return <Chip label={label} className={chipStyle} size="small" />;
};
export default StatusChip;
