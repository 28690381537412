import React from "react";
import {
  Grid,
  Typography,
  Box,
  IconButton,
  Button,
  TextField,
  Tooltip,
} from "@mui/material";
import { IoCloudUploadOutline, IoCloudDoneOutline } from "react-icons/io5";
import {
  MdUndo,
  MdRedo,
  MdOutlineFitScreen,
  MdFitScreen,
} from "react-icons/md";
import { BsTablet } from "react-icons/bs";
import { BiZoomIn, BiZoomOut } from "react-icons/bi";
import SelectDevice from "src/components/common/SelectDevice";
import { renderEditableElements } from "../renderPresentation";
import { useEditorHistory } from "src/hooks/editor/history";

const TopBar = (props) => {
  const { undo, redo, disabledButtons } = useEditorHistory({
    csRenderEditable: renderEditableElements,
  });

  return (
    <Box className="editorHead">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid item md={4.5}>
          <Tooltip title={props.heading}>
            <Typography variant="h2" className="topbar_heading">
              {props.heading}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item md={2}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {props.component === "presentation" && props.dimensions && (
              <>
                <Grid>
                  <Typography variant="body1" component="span" className="text">
                    Target Device :
                    <Typography
                      variant="body1"
                      component="span"
                      ml={1}
                      sx={{ verticalAlign: "middle" }}
                    >
                      <BsTablet />
                    </Typography>
                  </Typography>
                  {/* SelectBox */}
                </Grid>
                <Grid>
                  <SelectDevice
                    value={props.dimensions.deviceId}
                    disabled={true}
                  />
                </Grid>
              </>
            )}

            {/* Zoom */}
            <Grid>
              <Box className="zoom-selectbox">
                <Tooltip title="Zoom-Out" arrow>
                  <IconButton
                    onClick={() => props.handleZoomChange(props.zoom - 1)}
                  >
                    <BiZoomOut />
                  </IconButton>
                </Tooltip>
                <TextField
                  type="number"
                  value={props.zoom}
                  onChange={(e) => props.handleZoomChange(e.target.value)}
                />
                <Tooltip title="Zoom-In" arrow>
                  <IconButton
                    onClick={() => props.handleZoomChange(props.zoom + 1)}
                  >
                    <BiZoomIn />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Scale to Fit" arrow>
                  <IconButton
                    variant="contained"
                    onClick={() => props.handleZoomChange("scaleToFit")}
                  >
                    {props.scaledToFit ? (
                      <MdFitScreen />
                    ) : (
                      <MdOutlineFitScreen />
                    )}
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
            <Grid></Grid>
          </Grid>
        </Grid>
        <Grid item md={5.5}>
          <Grid
            container
            alignItems="center"
            justifyContent="end"
            textAlign="end"
          >
            <Grid item>
              <Tooltip
                title={
                  disabledButtons.undo
                    ? "No Previous History"
                    : "Undo (ctrl + z)"
                } arrow
              >
                <span>
                  <IconButton
                    className="iconColor"
                    onClick={undo}
                    disabled={disabledButtons.undo}
                  >
                    <MdUndo />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip
                title={
                  disabledButtons.redo ? "No Next History" : "Redo (ctrl + y)"
                } arrow
              >
                <span>
                  <IconButton
                    className="iconColor"
                    onClick={redo}
                    disabled={disabledButtons.redo}
                  >
                    <MdRedo />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
            <Grid item>
              <Button
                sx={{ width: "160px" }}
                variant="text"
                onClick={props.handleSlideExport}
                startIcon={
                  props.isAnyChange ? (
                    <IoCloudUploadOutline />
                  ) : (
                    <IoCloudDoneOutline />
                  )
                }
                disabled={!props.isAnyChange}
              >
                {props.isAnyChange ? "Save All Changes" : "All Changes saved"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TopBar;
