import React, { useState, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//APIs
import { fetchShared } from "src/store/actions/sharing";

//MUI Componenets
import { Grid, Typography } from "@mui/material";

import Content from "src/layouts/Content";
import Pagination from "src/components/ui/Pagination";
import LandingPageCard from "src/components/microsites/LandingPageCard";

//Dialogs
import MicrositesSharedWithMeDialogs from "src/components/dialogs/microsites/MicrositesSharedWithMe-Dialogs";

const SharedWithMe = () => {
  const type = "landingpage";
  const dispatch = useDispatch();
  const { shared: sharedLandingPages, count,loadingMsg } = useSelector(
    (state) => state.sharing
  );
  const { _id: userId } = useSelector((state) => state.auth.userData);

  const [searchParams] = useSearchParams();
  const [details, setDetails] = useState({});
  const [micrositeId, setMicrositeId] = useState("");

  //Dialogs State
  const [dialog, setDialog] = useState({});

  const keyword = searchParams.get("search");
  const languageParams = searchParams.get("language");
  const countryParams = searchParams.get("country");

  const limit = 10;
  useEffect(() => {
    const pageNo = Math.round(Number(searchParams.get("page"))) || 1;
    const skip = (pageNo - 1) * limit;
    const params = {};
    searchParams.forEach((value, key) => {
      if(key !== "page") params[key] = value;
    });

    params.skip = skip;
    params.limit = limit;
    params.type = type;
    if (!(keyword || languageParams || countryParams)) {
      dispatch(fetchShared(params));
    }
  }, [searchParams]);

  //microsite info
  const showDetails = useCallback((landingPage) => {
    setDialog({ info: true });
    setDetails(landingPage);
  }, []);

  //open dialogs function
  const openDialog = useCallback((id, name) => {
    setMicrositeId(id);
    setDialog({ [name]: true });
  }, []);

  return (
    <>
      {/* Dialogs */}
      <MicrositesSharedWithMeDialogs
        dialog={dialog}
        microsite={details}
        micrositeId={micrositeId}
        onClose={() => setDialog({})}
      />
      {/* Dialogs */}

      <Content>
        <Grid container spacing={3} display="flex" mb={8}>
          {!loadingMsg && userId && sharedLandingPages ? (
            sharedLandingPages.map((shared) => {
              const landingPage = shared.landingPageId;
              const role = shared.sharedWith.find(
                (user) => user.userId === userId
              );
              if (!role || !landingPage) {
                return <></>;
              }

              return (
                <LandingPageCard
                  key={landingPage._id}
                  microsite={landingPage}
                  showDetails={showDetails}
                  openDialog={openDialog}
                  role={role.role}
                />
              );
            })
          ) : (
            <Typography variant="h1" pl={3} pt={4}>
              {loadingMsg}
            </Typography>
          )}
        </Grid>
        <Pagination
          count={count}
          itemsPerPage={limit}
          enableParams
          onChange={(value) => {
            if (
              !searchParams.get("search") &&
              !searchParams.get("language") &&
              !searchParams.get("country")
            ) {
              dispatch(fetchShared({ ...value, type }));
            }
          }}
        />
      </Content>
    </>
  );
};
export default React.memo(SharedWithMe);
