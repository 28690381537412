import React, { useState, useEffect } from "react";
import { Checkbox } from "@mui/material";
import { convertRgbAToHexAplha } from "src/services/editor.service";
import Control from "./Control";
import Row from "src/components/properties/Row";
import "src/styles/properties.scss";
import Check_Box from "src/components/common/Check_Box";

const Popup = (props) => {
  const { elementId, updateHistory, undoRedoChange } = props;

  const element = document.getElementById(elementId);
  const closeBtn = element.querySelector(".closePop");

  const [showClosePopup, setShowClosePopup] = useState(true);
  const [outsideClick, setOutsideClick] = useState(false);
  const [showSlideEnter, setShowSlideEnter] = useState(false);
  const [btnColor, setBtnColor] = useState("#000000");
  const [label, setLabel] = useState("popup title");

  useEffect(() => {
    if (closeBtn.style.display == "none") {
      setShowClosePopup(false);
    } else {
      setShowClosePopup(true);
    }
    if (element.getAttribute("data-close-out-click") == "false") {
      setOutsideClick(false);
    } else {
      setOutsideClick(true);
    }
    if (element.getAttribute("data-show-slide-enter") == "false") {
      setShowSlideEnter(false);
    } else {
      setShowSlideEnter(true);
    }

    setBtnColor(convertRgbAToHexAplha(closeBtn.style.fill).hex);
    setLabel(element.dataset.label);
  }, [elementId, undoRedoChange]);

  const PopupCloseBtnShowHandler = (e) => {
    const check = e.target.checked;
    setShowClosePopup(check);

    if (check) {
      closeBtn.style.display = "block";
    } else {
      closeBtn.style.display = "none";
    }

    updateHistory();
  };

  const outsideClickHandler = (e) => {
    const check = e.target.checked;
    setOutsideClick(check);
    element.setAttribute("data-close-out-click", check);

    updateHistory();
  };

  const slideEnterHandler = (e) => {
    const check = e.target.checked;
    setShowSlideEnter(check);
    element.setAttribute("data-show-slide-enter", check);

    updateHistory();
  };

  let colorTimer;

  const btnColorHandler = (e) => {
    clearTimeout(colorTimer);
    const color = e.target.value;
    closeBtn.style.fill = color;
    colorTimer = setTimeout(() => {
      setBtnColor(color);
      updateHistory();
    }, 400);
  };

  let labelTimer;

  const labelHandler = (e) => {
    clearInterval(labelTimer);
    const label = e.target.value;
    element.dataset.label = label;
    setLabel(label);

    labelTimer = setTimeout(() => {
      updateHistory();
    }, 600);
  };

  return (
    <Control
      heading="Popup"
      className="Popup"
      isCollapse={true}
      expanded={true}
      divider={false}
    >
      <Row
        label="Popup Label"
        element={
          <input
            name="popupLabel"
            className="input fullwidth-input"
            type="text"
            value={label}
            onChange={labelHandler}
          />
        }
      />
      <Row
        label="Show Popup close Btn"
        leftsx={8}
        rightsx={4}
        element={
          <Check_Box
            name="popupClose"
            color="secondary"
            size="small"
            checked={showClosePopup}
            onClick={PopupCloseBtnShowHandler}
          />
        }
      />
      <Row
        label="Close on outside click"
        leftsx={8}
        rightsx={4}
        element={
          <Check_Box
            name="popupClose"
            color="secondary"
            size="small"
            checked={outsideClick}
            onClick={outsideClickHandler}
          />
        }
      />
      <Row
        label="Show on slide enter"
        element={
          <Check_Box
            name="popupClose"
            color="secondary"
            size="small"
            checked={showSlideEnter}
            onClick={slideEnterHandler}
          />
        }
      />
      <Row
        label="close button Color"
        element={
          <input
            name="btnColor"
            type="color"
            className="color"
            value={btnColor}
            onChange={btnColorHandler}
          />
        }
      />
    </Control>
  );
};

export default Popup;
