import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IoEllipsisVerticalSharp, IoEye } from "react-icons/io5";
import { MdFileDownload } from "react-icons/md";

import { fetchVersions } from "src/store/actions/versions";
import { dateFormat, statusTypes } from "src/services/utils";

import {
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
} from "@mui/material";

import DropDown from "src/components/ui/DropDown";

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const VersionsDetails = (props) => {
  const { type, id, initialRows, downloadVersion, previewVersion } = props;
  const dispatch = useDispatch();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initialRows);

  const { list: versions } = useSelector((state) => state.versions);

  useEffect(() => {
    dispatch(fetchVersions({ id, type }));
  }, [dispatch]);

  const dropDownData = (versionId) => {
    return [
      {
        label: "Preview",
        icon: <IoEye />,
        onClick: () => {
          previewVersion(versionId);
        },
      },
      {
        label: "Download Source",
        icon: <MdFileDownload />,
        onClick: () => {
          downloadVersion(versionId);
        },
        permissionPath: getPermissionPath()
      },
    ];
  };

  function getPermissionPath() {
    if (type === 'landingpage') return 'microsites.export.downloadSourceCode'
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    if (isAsc) {
      setOrder("desc");
    } else if (order === "desc") {
      setOrderBy("");
      setOrder("asc");
    } else {
      setOrderBy(property);
      setOrder("asc");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - versions.length) : 0;

  return (
    <>
      <TableContainer>
        <Table aria-labelledby="tableTitle">
          {/* Table Header Starts*/}
          <TableHead>
            <TableRow>
              {versionData.map((headCell) => (
                <TableCell
                  sx={{ textOverflow: "ellipsis" }}
                  key={headCell.id}
                  padding="normal"
                  align="left"
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.sort ? (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={createSortHandler(headCell.id)}
                      className="tableHead"
                    >
                      {headCell.label}
                    </TableSortLabel>
                  ) : (
                    headCell.label
                  )}
                </TableCell>
              ))}
              <TableCell
                sx={{ textOverflow: "ellipsis", padding: "0px 10px" }}
                align="right"
                className="tableHead"
              >
                ACTION
              </TableCell>
            </TableRow>
          </TableHead>
          {/* Table Header Endes*/}

          {/* Table Body Starts*/}
          <TableBody>
            {stableSort(versions, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((version, index) => {
                return (
                  <TableRow
                    padding="normal"
                    hover
                    tabIndex={-1}
                    key={version._id}
                  >
                    <TableCell align="left" className="tableBody">
                      {version.version}
                    </TableCell>
                    <TableCell align="left" className="tableBody">
                      {dateFormat(version.createdAt)}
                    </TableCell>
                    <TableCell align="left" className="tableBody">
                      {`${version.createdBy?.firstName} ${version.createdBy?.lastName}`}
                    </TableCell>
                    <TableCell align="left" className="tableBody">
                      {statusTypes[version.status]}
                    </TableCell>
                    <TableCell sx={{ textOverflow: "ellipsis" }} align="right">
                      <DropDown
                        dropIcon={<IoEllipsisVerticalSharp size={16} />}
                        tooltip="options"
                        items={dropDownData(version._id)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          {/* Table Body Starts*/}
        </Table>
      </TableContainer>
      {/* Table Pagination*/}
      <TablePagination
        rowsPerPageOptions={[
          5,
          10,
          25,
          { label: "All", value: versions.length },
        ]}
        component="div"
        count={versions.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default React.memo(VersionsDetails);

VersionsDetails.defaultProps = {
  initialRows: 10,
};

const versionData = [
  {
    id: "version",
    numeric: false,
    sort: true,
    label: "VERSION",
  },
  {
    id: "createdAt",
    numeric: true,
    sort: true,
    label: "DATE",
  },
  {
    id: "createdBy",
    numeric: true,
    sort: true,

    label: "CREATED BY",
  },
  {
    id: "status",
    numeric: true,
    sort: true,
    label: "STATUS",
  },
];
