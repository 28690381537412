import { createSlice } from "@reduxjs/toolkit";
import { fetchVersions } from "../actions/versions";

const initialState = {
  list: [],
};

const versionSlice = createSlice({
  name: "versionSlice",
  initialState,
  extraReducers: (builder) => {
    // Fetch Versions : Pending
    builder.addCase(fetchVersions.pending, (state, action) => {
      state.list = [];
    });

    // Fetch Versions : Fulfilled
    builder.addCase(fetchVersions.fulfilled, (state, action) => {
      state.list = action.payload;
    });
  },
});
export default versionSlice.reducer;
