import React from "react";
import { dateFormat } from "src/services/utils";
import { Grid, Box, Button, Typography } from "@mui/material";
import "../../../src/styles/presentations/details.scss";
import Image from "../ui/Image";
import { defaultImage } from "src/data/defaultAssets";
const SalesforcePresentationCard = (props) => {
  const { presentation, Action, showStatus } = props;
  return (
    <Box className="sub-details">
      <Grid container>
        <Grid item xs={4} sx={{ display: "flex" }}>
          <Box>
            <Image
              src={`${presentation.Download_Url__c}`}
              fallbackSrc={defaultImage}
              width="70px"
              alt="no image"
            />
          </Box>
          <Box className="imageText">
            <Typography variant="h2" className="details-heading" textTransform='capitalize'>
              {presentation.Name}
            </Typography>
          </Box>

        </Grid>
        <Grid item xs={2} >
          <Box className="vertical-line"></Box>
        </Grid>
        <Grid item xs={4} className="gridItem">
          <Typography className="subheading">Country</Typography>
          <Typography component="div" className="subheadingtext">
            {presentation.Country__c}
          </Typography>
        </Grid>
        {Action && (
          <Grid item xs={2} className="clonegrid">
            {Action}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default SalesforcePresentationCard;

SalesforcePresentationCard.defaultProps = {
  showStatus: true,
};
