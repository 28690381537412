import React, { useEffect, useState } from "react";
import Control from "./Control";
import { Slider, IconButton,Tooltip } from "@mui/material";
import {
  MdOutlineAlignHorizontalLeft,
  MdOutlineAlignHorizontalCenter,
  MdOutlineAlignHorizontalRight,
} from "react-icons/md";
import Row from "src/components/properties/Row";
import "src/styles/styles.scss";
import "src/styles/properties.scss";

const Width = ({
  elementId,
  undoRedoChange,
  updateHistory,
  heading,
  isCollapse,
  collapseTitle,
}) => {
  const element = document.getElementById(elementId);
  
  const getWidthInPercent = (parent, child) => {
    const parentWidth = parseInt(getComputedStyle(parent).width.split('px')[0]);
    const childWidth = parseInt(getComputedStyle(child).width);
    const widthInPercent = (childWidth / parentWidth) * 100;
    return Math.round(widthInPercent);
  };
  getWidthInPercent(element.parentNode.parentNode, element);

  const [width, setWidth] = useState(
    getWidthInPercent(element.parentNode.parentNode, element)
  );
  useEffect(() => {
    setWidth(getWidthInPercent(element.parentNode.parentNode, element));
  }, [elementId, undoRedoChange]);

  const widthHandler = (event) => {
    const { value } = event.target;
    element.style.width = value + "%";
    setWidth(value);
    updateHistory();
  };

  const alignStart = (e) => {
    const element = document.getElementById(elementId);
    element.style.display = "block";
    element.style.margin = "0 auto 0 0";
    changeActive(e, "activeEleAlign");
  };
  const alignCenter = (e) => {
    const element = document.getElementById(elementId);
    element.style.display = "block";
    element.style.margin = "0 auto 0 auto";
    changeActive(e, "activeEleAlign");
  };
  const alignEnd = (e) => {
    const element = document.getElementById(elementId);
    element.style.display = "block";
    element.style.margin = " 0 0 0 auto";
    changeActive(e, "activeEleAlign");
  };

  // Add Component Class in the array Below If you want to make it eligible for text Alignment
  const textAlignEligible = [
    "cs-input",
    "cs-button",
    "cs-link",
    "cs-rating",
    "cs-social",
  ];

  const textAlignStart = (e) => {
    const element = document.getElementById(elementId);
    element.style.textAlign = "start";
    changeActive(e, "activeTextAlign");
  };
  const textAlignCenter = (e) => {
    const element = document.getElementById(elementId);
    element.style.textAlign = "center";
    changeActive(e, "activeTextAlign");
  };
  const textAlignEnd = (e) => {
    const element = document.getElementById(elementId);
    element.style.textAlign = "end";
    changeActive(e, "activeTextAlign");
  };

  const changeActive = (e, className) => {
    const element = document.querySelector(`.${className}`);
    if (element) {
      element.classList.remove(className);
    }
    e.currentTarget.classList.add(className);
  };

  const getMargin = () => {
    const margin = { left: false, right: false, center: false };
    const style = element.style;

    if (style.marginLeft == "auto" && style.marginRight == "auto") {
      margin.center = true;
    }

    if (style.marginLeft == "auto" && style.marginRight != "auto") {
      margin.left = true;
    }

    if (style.marginRight == "auto" && style.marginLeft != "auto") {
      margin.right = true;
    }

    return margin;
  };

  const getTextAlign = () => {
    const text = { left: false, right: false, center: false };
    const style = element.style;

    if (style.textAlign == "start") {
      text.right = true;
    }

    if (style.textAlign == "end") {
      text.left = true;
    }

    if (style.textAlign == "center") {
      text.center = true;
    }

    return text;
  };

  return (
    <>
      <Control
        heading={collapseTitle !== undefined ? collapseTitle : ""}
        isCollapse={isCollapse !== undefined ? isCollapse : false}
        divider={false}
        expanded={false}
      >
        <Row
          label={heading ? heading : "Element Width"}
          element={
            <Slider
              className="slider"
              size="small"
              value={width}
              onChange={widthHandler}
            />
          }
        />

        <Row
          label="Element Alignment"
          element={
            <>
            <Tooltip title="Left Align" placement="top" arrow>
              <IconButton
                onClick={alignStart}
                size="small"
                color="primary"
                className={getMargin().right && "activeEleAlign"}
              >
                <MdOutlineAlignHorizontalLeft />
              </IconButton>
              </Tooltip>
              <Tooltip title="Center Align" placement="top" arrow>
              <IconButton
                onClick={alignCenter}
                size="small"
                color="primary"
                className={getMargin().center && "activeEleAlign"}
              >
                <MdOutlineAlignHorizontalCenter />
              </IconButton>
              </Tooltip>
              <Tooltip title="Right Align" placement="top" arrow>
              <IconButton
                onClick={alignEnd}
                size="small"
                color="primary"
                className={getMargin().left && "activeEleAlign"}
              >
                <MdOutlineAlignHorizontalRight />
              </IconButton>
              </Tooltip>
            </>
          }
        />
        {textAlignEligible.some((item) => element.classList.contains(item)) && (
          <Row
            label="Text Alignment"
            element={
              <>
                <IconButton
                  onClick={textAlignStart}
                  size="small"
                  color="primary"
                  className={getTextAlign().right && "activeTextAlign"}
                >
                  <MdOutlineAlignHorizontalLeft />
                </IconButton>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={textAlignCenter}
                  className={getTextAlign().center && "activeTextAlign"}
                >
                  <MdOutlineAlignHorizontalCenter />
                </IconButton>
                <IconButton
                  onClick={textAlignEnd}
                  size="small"
                  color="primary"
                  className={getTextAlign().left && "activeTextAlign"}
                >
                  <MdOutlineAlignHorizontalRight />
                </IconButton>
              </>
            }
          />
        )}
      </Control>
    </>
  );
};

export default Width;
