import React, { useState, useEffect } from "react";
import Pagination from "@mui/material/Pagination";
import { useSearchParams } from "react-router-dom";

const CustomPagination = (props) => {
  const { count, itemsPerPage, onChange, enableParams, queryName, disabled } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(parseInt(searchParams.get(queryName)) || 1);

  ;

  useEffect(() => {
    let pagesCount = count / itemsPerPage;
    pagesCount = Math.ceil(pagesCount);
    setPageCount(pagesCount);
    setPage(parseInt(searchParams.get(queryName)) || 1)
  }, [count]);

  const handleChange = (event, value) => {
    if (onChange) {
      onChange({ limit: itemsPerPage, skip: (value - 1) * itemsPerPage });
    }

    if (enableParams) {
      searchParams.set(queryName, value);
      setPage(parseInt(searchParams.get(queryName)))
      return setSearchParams(searchParams);
    }
  }
  return (
    <>
      {pageCount > 1 && (
        <Pagination
          disabled={disabled}
          size="medium"
          className="pagination"
          color="secondary"
          count={pageCount}
          page={page}
          onChange={handleChange}
        />
      )}
    </>
  );
};

export default React.memo(CustomPagination);

CustomPagination.defaultProps = {
  itemsPerPage: 10,
  enableParams: true,
  query: {},
  queryName: "page",
};
