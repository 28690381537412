import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

//icons
import { VscListUnordered } from "react-icons/vsc";
import { IoEye } from "react-icons/io5";

//reducers
import { setPreiewPresentation } from "src/store/reducers/presentations-slice";

//components
import Review from "src/components/reviews/Review";
import ReviewItem from "src/components/reviews/ReviewItem";

const PresentationReviewTable = () => {
  const type = "presentation";
  const { reviews, count, loadingMsg } = useSelector((state) => state.reviews);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handlePreview = (reviewId, data) => {
    dispatch(
      setPreiewPresentation({
        isOpen: true,
        presentationId: data?._id,
        reviewId,
      })
    );
  };

  const dropDownData = [
    {
      label: "Preview",
      icon: <IoEye />,
      onClick: (reviewId, data) => {
        handlePreview(reviewId, data);
      },
    },
    {
      label: "Details",
      icon: <VscListUnordered />,
      onClick: (id) => {
        navigate(`/reviews/${id}/${type}`);
      },
    },
  ];
  return (
    <Review
      type={type}
      subtitle="Presentation Reviews"
      count={count}
      loadingMsg={loadingMsg}
    >
      {reviews.map((review) => {
        const detail = review.presentationId;
        if (!detail) {
          return <></>;
        }
        const {
          _id,
          name,
          bucketURL,
          thumbnail,
          updatedAt,
          status,
          createdBy: { firstName, lastName },
        } = detail;
        const data = {
          _id,
        };
        return (
          <ReviewItem
            data={data}
            title={name}
            status={review.status}
            key={review._id}
            updatedAt={updatedAt}
            reviewId={review._id}
            dropDown={dropDownData}
            ownerName={`${firstName} ${lastName}`}
            thumbnail={`${bucketURL}/${thumbnail}`}
          />
        );
      })}
    </Review>
  );
};

export default PresentationReviewTable;
