import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../interfaces/api.interface";
import HttpService from "../../services/http.service";
import { uiActions } from "../reducers/ui-slice";

//Accounts
export const createAccount = createAsyncThunk(
  "createAccount",
  async (data, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.campaign.createAccount(),
        data,
        thunkAPI
      );
      if (response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Account created!",
            message: "Account created successfully",
          })
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const fetchAccounts = createAsyncThunk(
  "fetchAccounts",
  async (_, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.campaign.fetchAccounts(),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const fetchAccountById = createAsyncThunk(
  "fetchAccountById",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.campaign.fetchAccountById(id),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const updateAccount = createAsyncThunk(
  "updateAccount",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.campaign.updateAccount(id),
        data,
        thunkAPI
      );
      if (response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Account updated!",
            message: "Account updated successfully",
          })
        );
      }
      return { response: response.data, id };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const deleteAccount = createAsyncThunk(
  "deleteAccount",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.campaign.deleteAccount(id),
        undefined,
        thunkAPI
      );
      if (response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Account deleted!",
            message: "Account deleted successfully",
          })
        );
      }
      return { response: response.data, id };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

//Contacts
export const createContact = createAsyncThunk(
  "createContact",
  async (data, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.campaign.createContact(),
        data,
        thunkAPI
      );
      if (response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Contact created!",
            message: "Contact created successfully",
          })
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const fetchContacts = createAsyncThunk(
  "fetchContacts",
  async (_, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.campaign.fetchContacts(),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const fetchContactById = createAsyncThunk(
  "fetchContactById",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.campaign.fetchContactById(id),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const updateContact = createAsyncThunk(
  "updateContact",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.campaign.updateContact(id),
        data,
        thunkAPI
      );
      if (response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Contact updated!",
            message: "Contact updated successfully",
          })
        );
      }
      return { response: response.data, id };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const deleteContact = createAsyncThunk(
  "deleteContact",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.campaign.deleteContact(id),
        undefined,
        thunkAPI
      );
      if (response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Contact deleted!",
            message: "Contact deleted successfully",
          })
        );
      }
      return { response: response.data, id };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
//uploadContacts
export const uploadContacts = createAsyncThunk(
  "uploadContacts",
  async (data, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.campaign.uploadContacts(),
        data,
        thunkAPI
      );
      if (response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Contact created!",
            message: "Contact created successfully",
          })
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

//filter contacts
export const filterContact = createAsyncThunk(
  "filterContact",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.campaign.filterContact(id),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

//fetch contacts by accounts
export const fetchContactsByAccount = createAsyncThunk(
  "fetchContactsByAccount",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.campaign.fetchContactsByAccount(id),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

//Groups
export const createGroup = createAsyncThunk(
  "createGroup",
  async (data, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.campaign.createGroup(),
        data,
        thunkAPI
      );
      if (response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Group created!",
            message: "Group created successfully",
          })
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const fetchGroups = createAsyncThunk(
  "fetchGroups",
  async (_, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.campaign.fetchGroups(),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const fetchGroupById = createAsyncThunk(
  "fetchGroupById",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.campaign.fetchGroupById(id),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const updateGroup = createAsyncThunk(
  "updateGroup",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.campaign.updateGroup(id),
        data,
        thunkAPI
      );
      if (response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Group updated!",
            message: "Group updated successfully",
          })
        );
      }
      return { response: response.data, id };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const deleteGroup = createAsyncThunk(
  "deleteGroup",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.campaign.deleteGroup(id),
        undefined,
        thunkAPI
      );
      if (response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Group deleted!",
            message: "Group deleted successfully",
          })
        );
      }
      return { response: response.data, id };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

//Campaign
export const createCampaign = createAsyncThunk(
  "createCampaign",
  async (data, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.campaign.createCampaign(),
        data,
        thunkAPI
      );
      if (response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Campaign created!",
            message: "Campaign created successfully",
          })
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }

    return new Promise(function (resolve, reject) {
      thunkAPI.dispatch(uiActions.showLoading());
      setTimeout(() => {
        thunkAPI.dispatch(uiActions.hideLoading());
        resolve({ success: true, id: "my-id" });
      }, 1000);
    });
  }
);
export const fetchCampaigns = createAsyncThunk(
  "fetchCampaigns",
  async (_, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.campaign.fetchCampaigns(),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const fetchCampaignById = createAsyncThunk(
  "fetchCampaignById",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.campaign.fetchCampaignById(id),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const updateCampaign = createAsyncThunk(
  "updateCampaign",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.campaign.updateCampaign(id),
        data,
        thunkAPI
      );
      if (response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Campaign updated!",
            message: "Campaign updated successfully",
          })
        );
      }
      return { response: response.data, id };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const deleteCampaign = createAsyncThunk(
  "deleteCampaign",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.campaign.deleteCampaign(id),
        undefined,
        thunkAPI
      );
      if (response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Campaign deleted!",
            message: "Campaign deleted successfully",
          })
        );
      }
      return { response: response.data, id };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

// Analytics
export const getUsersAnalytics = createAsyncThunk(
  "getUsersAnalytics",
  async ({ campaignId, userId }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.campaign.getUsersAnalytics(campaignId, userId),
        undefined,
        thunkAPI
      );
      return { response: response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getAnalyticsReports = createAsyncThunk(
  "getAnalyticsReports",
  async (campaignId, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.campaign.getAnalyticsReports(campaignId),
        undefined,
        thunkAPI
      );
      return { response: response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
