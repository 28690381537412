import React, { useState } from "react";
import {
  Button,
  Typography,
  Checkbox,
  Radio,
  Box,
  FormControlLabel,
  RadioGroup,
  Tabs,
  Tab,
} from "@mui/material";
import { IoInformationCircle, IoCloseSharp } from "react-icons/io5";
import { RiShareBoxFill } from "react-icons/ri";
import CustomAlert from "src/components/ui/CustomAlert";
import Main from "../Main";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

const ExportEmail = (props) => {
  const { isOpen, handleClose, exportAs, handleExport } = props;
  const [showTab, setShowTab] = useState(0);
  const [checked, setChecked] = useState(false);

  const tabshandleChange = (event, newValue) => {
    setShowTab(newValue);
    setChecked(false);
  };

  const exportEmail = () => {
    if (handleExport) {
      handleExport();
    }
    handleClose();
  };

  return (
    <Main
      background="white"
      modalWidth="md"
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <Box className="exports">
        <Typography variant="h1">Export Email Template</Typography>
        <Box className="horizontal-line"></Box>
        <Tabs
          value={showTab}
          // onChange={tabshandleChange}
          aria-label="wrapped label tabs example"
          variant="scrollable"
          sx={{ marginBottom: "20px" }}
        >
          <Tab value={0} label="Document" />
        </Tabs>

        <TabPanel value={showTab} index={0}>
          <RadioGroup value={exportAs}>
            <FormControlLabel
              className="radiobtn"
              value="pdf"
              name="pdf"
              control={<Radio color="secondary" />}
              label={
                <>
                  <Typography variant="h2" className="radiolabel">
                    Portable Document Format (PDF)
                  </Typography>
                  <Typography>
                    Select this option to export only the presentation itself
                    into a PDF file. The generated file will not contain any
                    comments.
                  </Typography>
                </>
              }
            />
          </RadioGroup>
        </TabPanel>
        <Box className="alertbox">
          <CustomAlert
            action={
              <Button
                variant="contained"
                color="secondary"
                className="alert-btn"
                style={{ textTransform: "none" }}
              >
                View Guide
              </Button>
            }
            icon={<IoInformationCircle color="#2CC1D6" />}
          >
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
            sint. Velit officia consequat.
          </CustomAlert>
        </Box>

        <Box className="iconBox">
          <Button
            startIcon={<IoCloseSharp />}
            className="cancelbtn"
            variant="outlined"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            startIcon={<RiShareBoxFill />}
            className="exportbtn"
            variant="contained"
            onClick={exportEmail}
          >
            Export
          </Button>
        </Box>
      </Box>
    </Main>
  );
};
export default React.memo(ExportEmail);
