import React, { useState } from "react";
import Drawer from "./Drawer";
import TabsComments from "../presentations/comments/TabsComments";
import PermissionComp from "../Permission";
import { useLocation } from "react-router";

const Comments = (props) => {
  const { isOpen, handleClose, id, slideId, type } = props;
  const location = useLocation()

  const getPathName = () => {
    if (location.pathname.includes('email')) {
      return "emailTemplates"
    }
    if (location.pathname.includes('presentation')) {
      return "presentation"
    }
    if (location.pathname.includes('microsite')) {
      return "microsites"
    }
  }

  return (
    <Drawer
      className="preview-comments"
      paperClass="drawer-width"
      open={isOpen}
      onClose={handleClose}
    >
      <PermissionComp permissionPath={`${[getPathName()]}.commentsAndCollaboration`} alert>
        <TabsComments type={type} id={id} slideId={slideId} />
      </PermissionComp>
    </Drawer>
  );
};

export default React.memo(Comments);
