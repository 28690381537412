import React, { useEffect } from "react";
import Content from "src/layouts/Content";
import ContentAction from "src/components/ui/formElements/ContentAction";
import { IoAdd } from "react-icons/io5";
import { Link } from "react-router-dom";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchGroups } from "src/store/actions/campaign";
import ViewGroups from "./ViewGroups";
import "../../../styles/campaign/campaign.scss";

const Groups = () => {
  const dispatch = useDispatch();
  const campaignGroups = useSelector((state) => state.campaign.groups);
  useEffect(() => {
    dispatch(fetchGroups());
  }, [dispatch]);

  return (
    <>
      <Content
        title="Groups"
        headerAction={
          <ContentAction
            text="Create Group"
            variant="contained"
            className="campaignBtn"
            icon={<IoAdd />}
            component={Link}
            to="create"
          />
        }
      ></Content>

      <Grid style={{ mt: 2 }}>
        <Grid item xs={12}>
          <TableContainer component={Paper} className="campaignTable">
            <Table aria-label="simple table" stickyHeader>
              <TableHead className="tableHead">
                <TableRow>
                  <TableCell className="tableHeading">Name</TableCell>
                  <TableCell className="tableHeading">Updated At</TableCell>

                  <TableCell className="tableHeading tableAction">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="tableBody">
                {campaignGroups &&
                  campaignGroups.map((data) => {
                    return (
                      <ViewGroups
                        key={data._id}
                        id={data._id}
                        name={data.name}
                        updatedAt={data.updatedAt}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default Groups;
