import React, { useEffect } from "react";
import Content from "src/layouts/Content";
import ContentAction from "src/components/ui/formElements/ContentAction";
import { IoAdd } from "react-icons/io5";
import { Link } from "react-router-dom";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ViewCampaign from "./ViewCampaign";
import { useDispatch, useSelector } from "react-redux";
import { fetchCampaigns } from "src/store/actions/campaign";
import "../../../styles/campaign/campaign.scss";

const Campaign = () => {
  const dispatch = useDispatch();
  //get all campaigns
  const campaigns = useSelector((state) => state.campaign.campaigns);
  useEffect(() => {
    dispatch(fetchCampaigns());
  }, [dispatch]);

  return (
    <>
      <Content
        title="Campaigns"
        headerAction={
          <>
            <ContentAction
              text="Create Campaign"
              variant="contained"
              className="campaignBtn"
              icon={<IoAdd />}
              component={Link}
              to="/campaign/create"
            />
          </>
        }
      ></Content>

      <Grid style={{ mt: 2 }}>
        <Grid item xs={12}>
          <TableContainer className="campaignTable" component={Paper}>
            <Table aria-label="simple table" stickyHeader>
              <TableHead className="tableHead">
                <TableRow>
                  <TableCell className="tableHeading">Name</TableCell>
                  <TableCell className="tableHeading">Timezone</TableCell>
                  <TableCell className="tableHeading">Date / Days</TableCell>
                  <TableCell className="tableHeading">Time</TableCell>
                  <TableCell className="tableHeading">Status</TableCell>
                  <TableCell className="tableHeading">Update Date</TableCell>         

                  <TableCell className="tableHeading tableAction">
                  Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="tableBody">
                {campaigns &&
                  campaigns.map((data) => {
                    return (
                      <ViewCampaign
                        key={data._id}
                        id={data._id}
                        name={data.name}
                        timezone={data.timezone}
                        updatedAt={data.updatedAt}
                        date={data.date}
                        time={data.time}
                        weekDays={data.weekDays}
                        status={data.status}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};
export default Campaign;
