class csKPI {
  constructor({ _id, name, country, language }) {
    this.presentationId = _id;
    this.presentationName = name;
    this.country = country;
    this.language = language;
    this.summary = [];
    this.details = [];
  }

  //method to calculate time difference
  #timeDiff(startDate, endDate) {
    let getStartDate = new Date(startDate);
    let getEndDate = new Date(endDate);
    let seconds = Math.round(
      (getEndDate.getTime() - getStartDate.getTime()) / 1000
    );
    return seconds;
  }

  //method to sum total time spend on slide from details
  #sumOfSlideTime(slide, chapter) {
    let time = 0;
    let likeDislikeVal = null;
    this.details.forEach((detail) => {
      if (detail.slide === slide && detail.chapter === chapter) {
        time = time + detail.time;
        likeDislikeVal = detail.likeDislikeVal;
      }
    });

    return { time, likeDislikeVal };
  }

  //method to set times in details and summary
  #setTimeDiff() {
    if (!this.details) {
      return false;
    }

    const details = JSON.parse(JSON.stringify(this.details));

    let newDetails = details.map((detail, i) => {
      let nextItemIndex = i + 1;

      //skip last item
      if (nextItemIndex != this.details.length) {
        //on slide change get previous time and current time
        detail.time = this.#timeDiff(
          detail.loadTime,
          this.details[nextItemIndex].loadTime
        );
      }

      //the end time will be current time of first slide and last slide
      if (i == this.details.length - 1) {
        let currentTime = new Date().toLocaleString();
        detail.time = this.#timeDiff(detail.loadTime, currentTime);
      }
      return detail;
    });
    this.details = newDetails;

    //update time and like dislike value in summary
    let newSummary = this.summary.map((element) => {
      const { time, likeDislikeVal } = this.#sumOfSlideTime(
        element.slide,
        element.chapter
      );
      element.time = time;
      element.likeDislikeVal = likeDislikeVal;
      return element;
    });

    this.summary = newSummary;
  }

  setLikeDisLike = (isLiked) => {
    const lastItem = this.details.at(-1);
    if (lastItem) {
      lastItem.likeDislikeVal = isLiked;
    }
  };

  setSlideTimeDuration(slide, chapter, slideId, likeDislikeVal = null) {
    let loadTime = new Date()
    //enteries stored to storage
    const slideObj = { slide, slideId, chapter, loadTime, likeDislikeVal };

    //avoid duplication of same chapter of same slide in summary
    const isExist = this.summary.find(
      (element) => element.slide === slide && element.chapter === chapter
    );

    if (!isExist) {
      this.summary.push(slideObj);
    } else {
      if (isExist.likeDislikeVal !== likeDislikeVal) {
        isExist.likeDislikeVal = likeDislikeVal;
      }
    }

    // //insert details entery
    this.details.push(slideObj);
  }

  //getters
  get data() {
    this.#setTimeDiff();
    return {
      presentationId: this.presentationId,
      presentationName: this.presentationName,
      country: this.country,
      language: this.language,
      summary: this.summary,
      details: this.details,
    };
  }

  get getSummary() {
    let { summary } = this.data;
    return summary;
  }

  get getDetails() {
    let { details } = this.data;
    return details;
  }
}

export default csKPI;
